import { Component, OnInit, ElementRef } from '@angular/core';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ReportModel } from '../../classes/ReportModel.class';
import { ExternalService } from 'src/app/services/external/external.service';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { ReportSettingsViewComponent } from '../report-settings-view/report-settings-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import * as CanvasJS from 'src/assets/js/lib/canvasjs.min';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as $ from 'jquery';


@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.css']
})
export class ReportViewComponent extends AccessViewComponent
{
    public toolbar: Toolbar = {
        viewTitle: 'Rapport',
        class: 'toolbar-big',
        data: this,
        elements: [
            { type: 'separator' },
            {
                type: 'button',
                text: 'Exporter<br/>vers Excel',
                icon: 'file-excel',
                click: (view: ReportViewComponent) => {
                    view.exportToExcel();
                }
            },
            { type: 'separator' },
            {
                type: 'button',
                text: 'Paramètres<br/>du rapport',
                icon: 'cog',
                click: (view: ReportViewComponent) => {
                    view.showSettings();
                }
            },
            { type: 'spacing' }
        ]
    };

    private amcharts: any[] = [];

    public models: { [name: string]: ReportModel } = {};
    public selectedModel: string = null;
    public get currentModel() { return this.selectedModel ? this.models[this.selectedModel] : null; }

    initView(models: { [name: string]: ReportModel })
    {
        this.models = models;
        if (this.modelNames.length > 0) this.selectModel(this.modelNames[0]);
    }

    selectModel(name: string)
    {
        let model: ReportModel = this.models[name];
        if (model)
        {
            console.log('regenerating model...');
            model.regenerate().then(
                (result) => {
                    console.log('regenerated!');
                    if (model.title) this.toolbar.viewTitle = model.title;
                    this.selectedModel = name;
                    console.log('currentMode:', this.currentModel);
                    this.regenerateChart();
                },
                (err) => { console.error(err); }
            );
        }
    }
    public get model()
    {
        return this.models[this.selectedModel];
    }
    public get modelNames()
    {
        return Object.keys(this.models);
    }

    public regenerateChart()
    {
        console.log('regenerate chart');
        if (this.currentModel && this.currentModel.charts) {
            for(let i=0; i<this.currentModel.charts.length; ++i) {
                // if (this.amchart) this.amchart.dispose();
                this.currentModel.charts[i].exporting = {
                    "menu": {
                        "items": [{
                            "label": "...",
                            "menu": [
                                {"type": "jpg", "label": "Enregistrer l'image ..."},
                                // { "type": "json", "label": "JSON" },
                                {"label": "Imprimer", "type": "print"}
                            ]
                        }]
                    }
                };
                // this.amcharts.push(am4core.createFromConfig(this.currentModel.charts[i], "chartContainer"+i, this.currentModel.charts[i].type));
            }
        }
        setTimeout(() => {
            console.log('resettings amcharts divs');
            for(let i=0; i<this.currentModel.charts.length; ++i) {
                console.log($('#chartContainer'+i));
                this.amcharts.push(am4core.createFromConfig(this.currentModel.charts[i], "chartContainer" + i, this.currentModel.charts[i].type));
            }
        }, 0);
    }

    exportToExcel()
    {
        ExternalService.call('exportToExcel', {
            workbooks: [
                {
                    sheets: [
                        {
                            name: 'Rapport',
                            headerRows: this.model.headerRows,
                            rows: this.model.rows
                        }
                    ]
                }
            ]
        });
    }

    showSettings()
    {
        if (this.model && this.model.settings)
        {
            DialogsComponent.displayComponent(ReportSettingsViewComponent, {
                settings: ArrayUtil.cloneArray(this.model.settings, true, false)
            }).then(
                (result: any) => {
                    if (Array.isArray(result)) this.model.settings = ArrayUtil.cloneArray(result, true, false);
                    this.model.regenerate().then(
                        (result) => {
                            // if (this.model.title) this.toolbar.viewTitle = this.model.title;
                            // this.selectedModel = name;
                            this.regenerateChart();
                        },
                        (err) => { console.error(err); }
                    );
                }
            );
        }
    }
}
