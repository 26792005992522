import { Accounting } from '../../other-data/classes/Accounting.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';

export class Incident extends ObjectModel3 {
  public accounting: Accounting = null;
  public date: string = null;
  public last_update: string = null;
  public order: Order = null;
  public supplier: Supplier = null;

  public quantity: number = null;
  public unit: string = null;

  public amount: number = null;
  public description: string = null;
  public internal_notes: string = null;

  public closed: number = 0;

  constructor() {
    super(Incident);
  }

  public get quantityAndUnit() {
    let arr: string[] = [];
    if (this.quantity) arr.push('' + this.quantity);
    if (this.unit) arr.push(this.unit);
    return arr.join(' ');
  }

  public get sale() {
    return this.order ? this.order.sale : null;
  }
  public get customer() {
    return this.order ? this.order.customer : null;
  }
  public get article() {
    return this.order ? this.order.article : null;
  }

  /* ObjectModel FUNCTIONS */

  // public static instances: { [id: number]: Address; } = {};

  public static definition: ObjectDefinition = {
    database: {
      table: 'incidents',
      id: 'id',
    },
    values: {
      date: { type: 'string' },
      last_update: { type: 'string' },
      quantity: { type: 'number' },
      unit: { type: 'string' },
      amount: { type: 'number' },
      description: { type: 'string' },
      internal_notes: { type: 'string' },
      closed: { type: 'number' },
    },
    children: {
      accounting: {
        type: 'Accounting',
        clone: false,
        save: false,
        delete: false,
      },
      order: { type: 'Order', clone: false, save: false, delete: false },
      supplier: { type: 'Supplier', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
