import { Component, OnInit } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { ParentStock } from '../../classes/ParentStock.class';
import { ParentStockViewComponent } from '../parent-stock-view/parent-stock-view.component';
import { StockViewComponent } from '../stock-view/stock-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-stocks-list-view',
  templateUrl: './parent-stocks-list-view.component.html',
  styleUrls: ['./parent-stocks-list-view.component.css'],
})
export class ParentStocksListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_listeparents';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Listes des stocks parents',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Ajouter un<br/>stock parent',
        icon: 'plus',
        click: function (view: ParentStocksListViewComponent) {
          view.createParentStock();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: ParentStocksListViewComponent) {
          view.deleteStocks();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public parentStocks: ParentStock[] = [];
  public parentStocksGridColumns = [
    { title: 'Date', field: 'creation_date', type: 'date', width: 80 },
    { title: 'Désignation', field: 'article.designation', width: 400 },
    // { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Quantité', field: 'quantityAndUnit', width: 200 },
    // { title: 'Commande', field: 'order.number' }
    {
      title: "Prix d'achat négocié",
      type: 'number',
      field: 'buy_price',
      decimalsCount: 5,
      unit: ' € H.T.V.A.',
      textAlign: 'left',
      width: 150,
    },
  ];

  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.stockDblClick(event, item);
      },
    },
  ];

  ngOnInit() {
    this.loadStocks();
  }
  onAccountingChanged(accounting: Accounting) {
    this.loadStocks();
  }
  onActivate() {
    this.loadStocks();
  }

  loadStocks() {
    let conditions: string = null;
    if (AccountingsService.currentAccounting) conditions = `id_accounting='${AccountingsService.currentAccounting.id}'`;
    ParentStock.load(null, ['~creation_date'], null, null, conditions).then(
      (result) => {
        this.parentStocks = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createParentStock() {
    ViewsComponent.openView(ParentStockViewComponent, null);
  }

  deleteStocks() {
    /*let demands: PriceDemand[] = this.grid.selectedItems as PriceDemand[];
    if (Array.isArray(demands) && demands.length > 0)
    {
        let promises: any[] = [];
        for(let i=0; i<demands.length; ++i) promises.push(demands[i].delete());
        Promise.all(promises).then(
            (result) => {
                NotificationsComponent.push({
                    type: 'success',
                    title: 'Demandes supprimées',
                    summary: 'Les demandes de prix ont été supprimées avec succès'
                });
                ArrayUtil.removeElements(this.demands, demands);
            },
            (err) => {
                NotificationsComponent.push({
                    type: 'error',
                    title: 'Erreur lors de la suppression',
                    summary: 'Une erreur s\'est produite lors de la suppression',
                    content: err
                });
                console.error(err);
            }
        )
    }*/
  }

  stockDblClick(event, stock) {
    ViewsComponent.openView(ParentStockViewComponent, stock);
  }
}
