import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {

    public notes: string[] = [
        'Première note',
        'Ignorer la troisième note pour le client :-)'
    ];

    constructor() { }

    ngOnInit() {
    }

}
