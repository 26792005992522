import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Postcode extends ObjectModel3 {
  public postcode: string = null;
  public city: string = null;

  public constructor() {
    super(Postcode);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'postcodes',
      id: 'id',
    },
    values: {
      postcode: { type: 'string' },
      city: { type: 'string' },
    },
    children: {},
    links: {},
  };
}
