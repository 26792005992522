import { Component, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from '../../classes/Accounting.class';
import { ApiService } from '../../../../services/api/api.service';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { ChargeType } from 'src/app/modules/charges/classes/ChargeType.class';
import { Country } from '../../classes/Country.class';
import { CredentialsService } from '../../../../services/credentials/credentials.service';
import { Currency } from '../../classes/Currency.class';
import { CustomerCategory } from '../../../customers/classes/CustomerCategory.class';
import { CustomerReference } from '../../classes/CustomerReference.class';
import { CustomersNumbering } from '../../classes/CustomersNumbering.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { Deadline } from '../../classes/Deadline.class';
import { Delivery } from '../../classes/Delivery.class';
import { DeliveryTime } from '../../classes/DeliveryTime.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Discount } from '../../classes/Discount.class';
import { ExternalService } from '../../../../services/external/external.service';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { LabelName } from '../../classes/LabelName.class';
import { Lang } from '../../classes/Lang.class';
import { Merchant } from '../../classes/Merchant.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { ObjectModel2 } from 'src/app/classes/objects/ObjectModel2.class';
import { PaymentStatus } from 'src/app/modules/payments/classes/PaymentStatus.class';
import { Permission } from 'src/app/classes/credentials/Permission.class';
import { Postcode } from '../../classes/Postcode.class';
import { ReminderType } from 'src/app/modules/payments/classes/ReminderType.class';
import { RollingDirection } from '../../classes/RollingDirection.class';
import { Signature } from '../../classes/Signature';
import { SupplierCategory } from '../../../suppliers/classes/SupplierCategory.class';
import { SuppliersNumbering } from '../../classes/SuppliersNumbering.class';
import { TabsetComponent } from '../../../../../../node_modules/ngx-bootstrap';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { User } from 'src/app/classes/credentials/User.class';
import { UserProfile } from 'src/app/classes/credentials/UserProfile.class';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-other-data-view',
  templateUrl: './other-data-view.component.html',
  styleUrls: ['./other-data-view.component.css'],
})
export class OtherDataViewComponent extends AccessViewComponent {
  public buttonsOptions: any = {
    merchants: { visible: true },
    customers: { visible: false },
    addresses: { visible: false },
    billing: { visible: false },
    delivery: { visible: false },
  };

  @ViewChild('permissionsGrid') permissionsGrid: DataGridComponent;
  @ViewChild('usersGrid') usersGrid: DataGridComponent;
  @ViewChild('userProfilesGrid') userProfilesGrid: DataGridComponent;
  @ViewChild('merchantsGrid') merchantsGrid: DataGridComponent;
  @ViewChild('customerCategoriesGrid')
  customerCategoriesGrid: DataGridComponent;
  @ViewChild('supplierCategoriesGrid')
  supplierCategoriesGrid: DataGridComponent;
  @ViewChild('langsGrid') langsGrid: DataGridComponent;
  @ViewChild('customerReferencesGrid')
  customerReferencesGrid: DataGridComponent;
  @ViewChild('customersNumberingsGrid')
  customersNumberingsGrid: DataGridComponent;
  @ViewChild('suppliersNumberingsGrid')
  suppliersNumberingsGrid: DataGridComponent;
  @ViewChild('postcodesGrid') postcodesGrid: DataGridComponent;
  @ViewChild('countriesGrid') countriesGrid: DataGridComponent;
  @ViewChild('deadlinesGrid') deadlinesGrid: DataGridComponent;
  @ViewChild('discountsGrid') discountsGrid: DataGridComponent;
  @ViewChild('labelnamesGrid') labelnamesGrid: DataGridComponent;
  @ViewChild('paymentStatusesGrid') paymentStatusesGrid: DataGridComponent;
  @ViewChild('remindersGrid') remindersGrid: DataGridComponent;
  @ViewChild('deliveriesGrid') deliveriesGrid: DataGridComponent;
  @ViewChild('deliverytimesGrid') deliverytimesGrid: DataGridComponent;
  @ViewChild('rollingsGrid') rollingsGrid: DataGridComponent;
  @ViewChild('chargesTypesGrid') chargesTypesGrid: DataGridComponent;
  @ViewChild('accountingsGrid') accountingsGrid: DataGridComponent;
  @ViewChild('currenciesGrid') currenciesGrid: DataGridComponent;

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Autres données',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: OtherDataViewComponent) {
          view.save();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        name: 'resetPasswordButton',
        text: 'Réinitialiser le<br/>mot de passe',
        icon: 'key',
        visible: false,
        click: function (view: OtherDataViewComponent) {
          if (view.usersGrid.selectedItems.length == 0)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Aucun utilisateur sélectionné',
              summary: "Veuillez d'abord sélectionner un ou plusieurs utilisateurs",
            });
          else
            DialogsComponent.display({
              title: 'Réinitialiser le mot de passe',
              message:
                'Êtes vous sûr(e) de vouloir réinitialiser le mot de passe pour les <strong>' +
                view.usersGrid.selectedItems.length +
                ' utilisateurs</strong> sélectionnés ?',
              icon: 'warning',
              buttons: DialogButton.yesNoButtons,
            }).then((result) => {
              if (result === DialogButton.RESULT_YES) {
                let ids: string[] = view.usersGrid.selectedItems.map((value) => value.id);
                ApiService.callModule('users', 'resetPassword', {
                  ids: ids,
                }).then(
                  (result) => {
                    if (result.details && result.details.length === ids.length) {
                      let items: string[] = [];
                      for (let i = 0; i < result.details.length; ++i) {
                        let url: string = config.baseUrl + 'reset_pwd.php?reset_key=' + result.details[i];
                        items.push(
                          '<li><strong>' +
                            view.usersGrid.selectedItems[i].name +
                            ' : </strong>' +
                            '<a target="_blank" href="' +
                            url +
                            '">' +
                            url +
                            '</a></li>'
                        );
                      }
                      DialogsComponent.display({
                        title: 'Réinitialisation du mot de passe',
                        message:
                          '<p>Les mots de passe ont été réinitialisés.<br/>Voici les liens pour créer les nouveaux mots de passe :</p>' +
                          '<ul>' +
                          items.join('') +
                          '</ul>' +
                          (ExternalService.available
                            ? '<p>Voulez-vous créer les e-mails pour envoyer ces liens ?</p>'
                            : ''),
                        icon: 'info',
                        buttons: ExternalService.available ? DialogButton.yesNoButtons : DialogButton.okOnlyButtons,
                      }).then((result2) => {
                        if (result2 === DialogButton.RESULT_YES) {
                          for (let i = 0; i < result.details.length; ++i) {
                            let url: string = config.baseUrl + 'reset_pwd.php?reset_key=' + result.details[i];
                            ExternalService.createMail(
                              '',
                              null,
                              null,
                              'Réinitialisation du mot de passe',
                              '<p>Bonjour,</p><p>Votre mot de passe a été réinitialisé.<br/>Veuillez suivre le lien indiquer pour créer un nouveau mot de passe.</p>' +
                                '<p>Utilisateur : <strong>' +
                                view.usersGrid.selectedItems[i].name +
                                '</strong><br/>Lien : <a target="_blank" href="' +
                                url +
                                '">' +
                                url +
                                '</a></p><p>Cordialement,</p>',
                              null,
                              CredentialsService.loggedMerchant.outlook_signature
                            );
                          }
                        }
                      });
                    } else {
                      NotificationsComponent.push({
                        type: 'error',
                        title: 'Erreur',
                        summary: 'Erreur lors de la réinitialisation du mot de passe',
                      });
                    }
                  },
                  (err) => {
                    NotificationsComponent.push({
                      type: 'error',
                      title: 'Erreur',
                      summary: 'Erreur lors de la réinitialisation du mot de passe',
                    });
                    console.error(err);
                  }
                );
              }
            });
        },
      },
      { type: 'spacing' },
    ],
  };

  public permissionsData: any = { items: [] };
  public chargesTypesData: any = { items: [] };
  public userProfilesData: any = { items: [] };
  public usersData: any = { items: [] };

  public permissions: Permission[] = [];
  public permissionsGridColumns = [
    { title: 'Nom', field: 'name', width: 300 },
    { title: 'Description', field: 'description', width: 300 },
    {
      title: 'Utilisateurs',
      field: 'allowedUsers',
      type: 'foreign-list',
      multiSelect: true,
      listItems: this.usersData,
      listField: 'name',
      nullText: '(Aucun)',
      width: 300,
    },
    {
      title: 'Profils',
      field: 'allowedProfiles',
      type: 'foreign-list',
      multiSelect: true,
      listItems: this.userProfilesData,
      listField: 'name',
      nullText: '(Aucun)',
      width: 300,
    },
  ];
  public permissionsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.permissionsGrid.addItems(Permission);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('permissions');
      },
    },
  ];
  public userProfiles: UserProfile[] = [];
  public userProfilesGridColumns = [
    { title: 'Nom', field: 'name', width: 300 },
    { title: 'Administrateur', field: 'admin', type: 'checkbox' },
  ];
  public userProfilesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.userProfilesGrid.addItems(UserProfile);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('userProfiles');
      },
    },
  ];
  public users: User[] = [];
  public usersGridColumns = [
    { title: "Nom d'utilisateur", field: 'username' },
    { title: 'Nom', field: 'name', width: 300 },
    { title: 'Administrateur', field: 'admin', type: 'checkbox' },
    {
      title: 'Profil',
      field: 'profile',
      type: 'foreign-list',
      multiSelect: false,
      listItems: this.userProfilesData,
      listField: 'name',
      nullText: '(Aucun)',
    },
  ];
  public usersGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.usersGrid.addItems(User);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('users');
      },
    },
  ];

  public merchants: Merchant[] = [];
  public merchantsGridColumns = [
    { title: 'Nom', field: 'name' },
    { title: 'Numéro', field: 'number' },
    { title: 'Employé ?', field: 'is_employee', type: 'checkbox' },
    {
      title: 'Marge',
      field: 'margin',
      type: 'number',
      unit: '%',
      decimalsCount: 1,
    },
    {
      title: 'Coût fixe',
      field: 'base',
      type: 'number',
      unit: '€',
      decimalsCount: 2,
    },
    {
      title: 'Base commission\n(employés)',
      field: 'start_margin',
      type: 'number',
      unit: '€',
      decimalsCount: 2,
    },
    { title: 'E-mail', field: 'email' },
    { title: 'Téléphone', field: 'phone' },
    { title: 'Couleur', field: 'color', type: 'color' },
    {
      title: 'Utilisateur',
      field: 'user',
      type: 'foreign-list',
      multiSelect: false,
      listItems: this.usersData,
      listField: 'name',
      nullText: '(Aucun)',
      allowNull: true,
    },
    { title: 'Signature Outlook', field: 'outlook_signature' },
  ];
  public merchantsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.merchantsGrid.addItems(Merchant);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('merchants');
      },
    },
    {
      iconClass: 'fa fa-archive',
      onClick: (event) => {
        this.archive('merchants');
      },
    },
  ];
  public signatures: Signature[] = [];
  public signatureBlock: InfoBlock = {
    title: 'Signature',
    fields: [
      { title: 'Nom', field: 'name' },
      { title: 'Service', field: 'service' },
      { title: 'Signature', field: 'picture', type: 'image' },
    ],
  };
  public selectedSignatures: Signature[] = [];
  public addSignature() {
    this.signatures.push(new Signature());
  }
  public deleteSignatures() {}
  public signaturesSelectionChanged(signature: Signature = null) {
    this.selectedSignatures.splice(0);
    for (let i = 0; i < this.signatures.length; ++i) {
      let sign = this.signatures[i];
      if (sign.selected === true) this.selectedSignatures.push(sign);
    }
  }

  public customer_categories: CustomerCategory[] = [];
  public supplier_categories: SupplierCategory[] = [];
  public categoriesGridColumns = [
    { title: 'Catégorie', field: 'name' },
    { title: 'Jours pour suivi', field: 'visit_days' },
  ];
  public customerCategoriesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.customerCategoriesGrid.addItems(CustomerCategory);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('customer_categories');
      },
    },
  ];
  public supplierCategoriesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.supplierCategoriesGrid.addItems(User);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('supplier_categories');
      },
    },
  ];
  public langs: Lang[] = [];
  public langsGridColumns = [
    { title: 'Code', field: 'code' },
    { title: 'Langue', field: 'name' },
  ];
  public langsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.langsGrid.addItems(Lang);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('langs');
      },
    },
  ];
  public customerReferences: CustomerReference[] = [];
  public customerReferencesGridColumns = [{ title: 'Nom', field: 'name' }];
  public customerReferencesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.customerReferencesGrid.addItems(CustomerReference);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('customer_references');
      },
    },
  ];
  public customersNumberings: CustomersNumbering[] = [];
  public customersNumberingsGridColumns = [{ title: 'Préfixe', field: 'country' }];
  public customersNumberingsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.customersNumberingsGrid.addItems(CustomersNumbering);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('customers_numbering');
      },
    },
  ];
  public suppliersNumberings: SuppliersNumbering[] = [];
  public suppliersNumberingsGridColumns = [{ title: 'Préfixe', field: 'country' }];
  public suppliersNumberingsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.suppliersNumberingsGrid.addItems(SuppliersNumbering);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('suppliers_numbering');
      },
    },
  ];

  public postcodes: Postcode[] = [];
  public postcodesGridColumns = [
    { title: 'Code postal', field: 'postcode' },
    { title: 'Commune', field: 'city' },
  ];
  public postcodesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.postcodesGrid.addItems(Postcode);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('postcodes');
      },
    },
  ];

  public countries: Country[] = [];
  public countriesGridColumns = [
    { title: 'Nom', field: 'name' },
    { title: 'Code ISO', field: 'iso_code' },
  ];
  public countriesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.countriesGrid.addItems(Country);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('countries');
      },
    },
  ];

  public deadlines: Deadline[] = [];
  public deadlinesGridColumns = [{ title: 'Nom', field: 'name' }];
  public deadlinesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.deadlinesGrid.addItems(Deadline);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('deadlines');
      },
    },
  ];

  public discounts: Discount[] = [];
  public discountsGridColumns = [
    { title: 'Nom', field: 'name' },
    { title: 'Pourcentage', field: 'percent' },
  ];
  public discountsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.discountsGrid.addItems(Discount);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('discounts');
      },
    },
  ];

  public label_names: LabelName[] = [];
  public label_namesGridColumns = [{ title: 'Nom', field: 'name' }];
  public label_namesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.labelnamesGrid.addItems(LabelName);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('label_names');
      },
    },
  ];

  public paymentStatuses: PaymentStatus[] = [];
  public paymentStatusesGridColumns = [
    { title: 'Nom', field: 'name' },
    { title: 'Terminé', field: 'ended', type: 'checkbox' },
    { title: 'Payé', field: 'paid', type: 'checkbox' },
    { title: 'A réclamer', field: 'to_claim', type: 'checkbox' },
  ];
  public paymentStatusesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.paymentStatusesGrid.addItems(PaymentStatus);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('paymentStatuses');
      },
    },
  ];

  public reminders: ReminderType[] = [];
  public remindersGridColumns = [
    { title: 'Nom FR', field: 'name' },
    { title: 'Nom EN', field: 'name_en' },
    { title: 'Nom NL', field: 'name_nl' },
    { title: 'Couleur de fond', field: 'back_color', type: 'color' },
    { title: 'Couleur du texte', field: 'text_color', type: 'color' },
  ];
  public remindersGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.remindersGrid.addItems(ReminderType);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('reminders');
      },
    },
  ];

  public deliveries: Delivery[] = [];
  public deliveriesGridColumns = [{ title: 'Description', field: 'description' }];
  public deliveriesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.deliveriesGrid.addItems(Delivery);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('deliveries');
      },
    },
  ];

  public delivery_times: DeliveryTime[] = [];
  public delivery_timesGridColumns = [{ title: 'Description', field: 'name' }];
  public delivery_timesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.deliverytimesGrid.addItems(DeliveryTime);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('delivery_times');
      },
    },
  ];

  public rollings: RollingDirection[] = [];
  public rollingsGridColumns = [{ title: 'Description', field: 'name' }];
  public rollingsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.rollingsGrid.addItems(RollingDirection);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('rollings');
      },
    },
  ];

  public chargesTypes: ChargeType[] = [];
  public accountings: Accounting[] = [];
  public currencies: Currency[] = [];
  public chargesTypesGridColumns = [
    { title: 'Nom', field: 'name' },
    {
      title: 'TVA par défaut',
      field: 'default_vat',
      type: 'number',
      unit: '%',
      decimalsCount: 1,
    },
  ];
  public accountingsGridColumns = [
    { title: 'Nom', field: 'name' },
    { title: 'Numéro', field: 'number', type: 'number', decimalsCount: 0 },
    { title: 'Numéro TVA', field: 'vat_number' },
    { title: 'Adresse', field: 'address' },
    { title: 'Téléphone', field: 'phone_number' },
    { title: 'Code du\ncompte BOB', field: 'general_account' },
    {
      title: 'Code journal\nde vente BOB\n(commercial)',
      field: 'commercial_sales_journal',
    },
    {
      title: 'Code journal\nde vente BOB\n(logistique)',
      field: 'logistics_sales_journal',
    },
    {
      title: 'Code journal\nde N.C. BOB\n(commercial)',
      field: 'commercial_credit_journal',
    },
    {
      title: 'Code journal\nde N.C. BOB\n(logistique)',
      field: 'logistics_credit_journal',
    },
    { title: 'Compte vente\nmarchandises BOB', field: 'sales_account' },
    { title: 'Compte notes\nde crédit BOB', field: 'credit_account' },
    { title: "Code journal\nd'achats BOB", field: 'purchases_journal' },
    { title: 'Compte achat\nmarchandises BOB', field: 'purchases_account' },
  ];
  public currenciesGridColumns = [
    { title: 'Symbole', field: 'symbol', width: 50 },
    { title: 'Abbréviation', field: 'name', width: 50 },
    { title: 'Nom', field: 'description', width: 80 },
    {
      title: 'Unité au singulier\n(voir notes)',
      field: 'singular_units',
      width: 120,
    },
    {
      title: 'Unité au pluriel\n(voir notes)',
      field: 'plural_units',
      width: 120,
    },
    {
      title: 'Centimes au singulier\n(voir notes)',
      field: 'singular_cents',
      width: 120,
    },
    {
      title: 'Centimes au pluriel\n(voir notes)',
      field: 'plural_cents',
      width: 120,
    },
    {
      title: 'Taux de change',
      field: 'rate',
      type: 'number',
      decimalsCount: 6,
      width: 80,
    },
    { title: 'Date', field: 'date', type: 'date', width: 80 },
  ];
  public chargesTypesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.chargesTypesGrid.addItems(ChargeType);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('chargesTypes');
      },
    },
  ];
  public accountingsGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.accountingsGrid.addItems(Accounting);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('accountings');
      },
    },
  ];
  public currenciesGridActions: any[] = [
    {
      iconClass: 'fa fa-plus',
      onClick: (event) => {
        this.currenciesGrid.addItems(Currency);
      },
    },
    {
      iconClass: 'fa fa-trash',
      onClick: (event) => {
        this.delete('currencies');
      },
    },
  ];

  @ViewChild('dataTabs') dataTabs: TabsetComponent;
  public selectedTab: string = 'merchants';
  @ViewChildren('grids') grids: QueryList<DataGridComponent>;

  ngOnInit() {
    let self = this;
    Permission.load(null, ['name']).then(
      function (data: Permission[]) {
        self.permissions = data;
        self.updateView();
      },
      function (err) {
        console.error('error loading user profiles:', err);
      }
    );
    UserProfile.load(null, ['name']).then(
      function (data: UserProfile[]) {
        self.userProfiles = data;
        self.updateView();
      },
      function (err) {
        console.error('error loading user profiles:', err);
      }
    );
    User.load(null, ['name']).then(
      function (data: User[]) {
        self.users = data;
        self.updateView();
      },
      function (err) {
        console.error('error loading users:', err);
      }
    );
    Signature.load(null, ['name']).then(
      (data: Signature[]) => {
        this.signatures = data;
      },
      (err) => {
        console.error('error loading signatures:', err);
      }
    );
    Merchant.load(null, ['number']).then(
      function (data: Merchant[]) {
        self.merchants = data;
      },
      function (err) {
        console.error('error loading merchants:', err);
      }
    );
    CustomerCategory.load(null, ['name']).then(
      function (data: CustomerCategory[]) {
        self.customer_categories = data;
      },
      function (err) {
        console.error('error loading categories:', err);
      }
    );
    SupplierCategory.load(null, ['name']).then(
      function (data: SupplierCategory[]) {
        self.supplier_categories = data;
      },
      function (err) {
        console.error('error loading suppliers categories:', err);
      }
    );
    Lang.load(null, ['code']).then(
      function (data: Lang[]) {
        self.langs = data;
      },
      function (err) {
        console.error('error loading langs:', err);
      }
    );
    CustomerReference.load(null, ['name']).then(
      function (data: CustomerReference[]) {
        self.customerReferences = data;
      },
      function (err) {
        console.error('error loading customerReferences:', err);
      }
    );
    CustomersNumbering.load(null, ['country']).then(
      function (data: CustomersNumbering[]) {
        self.customersNumberings = data;
      },
      function (err) {
        console.error('error loading customersNumberings:', err);
      }
    );
    SuppliersNumbering.load(null, ['country']).then(
      function (data: SuppliersNumbering[]) {
        self.suppliersNumberings = data;
      },
      function (err) {
        console.error('error loading suppliersNumberings:', err);
      }
    );
    Postcode.load(null, ['postcode']).then(
      function (data: Postcode[]) {
        self.postcodes = data;
      },
      function (err) {
        console.error('error loading postcodes:', err);
      }
    );
    Country.load(null, ['name']).then(
      function (data: Country[]) {
        self.countries = data;
      },
      function (err) {
        console.error('error loading countries:', err);
      }
    );
    Deadline.load(null, ['name']).then(
      function (data: Deadline[]) {
        self.deadlines = data;
      },
      function (err) {
        console.error('error loading deadlines:', err);
      }
    );
    LabelName.load(null, ['name']).then(
      function (data: LabelName[]) {
        self.label_names = data;
      },
      function (err) {
        console.error('error loading label_names:', err);
      }
    );
    PaymentStatus.load(null, ['name']).then(
      function (data: PaymentStatus[]) {
        self.paymentStatuses = data;
      },
      function (err) {
        console.error('error loading payment statuses:', err);
      }
    );
    ReminderType.load().then(
      function (data: ReminderType[]) {
        self.reminders = data;
      },
      function (err) {
        console.error('error loading reminder types:', err);
      }
    );
    Discount.load(null, ['name']).then(
      function (data: Discount[]) {
        self.discounts = data;
      },
      function (err) {
        console.error('error loading discounts:', err);
      }
    );
    Delivery.load(null, ['description']).then(
      function (data: Delivery[]) {
        self.deliveries = data;
      },
      function (err) {
        console.error('error loading deliveries:', err);
      }
    );
    DeliveryTime.load(null, ['name']).then(
      function (data: DeliveryTime[]) {
        self.delivery_times = data;
      },
      function (err) {
        console.error('error loading delivery_times:', err);
      }
    );
    RollingDirection.load(null, ['name']).then(
      function (data: DeliveryTime[]) {
        self.rollings = data;
      },
      function (err) {
        console.error('error loading rollings:', err);
      }
    );
    ChargeType.load(null, ['name']).then(
      function (data: ChargeType[]) {
        self.chargesTypes = data;
      },
      function (err) {
        console.error('error loading charges types:', err);
      }
    );
    Accounting.load(null, ['number']).then(
      function (data: Accounting[]) {
        self.accountings = data;
      },
      function (err) {
        console.error('error loading accountings:', err);
      }
    );
    Currency.load(null, ['symbol']).then(
      function (data: Currency[]) {
        self.currencies = data;
      },
      function (err) {
        console.error('error loading currencies:', err);
      }
    );
  }

  setSelectedTab(tabName: string) {
    this.selectedTab = tabName;
    setTimeout(() => {
      let grids = this.grids.toArray();
      for (let i = 0; i < grids.length; ++i) grids[i].resizeTableBody();
      Toolbar.getToolbarItem(this.toolbar, 'resetPasswordButton').visible =
        tabName === 'users' && CredentialsService.isUserAllowed('reinitialiser_mot_de_passe');
    }, 0);
  }

  updateView() {
    this.permissionsData.items = this.permissions;
    this.userProfilesData.items = this.userProfiles;
    this.usersData.items = this.users;
  }

  save() {
    let promises: Promise<any>[] = [];
    let seq: number = ++ObjectModel2.nextSequence;
    // switch(this.selectedTab)
    // {

    // case 'users':
    if (this.permissions == null) console.log('permissions not loaded or null');
    else {
      for (let i = 0; i < this.permissions.length; ++i)
        if (this.permissions[i].changed === true) promises.push(this.permissions[i].save2(seq));
    }
    if (this.userProfiles == null) console.log('userProfiles not loaded or null');
    else {
      for (let i = 0; i < this.userProfiles.length; ++i)
        if (this.userProfiles[i].changed === true) promises.push(this.userProfiles[i].save2(seq));
    }
    if (this.users == null) console.log('users not loaded or null');
    else {
      for (let i = 0; i < this.users.length; ++i)
        if (this.users[i].changed === true) promises.push(this.users[i].save2(seq));
    }
    //     break;

    // case 'merchants':
    if (this.merchants == null) console.log('merchants not loaded or null');
    else {
      for (let i = 0; i < this.merchants.length; ++i)
        if (this.merchants[i].changed === true) promises.push(this.merchants[i].save2(seq));
    }
    if (this.signatures == null) console.log('signatures not loaded or null');
    else {
      for (let i = 0; i < this.signatures.length; ++i)
        if (this.signatures[i].changed === true) promises.push(this.signatures[i].save2(seq));
    }
    //     break;

    // case 'customers':
    if (this.customer_categories == null) console.log('categories not loaded or null');
    else {
      for (let i = 0; i < this.customer_categories.length; ++i)
        if (this.customer_categories[i].changed === true) promises.push(this.customer_categories[i].save2(seq));
    }
    if (this.supplier_categories == null) console.log('suppl categories not loaded or null');
    else {
      for (let i = 0; i < this.supplier_categories.length; ++i)
        if (this.supplier_categories[i].changed === true) promises.push(this.supplier_categories[i].save2(seq));
    }
    if (this.langs == null) console.log('langs not loaded or null');
    else {
      for (let i = 0; i < this.langs.length; ++i)
        if (this.langs[i].changed === true) promises.push(this.langs[i].save2(seq));
    }
    if (this.customerReferences == null) console.log('customerReferences not loaded or null');
    else {
      for (let i = 0; i < this.customerReferences.length; ++i)
        if (this.customerReferences[i].changed === true) promises.push(this.customerReferences[i].save2(seq));
    }
    if (this.customersNumberings == null) console.log('customersNumberings not loaded or null');
    else {
      for (let i = 0; i < this.customersNumberings.length; ++i)
        if (this.customersNumberings[i].changed === true) promises.push(this.customersNumberings[i].save2(seq));
    }
    if (this.suppliersNumberings == null) console.log('suppliersNumberings not loaded or null');
    else {
      for (let i = 0; i < this.suppliersNumberings.length; ++i)
        if (this.suppliersNumberings[i].changed === true) promises.push(this.suppliersNumberings[i].save2(seq));
    }
    //     break;

    // case 'addresses':
    if (this.postcodes == null) console.log('postcodes not loaded or null');
    else {
      for (let i = 0; i < this.postcodes.length; ++i)
        if (this.postcodes[i].changed === true) promises.push(this.postcodes[i].save2(seq));
    }
    if (this.countries == null) console.log('countries not loaded or null');
    else {
      for (let i = 0; i < this.countries.length; ++i)
        if (this.countries[i].changed === true) promises.push(this.countries[i].save2(seq));
    }
    //     break;

    // case 'billing':
    if (this.deadlines == null) console.log('deadlines not loaded or null');
    else {
      for (let i = 0; i < this.deadlines.length; ++i)
        if (this.deadlines[i].changed === true) promises.push(this.deadlines[i].save2(seq));
    }
    if (this.label_names == null) console.log('label_names not loaded or null');
    else {
      for (let i = 0; i < this.label_names.length; ++i)
        if (this.label_names[i].changed === true) promises.push(this.label_names[i].save2(seq));
    }
    if (this.paymentStatuses == null) console.log('paymentStatuses not loaded or null');
    else {
      for (let i = 0; i < this.paymentStatuses.length; ++i)
        if (this.paymentStatuses[i].changed === true) promises.push(this.paymentStatuses[i].save2(seq));
    }
    if (this.reminders == null) console.log('reminders not loaded or null');
    else {
      for (let i = 0; i < this.reminders.length; ++i)
        if (this.reminders[i].changed === true) promises.push(this.reminders[i].save2(seq));
    }
    if (this.discounts == null) console.log('discounts not loaded or null');
    else {
      for (let i = 0; i < this.discounts.length; ++i)
        if (this.discounts[i].changed === true) promises.push(this.discounts[i].save2(seq));
    }
    //     break;

    // case 'delivery':
    if (this.deliveries == null) console.log('deliveries not loaded or null');
    else {
      for (let i = 0; i < this.deliveries.length; ++i)
        if (this.deliveries[i].changed === true) promises.push(this.deliveries[i].save2(seq));
    }
    if (this.delivery_times == null) console.log('delivery_times not loaded or null');
    else {
      for (let i = 0; i < this.delivery_times.length; ++i)
        if (this.delivery_times[i].changed === true) promises.push(this.delivery_times[i].save2(seq));
    }
    //     break;

    // case 'articles':
    if (this.rollings == null) console.log('rollings not loaded or null');
    else {
      for (let i = 0; i < this.rollings.length; ++i)
        if (this.rollings[i].changed === true) promises.push(this.rollings[i].save2(seq));
    }

    //     case 'accounting':
    if (this.chargesTypes == null) console.log('chargesTypes not loaded or null');
    else {
      for (let i = 0; i < this.chargesTypes.length; ++i)
        if (this.chargesTypes[i].changed === true) promises.push(this.chargesTypes[i].save2(seq));
    }
    if (this.accountings == null) console.log('accountings not loaded or null');
    else {
      for (let i = 0; i < this.accountings.length; ++i)
        if (this.accountings[i].changed === true) promises.push(this.accountings[i].save2(seq));
    }
    if (this.currencies == null) console.log('currencies not loaded or null');
    else {
      for (let i = 0; i < this.currencies.length; ++i)
        if (this.currencies[i].changed === true) promises.push(this.currencies[i].save2(seq));
    }
    // break;
    //     default:
    //         console.log('unsupported selected tab:', this.selectedTab);
    // }
    Promise.all(promises).then(
      (result) => {
        NotificationsComponent.push({
          type: 'success',
          title: 'Enregistrement effectué',
          summary: '<b>' + promises.length + ' objet' + (promises.length > 1 ? 's' : '') + '</b> ont été enregistrés',
        });
        this.updateView();
      },
      (err) => {
        NotificationsComponent.push({
          type: 'error',
          title: "Échec de l'enregistrement",
          summary: "Une erreur s'est produite lors de l'enregistrement des objets.",
          content: JSON.stringify(err),
        });
        console.error(err);
      }
    );
  }

  delete(gridName: string) {
    let self = this;
    let selectedGrid = null;
    switch (gridName) {
      case 'permissions':
        selectedGrid = this.permissionsGrid;
        break;
      case 'userProfiles':
        selectedGrid = this.userProfilesGrid;
        break;
      case 'users':
        selectedGrid = this.usersGrid;
        break;
      case 'merchants':
        selectedGrid = this.merchantsGrid;
        break;
      case 'customer_categories':
        selectedGrid = this.customerCategoriesGrid;
        break;
      case 'supplier_categories':
        selectedGrid = this.supplierCategoriesGrid;
        break;
      case 'langs':
        selectedGrid = this.langsGrid;
        break;
      case 'customer_references':
        selectedGrid = this.customerReferencesGrid;
        break;
      case 'customersNumberings':
        selectedGrid = this.customersNumberingsGrid;
        break;
      case 'suppliersNumberings':
        selectedGrid = this.suppliersNumberingsGrid;
        break;
      case 'postcodes':
        selectedGrid = this.postcodesGrid;
        break;
      case 'countries':
        selectedGrid = this.countriesGrid;
        break;
      case 'deadlines':
        selectedGrid = this.deadlinesGrid;
        break;
      case 'discounts':
        selectedGrid = this.discountsGrid;
        break;
      case 'labelnames':
        selectedGrid = this.labelnamesGrid;
        break;
      case 'paymentStatuses':
        selectedGrid = this.paymentStatusesGrid;
        break;
      case 'reminders':
        selectedGrid = this.remindersGrid;
        break;
      case 'deliveries':
        selectedGrid = this.deliveriesGrid;
        break;
      case 'deliverytimes':
        selectedGrid = this.deliverytimesGrid;
        break;
      case 'rollings':
        selectedGrid = this.rollingsGrid;
        break;
      case 'chargesTypes':
        selectedGrid = this.chargesTypesGrid;
        break;
      case 'accountings':
        selectedGrid = this.accountingsGrid;
        break;
      case 'currencies':
        selectedGrid = this.currenciesGrid;
        break;
      default:
        return;
    }
    if (selectedGrid) {
      if (selectedGrid.selectedItems.length > 0) {
        DialogsComponent.display({
          icon: 'warning',
          title: 'Supprimer la sélection ?',
          message: 'Êtes-vous sûr(e) de vouloir supprimer les éléments sélectionnés ?',
          buttons: DialogButton.yesNoButtons,
        }).then((result) => {
          if (result == DialogButton.RESULT_YES) {
            for (let i = 0; i < selectedGrid.selectedItems.length; ++i) {
              if (selectedGrid.selectedItems[i].id != 0) {
                selectedGrid.selectedItems[i].delete().then(
                  function (result) {
                    ArrayUtil.removeElements(selectedGrid.items, [selectedGrid.selectedItems[i]]);
                  },
                  function (err) {
                    console.error('error while deleting customer:', err);
                  }
                );
              } else {
                ArrayUtil.removeElements(selectedGrid.items, [selectedGrid.selectedItems[i]]);
              }
            }
          }
        });
      }
    }
  }

  archive(gridName: string) {
    switch (gridName) {
      case 'merchants':
        this.merchantsGrid.selectedItems.map((m) => {
          m.archived = m.archived == 1 ? 0 : 1;
          m.changed = true;
        });
        break;
      default:
        return;
    }
    this.save();
  }

  merchantsRowStyle(item: Merchant) {
    return item && item.archived == 1
      ? {
          color: '#ccc',
          'font-style': 'italic',
        }
      : null;
  }
}
