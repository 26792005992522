import { EventEmitter } from '@angular/core';
import { ObjectModel } from './ObjectModel.class';

export class ObjectModel2 extends ObjectModel {
  public selected: boolean = false;
  public changed: boolean = false;

  public onSave: EventEmitter<any> = new EventEmitter<any>();

  public static nextSequence: number = 1;
}
