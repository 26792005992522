export class TextUtil
{
    public static escapeDoubleQuotes(str)
    {
        return str.replace(/\\([\s\S])|(")/g,"\\$1$2");
    }

    public static isMmSize(str: string)
    {
        return /^\d*\.?\d+mm$/.test(str);
    }
    public static isPtSize(str: string)
    {
        return /^\d*\.?\d+pt$/.test(str);
    }

    public static left(str: string, len: number)
    {
        if (len < 0) len = str.length + len;
        return str.substring(0, len);
    }
    public static right(str: string, len: number)
    {
        if (len < 0) len = str.length + len;
        return str.substring(0, str.length - len);
    }

    public static capitalizeFirst(str: string)
    {
        return str.replace(/^\w/g, c => c.toUpperCase());
    }
    public static capitalizeWords(str: string)
    {
        return TextUtil.capitalizeFirst(str.replace(/^.*\s\w/g, c => c.toUpperCase()));
    }

    public static lowercaseFirst(str: string)
    {
        return str.replace(/^\w/g, c => c.toLowerCase());
    }

    public static createRegexFromString(str: string, exact: boolean = false, flags?: string)
    {
        return new RegExp((exact ? '^' : '') + str.replace(/[\\\/\+\(\)\[\]\^\$\*\.\_\*\-\'\"]/g, (x) => '\\' + x) + (exact ? '$' : ''), flags);
    }
}
