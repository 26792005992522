import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { FormsModule } from '@angular/forms';
import { Goal } from './classes/Goal.class';
import { GoalsListViewComponent } from './views/goals-list-view/goals-list-view.component';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { ReportsModule } from '../reports/reports.module';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule, ReportsModule],
  declarations: [GoalsListViewComponent],
  exports: [GoalsListViewComponent],
  entryComponents: [GoalsListViewComponent],
})
export class GoalsModule extends ModuleBase {
  public static moduleName = 'goals';

  public ngOnInit() {
    ClassManager.registerClass('Goal', Goal);

    HomepageViewComponent.registerGroups({
      sales: {
        children: [
          {
            name: 'Budgets',
            backColor: 'rgb(248, 160, 64)',
            textColor: 'rgb(0,0,0)',
            permission: 'home_bouton_consolidations_budgets',
            component: GoalsListViewComponent,
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
