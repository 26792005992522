export class ReportCell
{
    public value?: string = null;
    public style?: any = null;

    public rowSpan?: number = null;
    public colSpan?: number = null;

    public static moneyCellStyle = {
        'text-align': 'right'
    }
}