<div class="article-view-wrapper">
  <div class="article-view-column">
    <h3
      [innerHTML]="!articleCopy.id ? 'Nouvel article' : editMode ? 'Modifier un article' : 'Informations article'"
    ></h3>
    <info-block
      [block]="typeBlock"
      [data]="articleCopy"
      [readonly]="(articleCopy && articleCopy.id && !editMode) || !writeAccess"
    ></info-block>
    <info-block
      #articleBlockComponent
      [block]="articleBlock"
      [data]="articleCopy"
      [readonly]="(articleCopy && articleCopy.id && !editMode) || !writeAccess"
      *ngIf="!articleCopy || articleCopy.type == 0"
    ></info-block>
    <info-block
      #deviceBlockComponent
      [block]="deviceBlock"
      [data]="articleCopy"
      [readonly]="(articleCopy && articleCopy.id && !editMode) || !writeAccess"
      *ngIf="articleCopy && articleCopy.type == 1"
    ></info-block>
    <info-block
      #serviceBlockComponent
      [block]="serviceBlock"
      [data]="articleCopy"
      [readonly]="(articleCopy && articleCopy.id && !editMode) || !writeAccess"
      *ngIf="articleCopy && articleCopy.type == 2"
    ></info-block>
  </div>

  <div class="article-view-column">
    <h3>Notes internes</h3>
    <info-block
      [block]="notesBlock"
      [data]="articleCopy"
      [readonly]="(articleCopy && articleCopy.id && !editMode) || !writeAccess"
    ></info-block>

    <h3>Historique article</h3>
    <data-grid
      id="article-old-prices"
      class="old-prices-grid"
      [columns]="oldPricesColumns"
      [items]="old_prices"
      [selectable]="false"
      [editable]="false"
      [resizable]="false"
      (itemDblClick)="priceDblClick($event, $event.item)"
    ></data-grid>
  </div>
</div>
