import { Accounting } from '../../other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArticleBase } from '../../articles/classes/ArticleBase.class';
import { PrintableElement } from '../../printing/classes/PrintableElement.class';

export class PrintingUtil {
  public static getArticleHeaders(article: ArticleBase, lang: string) {
    if (!article) return [];
    if (!lang || lang == '') lang = 'fr';
    let arr: any[] = [];
    if (article) {
      if (article.type == 0) {
        if (article.field1) arr.push({ fr: 'Format :', en: 'Size :', nl: 'Formaat :' }[lang]);
        if (article.field2) arr.push({ fr: 'Matière :', en: 'Material :', nl: 'Materiaal :' }[lang]);
        if (article.field4) arr.push({ fr: 'Impression :', en: 'Printing :', nl: 'Bedrukking :' }[lang]);
        if (article.field5)
          arr.push(
            { fr: 'ø du mandrin / ø du rouleau :', en: 'Interior core ø /ø of reel rool :', nl: 'ø kern / ø rol :' }[
              lang
            ]
          );
        if (article.field6) arr.push({ fr: 'Présentation :', en: 'Presented as :', nl: 'Presentatie :' }[lang]);
        if (article.field7)
          arr.push(
            {
              fr: "Nb. d'éti. /Feui. /Bob. /Parav. :",
              en: 'Quantity per sheet/roll/reel/fold: ',
              nl: 'Aantal etik. /Bl. /Rol. /Zig-zag. :',
            }[lang]
          );
        if (article.field8)
          arr.push(
            {
              fr: 'Espace verti. / Espace horiz. :',
              en: 'Vertical gap / horizontal gap :',
              nl: 'Verti. spatie / Horiz. spatie :',
            }[lang]
          );
        if (article.field9)
          arr.push({ fr: "Sens d'enroulement :", en: 'Winding direction :', nl: 'Wikkelrichting :' }[lang]);
        if (article.field10) arr.push({ fr: 'Laize :', en: 'Total material width :', nl: 'Totale breedte :' }[lang]);
        if (article.field11) arr.push({ fr: 'Pinfeed :', en: 'Spoket holes :', nl: 'Pinfeed :' }[lang]);
        if (article.field12)
          arr.push(
            {
              fr: 'Perforations ou façonnage :',
              en: 'Perforation or manufacturing :',
              nl: 'Perforaties of afwerking :',
            }[lang]
          );
        if (article.field13) arr.push({ fr: 'Autres :', en: 'Others :', nl: 'Andere :' }[lang]);
        if (article.field14) arr.push({ fr: 'Conditionnement :', en: 'Packing / Boxing :', nl: 'Verpakking :' }[lang]);
      } else if (article.type == 1) {
        if (article.field1)
          arr.push({ fr: 'Marque et modèle :', en: 'Brand and model :', nl: 'Merk en het model :' }[lang]);
        if (article.field2) arr.push({ fr: 'Technologie :', en: 'Technology :', nl: 'De technologie :' }[lang]);
        if (article.field3) arr.push({ fr: 'Résolution :', en: 'Resolution :', nl: 'De resolutie :' }[lang]);
        if (article.field4)
          arr.push({ fr: 'Vitesse maximum :', en: 'Maximum speed :', nl: 'De maximumsnelheid :' }[lang]);
        if (article.field5)
          arr.push({ fr: 'Largeur du média :', en: 'Width of the media :', nl: 'De breedte van de media :' }[lang]);
        if (article.field6)
          arr.push({ fr: 'Épaisseur du média :', en: 'Thickness of the media :', nl: 'De dikte van de media :' }[lang]);
        if (article.field7) arr.push({ fr: 'Mémoire :', en: 'Memory :', nl: 'Het geheugen :' }[lang]);
        if (article.field8) arr.push({ fr: 'Couteau :', en: 'Knife :', nl: 'Het mes :' }[lang]);
        if (article.field9)
          arr.push(
            { fr: 'Interface (standard) :', en: 'Interface (standard) :', nl: 'Interface (de standaard) :' }[lang]
          );
        if (article.field10) arr.push({ fr: 'Programmation :', en: 'Programming :', nl: 'De programmering :' }[lang]);
        if (article.field11) arr.push({ fr: 'Options :', en: 'Options :', nl: 'De opties :' }[lang]);
        if (article.field12) arr.push({ fr: 'Construction :', en: 'Construction :', nl: 'De bouw :' }[lang]);
        if (article.field13) arr.push({ fr: 'Garantie :', en: 'Warranty :', nl: 'De garantie :' }[lang]);
        if (article.field14) arr.push({ fr: 'Autres :', en: 'Others :', nl: 'Andere :' }[lang]);
      } else if (article.type == 2) {
        if (article.field1) arr.push({ fr: 'Description :', en: 'Description :', nl: 'Beschrijving :' }[lang]);
      }
    }
    return arr;
  }

  public static getArticleValues(article: ArticleBase, hideField2: boolean = false) {
    if (!article) return [];
    let arr: any[] = [];
    if (article) {
      if (article.type == 0) {
        if (article.field1) arr.push(article.field1); //format
        if (article.field2) arr.push(hideField2 && article.field3 ? article.field3 : article.field2); // material & material2
        if (article.field4) arr.push(article.field4); //printing
        if (article.field5) arr.push(article.field5); //diameter
        if (article.field6) arr.push(article.field6); //presentation
        if (article.field7) arr.push(article.field7); //labels_count
        if (article.field8) arr.push(article.field8); //space
        if (article.field9) arr.push(article.field9); //rolling_direction
        if (article.field10) arr.push(article.field10); //width
        if (article.field11) arr.push(article.field11); //pinfeed
        if (article.field12) arr.push(article.field12); //perforations
        if (article.field13) arr.push(article.field13); //other
        if (article.field14) arr.push(article.field14); //packaging
      } else if (article.type == 1) {
        if (article.field1) arr.push(article.field1); //model
        if (article.field2) arr.push(article.field2); //technology
        if (article.field3) arr.push(article.field3); // resolution
        if (article.field4) arr.push(article.field4); // max_speed
        if (article.field5) arr.push(article.field5); // media_width
        if (article.field6) arr.push(article.field6); // media_thickness
        if (article.field7) arr.push(article.field7); // memory
        if (article.field8) arr.push(article.field8); // knife
        if (article.field9) arr.push(article.field9); // interface
        if (article.field10) arr.push(article.field10); // programming
        if (article.field11) arr.push(article.field11); // options
        if (article.field12) arr.push(article.field12); // building
        if (article.field13) arr.push(article.field13); // warranty
        if (article.field14) arr.push(article.field14); // other
      } else if (article.type == 2) {
        if (article.field1) arr.push(article.field1); //description
      }
    }
    return arr;
  }

  public static formatAddress(
    name: string,
    contact: string,
    address: string,
    vat_number: string = null,
    opening_hours: string = null,
    lang: string = 'fr',
    hide_customer: number = 0
  ) {
    let elements: PrintableElement[] = [];
    if (hide_customer !== 1)
      elements.push({
        style: { 'font-weight': 'bold' },
        html: name,
      });
    if (contact && hide_customer !== 1) elements.push({ html: contact });
    elements.push({
      style: { 'white-space': 'pre-wrap' },
      html: address,
    });
    if (vat_number)
      elements.push({
        html:
          {
            fr: 'N° de T.V.A. : ',
            nl: 'B.T.W. Nr : ',
            en: 'VAT No : ',
          }[lang] + vat_number,
      });
    if (opening_hours)
      elements.push({
        html:
          {
            fr: 'Horaires : ',
            nl: 'Openingstijden : ',
            en: 'Opening hours : ',
          }[lang] + opening_hours,
      });
    return elements;
  }

  public static linesToDivs(str: string, style: any = null, min: number = 0): PrintableElement[] {
    console.log('lines to div:', str);
    let elements: PrintableElement[] = [];
    let base_style: any = {
      display: 'block',
      'line-height': '5mm',
      'white-space': 'pre-wrap',
    };
    if (style) base_style = Object.assign(base_style, style);
    if (str && str.length > 0) {
      let lines: string[] = str.split('\n');
      console.log('lines:', lines);
      for (let i = 0; i < lines.length; ++i) {
        let elem: PrintableElement = {
          style: base_style,
          html: lines[i].length == 0 ? ' ' : lines[i],
        };
        elements.push(elem);
      }
    }
    console.log('elems:', elements);
    for (let i = elements.length; i < min; ++i) {
      let elem: PrintableElement = {
        style: base_style,
        html: ' ',
      };
      elements.push(elem);
    }
    console.log('final elems:', elements);
    return elements;
  }

  public static getFooter(accounting: Accounting, lang: string = 'fr'): PrintableElement {
    if (!accounting) accounting = AccountingsService.currentAccounting;
    if (!lang) lang = 'fr';
    return {
      style: {
        position: 'absolute',
        height: '24mm',
        left: '0mm',
        width: '190mm',
        bottom: '0mm',
        'background-image': 'url(assets/img/footer_documents.png)',
        'background-size': '190mm 24mm',
        'padding-top': '2mm',
      },
      children: [
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: '0mm',
            right: '0mm',
            height: '5mm',
            'font-size': '10pt',
            'text-align': 'center',
            'line-height': '4mm',
            'padding-top': '1mm',
            'white-space': 'pre',
            color: 'rgb(0, 156, 216)',
          },
          children: [
            {
              style: { display: 'inline-block', width: '33mm', 'text-align': 'right' },
              html:
                {
                  fr: 'TVA',
                  en: 'VAT',
                  nl: 'BTW',
                }[lang] +
                ' : ' +
                accounting.vat_number,
            },
            {
              style: { display: 'inline-block', color: 'rgb(0, 58, 96)', padding: '0 1mm' },
              html: '•',
            },
            {
              style: { display: 'inline-block' },
              html: 'IBAN : ' + accounting.iban,
            },
            {
              style: { display: 'inline-block', color: 'rgb(0, 58, 96)', padding: '0 1mm' },
              html: '•',
            },
            {
              style: { display: 'inline-block' },
              html: 'BIC : ' + accounting.bic,
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            right: '2mm',
            top: '12.5mm',
            'font-size': '9pt',
            'white-space': 'pre',
            color: 'rgb(0, 58, 96)',
            'line-height': '3.5mm',
            'text-align': 'right',
            width: '50mm',
          },
          children: [
            {
              style: {
                display: 'block',
                'margin-left': 'auto',
                'background-image': 'url(assets/img/phone.png)',
                'background-position': 'top left',
                'background-size': '4mm 4mm',
                'background-repeat': 'no-repeat',
                'line-height': '4mm',
                height: '4mm',
                'margin-bottom': '1mm',
                width: '26.25mm',
              },
              html: accounting.phone_number,
            },
            {
              style: {
                display: 'block',
                'margin-left': 'auto',
                position: 'relative',
                top: '-3mm',
                'padding-top': '3mm',
                'background-image': 'url(assets/img/mail.png)',
                'background-position': 'top left',
                'background-size': '4mm 7mm',
                'background-repeat': 'no-repeat',
                'line-height': '3mm',
                width: '34mm',
                height: '10mm',
              },
              html: accounting.contacts,
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '4mm',
            top: '6mm',
            width: '50mm',
            color: 'rgba(255,255,255,1.0)',
            'white-space': 'pre',
            'background-image': 'url(assets/img/home-orange.png)',
            'background-position': 'top left',
            'background-size': '6mm 6mm',
            'padding-left': '8mm',
            'background-repeat': 'no-repeat',
          },
          children: [
            {
              style: {
                'font-size': '9pt',
                'line-height': '3mm',
                height: '3.25mm',
                'font-weight': 'bold',
                display: 'block',
                color: 'rgb(0, 156, 216)',
                'vertical-align': 'bottom',
              },
              html: accounting.company,
            },
            {
              style: {
                'font-size': '9pt',
                'line-height': '3mm',
                height: '6.5mm',
                display: 'block',
                color: 'rgb(0, 58, 96)',
                'white-space': 'pre',
              },
              html: accounting.seat,
            },
          ],
        },
        accounting.address && accounting.address.length
          ? {
              style: {
                position: 'absolute',
                left: '55mm',
                top: '6mm',
                width: '50mm',
                color: 'rgba(255,255,255,1.0)',
                'white-space': 'pre',
                'background-image': 'url(assets/img/home-blue.png)',
                'background-position': 'top left',
                'background-size': '6mm 6mm',
                'padding-left': '8mm',
                'background-repeat': 'no-repeat',
              },
              children: [
                {
                  style: {
                    'font-size': '9pt',
                    'line-height': '3mm',
                    height: '3.25mm',
                    'font-weight': 'bold',
                    display: 'block',
                    color: 'rgb(0, 156, 216)',
                    'vertical-align': 'bottom',
                  },
                  html: accounting.address.split('\n')[0],
                },
                {
                  style: {
                    'font-size': '9pt',
                    'line-height': '3mm',
                    height: '10mm',
                    display: 'block',
                    color: 'rgb(0, 58, 96)',
                    'white-space': 'pre',
                  },
                  html: accounting.address.split('\n').slice(1).join('\n'),
                },
              ],
            }
          : {},
      ],
    };
  }
}
