import * as $ from 'jquery';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiService } from 'src/app/services/api/api.service';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NotificationsComponent } from '../notifications/notifications.component';
import { config } from '../../classes/config';

@Component({
  selector: 'image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements OnInit {
  @Input() readonly: boolean = false;
  @Output() public change: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('form') form: ElementRef = null;
  @ViewChild('fileField') fileField: ElementRef = null;
  //public image_url: string = null;

  constructor(private http: HttpClient) {}

  ngOnInit() {}

  browse() {
    if (this.readonly !== true) $('#image_file').click();
  }
  reset() {
    if (this.readonly !== true) this.value = null;
  }

  fileChanged() {
    if (this.readonly !== true) this.submitForm(null);
  }

  public get formAction() {
    return config.apiUrl + 'modules/image_upload/upload.php';
  }

  submitForm(event) {
    if (this.fileField.nativeElement.files && this.fileField.nativeElement.files.length > 0) {
      let fd: FormData = new FormData(this.form.nativeElement);
      const options = ApiService.getOptions();
      options.headers['Accept'] = 'application/json';
      this.http.post(this.formAction, fd, options).subscribe(
        (response: any) => {
          if (response.result && response.result === 'success') this.value = response.details;
          else {
            NotificationsComponent.push({
              title: "Erreur lors de l'envoi de l'image",
              summary: response.details,
              type: 'error',
            });
            console.error(response);
          }
        },
        (error) => {
          NotificationsComponent.push({
            title: "Erreur lors de l'envoi de l'image",
            summary: error,
            type: 'error',
          });
          console.error(error);
        }
      );
    }
  }

  public get image_url() {
    return this._value ? 'uploaded_images/' + this._value : 'assets/img/no_image.png';
  }

  // ControlValueAccessor

  public _value: string = null;
  public get value() {
    return this._value;
  }
  public set value(value: string) {
    this._value = value;
    this.propagateChange(value);
    this.change.next(value);
  }

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
  writeValue(value: any) {
    this._value = value;
  }
}
