import { PrintableElement } from "./PrintableElement.class";

export class Page
{
    public style: any = null;

    public width: number = 210;
    public height: number = 295;
    
    public headerSize: number = 0;
    public footerSize: number = 0;
    
    public leftMargin: number = 18;
    public rightMargin: number = 25;
    public topMargin: number = 19;
    public bottomMargin: number = 15;

    public headerElements: PrintableElement[] = [];
    public bodyElements: PrintableElement[] = [];
    public footerElements: PrintableElement[] = [];
}
