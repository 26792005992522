import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { Nomenclature } from "../../articles/classes/Nomenclature.class";
import { ArticleBase } from "./ArticleBase.class";


export class Service extends ArticleBase
{
    public get description(): string { return this.field1; };
    public set desription(value: string) { this.field1 = value; };

    constructor()
    {
        super();
        this.type = 2;
    }

    public static load(ids: string[] = null, orderBy: string[] = null, groupBy: string[] = null,
        deleted: boolean = false, conditions: string = null, forceReload: boolean = false)
    {
        if (conditions != null) conditions = '(' + conditions + ') AND type = 2';
        else conditions = 'type = 2';
        return super.load(ids, orderBy, groupBy, deleted, conditions, forceReload);
    }
}
