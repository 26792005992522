import { Injectable } from '@angular/core';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';

@Injectable({
  providedIn: 'root'
})
export class ExternalService {

    private static _cefSharp: any = null;
    private static _ui: any = null;

    constructor() {}

    public static init(): Promise<any>
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            if (ExternalService._ui) resolve(ExternalService._ui);
            ExternalService._cefSharp = window['CefSharp'];
            if (!ExternalService._cefSharp) resolve(false);
            ExternalService._cefSharp.BindObjectAsync('graphiSealUI').then(
                (result) => {
                    ExternalService._ui = window['graphiSealUI'];
                    resolve(ExternalService._ui);
                },
                (err) => {
                    console.error(err);
                    resolve(false);
                }
            );
        });
    }

    public static call(funcName, ...args) : Promise<any>
    {
        return LoadingPromise.create<any>((resolve, reject) => {
            ExternalService.init().then(
                (result) => {
                    if (!result) reject('external component not available');
                    if (!result[funcName] || typeof(result[funcName]) !== 'function') reject(funcName + ' is not a function');
                    else resolve(result[funcName](...args));
                },
                (err) => { reject(err); }
            );
        });
    }

    public static callSync(funcName, ...args) : any
    {
        if (!ExternalService._ui) return false;
        if (!ExternalService._ui[funcName] || typeof(ExternalService._ui[funcName]) !== 'function') return false;
        return ExternalService._ui[funcName](...args);
    }

    public static get available(): boolean
    {
        return !(!ExternalService._ui);
    }

    public static showAvailabilityWarning()
    {
        return DialogsComponent.display({
            title: 'Fonction indisponible',
            message: 'Le composant externe offrant cette fonction est indisponible.<br/>Vous utilisez probablement l\'ERP dans un navigateur (Google Chrome).<br/>Assurez-vous d\'exécuter le programme ERP dédié, afin de pouvoir bénéficier de toutes les fonctionnalités.',
            icon: 'warning',
            buttons: DialogButton.okOnlyButtons
        });
    }


    public static createRows(data: any)
    {
        return ExternalService.call('createRows', data);
    }


    public static downloadUrlString(url: string, method: string, data: string)
    {
        return ExternalService.call('downloadUrlString', url, method, data);
    }
    public static downloadToFile(path: string, url: string, method: string, data: string)
    {
        return ExternalService.call('downloadToFile', path, url, method, data);
    }
    public static downloadSaveAs(url: string, method: string, data: string, filename: string = null, filter: string = null)
    {
        return ExternalService.call('downloadSaveAs', url, method, data, filename, filter);
    }
    public static getTempFileName(extension: string)
    {
        return ExternalService.call('getTempFileName', extension);
    }
    public static saveAsTemp(extension: string, url: string, method: string, data: string)
    {
        return ExternalService.call('saveAsTemp', extension, url, method, data);
    }
    public static saveAsTempFilename(filename: string, url: string, method: string, data: string)
    {
        return ExternalService.call('saveAsTempFilename', filename, url, method, data);
    }
    public static downloadAndOpen(extension: string, url: string, method: string, data: string)
    {
        return ExternalService.call('downloadAndOpen', extension, url, method, data);
    }
    public static downloadAndPrint(extension: string, url: string, method: string, data: string)
    {
        return ExternalService.call('downloadAndPrint', extension, url, method, data);
    }

    public static createMail(to: string, cc: string = null, bcc: string = null, subject: string = null, body: string = null,
                             attachments: string[] = null, signature: string = null)
    {
        return ExternalService.call('createMail', to, cc, bcc, subject, body, attachments, signature);
    }

    public static getClipboardText()
    {
        return ExternalService.call('getClipboardText');
    }

    public static setWindowTitle(title: string)
    {
        return ExternalService.call('setWindowTitle', title);
    }
}
