import {Component, OnInit, ViewChild} from '@angular/core';
import {Toolbar} from '../../../../components/toolbar/classes/Toolbar.class';
import {DeviceInstance} from '../../../devices/classes/DeviceInstance.class';
import {Invoice} from '../../../invoicing/classes/Invoice.class';
import {DataGridComponent} from '../../../../components/data-grid/data-grid.component';
import {AccessViewComponent} from '../../../../components/views/access-view.component';
import * as moment from 'moment';
import {ApiService} from '../../../../services/api/api.service';
import {DateTimeUtil} from '../../../utils/classes/DateTimeUtil.class';
import {Deadline} from '../../../other-data/classes/Deadline.class';
import {DialogsComponent} from '../../../../components/dialogs/dialogs.component';
import {DialogButton} from '../../../../components/dialogs/classes/DialogButton.class';
import * as $ from 'jquery';


@Component({
  selector: 'app-bob-purchases-view',
  templateUrl: './bob-purchases-view.component.html',
  styleUrls: ['./bob-purchases-view.component.css']
})
export class BobPurchasesViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'BOB - Exporter les achats',
        data: this,
        elements: [
            {
                type: 'button',
                name: 'generateFilesButtons',
                text: 'Générer<br/>les fichiers',
                icon: ' far fa-file',
                click: function(view: BobPurchasesViewComponent) {
                    view.generateFiles();
                },
                visible: true
            },
            { type: 'spacing' }
        ]
    };

    public fromDate: string = null;
    public toDate: string = null;
    public data: any[] = [];

    public gridColumns = [
        { title: 'Journal', field: 'journal', width: 90, readonly: true },
        { title: 'Année fiscale', field: 'fiscal_year', width: 90, readonly: true },
        { title: 'Année', field: 'year', width: 90, readonly: true },
        { title: 'Mois', field: 'month', width: 90, readonly: true },
        { title: 'N°', field: 'number', width: 90, readonly: true },
        { title: 'Date', field: 'date', type: 'date', width: 130, readonly: true },
        { title: 'Type', field: 'type', readonly: true },
        { title: 'Client', field: 'customer', readonly: true },
        { title: 'Date\nd\'échéance', field: 'due_date', type: 'date', width: 130, readonly: true },
        { title: 'Escompte', field: 'discount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
        { title: 'Date\nd\'échéance\nde l\'escompte', field: 'discount_due_date', type: 'date', width: 130, readonly: true },
        { title: 'Devise', field: 'currency', width: 90, readonly: true },
        { title: 'Cours de\nla devise', field: 'xrate', type: 'number', decimalsCount: 8, width: 150, readonly: true },
        // { title: 'Montant en\ndevise', field: 'currency_amount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
        { title: 'Montant en\neuros', field: 'total_amount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
        { title: 'Remarque interne\nou commentaire', field: 'internal_remark', width: 300 },
        { title: 'Remarque externe\nou communication', field: 'external_remark', width: 300 }, // n° fac. fourn (achats) + montant en £ (+ taux de change)
        // { title: 'Total HTVA', field: 'amount', type: 'number', decimalsCount: 2, width: 150 },
        // { title: 'TVA', field: 'vat', type: 'number', decimalsCount: 2, width: 150 },
        // { title: 'Total TVAC', field: 'total', type: 'number', decimalsCount: 2, width: 150 }
    ];

    @ViewChild('grid') grid: DataGridComponent;

    public ngOnInit()
    {
        this.fromDate = moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
        this.toDate = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
        this.generateReport();
    }


    public generateReport()
    {
        ApiService.callModule('bob', 'purchasesData', {
            'from_date': this.fromDate,
            'to_date': this.toDate,
        }).then(
            (result) => {
                if (result.result === 'success' && result.details) {
                    this.data = result.details;
                    // for(let i=0; i<this.data.length; ++i) {
                    //     let invoice: any = this.data[i];
                    //     invoice.due_date = Deadline.getFinalDate(invoice.deadline, new Date(invoice.date));
                    //     invoice.discount_due_date = invoice.discount_days > 0 ? Deadline.getFinalDate(invoice.discount_days + " jours", new Date(invoice.date)) : null;
                    // }
                }
                else console.error(result);
            },
            (err) => { console.error(err); }
        );
    }

    public generateFiles()
    {
        $('#list_json').val(JSON.stringify(this.data));
        // $('#json_form').attr('action', ApiService.url + 'modules/bob/generateSalesDocuments.php').submit();
        // $('#json_form').attr('action', ApiService.url + 'modules/bob/generateSalesAmounts.php').submit();
        // $('#json_form').attr('action', ApiService.url + 'modules/bob/generateSalesCustomers.php').submit();
    }

}
