import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { Order } from './Order.class';
import { Page } from '../../printing/classes/Page.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
import { config } from '../../../classes/config';

export class OrderAckPrintingModel extends PrintingModel {
  public deliveryAddressField: InfoBlockField = {
    title: 'Adresse de livraison',
    field: 'delivery_address',
    type: 'address-text',
    listItems: { items: [] },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
    addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
  };
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon client)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      this.deliveryAddressField,
      {
        title: 'Avertissement pénuries',
        field: 'shortage_warning',
        type: 'checkbox',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
    ],
  };

  public order: Order = null;
  public lang: string = '';
  public shortage_warning: number = 0;
  public delivery_address: string = null;
  // public delivery_address: string = null;
  // public billing_address: string = null;
  // public send_address: string = null;
  //public hide_customer: boolean = false;

  public static generate(order: Order): PrintingModel {
    let model: OrderAckPrintingModel = new OrderAckPrintingModel();
    model.order = order;
    model.delivery_address = order.delivery_address_text;
    model.deliveryAddressField.listItems.items = order.customer.deliveryAddressesList;
    model.shortage_warning = order.sale.shortage_warning;
    return model.regenerate();
  }

  public regenerate(): PrintingModel {
    let order: Order = this.order;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let totals: string[] = [];

    // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
    // if (!this.billing_address && order.billing_address) this.billing_address = order.billing_address;
    // if (!this.send_address && order.send_address) this.send_address = order.send_address;

    this.pages = [];

    let lang: string = this.lang;
    let mail_lang: string = this.lang;
    if (!lang || lang == '') {
      if (this.order.customer.lang) lang = this.order.customer.lang.code;
      else lang = 'fr';
    }
    if (!mail_lang || mail_lang == '') {
      if (this.order.customer.lang) mail_lang = this.order.customer.lang.code;
      else mail_lang = 'fr';
    }

    this.saveFilename = 'Accusé de réception n°' + this.order.number.replace(/\//g, '-') + '.pdf';
    this.mailFilename =
      {
        fr: 'Accusé de réception n° ',
        nl: 'Bevesteging van bestelling nr ',
        en: 'Order confirmation no ',
      }[mail_lang] +
      this.order.shortNumber.replace(/\//g, '-') +
      '.pdf';

    // for(let i=0; i<this.order.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
        'line-height': '4mm',
      },

      headerSize: 33,
      headerElements: [
        this.shortage_warning
          ? {
              style: {
                position: 'absolute',
                right: '0mm',
                top: '-6mm',
                height: '10mm',
                'line-height': '4mm',
                'font-size': '12pt',
                color: 'rgb(255,0,0)',
                'font-weight': 'bold',
                'text-align': 'center',
              },
              html: {
                en: 'Given the current market shortages the cost of raw material could change prior to delivery and we may be obliged to review this price.',
                fr: 'Compte tenu des pénuries actuelles du marché, le coût des matières premières pourrait changer avant la livraison et nous pourrions être obligés de revoir ce prix.',
                nl: 'Vermits ons aktueel tekortkoming van materiaal op de markt, de kost van het materiaal zou kunnen veranderen voor uw levevering en wij zouden verplicht zijn om onze prijsen te moeten aanpassen.',
              }[lang],
            }
          : {},
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: this.shortage_warning ? '4mm' : '1mm',
            width: '85mm',
            height: '17mm',
            border: '.5mm solid rgba(0, 176, 240, 1.0)',
            'border-radius': '2.5mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                'font-size': '14pt',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%',
                'line-height': '5mm',
                'margin-top': '1.5mm',
                'margin-bottom': '1mm',
                'vertical-align': 'top',
              },
              html: {
                fr: order.isStock ? 'CONFIRMATION DE MISE EN STOCK' : 'ACCUSÉ DE RÉCEPTION DE COMMANDE',
                en: order.isStock ? 'ACKNOWLEDGEMENT STOCK CONTRACT' : 'ORDER CONFIRMATION',
                nl: order.isStock ? 'BEVESTEGING VAN BESTELLING' : 'BEVESTEGING VAN BESTELLING',
              }[lang],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° :',
                    en: 'No :',
                    nl: 'Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '80%',
                    padding: '0 1mm',
                    'font-weight': 'bold',
                  },
                  html: order.number,
                },
              ],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'Date :',
                    en: 'Date :',
                    nl: 'Datum :',
                  }[lang],
                },
                {
                  style: {
                    width: '30%',
                    padding: '0 1mm',
                  },
                  html: DateTimeUtil.format(new Date(order.date), 'd/m/Y'),
                },
                {
                  style: {
                    width: '25%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° client :',
                    en: 'Cust. No :',
                    nl: 'Klant Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '25%',
                    padding: '0 1mm',
                  },
                  html: order.sale.customer.number,
                },
              ],
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: this.shortage_warning ? '4mm' : '0mm',
            width: '50mm',
            height: '30mm',
            'background-image': 'url(assets/img/logo_documents.png)',
            'background-position': 'center center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          },
          html: '',
        },
      ],

      bodyElements: [
        {
          style: {
            'text-decoration': 'underline',
            'white-space': 'pre',
          },
          html: {
            fr: '\nVotre interlocuteur :',
            en: '\nYour contact-person :',
            nl: '\nUw contact persoon :',
          }[lang],
        },
        {
          style: {
            'white-space': 'pre',
            'font-weight': 'bold',
          },
          html: order.sale.merchant.name,
        },
        {
          style: {
            'white-space': 'pre',
          },
          html:
            (this.order.sale.merchant && this.order.sale.merchant.email
              ? 'Mail : ' + this.order.sale.merchant.email + '\n'
              : '') +
            (this.order.sale.merchant && this.order.sale.merchant.phone
              ? {
                  fr: 'Portable : ',
                  en: 'Mobile : ',
                  nl: 'Mobiele : ',
                }[lang] +
                this.order.sale.merchant.phone +
                '\n'
              : '') +
            {
              fr: 'Téléphone : ',
              en: 'Phone : ',
              nl: 'Telefoon : ',
            }[lang] +
            '+32 (0)4 380 06 05',
        },
        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            margin: '3mm 0',
          },
          html: {
            fr: 'À la suite de votre commande #, pour laquelle nous vous remercions, nous vous confirmons la production et les termes pour la fourniture suivante :',
            en: 'Further to your order #, we are pleased to confirm to you the production and terms for :',
            nl: 'Naar aanleiding van uw bestelling #, waarvoor wij U danken, bevestigen wij U de productie en de voorwaarden voor volgende goederen :',
          }[lang].replace('#', this.order.customer_reference ? this.order.customer_reference : ''),
        },

        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '0mm',
            width: '85mm',
            height: '25m',
          },
          children: PrintingUtil.formatAddress(
            this.order.customer.name,
            this.order.ordinator_contact,
            this.order.ordinator_address_text
          ),
        },

        {
          style: {
            // 'display': 'flex',
            width: '100%',
            // 'flex-direction': 'row'
          },
          children: [
            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                // 'flex': '1'
              },
              children: [
                this.order.article.field9_img //rolling picture
                  ? {
                      style: {
                        display: 'block',
                        width: '10mm',
                        height: '10mm',
                        position: 'absolute',
                        right: '0.5mm',
                        bottom: '0.5mm',
                      },
                      image: config.baseUrl + 'assets/img/rolling-directions/' + this.order.article.field9_img + '.png',
                      imageStyle: {
                        width: '10mm',
                        height: '10mm',
                      },
                    }
                  : {},
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Désignation',
                        en: 'Specifications',
                        nl: 'Specificaties',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'font-weight': 'bold',
                      },
                      html: order.sale.article.designation,
                    },
                  ],
                },
              ].concat(this.getArticleLines(order, lang, this.order.article.field9_img ? '11mm' : undefined)),
            },

            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                'margin-top': '3mm',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre',
                        'line-height': '4mm',
                      },
                      html: {
                        fr:
                          'Nombre de facturations\nNombre de livraisons' +
                          (order.storage > 0 ? '\nMois de stockage' : '') +
                          (order.productions > 0 ? '\nNombre de productions' : ''),
                        en:
                          'Number of invoicings\nNumber of deliveries' +
                          (order.storage > 0 ? '\nStorage months' : '') +
                          (order.productions > 0 ? '\nNumber of productions' : ''),
                        nl:
                          'Antaal facturen\nAntaal leveringen' +
                          (order.storage > 0 ? '\nAntaal manden opslag' : '') +
                          (order.productions > 0 ? '\nAantal producties' : ''),
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'white-space': 'pre',
                        'line-height': '4mm',
                      },
                      html:
                        [order.invoicings, order.deliveries].join('\n') +
                        (order.storage > 0 ? '\n' + order.storage : '') +
                        (order.productions > 0 ? '\n' + order.productions : ''),
                    },
                  ],
                },
              ],
            },

            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                'margin-top': '3mm',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        width: '50mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: "Quantité et unité\nPrix de vente à l'unité\nMontant de la commande\nDélai de livraison\nConditions de paiement",
                        nl: 'Hoeveelheid en verkoopseenheid\nPrijs per verkoopseenheid\nTotaal bedrag\nLeveringstermijn\nBetalingsvoorwaarden',
                        en: 'Quantity and unit\nPurchase price per unit\nOrder amount\nDelivery time\nPayement terms',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                      },
                      html: [
                        order.quantityAndUnit,
                        NumberUtil.formatMoney(
                          order.converted_sell_price,
                          (order.sell_currency ? order.sell_currency.symbol : '€') +
                            { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                          5,
                          '.'
                        ),
                        NumberUtil.formatMoney(
                          order.converted_totalAmount,
                          (order.sell_currency ? order.sell_currency.symbol : '€') +
                            { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                          2,
                          '.'
                        ),
                        order.delivery_time,
                        order.deadline ? order.deadline : '',
                      ].join('\n'),
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            'background-color': 'rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            'text-align': 'center',
            // 'line-height': '5mm',
            color: 'rgba(255,255,255,1)',
            'font-weight': 'bold',
            'margin-top': '3mm',
          },
          html: {
            fr: 'CLAUSE DE RÉSERVE DE PROPRIÉTÉ',
            en: 'PROPERTY RETENTION CLAUSE',
            nl: 'CLAUSULE VAN EIGENDOMSVOORBEHOUD',
          }[lang],
        },
        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            // 'line-height': '4mm',
            'white-space': 'pre-wrap',
            'vertical-align': 'top',
          },
          html: {
            fr: "La commande est soumise à l'adhésion à nos Conditions Générales de vente et notament à notre réserve de propriété. Nous conservons l'entière propriété de la marchandise vendue jusqu'à complet paiement de son prix.",
            en: 'The order is subject to our general terms and conditions of sale and the acceptance of our property law. The goods remain our property until full payment of the outstanding invoice amount.',
            nl: 'De bestelling is onderworpen aan onze algemeene verkoopsvoorwaarden en het aanvaarden van ons eigendomsrecht. De goederen blijven onze eigendom tot het volledig betalen van het verschuldigde factuurbedrag.',
          }[lang],
        },

        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            'background-color': 'rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            'text-align': 'center',
            // 'line-height': '5mm',
            color: 'rgba(255,255,255,1)',
            'font-weight': 'bold',
            'margin-top': '3mm',
          },
          html: {
            fr: 'REMARQUES IMPORTANTES',
            en: 'IMPORTANT REMARKS',
            nl: 'BELANGRIJKE OPMERKINGEN',
          }[lang],
        },
        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            // 'line-height': '4mm',
            'white-space': 'pre-wrap',
            flex: '1',
            'vertical-align': 'top',
          },
          children: PrintingUtil.linesToDivs(order.remarks_ack || ''),
        },

        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-top': '3mm',
            'font-style': 'italic',
          },
          html: {
            fr: "En restant à votre disposition pour d'éventuelles questions, nous vous prions d'agréer, Madame, Monsieur, nos plus sincères salutations.",
            nl: 'Wij blijven geheel tot uw beschikking voor verdere vragen. Met vriendelijke groeten.',
            en: 'We are at your disposal for any further questions. Thank you for your valued custom.',
          }[lang],
        },
      ],

      footerSize: 63,
      footerElements: [
        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE LIVRAISON',
                    en: 'DELIVERY ADDRESS',
                    nl: 'LEVERINGS ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE FACTURATION',
                    en: 'BILLING ADDRESS',
                    nl: 'FACTURATIE ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'NOM ET SIGNATURE',
                    en: 'NAME AND SIGNATURE',
                    nl: 'HANDTEKENING',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children:
                    this.order.deliveries > 1
                      ? [
                          {
                            style: {
                              'white-space': 'pre-wrap',
                            },
                            html: {
                              fr: "Selon l'adresse qui sera sur les appels de stock.",
                              nl: "Selon l'adresse qui sera sur les appels de stock.",
                              en: "Selon l'adresse qui sera sur les appels de stock.",
                            }[lang],
                          },
                        ]
                      : PrintingUtil.formatAddress(
                          this.order.customer.name,
                          this.order.delivery_contact,
                          this.order.delivery_address_text
                        ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    this.order.customer.name,
                    this.order.invoicing_contact,
                    this.order.invoicing_address_text,
                    this.order.customer.vat_number,
                    null,
                    lang
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '20%',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre',
                    position: 'relative',
                    'vertical-align': 'top',
                  },
                  children: [
                    {
                      style: {
                        'text-align': 'center',
                        'white-space': 'pre',
                        'margin-top': '15mm',
                      },
                      html: {
                        fr: 'Le service logistique',
                        en: 'The logistics service',
                        nl: 'De logistieke dienst',
                      }[lang],
                    },
                    {
                      style: {
                        'font-weight': 'bold',
                        'text-align': 'center',
                        'margin-bottom': '5mm',
                      },
                      html: 'Gillian Townley',
                    },
                    {
                      style: {
                        position: 'absolute',
                        left: '2mm',
                        top: '5mm',
                        width: '35mm',
                        height: '8.4mm',
                        'background-image': 'url(assets/img/signature_gill.png)',
                        'background-size': '100% 100%',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        PrintingUtil.getFooter(this.order.accounting, lang),
      ],
    };

    this.pages.push(page);

    //}
    return this;
  }

  public getArticleLines(order: Order, lang: string, paddingRight?: string) {
    let headers: any[] = PrintingUtil.getArticleHeaders(order.article, lang);
    let values: any[] = PrintingUtil.getArticleValues(order.article, true);
    let arr: any[] = [];
    for (let i = 0; i < headers.length; ++i) {
      arr.push({
        style: {
          display: 'table-row',
        },
        children: [
          {
            style: {
              display: 'table-cell',
              'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '50mm',
              padding: '.5mm 1mm',
              'text-align': 'right',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: headers[i],
          },
          {
            style: {
              display: 'table-cell',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              padding: '.5mm 1mm',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': paddingRight || '1mm',
              'padding-bottom': '0',
            },
            html: values[i],
          },
        ],
      });
    }
    if (arr.length > 0) {
      arr[arr.length - 1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
      arr[arr.length - 1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
    }
    return arr;
  }
}
