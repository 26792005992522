import { AccessViewComponent } from './views/access-view.component';
import { AddressSelectionModalComponent } from './address-text-field/address-selection-modal.component';
import { AddressTextFieldComponent } from './address-text-field/address-text-field.component';
import { AutoSizeInputModule } from 'ngx-autosize-input';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { CharsTableComponent } from './chars-table/chars-table.component';
import { ColorModal2Component } from './color-modal2/color-modal2.component';
import { ColorModalComponent } from './color-modal/color-modal.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ComboBoxComponent } from './combo-box/combo-box.component';
import { CommonModule } from '@angular/common';
import { DataGrid2Component } from './data-grid2/data-grid2.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataGridFilterComponent } from './data-grid-filter/data-grid-filter.component';
import { DataListComponent } from './data-list/data-list.component';
import { DataListSearchPipe } from './data-list/data-list-search.pipe';
import { DataTreeComponent } from './data-tree/data-tree.component';
import { DataTreeDirectoryComponent } from './data-tree/data-tree-directory/data-tree-directory.component';
import { DataTreeFileComponent } from './data-tree/data-tree-file/data-tree-file.component';
import { DialogComponent } from './dialogs/dialog/dialog.component';
import { DialogsComponent } from './dialogs/dialogs.component';
import { ForeignListComponent } from './foreign-list/foreign-list.component';
import { FormsModule } from '@angular/forms';
import { ImageUploadComponent } from './image-upload/image-upload.component';
import { IndeterminateDirective } from '../classes/directives/IndeterminateDirective';
import { InfoBlockComponent } from './info-block/info-block.component';
import { LoadingComponent } from './loading/loading.component';
import { LongPressDirective } from '../classes/directives/LongPressDirective';
import { ModalModule } from 'ngx-bootstrap';
import { NgDragDropModule } from 'ng-drag-drop';
import { NgModule } from '@angular/core';
import { NotesComponent } from './notes/notes.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NullableFieldComponent } from './nullable-field/nullable-field.component';
import { NumberFieldComponent } from './number-field/number-field.component';
import { RollingDirectionFieldComponent } from './rolling-direction-field/rolling-direction-field.component';
import { SettingsComponent } from './settings/settings.component';
import { SqlDataGridComponent } from './sql-data-grid/sql-data-grid.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ViewBaseComponent } from './views/view-base.component';
import { ViewsComponent } from './views/views.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ColorPickerModule,
    ModalModule.forRoot(),
    ButtonsModule.forRoot(),
    NgDragDropModule.forRoot(),
    AutoSizeInputModule,
  ],
  declarations: [
    LoadingComponent,
    DataGridComponent,
    DataGrid2Component,
    DataGridFilterComponent,
    SqlDataGridComponent,
    DataListComponent,
    DataListSearchPipe,
    ToolbarComponent,
    ViewsComponent,
    ViewBaseComponent,
    AccessViewComponent,
    InfoBlockComponent,
    NullableFieldComponent,
    ColorModalComponent,
    ColorModal2Component,
    ForeignListComponent,
    ComboBoxComponent,
    DataTreeComponent,
    DataTreeDirectoryComponent,
    DataTreeFileComponent,
    NotificationsComponent,
    AddressTextFieldComponent,
    NotesComponent,
    ImageUploadComponent,
    DialogsComponent,
    DialogComponent,
    NumberFieldComponent,
    LongPressDirective,
    IndeterminateDirective,
    AddressSelectionModalComponent,
    CharsTableComponent,
    SettingsComponent,
    RollingDirectionFieldComponent,
  ],
  exports: [
    LoadingComponent,
    DataGridComponent,
    DataGrid2Component,
    DataGridFilterComponent,
    SqlDataGridComponent,
    DataListComponent,
    ToolbarComponent,
    ViewsComponent,
    ViewBaseComponent,
    AccessViewComponent,
    InfoBlockComponent,
    NullableFieldComponent,
    ColorModalComponent,
    ColorModal2Component,
    ForeignListComponent,
    ComboBoxComponent,
    DataTreeComponent,
    DataTreeDirectoryComponent,
    DataTreeFileComponent,
    NotificationsComponent,
    AddressTextFieldComponent,
    NotesComponent,
    ImageUploadComponent,
    DialogsComponent,
    DialogComponent,
    NumberFieldComponent,
    LongPressDirective,
    IndeterminateDirective,
    AddressSelectionModalComponent,
    CharsTableComponent,
    SettingsComponent,
    RollingDirectionFieldComponent,
  ],
  entryComponents: [ColorModalComponent, ColorModal2Component, DialogComponent, AddressSelectionModalComponent],
  //   entryComponents: [CustomersListViewComponent]
})
export class ComponentsModule {}
