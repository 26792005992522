<div class="customer-validation-warning" *ngIf="isCustomerWaitingValidation">Client en attente de validation !</div>

<div class="customer-view-wrapper">
  <div class="customer-view-info-column">
    <h3>Informations client</h3>

    <info-block #nameBlockComponent [block]="nameBlock" [data]="customerCopy" [readonly]="isViewReadOnly"></info-block>
    <info-block
      #generalBlockComponent
      [block]="generalBlock"
      [data]="customerCopy"
      [readonly]="isViewReadOnly"
    ></info-block>
    <info-block #vatBlockComponent [block]="vatBlock" [data]="customerCopy" [readonly]="isViewReadOnly"></info-block>
    <!-- <info-block #mailsBlockComponent [block]="mailsBlock" [data]="customerCopy" [readonly]="isViewReadOnly"></info-block> -->

    <info-block
      #ordinatorBlockComponent
      [block]="ordinatorBlock"
      [data]="customerCopy.ordinator_address"
      [readonly]="isViewReadOnly"
      [title]="'Client\nordonateur'"
    ></info-block>
    <info-block
      #billingBlockComponent
      [block]="billingBlock"
      [data]="customerCopy.billing_address"
      [readonly]="isViewReadOnly"
      [title]="'Adresse de\nfacturation'"
      backColor="rgb(54,96,146)"
      textColor="white"
    ></info-block>
    <info-block
      #deliveryBlockComponent
      [block]="deliveryBlock"
      [data]="customerCopy.delivery_address"
      [readonly]="isViewReadOnly"
      [title]="'Adresse de\nlivraison'"
      backColor="rgb(36,64,98)"
      textColor="white"
    ></info-block>

    <info-block #bankBlockComponent [block]="bankBlock" [data]="customerCopy" [readonly]="isViewReadOnly"></info-block>
    <info-block
      #remarksBlockComponent
      [block]="remarksBlock"
      [data]="customerCopy"
      [readonly]="isViewReadOnly"
    ></info-block>
  </div>

  <div class="customer-view-addresses-column">
    <div class="customer-view-contacts-column {{ views.printMode ? '' : 'data-grid2' }}">
      <h3>
        Contacts
        <div class="title-buttons">
          <button class="btn btn-default" (click)="addContact()" [disabled]="isViewReadOnly">
            <i class="fa fa-plus"></i>
          </button>
          <button class="btn btn-default" (click)="deleteContacts()" [disabled]="isViewReadOnly">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </h3>

      <div *ngIf="views.printMode">
        <info-block
          *ngFor="let contact of customerCopy.contacts; let index = index"
          #contactsBlockComponent
          [block]="contactBlock"
          [data]="contact"
          [title]="'Contact ' + (index + 1)"
          [backColor]="contactBackColor(99, 37, 35, index, customerCopy.contacts.length)"
          [textColor]="contactTextColor(index, customerCopy.contacts.length)"
          [checkable]="true"
          (selectedChange)="contactsSelectionChanged()"
          [readonly]="isViewReadOnly"
        ></info-block>
      </div>

      <data-grid2
        *ngIf="!views.printMode"
        #contactsGrid
        [columns]="contactsColumns"
        [selectable]="true"
        [items]="customerCopy.orderedContacts"
        [editable]="true"
      ></data-grid2>
    </div>

    <div class="customer-view-delivery-column {{ views.printMode ? '' : 'half-width' }}">
      <h3>
        Adresses de livraison supplémentaires
        <div class="title-buttons">
          <button class="btn btn-default" (click)="addDeliveryAddress()" [disabled]="isViewReadOnly">
            <i class="fa fa-plus"></i>
          </button>
          <button class="btn btn-default" (click)="deleteDeliveryAddresses()" [disabled]="isViewReadOnly">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </h3>

      <div *ngIf="customerCopy.delivery_addresses.length > 0">
        <info-block
          *ngFor="let address of customerCopy.delivery_addresses; let index = index"
          #deliveryAddressesBlockComponent
          [block]="deliveryAddress2Block"
          [data]="address"
          [title]="'Adresse de\nlivraison ' + (index + 2)"
          [backColor]="contactBackColor(99, 37, 35, index, customerCopy.delivery_addresses.length)"
          [textColor]="contactTextColor(index, customerCopy.delivery_addresses.length)"
          [checkable]="true"
          (selectedChange)="deliveryAddressesSelectionChanged()"
          [readonly]="isViewReadOnly"
        ></info-block>
      </div>

      <div *ngIf="customerCopy.delivery_addresses == null || customerCopy.delivery_addresses.length == 0">
        (Aucune adresse supplémentaire pour ce client)
      </div>
    </div>
    <div class="customer-view-invoicing-column {{ views.printMode ? '' : 'half-width' }}">
      <h3>
        Adresses de facturation supplémentaires
        <div class="title-buttons">
          <button class="btn btn-default" (click)="addInvoicingAddress()" [disabled]="isViewReadOnly">
            <i class="fa fa-plus"></i>
          </button>
          <button class="btn btn-default" (click)="deleteInvoicingAddresses()" [disabled]="isViewReadOnly">
            <i class="fa fa-trash"></i>
          </button>
        </div>
      </h3>

      <div *ngIf="customerCopy.invoicing_addresses.length > 0">
        <info-block
          *ngFor="let address of customerCopy.invoicing_addresses; let index = index"
          #invoicingAddressesBlockComponent
          [block]="invoicingAddress2Block"
          [data]="address"
          [title]="'Adresse de\nfacturation ' + (index + 2)"
          [backColor]="contactBackColor(103, 91, 11, index, customerCopy.invoicing_addresses.length)"
          [textColor]="contactTextColor(index, customerCopy.invoicing_addresses.length)"
          [checkable]="true"
          (selectedChange)="invoicingAddressesSelectionChanged()"
          [readonly]="isViewReadOnly"
        ></info-block>
      </div>

      <div *ngIf="customerCopy.invoicing_addresses == null || customerCopy.invoicing_addresses.length == 0">
        (Aucune adresse supplémentaire pour ce client)
      </div>
    </div>

    <div style="width: 100%">
      <h3
        class="{{ turnoversVisible ? 'toggle-opened' : 'toggle-closed' }}"
        (click)="turnoversVisible = !turnoversVisible"
      >
        Chiffres client
      </h3>
      <data-grid
        *ngIf="turnoversVisible"
        id="sale-customer-turnover"
        class="turnovers-grid"
        [columns]="turnoverColumns"
        [items]="turnovers"
        [selectable]="false"
        [editable]="false"
        [resizable]="false"
      ></data-grid>
    </div>

    <div style="width: 100%">
      <h3
        class="{{ incidentsVisible ? 'toggle-opened' : 'toggle-closed' }}"
        (click)="incidentsVisible = !incidentsVisible"
      >
        Incidents
      </h3>
      <data-grid
        *ngIf="incidentsVisible"
        id="sale-incidents"
        class="incidents-grid"
        [columns]="incidentsColumns"
        [items]="incidents"
        [selectable]="false"
        [editable]="false"
        [resizable]="false"
        [rowStyleFunction]="incidentRowStyle"
      ></data-grid>
    </div>
  </div>
</div>

<div class="stock-platform-container" *ngIf="showStockPlatform">
  <form #loginForm action="https://stock.graphiseal.com/login.php" method="POST" target="customer_stocks">
    <input name="username" [value]="customerCopy.number" />
    <input type="hidden" name="gs_secret" value="e5b7e54c873d2be5935defbb12a7348f4273bec67cf4df70836f326e06ead9cf" />
    <input type="hidden" name="login" value="1" />
  </form>
  <iframe name="customer_stocks" src="https://stock.graphiseal.com/logout.php" (load)="onIframeLoaded()"></iframe>
  <button class="stock-platform-close-button" (click)="closeStockPlatform()">
    <i class="fa fa-times"></i>&nbsp;Fermer
  </button>
</div>

<div class="copy-to-sales-container" *ngIf="shouldShowCopyToSales">
  <div class="content__container">
    <h2>Copier vers les dossiers commerciaux</h2>

    <hr />
    <h3>Informations à copier</h3>
    <p>Veuillez cocher les informations à copier :</p>

    <div class="row">
      <label for="copyDeadline" class="col-md-4">
        <input id="copyDeadline" type="checkbox" [(ngModel)]="shouldCopyDeadline" />
        <span>Conditions de paiement</span>
      </label>
      <div class="col-md-8">{{ customerCopy.deadline }}</div>
    </div>

    <div class="row">
      <label for="copyOrdinatorAddress" class="col-md-4">
        <input id="copyOrdinatorAddress" type="checkbox" [(ngModel)]="shouldCopyOrdinatorAddress" />
        <span>Client ordonateur</span>
      </label>
      <div class="col-md-8">{{ customerCopy.ordinator_address.oneLineText }}</div>
    </div>

    <div class="row">
      <label for="copyInvoicingAddress" class="col-md-4">
        <input id="copyInvoicingAddress" type="checkbox" [(ngModel)]="shouldCopyInvoicingAddress" />
        <span>Adresse de facturation</span>
      </label>
      <div class="col-md-8">
        <select [(ngModel)]="selectedCopyInvoicingAddress">
          <optgroup label="Adresse de facturation principale">
            <option [value]="-1" selected>{{ customerCopy.delivery_address.oneLineText }}</option>
          </optgroup>
          <option *ngIf="customerCopy.invoicing_addresses.length > 0" disabled value></option>
          <optgroup *ngIf="customerCopy.invoicing_addresses.length > 0" label="Adresses de facturation supplémentaires">
            <option *ngFor="let address of customerCopy.invoicing_addresses; let index = index" [value]="index">
              {{ address.oneLineText }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>

    <div class="row">
      <label for="copyDeliveryAddress" class="col-md-4">
        <input id="copyDeliveryAddress" type="checkbox" [(ngModel)]="shouldCopyDeliveryAddress" />
        <span>Adresse de livraison</span>
      </label>
      <div class="col-md-8">
        <select [(ngModel)]="selectedCopyDeliveryAddress">
          <optgroup label="Adresse de livraison principale">
            <option [value]="-1" selected>{{ customerCopy.delivery_address.oneLineText }}</option>
          </optgroup>
          <option *ngIf="customerCopy.delivery_addresses.length > 0" disabled value></option>
          <optgroup *ngIf="customerCopy.delivery_addresses.length > 0" label="Adresses de livraison supplémentaires">
            <option *ngFor="let address of customerCopy.delivery_addresses; let index = index" [value]="index">
              {{ address.oneLineText }}
            </option>
          </optgroup>
        </select>
      </div>
    </div>

    <hr />
    <h3>Dossiers commerciaux</h3>

    <p>Veuillez sélectionner les dossiers commerciaux vers lesquels copiers ces informations :</p>

    <data-grid2
      #salesToCopyGrid
      [items]="salesToCopy.items"
      [columns]="salesToCopyColumns"
      [selectable]="true"
    ></data-grid2>

    <hr />
    <p>
      <strong
        >ATTENTION : cette opération est irréversible, les données des dossiers commerciaux seront écrasés !</strong
      >
    </p>

    <div class="buttons">
      <button (click)="copyToSales()" class="btn btn-primary">Copier</button>
      <button (click)="closeCopyToSales()" class="btn btn-default">Annuler</button>
    </div>
  </div>
</div>
