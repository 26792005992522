<div class="printable-page" #pageItem [ngStyle]="page.style">
    <div class="printable-page-header" [ngStyle]="{'height': page.headerSize + 'mm'}">
        <printable-element #childElement *ngFor="let elem of page.headerElements" [element]="elem" [ngStyle]="elem.style"></printable-element>
    </div>
    <div class="printable-page-content" [ngStyle]="{'height': (page.height - page.headerSize - page.footerSize - page.topMargin - page.bottomMargin) + 'mm'}">
        <printable-element #childElement *ngFor="let elem of page.bodyElements" [element]="elem" [ngStyle]="elem.style"></printable-element>
    </div>
    <div class="printable-page-footer" [ngStyle]="{'height': page.footerSize + 'mm'}">
        <printable-element #childElement *ngFor="let elem of page.footerElements" [element]="elem" [ngStyle]="elem.style"></printable-element>
    </div>
</div>