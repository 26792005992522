import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ModuleBase} from '../module-base.class';
import {ClassManager} from '../../classes/objects/ClassManager.class';
import {Charge} from '../charges/classes/Charge.class';
import {ChargeType} from '../charges/classes/ChargeType.class';
import {HomepageViewComponent} from '../homepage/views/homepage-view/homepage-view.component';
import {ChargesListViewComponent} from '../charges/views/charges-list-view/charges-list-view.component';
import {ReportViewComponent} from '../reports/views/report-view/report-view.component';
import {ChargesReportModel} from '../charges/classes/ChargesReportModel.class';
import { BobSalesViewComponent } from './views/bob-sales-view/bob-sales-view.component';
import { BobPurchasesViewComponent } from './views/bob-purchases-view/bob-purchases-view.component';
import {ComponentsModule} from '../../components/components.module';
import {FormsModule} from '@angular/forms';

@NgModule({
    imports: [
    CommonModule,
        ComponentsModule,
        FormsModule
    ],
    declarations: [
        BobSalesViewComponent,
        BobPurchasesViewComponent
    ],
    entryComponents: [
        BobSalesViewComponent,
        BobPurchasesViewComponent
    ]
})
export class BobModule extends ModuleBase {

    public static moduleName = 'bob';

    public ngOnInit()
    {
        // ClassManager.registerClass('Charge', Charge);
        // ClassManager.registerClass('ChargeType', ChargeType);

        HomepageViewComponent.registerGroups({
            'bob': {
                'name': "Comptabilité",
                'permission': 'home_groupe_comptabilité',
                'backColor': 'rgb(193,63,178)',
                'textColor': 'white',
                'children': [
                    {
                        'name': "Exporter les ventes",
                        'permission': 'home_bouton_comptabilite_ventes',
                        'component': BobSalesViewComponent
                    },
                    {
                        'name': "Exporter les achats",
                        'permission': 'home_bouton_comptabilite_achats',
                        'component': BobPurchasesViewComponent,
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}

