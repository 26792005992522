import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Invoice } from '../../../invoicing/classes/Invoice.class';
//import { InvoiceViewComponent } from '../invoice-view/invoice-view.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { ObjectModel2 } from 'src/app/classes/objects/ObjectModel2.class';
import { Payment } from '../../classes/Payment.class';
import { PaymentStatus } from '../../classes/PaymentStatus.class';
import { Reimbursement } from 'src/app/modules/invoicing/classes/Reimbursement.class';
import { RemindersViewComponentComponent } from '../reminders-view-component/reminders-view-component.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-customer-payments-list-view',
  templateUrl: './customer-payments-list-view.component.html',
  styleUrls: ['./customer-payments-list-view.component.css'],
})
export class CustomerPaymentsListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_paiements_clients';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Paiements clients',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: CustomerPaymentsListViewComponent) {
          view.save();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Archiver',
        icon: 'fas fa-archive',
        click: function (view: CustomerPaymentsListViewComponent) {
          view.archiveSelection();
        },
        access: this.writeAccess,
      },
      { type: 'separator-large' },
      {
        type: 'toggle-button',
        text: 'Afficher tous<br/>les paiements',
        icon: 'filter',
        value: false,
        click: function (view: CustomerPaymentsListViewComponent) {
          view.filterInvoices = !this.value;
          //view.updateSuppliersList();
        },
      },
      { type: 'separator-large' },
      {
        type: 'button',
        text: 'Envoyer<br/>un rappel',
        icon: 'euro-sign',
        click: function (view: CustomerPaymentsListViewComponent) {
          view.createReminder();
        },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public statusesData: any = { items: [] };
  public invoices: (Invoice | Reimbursement)[] = [];
  public filterInvoices: boolean = true;
  public get filteredInvoices(): (Invoice | Reimbursement)[] {
    if (!this.filterInvoices) return this.invoices;
    return this.invoices.filter((value: Invoice, index: number, array: Invoice[]) => value.payment_archived != 1);
  }

  public invoicesGridColumns = [
    {
      title: 'Date de\nfacture',
      field: 'date',
      type: 'date',
      readonly: true,
      width: 80,
    },
    { title: 'Numéro de facture', field: 'number', readonly: true, width: 190 },
    {
      title: "Numéro de commande\nou d'appel",
      field: 'orderNumber',
      readonly: true,
      width: 190,
    },
    {
      title: 'Nom du client',
      field: 'customer.nameWithIdentifier',
      readonly: true,
      width: 230,
    },
    {
      title: 'Désignation',
      field: 'article.designation',
      readonly: true,
      width: 450,
    },
    {
      title: 'Total\nH.T.V.A.',
      field: 'converted_totalAmount',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      readonly: true,
      width: 100,
    },
    {
      title: 'Total à\npercevoir',
      field: 'converted_totalWithTaxWithTaxDiscount',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      readonly: true,
      width: 100,
    },
    { title: 'Échéance', field: 'deadline', readonly: true, width: 150 },
    {
      title: "Date\nd'échéance",
      field: 'expiration',
      type: 'date',
      readonly: true,
      width: 80,
    },
    { title: 'Jours\nécoulés', field: 'daysSince', readonly: true, width: 85 },
    {
      title: 'Jours échus /\nà échoir',
      field: 'daysDue',
      readonly: true,
      width: 85,
    },
    {
      title: 'Paiement\nfournisseur',
      field: 'has_supplier_payment',
      readonly: true,
      width: 50,
    },
    {
      title: 'Date du\npaiement',
      field: 'payment_date',
      type: 'date',
      headerBackColor: '#666',
      headerTextColor: 'white',
      minWidth: '140px',
      width: 130,
    },
    {
      title: 'Montant\nperçu',
      field: 'converted_payment_amount',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      headerBackColor: '#666',
      headerTextColor: 'white',
      width: 100,
    },
    {
      title: 'État',
      field: 'payment_status',
      type: 'foreign-list',
      listItems: this.statusesData,
      allowNull: true,
      listField: 'name',
      multiSelect: false,
      nullText: '(En attente)',
      headerBackColor: '#666',
      headerTextColor: 'white',
      minWidth: '100px',
      width: 120,
    },
    {
      title: 'Remarques',
      field: 'payment_remarks',
      headerBackColor: '#666',
      headerTextColor: 'white',
      minWidth: '140px',
      width: 250,
    },
    {
      title: 'Pertes\n(escomptes)\net profits\n(payé 2x)',
      field: 'converted_payment_result',
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      width: 100,
      readonly: true,
    },
    {
      title: 'Représentant',
      field: 'merchant.numberAndName',
      readonly: true,
      width: 150,
    },
    {
      title: "Prix d'achat",
      field: 'total_buyPrice',
      readonly: true,
      type: 'number',
      currencyField: 'buy_currency',
      decimalsCount: 2,
      width: 100,
    },
    {
      title: `Frais\nexternes /\n${config.companyName}`,
      field: 'total_externalFees',
      readonly: true,
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      width: 100,
    },
    {
      title: 'Frais de\ngestion',
      field: 'total_internalFees',
      readonly: true,
      type: 'number',
      currencyField: 'sell_currency',
      decimalsCount: 2,
      width: 100,
    },
    {
      title: 'Marge brute',
      field: 'total_rawMargin',
      readonly: true,
      type: 'number',
      unit: '€',
      decimalsCount: 2,
      width: 100,
    },
    {
      title: 'Marge nette',
      field: 'total_netMargin',
      readonly: true,
      type: 'number',
      unit: '€',
      decimalsCount: 2,
      width: 100,
    },
    {
      title: 'Marge\naccordée au\nreprésentant',
      field: 'merchantMargin',
      readonly: true,
      type: 'number',
      unit: '€',
      decimalsCount: 2,
      width: 100,
    },
    //{ title: 'Statut', field: 'payment.status', listItems: this.statusesData, nameField: 'name', }
  ];

  @ViewChild('grid') grid: DataGridComponent;

  public logisticsView: boolean = false;
  initView(logisticsView: boolean = false) {
    // this.logisticsView = logisticsView;
    // if (logisticsView)
    // {
    //     this.toolbar.viewTitle = 'Facturier logistique';
    // }
  }

  ngOnInit() {
    let self = this;
    Invoice.loadSupplierPayments();
    this.reloadData();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadData();
  }
  reloadData() {
    let conditions1: string = 'DeletedAt IS NULL AND proforma != 1';
    if (AccountingsService.currentAccounting)
      conditions1 += ` AND id_accounting='${AccountingsService.currentAccounting.id}'`;
    this.invoices = [];
    let promises: any[] = [];
    promises.push(
      Invoice.load(null, null, null, null, conditions1).then(
        (result) => {
          this.invoices = this.invoices.concat(result);
          for (let i = 0; i < this.invoices.length; ++i) {
            let obj: Invoice | Reimbursement = this.invoices[i];
            if (obj instanceof Invoice) {
              let invoice: Invoice = obj;
              if (invoice.daysDue < 0) {
                PaymentStatus.loadUnpaidStatus().then((result: PaymentStatus) => {
                  if (!invoice.payment_status) invoice.payment_status = result;
                });
              }
            }
          }
        },
        (err) => {
          console.error(err);
        }
      )
    );
    let conditions2: string = 'DeletedAt IS NULL';
    if (AccountingsService.currentAccounting)
      conditions2 += ` AND id_accounting='${AccountingsService.currentAccounting.id}'`;
    promises.push(
      Reimbursement.load(null, null, null, null, conditions2).then(
        (result) => {
          this.invoices = this.invoices.concat(result);
        },
        (err) => {
          console.error(err);
        }
      )
    );
    PaymentStatus.load().then(
      (result) => {
        this.statusesData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Promise.all(promises).then(() => {
      this.invoices.sort((a: any, b: any) => {
        return b.date - a.date;
      });
    });
  }

  archiveSelection() {
    let selection: Invoice[] = this.grid.selectedItems;
    let promises: Promise<any>[] = [];
    let canArchive: boolean = true;
    for (let i = 0; i < selection.length; ++i) {
      if (!selection[i].payment_status || selection[i].payment_status.ended != 1) {
        promises.push(
          DialogsComponent.display({
            title: 'Paiements non confirmés',
            message:
              'Dans votre sélection, un ou plusieurs paiements n\'ont pas le statut "Payé".<br/>Êtes-vous sûr(e) de vouloir les archiver ?',
            buttons: DialogButton.yesNoButtons,
            icon: 'warning',
          }).then((result) => {
            if (result != DialogButton.RESULT_YES) canArchive = false;
          })
        );
      }
    }
    Promise.all(promises).then((result) => {
      let savePromises: Promise<any>[] = [];
      let seq: number = ++ObjectModel2.nextSequence;
      if (canArchive == true) {
        for (let i = 0; i < selection.length; ++i) {
          selection[i].payment_archived = 1;
          savePromises.push(selection[i].save2(seq));
        }
      }
      if (savePromises.length > 0) {
        Promise.all(savePromises).then(
          (result) => {
            NotificationsComponent.push({
              title: 'Sauvegarde effectuée',
              summary: '<b>' + savePromises.length + '</b> paiements ont été sauvegardés.',
              type: 'success',
            });
          },
          (err) => {
            NotificationsComponent.push({
              title: "Échec d'enregistrement",
              summary: "L'enregistrement des paiements a échoué.",
              content: err,
              type: 'error',
            });
          }
        );
      }
    });
  }

  createReminder() {
    let selection: Invoice[] = this.grid.selectedItems;
    ViewsComponent.openView(RemindersViewComponentComponent, selection);
  }

  gridRowStyle(item: Invoice) {
    return item && item.payment_archived == 1
      ? {
          color: 'gray',
          'font-style': 'italic',
        }
      : null;
  }

  save() {
    let seq = Date.now();
    let promises: Promise<any>[] = [];
    for (let i = 0; i < this.invoices.length; ++i) {
      if (this.invoices[i].changed === true) promises.push(this.invoices[i].save2(seq));
    }
    if (promises.length > 0) {
      Promise.all(promises).then(
        (result) => {
          NotificationsComponent.push({
            title: 'Sauvegarde effectuée',
            summary: '<b>' + promises.length + ' paiements</b> ont été sauvegardés avec succès.',
            type: 'success',
          });
        },
        (err) => {
          NotificationsComponent.push({
            title: 'Erreur',
            summary: "Une erreur s'est produite lors de la sauvegarde.",
            type: 'error',
            content: err,
          });
        }
      );
    }
  }
}
