<div *ngIf="model">
    <div class="pages-wrapper" #pagesWrapper [ngStyle]="{'zoom': zoom}" (click)="selectObject($event, null)">
        <printable-page #pagesElements *ngFor="let page of model.pages" [page]="page" [ngStyle]="{'width': page.width+'mm', 'height': page.height+'mm'}"></printable-page>
    </div>
    <div class="mm-standard" #mmStandard></div>
    <div class="style">
        <div class="parent">
            <!-- <button (click)="selectParent($event)">
                <i class="fa fa-level-up-alt"></i>
                <span>Parent</span>
            </button> -->
        </div>
        <info-block *ngIf="model.optionsBlock" [block]="model.optionsBlock" [data]="model"></info-block>
        <info-block [block]="contentBlock" [data]="self"></info-block>


        <!-- <info-block [block]="fontBlock" [data]="selectedStyle"></info-block> -->
        <div *ngIf="selectedStyle" class="custom-block font-block">
            <h3>Police</h3>
            <select [(ngModel)]="selectedStyle['font-family']">
                <option [ngValue]="'Calibri'">Calibri</option>
                <option [ngValue]="'Courier'">Courier</option>
                <option [ngValue]="'Helvetica'">Helvetica</option>
                <option [ngValue]="'Symbol'">Symbol</option>
                <option [ngValue]="'Times New Roman'">Times New Roman</option>
                <option [ngValue]="'Verdana'">Verdana</option>
            </select>
            <select class="font-size-input" [(ngModel)]="selectedStyle['font-size']">
                <option *ngFor="let i of [8,9,10,11,12,14,16,18,20,22,24,26,28,36,48,72]" [ngValue]="i+'pt'">{{i}}</option>
            </select>
            <button class="btn btn-default btn-bold" [(ngModel)]="selectedStyle['font-weight']" btnCheckboxTrue="bold" btnCheckboxFalse="normal" btnCheckbox>G</button>
            <button class="btn btn-default btn-italic" [(ngModel)]="selectedStyle['font-style']" btnCheckboxTrue="italic" btnCheckboxFalse="normal" btnCheckbox>I</button>
            <button class="btn btn-default btn-underline" [(ngModel)]="selectedStyle['text-decoration']" btnCheckboxTrue="underline" btnCheckboxFalse="none" btnCheckbox>S</button>
            <button class="btn btn-default btn-color" (click)="selectTextColor()">
                <i class="fas fa-palette"></i>
                <div class="color-band" [ngStyle]="{ 'background-color': selectedStyle['color'] }"></div>
            </button>
            <button class="btn btn-default btn-backcolor" (click)="selectBackColor()">
                <i class="fas fa-highlighter"></i>
                <div class="color-band" [ngStyle]="{ 'background-color': selectedStyle['background-color'] }"></div>
            </button>
        </div>

        <info-block [block]="backgroundBlock" [data]="selectedStyle"></info-block>
        <info-block [block]="paragraphBlock" [data]="selectedStyle"></info-block>
        <info-block [block]="borderBlock" [data]="selectedStyle"></info-block>
        <info-block [block]="positionBlock" [data]="selectedStyle"></info-block>
    </div>
</div>
<form #printForm style="display:none" target="_blank" [attr.action]="form_action" method="POST">
    <input type="hidden" name="model" />
</form>