<table *ngIf="block" class="info-block" [ngStyle]="{ 'background-color': backColor || block.backColor || 'gray', 'color': textColor || block.textColor || 'black' }" (click)="callBlockFunc('click', block, data, $event)">
    <tr *ngFor="let field of block.fields; let first = first;" [ngStyle]="{'display': field.visible === false ? 'none' : 'table-row'}" [ngClass]="{'readonly': readonly === true || field.readonly === true}">
        <th *ngIf="first" [attr.rowspan]="block.fields.length" class="info-block-title" (click)="selectData()">
            <span>{{ title || block.title }}</span>
        </th>
        <td class="info-block-label" (click)="selectData()">
            <span>{{ field.title }}</span>
        </td>
        <td class="info-block-input" [ngClass]="{'readonly': readonly || (field.options && field.options.disabled == true)}">
            <div *ngIf="(field.type == 'text' || field.type == 'url' || !field.type)">
                <input *ngIf="data && !readonly && !field.readonly"
                       type="text" [(ngModel)]="data[field.field]"
                       #blockInput #inputElements [attr.data-field]="field.field"
                       (focus)="focusField($event, field)" (change)="callFieldFunc('change', block, field, data, $event)"
                       (focus)="callFieldFunc('focus', block, field, data, $event)"
                       (keyup)="callFieldFunc('keyup', block, field, data, $event)" />
                <!-- <span class="textarea-field" -->
                <span class="textarea-field" *ngIf="(field.type == 'text' || !field.type) && (data && (readonly || field.readonly))"
                      [innerHTML]="getItemValue(data, field)"></span>
                <a class="textarea-field" *ngIf="(field.type == 'url') && (data && (readonly || field.readonly))"
                   [innerHTML]="getItemValue(data, field)" [attr.href]="getItemValue(data, field)" target="_blank"></a>
            </div>
            <div *ngIf="field.type == 'textarea'">
                <textarea class="textarea-input" *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" type="text" [(ngModel)]="data[field.field]" (focus)="focusField($event, field)" (change)="callFieldFunc('change', block, field, data, $event)" (focus)="callFieldFunc('focus', block, field, data, $event)"></textarea>
                <span class="textarea-input" *ngIf="data && (readonly || field.readonly)" [innerHTML]="data[field.field]"></span>
            </div>
            <div *ngIf="field.type == 'checkbox'">
                <input *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" type="checkbox" [(ngModel)]="data[field.field]" (focus)="focusField($event, field)" (change)="callFieldFunc('change', block, field, data, $event)" (focus)="callFieldFunc('focus', block, field, data, $event)" />
                <span class="checkbox-icon" *ngIf="data && (readonly || field.readonly)"><i class="far fa-{{data[field.field] == true ? 'check-' : ''}}square"></i></span>
                <span class="checkbox-label" (click)="checkboxLabelClick($event, block, field, data, $event)">{{ data[field.field] == true ? (field.checkedValue ? field.checkedValue : 'Oui') : ((field.uncheckedValue ? field.uncheckedValue : 'Non')) }}</span>
            </div>
            <div *ngIf="field.type == 'number'">
                <number-field *ngIf="data && !(readonly || field.readonly)" [readonly]="false"
                              #blockInput [attr.data-field]="field.field" [currency]="data[field.currencyField]"
                              [(ngModel)]="data[field.field]" [unit]="field.numberUnit"
                              [textAlign]="field.textAlign" [decimalsCount]="field.decimalsCount"
                              [inputSize]="field.inputSize" (focus)="focusField($event, field)"
                              (change)="callFieldFunc('change', block, field, data, $event)"
                              (focus)="callFieldFunc('focus', block, field, data, $event)"></number-field>
                <number-field *ngIf="data && (readonly || field.readonly)" [readonly]="true"
                              #blockInput [attr.data-field]="field.field" [currency]="data[field.currencyField]"
                              [ngModel]="getItemValue(data, field)" [unit]="field.numberUnit"
                              [textAlign]="field.textAlign" [decimalsCount]="field.decimalsCount"
                              (focus)="focusField($event, field)"
                              (change)="callFieldFunc('change', block, field, data, $event)"
                              (focus)="callFieldFunc('focus', block, field, data, $event)"></number-field>
            </div>
            <div *ngIf="field.type == 'date'">
                <input *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" type="date" [(ngModel)]="data[field.field]" (change)="callFieldFunc('change', block, field, data, $event)" (focus)="callFieldFunc('focus', block, field, data, $event)" />
                <span *ngIf="data && (readonly || field.readonly)" [innerHTML]="getDateValue(data, field)"></span>
            </div>
            <div *ngIf="field.type == 'rolling-direction'">
                <rolling-direction-field *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" [(ngModel)]="data[field.field]" (change)="callFieldFunc('change', block, field, data, $event)"></rolling-direction-field>
                <img *ngIf="data && (readonly || field.readonly) && getItemValue(data, field) > 0" [src]="'assets/img/rolling-directions/'+getItemValue(data, field)+'.png'"/>
            </div>
            <div *ngIf="field.type == 'nullable-text'">
                <nullable-field *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" type="text" [(ngModel)]="data[field.field]" (change)="callFieldFunc('change', block, field, data, $event)" (focus)="callFieldFunc('focus', block, field, data, $event)" [nullText]="field.nullText" [allowBlankValues]="field.allowBlankValues"></nullable-field>
                <span *ngIf="data && (readonly || field.readonly)" [innerHTML]="data[field.field]"></span>
            </div>
            <div *ngIf="field.type == 'select'">
                <select *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" [(ngModel)]="data[field.field]" (change)="callFieldFunc('change', block, field, data, $event)" (focus)="callFieldFunc('focus', block, field, data, $event)">
                    <option *ngFor="let option of field.selectOptions" [ngValue]="option.value">{{ option.text }}</option>
                </select>
                <span *ngIf="data && (readonly || field.readonly)" [innerHTML]="(field.selectOptions[data[field.field]] || {}).text"></span>
            </div>
            <div *ngIf="field.type == 'foreign-list'">
                <foreign-list *ngIf="data && !readonly && !field.readonly" #blockInput class="foreign-list"
                              [attr.data-field]="field.field" [(ngModel)]="data[field.field]"
                              (mousedown)="callFieldFunc('mousedown', block, field, data, $event)"
                              (change)="callFieldFunc('change', block, field, data, $event)"
                              (focus)="callFieldFunc('focus', block, field, data, $event)"
                              [multiSelect]="field.multiSelect" [objects]="field.listItems.items"
                              [fieldName]="field.listField" [nullText]="field.nullText"
                              [allowNull]="field.allowBlankValues" [getOptionStyle]="field.getOptionStyle"></foreign-list>
                <span *ngIf="data && (readonly || field.readonly)" [innerHTML]="getForeignListText(data, field)"></span>
            </div>
            <div *ngIf="field.type == 'searchable-foreign-list' && data && !readonly && !field.readonly" class="searchable-foreign-list">
                <input type="text" [(ngModel)]="field.textFilter" class="searchable-foreign-list-input" (blur)="blurSearchableTextField(block, field, data)" />
                <foreign-list #blockInput class="foreign-list"
                              [attr.data-field]="field.field" [(ngModel)]="data[field.field]"
                              (mousedown)="callFieldFunc('mousedown', block, field, data, $event)"
                              (change)="callFieldFunc('change', block, field, data, $event)"
                              (focus)="callFieldFunc('focus', block, field, data, $event)"
                              [multiSelect]="field.multiSelect" [objects]="filterForeignList(block, field, data)"
                              [fieldName]="field.listField" [nullText]="field.nullText"
                              [allowNull]="field.allowBlankValues" [getOptionStyle]="field.getOptionStyle"></foreign-list>
            </div>
            <div *ngIf="field.type == 'searchable-foreign-list' && data && (readonly || field.readonly)">
                <span [innerHTML]="getForeignListText(data, field)"></span>
            </div>
            <div *ngIf="field.type == 'combo-box'">
                <combo-box *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" [(ngModel)]="data[field.field]"
                           (mousedown)="callFieldFunc('mousedown', block, field, data, $event)"
                           (change)="callFieldFunc('change', block, field, data, $event)"
                           (focus)="callFieldFunc('focus', block, field, data, $event)"
                           (select)="callFieldFunc('select', block, field, data, $event)"
                           [objects]="field.listItems ? field.listItems.items : null"
                           [fieldName]="field.listField" [linesCount]="field.linesCount"
                           [disableFiltering]="field.disableFiltering"></combo-box>
                <span *ngIf="data && (readonly || field.readonly)" [innerHTML]="data[field.field]"></span>
            </div>
            <div *ngIf="field.type == 'address-text'">
                <address-text-field *ngIf="data && !readonly && !field.readonly" #blockInput [attr.data-field]="field.field" [(ngModel)]="data[field.field]"
                                    (mousedown)="callFieldFunc('mousedown', block, field, data, $event)"
                                    (addressChange)="callFieldFunc('addressChange', block, field, data, $event)"
                                    (textChange)="callFieldFunc('textChange', block, field, data, $event)"
                                    (focus)="callFieldFunc('focus', block, field, data, $event)"
                                    [addressFunction]="field.addressFunction"
                                    [addresses]="field.listItems.items" [nullText]="field.nullText"
                                    [allowNull]="field.allowBlankValues" [prefix]="field.prefix"></address-text-field>
                <span *ngIf="data && (readonly || field.readonly)" class="address-text-field" [innerHTML]="data[field.field] ? data[field.field] : ''"></span>
            </div>
            <div *ngIf="field.type == 'image'">
                <image-upload *ngIf="data && (data[field.field] || !readonly)" [(ngModel)]="data[field.field]" [readonly]="readonly === true || field.readonly === true" (change)="callFieldFunc('change', block, field, data, $event)"></image-upload>
                <!-- <img *ngIf="data && data[field.field] && readonly" [src]="data[field.field]" /> -->
                <span *ngIf="data && !data[field.field] && readonly">(Aucune image)</span>
                <!-- <img *ngIf="data && readonly" [src]]="data[field.field] ? data[field.field] : 'assets/img/no_image.png'" /> -->
            </div>
            <div *ngIf="field.comment" class="comment" [ngClass]="{'comment-left': field.commentLeft === true}">
                <div class="comment-content" [innerHTML]="field.comment"></div>
            </div>
        </td>
    </tr>
    <input type="checkbox" class="select-checkbox" *ngIf="data != null && !readonly && checkable == true" [(ngModel)]="data.selected" (click)="selectData()" />
</table>
