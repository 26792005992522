<div class="stock-container">
  <div class="stock-container-left-column">
    <h3>Informations stock</h3>

    <info-block #selectBlockComponent [block]="selectBlock" [data]="instance" [readonly]="!!stockCopy.id"></info-block>
    <!-- <info-block #parentBlockComponent [block]="parentBlock" [data]="stockCopy"></info-block> -->
    <info-block #stockBlockComponent [block]="stockBlock" [data]="stockCopy" [readonly]="!writeAccess"></info-block>
    <info-block #remarksBlockComponent [block]="remarksBlock" [data]="stockCopy" [readonly]="!writeAccess"></info-block>

    <h3>Appels sur stock</h3>

    <data-grid
      #movesGrid
      id="stock-linked-moves"
      [items]="stockCopy.moves"
      [columns]="movesGridColumns"
      [editable]="false"
    >
    </data-grid>

    <h3>Ajustements de stock</h3>

    <data-grid
      #adjustmentsGrid
      id="stock-linked-adjustments"
      [items]="stockCopy.adjustments"
      [columns]="adjustmentsGridColumns"
      [editable]="false"
    >
    </data-grid>
  </div>

  <div class="stock-container-right-column">
    <h3>Informations article</h3>

    <info-block
      #articleBlockComponent
      *ngIf="isArticle || !stockCopy"
      [block]="articleBlock"
      [data]="stockCopy ? stockCopy.article : null"
      [readonly]="true"
    ></info-block>
    <info-block
      #deviceBlockComponent
      *ngIf="isDevice"
      [block]="deviceBlock"
      [data]="stockCopy.article"
      [readonly]="true"
    ></info-block>
    <info-block
      #serviceBlockComponent
      *ngIf="isService"
      [block]="serviceBlock"
      [data]="stockCopy.article"
      [readonly]="true"
    ></info-block>
  </div>
</div>
