import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { ArticleBase } from 'src/app/modules/articles/classes/ArticleBase.class';
import { ArticleInfoBlocks } from 'src/app/modules/articles/classes/ArticleInfoBlocks.class';
import { Customer } from 'src/app/modules/customers/classes/Customer.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { Form } from 'src/app/classes/forms/Form.class';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { InfoBlockField } from 'src/app/components/info-block/classes/InfoBlockField.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Order } from 'src/app/modules/orders/classes/Order.class';
import { Stock } from '../../classes/Stock.class';
import { StockAdjustment } from '../../classes/StockAdjustment.class';
import { StockMove } from '../../classes/StockMove.class';
import { Supplier } from 'src/app/modules/suppliers/classes/Supplier.class';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';

@Component({
  selector: 'app-stock-adjustment-view',
  templateUrl: './stock-adjustment-view.component.html',
  styleUrls: ['./stock-adjustment-view.component.css'],
})
export class StockAdjustmentViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_ficheajustement';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Ajustement de stock',
    data: this,
    elements: [
      { type: 'separator' },
      {
        type: 'button',
        name: 'saveButton',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: StockAdjustmentViewComponent) {
          view.save().then((result) => {
            //view.updateView(view.demandCopy);
          });
        },
        options: { visible: true },
        access: this.writeAccess,
      },
      { type: 'spacing' },
    ],
  };

  public adjustment: StockAdjustment = null;
  public adjustmentCopy: StockAdjustment = null;

  public selectedStock: Stock = null;
  public stocksData: any = { items: [] };
  private orders: Order[] = [];
  public ordersData: any = { items: [] };

  @ViewChild('selectBlockComponent') selectBlockComponent;
  public selectBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(0,0,0)',
    textColor: 'white',
    fields: [
      {
        title: 'Stock lié',
        field: 'selectedStock',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.stocksData,
        listField: 'numberNameAndDate',
        nullText: '(Aucun)',
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
          this.updateView(this.adjustmentCopy);
        },
      },
    ],
  };
  // @ViewChild('parentBlockComponent') parentBlockComponent;
  // public parentBlock: InfoBlock = {
  //     title: '',
  //     backColor: "rgb(0,0,0)",
  //     textColor: "white",
  //     fields: [
  //         { title: 'Stock parent', field: 'parent', type: 'foreign-list',
  //           multiSelect: false, listItems: this.parentStockData, listField: 'fullDescription', nullText: '(Aucun)',
  //           change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
  //               this.updateView(this.stockCopy);
  //           } }
  //     ]
  // };

  @ViewChild('stockBlockComponent') stockBlockComponent;
  public stockBlock: InfoBlock = {
    title: 'Stock',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'Date de création', type: 'date', field: 'date' },
      { title: 'Dernière mise à jour', type: 'date', field: 'update_date' },
      { title: 'Client', field: 'customer.nameWithIdentifier' },
      { title: 'Référence article client', field: 'article_reference' },
      { title: 'Fournisseur', field: 'supplier.name' },
      { title: 'Quantité de départ', field: 'quantity', type: 'number', textAlign: 'left' },
      { title: 'Quantité actuelle', field: 'availableStock', type: 'number', textAlign: 'left', readonly: true },
      { title: 'Unité', field: 'unit', type: 'text' },
      {
        title: "Prix d'achat unité",
        field: 'converted_buy_price',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 5,
        currencyField: 'buy_currency',
      },
      {
        title: 'Prix de vente unité',
        field: 'converted_sell_price',
        type: 'number',
        textAlign: 'left',
        decimalsCount: 5,
        currencyField: 'sell_currency',
      },
    ],
  };

  @ViewChild('stockBlockComponent') adjustmentBlockComponent;
  public adjustmentBlock: InfoBlock = {
    title: 'Ajustement',
    backColor: 'rgb(25,48,75)',
    textColor: 'white',
    fields: [
      { title: 'Date', type: 'date', field: 'date' },
      { title: 'Quantité', field: 'quantity', type: 'number', textAlign: 'left' },
      // { title: 'Après ajustement', field: 'quantity_after', type: 'number', textAlign: 'left' },
      {
        title: 'Commande',
        field: 'order',
        type: 'searchable-foreign-list',
        multiSelect: false,
        listItems: this.ordersData,
        listField: 'numberAndName',
        nullText: '(Aucun)',
        // change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
        //     this.updateView(this.adjustmentCopy);
        // }
      },
      { title: 'Remarques', field: 'remarks', type: 'text' },
    ],
  };

  public articleBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyArticleBlock();
  public deviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyDeviceBlock();
  public serviceBlock: InfoBlock = ArticleInfoBlocks.generateReadOnlyServiceBlock();

  @ViewChild('movesGrid') movesGrid: DataGridComponent;
  public movesGridColumns = [
    { title: 'Date', field: 'date', type: 'date' },
    { title: 'Quantité', field: 'quantityAndUnit' },
    { title: "N° d'appel", field: 'fullNumber' },
    { title: 'N° de facture', field: 'invoice.number' },
    { title: 'N° de commande client', field: 'customer_reference' },
    { title: 'Remarques\n(suivi des appels)', field: 'followup_remarks' },
    { title: 'Remarques\n(suivi client)', field: 'cfollowup_remarks' },
  ];
  // public movesGridActions: any[] = [
  //     { iconClass: 'fa fa-plus', onClick: (event) => { this.adjustmentCopy.moves.push(new StockMove()); } },
  //     { iconClass: 'fa fa-trash', onClick: (event) => {
  //         for(let i=0; i<this.movesGrid.selectedItems.length; ++i) {
  //             if (this.movesGrid.selectedItems[i].id != 0) {
  //                 this.movesGrid.selectedItems[i].delete().then(
  //                     function(result) { ArrayUtil.removeElements(this.selectedGrid.items, [ this.selectedGrid.selectedItems[i] ]); },
  //                     function(err) { console.error('error while deleting move:', err); }
  //                 );
  //             } else {
  //                 ArrayUtil.removeElements(this.movesGrid.items, [ this.movesGrid.selectedItems[i] ]);
  //             }
  //         }
  //     } }
  // ];

  ngOnInit() {
    Stock.load(null).then(
      (result) => {
        this.stocksData.items = result;
      },
      (err) => {
        console.error(err);
      }
    );
    Order.load(null).then(
      (result) => {
        this.orders = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  initView(adjustment: StockAdjustment) {
    if (!adjustment) adjustment = new StockAdjustment();
    this.adjustment = adjustment;
    this.adjustmentCopy = adjustment.clone(true);
    this.selectedStock = this.adjustmentCopy.stock;
    this.updateView(this.adjustmentCopy);
  }

  updateView(adjustment: StockAdjustment) {
    if (this.selectedStock instanceof Stock && !adjustment.id) {
      adjustment.stock = this.selectedStock;
      if (!adjustment.date) adjustment.date = DateTimeUtil.toDateString(new Date());
      this.ordersData.items = this.orders.filter((order: Order) => {
        return order.article.id == adjustment.stock.article.id;
      });
    }
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      let addToStock: boolean = true;
      let adjustDest: StockAdjustment = this.adjustmentCopy;
      if (this.adjustmentCopy.id) {
        for (let i = 0; i < this.adjustmentCopy.stock.adjustments.length; ++i) {
          let adjustment: StockAdjustment = this.adjustmentCopy.stock.adjustments[i];
          if (adjustment.id == this.adjustmentCopy.id) {
            adjustDest = adjustment;
            addToStock = false;
            break;
          }
        }
      }
      let prom: Promise<any> = null;
      if (addToStock) {
        this.adjustmentCopy.stock.adjustments.push(adjustDest);
        prom = this.adjustmentCopy.stock.save2();
      } else prom = this.adjustmentCopy.save2();
      prom.then(
        (result) => {
          this.adjustmentCopy.clone(true, adjustDest);
          this.initView(adjustDest);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public get isArticle() {
    return this.adjustmentCopy && this.adjustmentCopy.article && this.adjustmentCopy.article.type === 0;
  }
  public get isDevice() {
    return this.adjustmentCopy && this.adjustmentCopy.article && this.adjustmentCopy.article.type === 1;
  }
  public get isService() {
    return this.adjustmentCopy && this.adjustmentCopy.article && this.adjustmentCopy.article.type === 2;
  }
}
