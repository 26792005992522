<div class="move-container">
  <div class="move-container-columns">
    <h3>Informations appel</h3>

    <info-block #selectBlockComponent [block]="selectBlock" [data]="instance" [readonly]="!!moveCopy.id"></info-block>
    <info-block #moveBlockComponent [block]="moveBlock" [data]="moveCopy" [readonly]="!writeAccess"></info-block>
    <info-block
      #followupBlockComponent
      [block]="followupBlock"
      [data]="moveCopy"
      [readonly]="!writeAccess"
    ></info-block>
    <!-- <info-block #deliveryBlockComponent [block]="deliveryBlock" [data]="moveCopy.invoice"></info-block> -->

    <info-block
      #generateBlockComponent
      [block]="generateInvoiceBlock"
      [data]="instance"
      *ngIf="!moveCopy.id"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #invoiceBlockComponent
      *ngIf="moveCopy.invoice"
      [block]="invoiceBlock"
      [data]="moveCopy.invoice"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #totalsBlockComponent
      *ngIf="moveCopy.invoice"
      [block]="totalsBlock"
      [data]="moveCopy"
      [readonly]="true"
    ></info-block>

    <info-block
      #deliveryAddressBlockComponent
      [block]="deliveryAddressBlock"
      [data]="moveCopy"
      [readonly]="!writeAccess"
    ></info-block>

    <info-block
      [block]="accountingBlock"
      #accountingBlockComponent
      [data]="moveCopy"
      [readonly]="!writeAccess"
    ></info-block>

    <div class="send-warning-block" *ngIf="dontSendByPost">NE PAS ENVOYER PAR VOIE POSTALE</div>
    <div class="send-warning-block" *ngIf="dontSendByMail">NE PAS ENVOYER PAR E-MAIL</div>
  </div>

  <div class="move-container-columns">
    <h3>Aperçus</h3>

    <div *ngIf="moveCopy && moveCopy.order && model; else no_preview">
      <ul class="tabs">
        <li class="tab" [ngClass]="{ active: selectedPreview == 'move' }" (click)="setPreview('move')">
          Appel sur stock
        </li>
        <li
          class="tab"
          *ngIf="moveCopy.invoice"
          [ngClass]="{ active: selectedPreview == 'invoice' }"
          (click)="setPreview('invoice')"
        >
          Facture
        </li>
        <li class="tab" [ngClass]="{ active: selectedPreview == 'delivery' }" (click)="setPreview('delivery')">
          Bon de livraison
        </li>
        <li class="tab" [ngClass]="{ active: selectedPreview == 'labels' }" (click)="setPreview('labels')">
          Etiquettes
        </li>
      </ul>
      <div>
        <printable-page
          #pagesElements
          *ngFor="let page of model.pages"
          [page]="page"
          [ngStyle]="{ width: page.width + 'mm', height: page.height + 'mm' }"
        ></printable-page>
      </div>
    </div>
    <ng-template #no_preview>(Aperçu non disponible)</ng-template>
  </div>
</div>
