import { InteropViewComponent } from './interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [],
  declarations: [InteropViewComponent],
  exports: [InteropViewComponent],
  entryComponents: [InteropViewComponent],
})
export class InteropModule extends ModuleBase {
  public static moduleName = 'interop';
}
