import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { ObjectDefinition } from 'src/app/classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from 'src/app/classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class PaymentStatus extends ObjectModel3 {
  public name: string = null;
  public ended: number = 0;
  public paid: number = 0;
  public to_claim: number = 0;

  private static unpaid_status: PaymentStatus = null;
  private static unpaid_status_promise: Promise<any> = null;

  public get isCancelled(): boolean {
    return this.ended > 0 && this.paid == 0;
  }

  constructor() {
    super(PaymentStatus);
  }

  /* ObjectModel FUNCTIONS */

  // public static instances: { [id: number]: Address; } = {};

  public static definition: ObjectDefinition = {
    database: {
      table: 'payment_statuses',
      id: 'id',
    },
    values: {
      name: { type: 'string' },
      ended: { type: 'number' },
      paid: { type: 'number' },
      to_claim: { type: 'number' },
    },
    children: {},
    links: {},
  };

  private static getUnpaidStatusLoadingPromise() {
    if (!PaymentStatus.unpaid_status_promise)
      PaymentStatus.unpaid_status_promise = new Promise<PaymentStatus>((resolve, reject) => {
        PaymentStatus.load(null, null, null, false, 'name = "À réclamer"').then(
          (result: PaymentStatus[]) => {
            PaymentStatus.unpaid_status = result[0];
            resolve(result[0]);
          },
          (err) => {
            reject(err);
          }
        );
      });
    return PaymentStatus.unpaid_status_promise;
  }

  public static loadUnpaidStatus() {
    return LoadingPromise.create<PaymentStatus>((resolve, reject) => {
      if (PaymentStatus.unpaid_status) resolve(PaymentStatus.unpaid_status);
      else {
        PaymentStatus.getUnpaidStatusLoadingPromise().then(
          (result: PaymentStatus) => {
            resolve(result);
          },
          (err) => {
            reject(err);
          }
        );
      }
    });
  }
}
