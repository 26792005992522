import { Accounting } from '../../other-data/classes/Accounting.class';
import { Address } from '../../addresses/classes/Address.class';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Customer } from '../../customers/classes/Customer.class';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Invoice } from '../../invoicing/classes/Invoice.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { Stock } from './Stock.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';

export class StockMove extends ObjectModel3 {
  public accounting: Accounting = null;

  public stock: Stock = null;
  public invoice: Invoice = null;

  public get reimbursements_quantity() {
    if (this.invoice instanceof Invoice) return this.invoice.reimbursements_quantity;
    else return 0;
  }

  public date: string = null;
  public quantity: number = null;
  public number: number = null;
  public internal_fees: number = null;
  public external_fees: number = null;
  public customer_reference: string = null;
  public delivery_date: string = null;

  public sell_price: number = 0;

  public delivery_contact: string = null;
  public delivery_address: Address = null;
  public delivery_address_text: string = null;
  public opening_hours: string = null;
  public self_delivery: number = 0;
  public show_name_on_stickers: number = 1;

  public remarks: string = null;
  public archived: number = 0;

  public followup_archived: number = 0;
  public followup_reminder: number = 0;
  public followup_remarks: string = null;

  public cfollowup_estimated_delivery: string = null;
  public cfollowup_delivered_quantity: number = null;
  public cfollowup_real_delivery_date: string = null;
  public cfollowup_remarks: string = null;

  public get buy_price() {
    return this.order ? this.order.buy_price : null;
  }
  public get unit() {
    return this.order ? this.order.unit : null;
  }
  public get deadline() {
    return this.order ? this.order.deadline : null;
  }
  public get remaining_quantity() {
    return this.quantity;
  }
  public get sale() {
    return this.order ? this.order.sale : null;
  }
  public get customer() {
    return this.order ? this.order.customer : null;
  }
  public get merchant() {
    return this.order ? this.order.merchant : null;
  }
  public get supplier() {
    return this.order ? this.order.supplier : null;
  }
  public get order() {
    return this.stock ? this.stock.order : null;
  }
  public get article() {
    return this.order ? this.order.article : null;
  }
  public get discount() {
    return this.order ? this.order.discount : null;
  }
  public get discount_days() {
    return this.order ? this.order.discount_days : null;
  }
  public get gain() {
    if (!this.stock || !this.stock.parent || !this.quantity || !this.buy_price) return 0;
    else return this.quantity * (this.buy_price - this.stock.parent.buy_price);
  }

  public get buy_currency() {
    return this.order ? this.order.buy_currency : null;
  }
  public get buy_xrate() {
    return this.order ? this.order.buy_xrate : null;
  }
  public get sell_currency() {
    return this.order ? this.order.sell_currency : null;
  }
  public get sell_xrate() {
    return this.order ? this.order.sell_xrate : null;
  }

  public get converted_buy_price() {
    return this.stock ? this.stock.converted_buy_price : null;
  }

  public get converted_internal_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.internal_fees);
  }
  public set converted_internal_fees(value: number) {
    this.internal_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_external_fees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.external_fees);
  }
  public set converted_external_fees(value: number) {
    this.external_fees = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }

  constructor() {
    super(StockMove);
  }

  public get fullNumber() {
    return this.order && this.number ? this.order.number + '-' + this.number : null;
  }
  public get delivery_number() {
    return this.order && this.number ? this.order.delivery_number + '-' + this.number : null;
  }
  public get numberAndName() {
    return this.fullNumber + ' - ' + (this.article ? this.article.designation : '');
  }
  public get shortNumber() {
    return this.fullNumber.replace(/(\/[A-Za-z]{2,}[0-9]{4,}\/[0-9A-Za-z]{3})/, '');
  }
  public get unitBuyPrice() {
    return this.order ? this.order.unitBuyPrice : null;
  }
  public get unitCostPrice() {
    return this.order ? this.order.unitCostPrice : null;
  }
  public get totalCostPrice() {
    return !this.unitCostPrice || !this.quantity ? null : this.quantity * this.unitCostPrice;
  }
  public get totalBuyAmount() {
    if (!this.quantity) return 0;
    return this.quantity * this.buy_price;
  }
  public get totalSellAmount() {
    if (!this.quantity) return 0;
    return this.quantity * this.sell_price;
  }
  public get totalProfitAmount() {
    if (!this.quantity) return 0;
    return this.totalSellAmount - this.totalBuyAmount;
  }

  // public get sell_price()
  // {
  //     return this.stock ? this.stock.sell_price : 0;
  // }
  public get quantityAndUnit() {
    let arr: string[] = [];
    if (this.quantity != null) arr.push(NumberUtil.formatNumber(this.quantity, null, '.'));
    if (this.stock) {
      if (this.stock.unit) arr.push(this.stock.unit);
    }
    return arr.join(' ');
  }
  public get totalAmount() {
    if (this.quantity != null && this.sell_price != null) return this.quantity * this.sell_price;
    else return 0;
  }
  public get vatAmount() {
    if (this.quantity != null && this.sell_price != null)
      return this.quantity * this.sell_price * ((isNaN(this.customer.vat) ? 21 : this.customer.vat) / 100);
    else return 0;
  }
  public get totalWithTax() {
    return this.totalAmount + this.vatAmount;
  }

  public get converted_totalBuyAmount() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalBuyAmount);
  }
  public get converted_totalSellAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalSellAmount);
  }
  public get converted_totalProfitAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalProfitAmount);
  }
  public get converted_totalAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalAmount);
  }
  public get converted_vatAmount() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.vatAmount);
  }
  public get converted_totalWithTax() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.totalWithTax);
  }
  public get converted_sell_price() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.sell_price);
  }
  public set converted_sell_price(value: number) {
    this.sell_price = CurrenciesService.currencyToEuro(this.sell_xrate || 1, value);
  }
  public get converted_unitBuyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitBuyPrice);
  }
  public get converted_unitCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unitCostPrice);
  }
  public get converted_totalCostPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.totalCostPrice);
  }

  public get delivery() {
    return this.invoice ? this.invoice.delivery : null;
  }
  public get invoice_delivery_date() {
    return this.invoice ? this.invoice.delivery_date : null;
  }
  public set delivery(value: string) {
    if (this.invoice) this.invoice.delivery = value;
  }
  public set invoice_delivery_date(value: string) {
    if (this.invoice) this.invoice.delivery_date = value;
  }
  public get openingHours() {
    return this.opening_hours || (this.order ? this.order.opening_hours : null);
  }

  public get delayDays() {
    if (!this.delivery_date) return null;
    let d: number = DateTimeUtil.daysBetween(this.delivery_date, new Date(), false);
    return Math.max(0, d) || null;
  }

  public get remaining_amount(): number {
    let total: number = this.totalAmount;
    if (this.invoice) {
      total -= this.invoice.totalAmount || 0;
      total -= this.invoice.reimbursements_totalAmount || 0;
    }
    return total;
  }

  public get unit_buyPrice() {
    return this.order ? this.order.unit_buyPrice : 0;
  }
  public get unit_costPrice() {
    return this.order ? this.order.unit_costPrice : 0;
  }
  public get unit_supplierFees() {
    return this.order ? this.order.unit_supplierFees : 0;
  }
  public get unit_externalFees() {
    return this.external_fees ? this.external_fees / this.quantity : this.order ? this.order.unit_externalFees : 0;
  }
  public get unit_internalFees() {
    return this.internal_fees ? this.internal_fees / this.quantity : this.order ? this.order.unit_internalFees : 0;
  }
  public get unit_sellPrice() {
    return this.sell_price;
  }
  public get unit_rawMargin() {
    return this.total_rawMargin / this.quantity;
  }
  public get unit_netMargin() {
    return this.total_netMargin / this.quantity;
  }

  public get total_buyPrice() {
    return this.unit_buyPrice * this.quantity;
  }
  public get total_costPrice() {
    return this.unit_costPrice * this.quantity;
  }
  public get total_supplierFees() {
    return this.unit_supplierFees * this.quantity;
  }
  public get total_externalFees() {
    return this.external_fees || this.unit_externalFees * this.quantity;
  }
  public get total_internalFees() {
    return this.internal_fees || this.unit_internalFees * this.quantity;
  }
  public get total_sellPrice() {
    return this.unit_sellPrice * this.quantity;
  }
  public get total_rawMargin() {
    return this.total_sellPrice - this.total_buyPrice;
  }
  public get total_netMargin() {
    return this.total_rawMargin - this.total_externalFees - this.total_internalFees;
  }

  public get converted_unit_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_buyPrice);
  }
  public get converted_unit_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_costPrice);
  }
  public get converted_unit_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.unit_supplierFees);
  }
  public get converted_unit_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_externalFees);
  }
  public get converted_unit_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_internalFees);
  }
  public get converted_unit_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.unit_sellPrice);
  }

  public get converted_total_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_buyPrice);
  }
  public get converted_total_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_costPrice);
  }
  public get converted_total_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate || 1, this.total_supplierFees);
  }
  public get converted_total_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_externalFees);
  }
  public get converted_total_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_internalFees);
  }
  public get converted_total_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate || 1, this.total_sellPrice);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'stock_moves',
      id: 'id',
    },
    values: {
      date: { type: 'string' },
      quantity: { type: 'number' },
      number: { type: 'number' },
      internal_fees: { type: 'number' },
      external_fees: { type: 'number' },
      sell_price: { type: 'number' },
      customer_reference: { type: 'string' },
      delivery_date: { type: 'string' },
      remarks: { type: 'string' },
      archived: { type: 'number', ignore_save: true },
      delivery_contact: { type: 'string' },
      delivery_address_text: { type: 'string' },
      opening_hours: { type: 'string' },
      self_delivery: { type: 'number' },
      show_name_on_stickers: { type: 'number' },

      followup_archived: { type: 'number', ignore_save: true },
      followup_reminder: { type: 'number' },
      followup_remarks: { type: 'string' },

      cfollowup_estimated_delivery: { type: 'string' },
      cfollowup_delivered_quantity: { type: 'number' },
      cfollowup_real_delivery_date: { type: 'string' },
      cfollowup_remarks: { type: 'string' },
    },
    children: {
      accounting: {
        type: 'Accounting',
        clone: false,
        save: false,
        delete: false,
      },
      stock: { type: 'Stock', clone: false, save: false, delete: false },
      invoice: { type: 'Invoice', clone: false, save: true, delete: false },
    },
    links: {},
  };
}
