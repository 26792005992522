import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class SalesByNomenclatureReportModel extends ReportModel {
  public title: string = 'CONSOLIDATION DES VENTES PAR PRODUIT (H.T.V.A.)';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
    {
      name: 'order_by',
      title: 'Trier par',
      type: 'list',
      listField: 'text',
      listItems: [
        { text: 'Nom', value: '`name`' },
        { text: 'Rang', value: '-`rank` DESC' },
        { text: 'Montant spécifique', value: 'SUM(t.balance) DESC' },
      ],
      value: { text: 'Nom', value: '`name`' },
    },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public dataPoints: any[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static headerCellStyle: any = {
    'background-color': 'rgb(141,180,226)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static name_CellStyle: any = {
    color: 'black',
    'font-weight': 'bold',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 189, 151)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: SalesByNomenclatureReportModel = new SalesByNomenclatureReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.headerRows = [
        {
          cells: [
            {
              value: 'CONSOLIDATION DES VENTES PAR PRODUIT (H.T.V.A.)',
              colSpan: 4,
              style: SalesByNomenclatureReportModel.titleCellStyle,
            },
          ],
        },
        {
          cells: [
            { value: 'Nomenclature du produit', style: SalesByNomenclatureReportModel.headerCellStyle },
            { value: 'Montant spécifique', style: SalesByNomenclatureReportModel.headerCellStyle },
            { value: 'Montant global', style: SalesByNomenclatureReportModel.headerCellStyle },
            { value: 'Rang', style: SalesByNomenclatureReportModel.headerCellStyle },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'nomenclatures_balance',
        settings: {
          year_start: this.getSetting('year_start').value,
          year_end: this.getSetting('year_end').value,
          order_by: this.getSetting('order_by').value ? this.getSetting('order_by').value.value || 'name' : 'name',
        },
      }).then(
        (result2) => {
          let balance_total: number = 0;
          this.rows = [];
          this.dataPoints = [];

          const list: any = {};
          result2.details.map((n) => {
            n._balance = parseFloat(n.balance) || 0;
            n._rank = parseFloat(n.rank) || 0;
            n.children_balance = 0;
            list[n.id] = n;
            n.children = [];
            n.parent = null;
          });
          result2.details.map((n) => {
            if (n.id_parent) {
              n.parent = list[n.id_parent];
              n.parent.children.push(n);
            }
          });
          result2.details
            .filter((n) => n.name.charAt(2) != '0')
            .map((n) => {
              if (n.parent && n._balance > 0) n.parent.children_balance += n._balance;
            });
          result2.details
            .filter((n) => n.name.charAt(2) == '0' && n.name.charAt(1) != '0')
            .map((n) => {
              if (n.parent) n.parent.children_balance += n._balance + n.children_balance;
            });

          console.log('result:', result2);
          for (let i = 0; i < result2.details.length; ++i) {
            let id: string = result2.details[i].id;
            let id_parent: string = result2.details[i].id_parent;
            let name: string = result2.details[i].name;
            let balance_str: string = result2.details[i].balance;
            let rank_str: string = result2.details[i].rank;
            let balance: number = result2.details[i]._balance;
            let balance_with_children: number = result2.details[i]._balance + result2.details[i].children_balance;
            let rank: number = result2.details[i]._rank;
            balance_total += balance;
            let cell_style: any = {
              'padding-left': '2rem',
              'font-weight': 'normal',
            };
            if (name.substr(1, 2) == '00')
              cell_style = {
                'font-weight': 'bold',
                'background-color': '#67b7dc',
                'padding-left': '0',
              };
            else if (name.substr(2, 1) == '0')
              cell_style = {
                'font-weight': 'bold',
                color: 'rgb(0, 67, 218)',
                'padding-left': '1rem',
              };
            this.rows.push({
              cells: [
                { value: name, style: { ...SalesByNomenclatureReportModel.name_CellStyle, ...cell_style } },
                {
                  value: balance && balance != 0 ? NumberUtil.formatMoney(balance, '€', 2, '.') : '',
                  style: ObjectUtil.merge(
                    SalesByNomenclatureReportModel.body_CellStyle,
                    ReportCell.moneyCellStyle,
                    cell_style
                  ),
                },
                {
                  value:
                    balance_with_children && balance_with_children != 0
                      ? NumberUtil.formatMoney(balance_with_children, '€', 2, '.')
                      : '',
                  style: ObjectUtil.merge(
                    SalesByNomenclatureReportModel.body_CellStyle,
                    ReportCell.moneyCellStyle,
                    cell_style
                  ),
                },
                {
                  value: rank && rank != 0 ? rank_str : '',
                  style: ObjectUtil.merge(
                    SalesByNomenclatureReportModel.body_CellStyle,
                    ReportCell.moneyCellStyle,
                    cell_style
                  ),
                },
              ],
            });
            if (balance > 0)
              this.dataPoints.push({ value: balance, name: name.length > 30 ? name.substring(0, 30) + '...' : name });
          }

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            language: {
              locale: {
                _decimalSeparator: ',',
                _thousandSeparator: '.',
              },
            },
            inline: true,
            titles: [{ text: 'Ventes par client', fontSize: 25, marginBottom: 30 }],
            series: [
              {
                type: 'ColumnSeries',
                dataFields: { valueX: 'value', categoryY: 'name' },
                bullets: [
                  {
                    type: 'LabelBullet',
                    label: {
                      text: '{value.formatNumber("#,###.00")}',
                      truncate: false,
                      hideOversized: false,
                      horizontalCenter: 'left',
                      dx: 10,
                    },
                  },
                ],
              },
            ],
            maskBullets: false,
            data: this.dataPoints,
            xAxes: [{ type: 'ValueAxis', dataFields: { value: 'value', category: 'name' } }],
            yAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: {
                  minGridDistance: 10,
                  // minLabelPosition: -0.1,
                  // maxLabelPosition: 1.1
                },
                // startLocation: -0.5,
                // endLocation: 1.5
              },
            ],
            container_width: 800,
            container_height: 250 + 25 * this.dataPoints.length,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.value - b.value;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
