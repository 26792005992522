import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { ObjectModel2 } from 'src/app/classes/objects/ObjectModel2.class';
import { Setting } from '../../classes/Setting.class';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import * as $ from 'jquery';

@Component({
  selector: 'app-settings-view',
  templateUrl: './settings-view.component.html',
  styleUrls: ['./settings-view.component.css']
})
export class SettingsViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Paramètres',
        data: this,
        elements: [
            {
                type: 'button',
                text: 'Enregistrer',
                icon: 'save',
                click: function(view: SettingsViewComponent) {
                    view.save();
                }
            },
            { type: 'spacing' }
        ]
    };

    public settings: any = {};
    public categoryGrids: any = {};
    @ViewChildren('grids') public grids: QueryList<DataGridComponent> = null;

    public columns: any[] = [
        { title: 'Nom', field: 'name' },
        { title: 'Valeur', field: 'value' },
    ];

    ngAfterViewInit() {
        let grids: DataGridComponent[] = this.grids.toArray();
        for(let i=0; i<grids.length; ++i) this.categoryGrids[$(grids[i].elem).attr('data-category-name')] = grids[i];
        console.log('grids:', this.categoryGrids);
        Setting.load().then(
            (result: Setting[]) => {
                for(let i=0; i<result.length; ++i)
                {
                    if (!this.settings[result[i].category]) this.settings[result[i].category] = [];
                    this.settings[result[i].category].push(result[i]);
                }
            },
            (err) => { console.error(err); }
        );
    }

    updateView() {
    }

    generateActions(categoryName: string)
    {
        if (!this.settings[categoryName]) this.settings[categoryName] = [];
        return [
            { iconClass: 'fa fa-plus', onClick: (event) => { this.settings[categoryName].push(new Setting(categoryName)); } },
            { iconClass: 'fa fa-trash', onClick: (event) => { this.delete(categoryName); } }
        ];
    }

    save()
    {
        let promises: Promise<any>[] = [];
        let seq: number = ++ObjectModel2.nextSequence;

        for(let categoryName in this.settings)
        {
            let category: Setting[] = this.settings[categoryName];
            if (Array.isArray(category)) for(let i=0; i<category.length; ++i) {
                if (category[i].changed === true) promises.push(category[i].save2(seq));
            }
        }
        Promise.all(promises).then(
            (result) => {
                NotificationsComponent.push({
                    type: 'success',
                    title: 'Enregistrement effectué',
                    summary: '<b>' + promises.length + ' paramètre' + (promises.length > 1 ? 's' : '') + '</b> ont été enregistrés'
                });
                this.updateView();
            },
            (err) => {
                NotificationsComponent.push({
                    type: 'error',
                    title: 'Échec de l\'enregistrement',
                    summary: 'Une erreur s\'est produite lors de l\'enregistrement des paramètres.',
                    content: JSON.stringify(err)
                });
                console.error(err);
            }
        );
    }

    delete(categoryName: string)
    {
        let self = this;
        let selectedGrid = this.categoryGrids[categoryName];
        if (selectedGrid) {
            if (selectedGrid.selectedItems.length > 0)
            {
                DialogsComponent.display({
                    icon: 'warning',
                    title: 'Supprimer la sélection ?',
                    message: 'Êtes-vous sûr(e) de vouloir supprimer les éléments sélectionnés ?',
                    buttons: DialogButton.yesNoButtons
                }).then(
                    (result) => {
                        if (result == DialogButton.RESULT_YES)
                        {
                            for(let i=0; i<selectedGrid.selectedItems.length; ++i) {
                                if (selectedGrid.selectedItems[i].id != 0) {
                                    selectedGrid.selectedItems[i].delete().then(
                                        function(result) { ArrayUtil.removeElements(selectedGrid.items, [ selectedGrid.selectedItems[i] ]); },
                                        function(err) { console.error('error while deleting:', err); }
                                    );
                                } else {
                                    ArrayUtil.removeElements(selectedGrid.items, [ selectedGrid.selectedItems[i] ]);
                                }
                            }
                        }
                    }
                );
            }
        }
    }

}
