import { Article } from './classes/Article.class';
import { ArticleBase } from './classes/ArticleBase.class';
import { ArticleViewComponent } from './views/article-view/article-view.component';
import { ArticlesListViewComponent } from './views/articles-list-view/articles-list-view.component';
import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { Device } from './classes/Device.class';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { NgModule } from '@angular/core';
import { Nomenclature } from './classes/Nomenclature.class';
import { NomenclaturesListViewcomponent } from './views/nomenclatures-list-view/nomenclatures-list-view.component';
import { RollingDirection } from '../other-data/classes/RollingDirection.class';
import { Service } from './classes/Service.class';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [
    NomenclaturesListViewcomponent,
    ArticleViewComponent,
    ArticlesListViewComponent,
  ],
  exports: [
    NomenclaturesListViewcomponent,
    ArticleViewComponent,
    ArticlesListViewComponent,
  ],
  entryComponents: [
    NomenclaturesListViewcomponent,
    ArticleViewComponent,
    ArticlesListViewComponent,
  ],
})
export class ArticlesModule extends ModuleBase {
  public static moduleName = 'articles';

  public ngOnInit() {
    ClassManager.registerClass('Nomenclature', Nomenclature);
    ClassManager.registerClass('ArticleBase', ArticleBase);
    ClassManager.registerClass('Article', Article);
    ClassManager.registerClass('Device', Device);
    ClassManager.registerClass('Service', Service);

    HomepageViewComponent.registerGroups({
      database: {
        name: 'Base de données',
        permission: 'home_groupe_basededonnees',
        children: [
          {
            name: 'Nomenclatures',
            component: NomenclaturesListViewcomponent,
            permission: 'home_bouton_basededonnees_nomenclatures',
          },

          ...(config.interop.lists.nomenclatures
            ? [
                {
                  name: 'Nomenclatures 🏎️',
                  permission: 'home_bouton_basededonnees_articles',
                  href: '/nomenclatures',
                },
              ]
            : []),
          {
            name: 'Articles',
            component: ArticlesListViewComponent,
            permission: 'home_bouton_basededonnees_articles',
          },

          ...(config.interop.lists.articles
            ? [
                {
                  name: 'Articles 🏎️',
                  href: '/articles',
                  permission: 'home_bouton_basededonnees_articles',
                },
              ]
            : []),
        ],
      },
      sales: {
        name: 'Ventes',
        backColor: 'rgb(226, 107, 10)',
        textColor: 'white',
        permission: 'home_groupe_ventes',
        children: [
          {
            name: 'Créer un article',
            component: ArticleViewComponent,
            permission: 'home_bouton_ventes_creerarticle',
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
