import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Address } from '../../../addresses/classes/Address.class';
import { ArrayUtil } from '../../../utils/classes/ArrayUtil.class';
import { BsModalService } from 'ngx-bootstrap';
import { BusinessUtil } from '../../../utils/classes/BusinessUtil.class';
import { Country } from '../../../other-data/classes/Country.class';
import { DataGrid2Component } from '../../../../components/data-grid2/data-grid2.component';
import { Deadline } from '../../../other-data/classes/Deadline.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { Form } from '../../../../classes/forms/Form.class';
import { FormValidator } from '../../../../classes/forms/FormValidator.class';
import { HttpClient } from '@angular/common/http';
import { InfoBlock } from '../../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../../components/info-block/classes/InfoBlockField.class';
import { Lang } from '../../../other-data/classes/Lang.class';
import { Nomenclature } from '../../../articles/classes/Nomenclature.class';
import { NotificationsComponent } from '../../../../components/notifications/notifications.component';
import { Postcode } from '../../../other-data/classes/Postcode.class';
import { ServerApi } from '../../../../classes/api/ServerApi.class';
import { Supplier } from '../../classes/Supplier.class';
import { SupplierCategory } from '../../classes/SupplierCategory.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from '../../../../components/views/views.component';
import { textColumn } from '../../../../components/data-grid2/classes/DataGridColumn.class';

@Component({
  selector: 'app-supplier-view',
  templateUrl: './supplier-view.component.html',
  styleUrls: ['./supplier-view.component.scss'],
})
export class SupplierViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_basededonnees_fichefournisseur';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Fiche fournisseur',
    data: this,
    onPrevPage: () => {
      if (!this.supplierCopy || this.supplierCopy.changed !== true) return true;
      else {
        DialogsComponent.display({
          icon: 'question',
          title: 'Enregistrer les modifications',
          message:
            'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
          buttons: DialogButton.yesNoCancelButtons,
        }).then((result: any) => {
          if (result === DialogButton.RESULT_YES) {
            this.save().then((result2: any) => {
              ViewsComponent.closeView();
            });
          } else if (result !== DialogButton.RESULT_CANCEL)
            ViewsComponent.closeView();
        });
        return false;
      }
    },
    elements: [
      {
        name: 'saveButton',
        type: 'button',
        text: 'Enregistrer',
        icon: 'save',
        click: function (view: SupplierViewComponent) {
          view.save();
        },
      },
      { type: 'separator-large' },
      {
        name: 'copyAddress',
        type: 'button',
        text: "Copier<br/>l'adresse",
        icon: 'copy',
        click: (view: SupplierViewComponent) => {
          if (!view.lastFocusAddr)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Aucune adresse sélectionnée',
              summary:
                "Sélectionnez d'abord une adresse à copier en cliquant dessus.",
            });
          else view.copyAddress(this.lastFocusAddr);
        },
      },
      { type: 'separator' },
      {
        name: 'pasteAddress',
        type: 'button',
        text: "Coller<br/>l'adresse",
        icon: 'paste',
        click: function (view: SupplierViewComponent) {
          if (!view.addressCopy)
            NotificationsComponent.push({
              type: 'warning',
              title: 'Aucune adresse copiée',
              summary: "Copiez d'abord une adresse pour pouvoir coller.",
            });
          else view.pasteAddress(view.lastFocusAddr);
        },
      },
      { type: 'separator-large' },
      {
        name: 'resetPasswordButton',
        type: 'button',
        text: 'Imprimer<br/>la fiche',
        icon: 'print',
        click: function (view: SupplierViewComponent) {
          view.printView();
        },
        // access: this.writeAccess
      },
      { type: 'spacing' },
    ],
  };

  public supplier: Supplier = null;
  public supplierCopy: Supplier = null;

  public nextNumbers: any = { items: [] };
  public deadlinesData: any = { items: [] };
  public categoriesData: any = { items: [] };
  public langsData: any = { items: [] };
  public countriesData: any = { items: [] };
  public postcodes: Postcode[];
  public commercialPostcodes: any = { items: [] };
  public nomenclatures: Nomenclature[] = [];

  @ViewChild('nameBlockComponent') nameBlockComponent: InfoBlockComponent;
  @ViewChild('bankBlockComponent') bankBlockComponent: InfoBlockComponent;
  @ViewChild('generalBlockComponent') generalBlockComponent: InfoBlockComponent;

  public nameBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(220,230,241)',
    textColor: 'black',
    fields: [
      { title: 'Fournisseur', field: 'name' },
      { title: 'ID pour la compta', field: 'bob_id' },
    ],
  };
  public generalBlock: InfoBlock = {
    title: 'Critères\nde tri',
    backColor: 'rgb(184,204,228)',
    textColor: 'black',
    fields: [
      {
        title: 'N° de fournisseur',
        field: 'number',
        type: 'combo-box',
        listItems: this.nextNumbers,
        listField: 'full_number',
        disableFiltering: true,
      },
      {
        title: 'Catégorie',
        field: 'category',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.categoriesData,
        listField: 'name',
        nullText: '(Aucune)',
      },
      {
        title: 'Langue',
        field: 'lang',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.langsData,
        listField: 'name',
        nullText: '(Aucune)',
      },
      {
        title: 'Paiement',
        field: 'deadline',
        type: 'combo-box',
        disableFiltering: true,
        multiSelect: false,
        listItems: this.deadlinesData,
        listField: 'name',
        nullText: '(Aucun)',
      },
      { title: 'Produits', field: 'product' },
    ],
  };
  public vatBlock: InfoBlock = {
    title: 'TVA',
    backColor: 'rgb(149, 179, 215)',
    textColor: 'black',
    fields: [
      {
        title: 'N° TVA',
        field: 'vat_number',
        type: 'nullable-text',
        nullText: '(Pas de TVA)',
        change: this.formatVatNumber,
        comment:
          'Cochez la case et entrez ici le numéro de TVA du fournisseur.',
      },
      {
        title: 'Taux TVA',
        field: 'vat',
        type: 'number',
        decimalsCount: 1,
        numberUnit: '%',
        textAlign: 'left',
        comment:
          'Insérez ici le taux de TVA que le fournisseur applique par défaut.',
      },
    ],
  };
  public commercialBlock: InfoBlock = {
    click: (
      component: InfoBlockComponent,
      block: InfoBlock,
      object: any,
      event: any
    ) => {
      this.addrClick(component, block, object, event);
    },
    title: 'Adresse\ncommerciale',
    backColor: 'rgb(122,157,199)',
    textColor: 'black',
    fields: [
      { title: 'Service', field: 'name1' },
      { title: 'Adresse', field: 'address1' },
      { title: 'Adresse (2)', field: 'address2' },
      {
        title: 'Code postal',
        field: 'postcode',
        change: (
          component: InfoBlockComponent,
          block: InfoBlock,
          field: InfoBlockField,
          event
        ) => {
          let addr: Address = this.supplierCopy.commercial_address;
          let postcodes: Postcode[] = addr.postcode
            ? this.postcodes.filter(
                (value: Postcode, index: number, obj: Postcode[]) => {
                  return value.postcode == addr.postcode;
                }
              )
            : this.postcodes;
          this.commercialPostcodes.items = postcodes;
          addr.city = null;
        },
      },
      {
        title: 'Commune',
        field: 'city',
        type: 'combo-box',
        listItems: this.commercialPostcodes,
        listField: 'city',
        change: (
          component: InfoBlockComponent,
          block: InfoBlock,
          field: InfoBlockField,
          event
        ) => {
          let addr: Address = this.supplierCopy.commercial_address;
          let postcode: Postcode = this.postcodes.find(
            (value: Postcode, index: number, obj: Postcode[]) => {
              return value.city == event;
            }
          );
          if (postcode != null) addr.postcode = postcode.postcode;
        },
      },
      {
        title: 'Pays',
        field: 'country',
        type: 'foreign-list',
        multiSelect: false,
        listItems: this.countriesData,
        listField: 'name',
      },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'E-mail', field: 'email' },
    ],
  };
  public bankBlock: InfoBlock = {
    title: 'Banque',
    backColor: 'rgb(54,96,146)',
    textColor: 'white',
    fields: [
      { title: 'IBAN', field: 'iban' },
      { title: 'BIC', field: 'bic' },
    ],
  };
  formatVatNumber(
    component: InfoBlockComponent,
    block: InfoBlock,
    field: InfoBlockField,
    object: any,
    event: any
  ) {
    let vat_elem: any = component.getInputElement('vat_number');
    if (vat_elem) {
      vat_elem = vat_elem.nativeElement;
      //   if (vat_elem.value.startsWith('BE')) $(vat_elem).mask('AA 0000.000.0000');
      //   else $(vat_elem).unmask();
    }
  }

  public contactBlock: InfoBlock = {
    click: (
      component: InfoBlockComponent,
      block: InfoBlock,
      object: any,
      event: any
    ) => {
      this.addrClick(component, block, object, event);
    },
    // title: "",
    // backColor: 'rgb(192,80,77)',
    // textColor: 'white',
    fields: [
      { title: 'Contact', field: 'name1' },
      { title: 'Service', field: 'name2' },
      { title: 'Téléphone', field: 'phone', change: this.phoneChange },
      { title: 'Fax', field: 'fax', change: this.phoneChange },
      { title: 'GSM', field: 'mobile', change: this.phoneChange },
      { title: 'Email', field: 'email' },
    ],
  };
  phoneChange(
    component: InfoBlockComponent,
    block: InfoBlock,
    field: InfoBlockField,
    object: any,
    event: any
  ) {
    let phone_elem: any = component.getInputElement('phone');
    if (phone_elem) {
      phone_elem = phone_elem.nativeElement;
      let phone_value: string = phone_elem.value.replace(/[^\+0-9]/g, '');
      if (phone_value.startsWith('+32')) {
        /*if (phone_value.length == 12) $(phone_elem).mask('+00 000 00 00 00');
                else if (phone_value.length == 11) {
                    if (/^\+32[2349]/.test(phone_value)) $(phone_elem).mask('+00 0 000 00 000');
                    else $(phone_elem).mask('+00 00 00 00 000');
                }*/
        //else $(phone_elem).unmask();
      }
      //else $(phone_elem).unmask();
    }
  }
  public remarksBlock: InfoBlock = {
    title: '',
    backColor: 'rgb(36,64,96)',
    textColor: 'white',
    fields: [
      { title: 'Remarques', field: 'remarks' },
      { title: 'URL', type: 'url', field: 'url' },
      {
        title: 'Logo Wallonie 🐓',
        field: 'show_wallonia_logo',
        type: 'checkbox',
      },
    ],
  };

  private lastFocusAddr: Address = null;
  private addressCopy: Address = null;
  addrClick(
    component: InfoBlockComponent,
    block: InfoBlock,
    object: any,
    event: any
  ) {
    this.lastFocusAddr = object as Address;
  }
  copyAddress(addr: Address) {
    if (addr) this.addressCopy = addr.clone(false);
  }
  pasteAddress(addr: Address) {
    if (addr && this.addressCopy) this.addressCopy.clone(false, addr);
  }

  contactBackColor(r, g, b, index, count) {
    if (count > 0) {
      let ratio = ((count - index - 1) / count) * 0.75;
      //console.log('ratio:', ratio);
      r = Math.round(r + (255 - r) * ratio);
      g = Math.round(g + (255 - g) * ratio);
      b = Math.round(b + (255 - b) * ratio);
    }
    return 'rgb(' + r + ',' + g + ',' + b + ')';
  }
  contactTextColor(index, count) {
    if (index >= count / 2 || count == 1) return 'white';
    else return 'black';
  }

  ngOnInit() {
    let self = this;
    SupplierCategory.load().then(
      function (result: SupplierCategory[]) {
        self.categoriesData.items = result;
      },
      function (err) {
        console.error('categories loading error:', err);
      }
    );
    Lang.load().then(
      function (result: Lang[]) {
        self.langsData.items = result;
      },
      function (err) {
        console.error('langs loading error:', err);
      }
    );
    Deadline.load().then(
      function (result: Deadline[]) {
        self.deadlinesData.items = ArrayUtil.sortQuantityArray(result, 'name');
      },
      function (err) {
        console.error('deadlines loading error:', err);
      }
    );
    Country.load().then(
      function (result: Country[]) {
        self.countriesData.items = result;
      },
      function (err) {
        console.error('countries loading error:', err);
      }
    );
    Postcode.load().then(
      function (result: Postcode[]) {
        self.postcodes = result;
        self.commercialPostcodes.items = result;
      },
      function (err) {
        console.error('postcodes loading error:', err);
      }
    );
    Nomenclature.load(null, ['name']).then(
      (result: Nomenclature[]) => {
        this.nomenclatures = result;
      },
      (err: any) => {
        console.error(err);
      }
    );
    ServerApi.callModule('suppliers', 'nextNumbers').then(
      (result) => {
        this.nextNumbers.items = result.details;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.bankBlockComponent.setFocus('name');
      let iban_elem: any = this.bankBlockComponent.getInputElement('iban');
      //if (iban_elem) $(iban_elem.nativeElement).mask('AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA AAAA');
      this.formatVatNumber(this.bankBlockComponent, null, null, null, null);
    }, 100);
  }

  initView(data, ...args) {
    this.supplier = data;
    this.supplierCopy = this.supplier.clone(true);
    Toolbar.getToolbarItem(this.toolbar, 'saveButton').disabled =
      !this.writeAccess;
    Toolbar.getToolbarItem(this.toolbar, 'copyAddress').disabled =
      !this.writeAccess;
    Toolbar.getToolbarItem(this.toolbar, 'pasteAddress').disabled =
      !this.writeAccess;
  }

  validateForm() {
    if (this.supplierCopy.iban) {
      if (!BusinessUtil.checkIban(this.supplierCopy.iban)) {
        NotificationsComponent.push({
          type: 'warning',
          title: 'IBAN invalide',
          summary: 'Le code IBAN semble invalide. Veuillez le vérifier.',
        });
        return false;
      }
    }
    let form: Form = {
      fields: [
        {
          name: 'Nom du fournisseur',
          type: 'string',
          data: this.supplierCopy,
          field: 'name',
          pattern: new RegExp(/.+/),
          element: this.nameBlockComponent.getElement('name'),
        },
        {
          name: 'Numéro de fournisseur',
          type: 'string',
          data: this.supplierCopy,
          field: 'number',
          pattern: new RegExp(/^FP[0-9]{4}$/),
          element: this.generalBlockComponent.getElement('number'),
        },
      ],
    };
    let result = FormValidator.validateForm(form);
    FormValidator.showFormInvalidFields(form, result);
    if (result !== true) {
      FormValidator.showNotification(result);
      return false;
    } else return true;
  }

  save() {
    return new Promise<any>((resolve, reject) => {
      if (!this.validateForm()) reject('invalid form');
      this.supplierCopy.clone(true, this.supplier);
      this.supplier.save2().then(
        (result) => {
          this.initView(this.supplier);
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public contactsColumns = [
    textColumn('name1', { title: 'Contact', width: 200, readonly: false }),
    textColumn('name2', { title: 'Service', width: 200, readonly: false }),
    textColumn('phone', {
      title: 'Téléphone',
      width: 150,
      readonly: false,
      change: this.phoneChange,
    }),
    textColumn('mobile', {
      title: 'GSM',
      width: 150,
      readonly: false,
      change: this.phoneChange,
    }),
    textColumn('email', { title: 'E-mail', width: 200, readonly: false }),
    textColumn('remarks', { title: 'Remarques', width: 300, readonly: false }),
  ];

  @ViewChild('contactsGrid') contactsGrid: DataGrid2Component;

  public addContact() {
    this.supplierCopy.contacts = [...this.supplierCopy.contacts, new Address()];
  }
  deleteContacts() {
    // ArrayUtil.removeElements(this.supplierCopy.contacts, this.selectedContacts);
    ArrayUtil.removeElements(
      this.supplierCopy.contacts,
      this.contactsGrid.selectedItems
    );
    this.supplierCopy.contacts = [...this.supplierCopy.contacts];
    this.contactsGrid.clearSelection();
  }

  get getSupplier() {
    return this.supplier;
  }

  nomenclatureChanged(event) {
    console.log(this.supplierCopy);
    console.log(event);
  }
}
