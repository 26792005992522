<span [style.background]="color"
      [cpToggle]="true"
      [cpDialogDisplay]="'inline'"
      [cpCancelButton]="true"
      [cpCancelButtonText]="'Annuler'"
      [cpCancelButtonClass]="'btn btn-default'"
      [cpOKButton]="true"
      [cpOKButtonClass]="'btn btn-primary'"
      [cpOutputFormat]="'rgba'"
      [(colorPicker)]="color"
      (colorPickerSelect)="selectColor($event)"></span>