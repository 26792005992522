import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class CustomersRemindersReportModel extends ReportModel {
  public title: string = 'SUIVI COMMERCIAL DES CLIENTS';
  public settings: ReportSetting[] = [
    {
      name: 'order_by',
      title: 'Trier par',
      type: 'list',
      listField: 'text',
      listItems: [
        { text: 'Nom', value: 't.`name`' },
        { text: 'Représentant', value: 't.`merchant`, t.`name`' },
        { text: 'Catégorie', value: 'ISNULL(t.`category`), t.`category`, t.`name`' },
        { text: 'Date dossier commercial', value: '-t.`sale_date` ASC' },
        { text: 'Date commande', value: '-t.`order_date` ASC' },
      ],
      value: { text: 'Catégorie', value: 'ISNULL(t.`category`), t.`category`, t.`name`' },
    },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(226, 107, 10)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static headerCellStyle: any = {
    'background-color': 'rgb(226,173,117)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    color: 'black',
  };
  public static bodyWarning_CellStyle: any = {
    color: 'red',
    'font-weight': 'bold',
  };
  public static name_CellStyle: any = {
    color: 'black',
    'font-weight': 'bold',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 189, 151)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: CustomersRemindersReportModel = new CustomersRemindersReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.headerRows = [
        {
          cells: [
            { value: 'SUIVI COMMERCIAL DES CLIENTS', colSpan: 7, style: CustomersRemindersReportModel.titleCellStyle },
          ],
        },
        {
          cells: [
            { value: 'Client', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Catégorie', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Représentant', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Date dossier', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Jours dossier', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Date commande', style: CustomersRemindersReportModel.headerCellStyle },
            { value: 'Jours commande', style: CustomersRemindersReportModel.headerCellStyle },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'customers_reminders',
        settings: {
          order_by: this.getSetting('order_by').value ? this.getSetting('order_by').value.value || 'name' : 'name',
        },
      }).then(
        (result2) => {
          console.log('result:', result2);
          this.rows = [];
          for (let i = 0; i < result2.details.length; ++i) {
            let name: string = result2.details[i].name;
            let category: string = result2.details[i].category;
            let merchant: string = result2.details[i].merchant;
            let visit_days: string = result2.details[i].visit_days;
            let sale_date: string = result2.details[i].sale_date;
            let sale_days: string = result2.details[i].sale_days;
            let order_date: string = result2.details[i].order_date;
            let order_days: string = result2.details[i].order_days;
            this.rows.push({
              cells: [
                { value: name, style: CustomersRemindersReportModel.name_CellStyle },
                { value: category, style: CustomersRemindersReportModel.body_CellStyle },
                { value: merchant, style: CustomersRemindersReportModel.body_CellStyle },
                { value: sale_date, style: CustomersRemindersReportModel.body_CellStyle },
                {
                  value: sale_days,
                  style:
                    !category || parseInt(visit_days) >= parseInt(sale_days)
                      ? CustomersRemindersReportModel.body_CellStyle
                      : CustomersRemindersReportModel.bodyWarning_CellStyle,
                },
                { value: order_date, style: CustomersRemindersReportModel.body_CellStyle },
                { value: order_days, style: CustomersRemindersReportModel.body_CellStyle },
              ],
            });
          }

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
