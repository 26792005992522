import { Component, OnInit, ViewChild } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
//import { ViewComponent } from '../../../../components/views/view.component';
import { Supplier } from '../../../suppliers/classes/Supplier.class';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { ToolbarItemOptions } from '../../../../components/toolbar/classes/ToolbarItemOptions.class';
import { Customer } from '../../../customers/classes/Customer.class';

@Component({
  selector: 'trash-view',
  templateUrl: './trash-view.component.html',
  styleUrls: ['./trash-view.component.css']
})
export class TrashViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        data: this,
        viewTitle: 'Corbeille',
        elements: [
            {
                name: 'restoreItems',
                type: 'button',
                text: 'Restaurer',
                icon: 'file-export',
                click: function(view: TrashViewComponent) {
                    view.restoreItems();
                },
                disabled: true
            },
            { type: 'spacing' }
        ]
    }

    // Suppliers
    @ViewChild('suppliersGrid') suppliersGrid: DataGridComponent;
    public selectedSuppliers: Supplier[] = null;
    public suppliers: Supplier[] = null;
    public suppliersColumns = [
        { title: 'Nom', field: 'name' },
        { title: 'Numéro', field: 'number' }
    ];
    // Customers
    @ViewChild('customersGrid') customersGrid: DataGridComponent;
    public selectedCustomers: Supplier[] = null;
    public customers: Supplier[] = null;
    public customersColumns = [
        { title: 'Nom', field: 'name' },
        { title: 'Numéro', field: 'number' }
    ];

    ngOnInit()
    {
        this.reloadData();
    }

    reloadData()
    {
        let self = this;
        Supplier.load(null, null, null, true).then(
            function(result) { self.suppliers = result; },
            function(err) { console.error(err); }
        );
        Customer.load(null, null, null, true).then(
            function(result) { self.customers = result; },
            function(err) { console.error(err); }
        );
        this.updateRestoreButton();
    }

    restoreItems()
    {
        let self = this;
        let promises: Promise<any>[] = [];
        if (this.selectedSuppliers)
        {
            for(let i=0; i<this.selectedSuppliers.length; ++i) promises.push(this.selectedSuppliers[i].restoreFromTrash());
        }
        if (this.selectedCustomers)
        {
            for(let i=0; i<this.selectedCustomers.length; ++i) promises.push(this.selectedCustomers[i].restoreFromTrash());
        }
        Promise.all(promises).then(
            function(result) { self.reloadData(); },
            function(err) { console.error(err); }
        );
    }

    suppliersSelectionChange($event)
    {
        this.selectedSuppliers = $event as Supplier[];
        this.updateRestoreButton();
    }
    customersSelectionChange($event)
    {
        this.selectedCustomers = $event as Supplier[];
        this.updateRestoreButton();
    }

    updateRestoreButton()
    {
        Toolbar.getToolbarItem(this.toolbar, 'restoreItems').disabled = (
            !(this.selectedSuppliers && this.selectedSuppliers.length > 0) &&
            !(this.selectedCustomers && this.selectedCustomers.length > 0)
        );
    }
}
