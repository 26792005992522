import { InfoBlockField } from "./InfoBlockField.class";
import { InfoBlockComponent } from "../info-block.component";

export class InfoBlock
{
    public title?: string = null;
    public backColor?: string = null;
    public textColor?: string = null;
    // public dataObject: any = null;
    public fields?: InfoBlockField[] = [];

    public click?: (component: InfoBlockComponent, block: InfoBlock, object: any, event: any) => void = null;
    public objectLoaded?: (object: any) => void = null;
}