import * as _ from 'lodash';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  NgZone,
  OnInit,
  ViewChild,
  ViewContainerRef,
  ViewRef,
} from '@angular/core';

import { AccessViewComponent } from './access-view.component';
import { ExternalService } from '../../services/external/external.service';
import { InteropViewComponent } from '../../modules/interop/interop-view.component';
import { LoginViewComponent } from '../../modules/login/views/login-view/login-view.component';
import { Toolbar } from '../toolbar/classes/Toolbar.class';
import { ViewBaseComponent } from './view-base.component';
import { config } from '../../classes/config';

type RouteDefinition = {
  pattern: RegExp | string;
  viewComponent: typeof ViewBaseComponent;
  viewArgs: any[];
  children?: RouteDefinition[];
};

const routeDefinitions: RouteDefinition[] = [
  {
    pattern: 'articles',
    viewComponent: InteropViewComponent,
    viewArgs: ['ArticlesList', 'ArticlesListToolbar'],
  },
  {
    pattern: /^articles\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['ArticleDetails', 'ArticleDetailsToolbar'],
  },
  {
    pattern: 'customers',
    viewComponent: InteropViewComponent,
    viewArgs: ['CustomersList', 'CustomersListToolbar'],
  },
  {
    pattern: /^customers\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['CustomerDetails', 'CustomerDetailsToolbar'],
  },
  {
    pattern: 'devices',
    viewComponent: InteropViewComponent,
    viewArgs: ['DevicesList', 'DevicesListToolbar'],
  },
  {
    pattern: 'incidents',
    viewComponent: InteropViewComponent,
    viewArgs: ['IncidentsList', 'IncidentsListsToolbar'],
  },
  {
    pattern: /^incidents\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['IncidentDetails', 'IncidentsDetailsToolbar'],
  },
  {
    pattern: 'nomenclatures',
    viewComponent: InteropViewComponent,
    viewArgs: ['NomenclaturesList', 'NomenclaturesListToolbar'],
  },
  {
    pattern: 'orders',
    viewComponent: InteropViewComponent,
    viewArgs: ['OrdersList', 'OrdersListToolbar'],
  },
  {
    pattern: /^orders\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['OrderDetails', 'OrderDetailsToolbar'],
  },
  {
    pattern: 'orders_followup',
    viewComponent: InteropViewComponent,
    viewArgs: ['OrdersFollowupList', 'OrdersFollowupListToolbar'],
  },
  {
    pattern: 'commercial_reimbursements',
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsList'],
  },
  {
    pattern: /^commercial_reimbursements\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsDetails'],
  },
  {
    pattern: 'commercial_invoices',
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoicesList'],
  },
  {
    pattern: /^commercial_invoices\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoiceDetails'],
  },
  {
    pattern: 'commercial_invoice',
    viewComponent: InteropViewComponent,
    viewArgs: ['Invoice'],
  },
  {
    pattern: 'logistic_invoices',
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoicesList'],
  },
  {
    pattern: /^logistic_invoices\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['InvoiceDetails'],
  },
  {
    pattern: 'logistic_reimbursements',
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsList'],
  },
  {
    pattern: /^logistic_reimbursements\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['ReimbursementsDetails'],
  },
  {
    pattern: 'rmas',
    viewComponent: InteropViewComponent,
    viewArgs: ['RmasList', 'RmasListToolbar'],
  },
  {
    pattern: 'sales',
    viewComponent: InteropViewComponent,
    viewArgs: ['SalesList', 'SalesListToolbar'],
  },
  {
    pattern: /^sales\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['SaleDetails', 'SaleDetailsToolbar'],
  },
  {
    pattern: 'stock_moves_followup',
    viewComponent: InteropViewComponent,
    viewArgs: ['StockMovesFollowupList', 'StockMovesFollowupListToolbar'],
  },
  {
    pattern: 'stocks_adjustments',
    viewComponent: InteropViewComponent,
    viewArgs: ['StocksAdjustmentsList', 'StocksAdjustmentsListToolbar'],
  },
  {
    pattern: /^stocks_adjustments\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['StockAdjustmentDetails', 'StockAdjustmentDetailsToolbar'],
  },
  {
    pattern: 'stocks',
    viewComponent: InteropViewComponent,
    viewArgs: ['StocksList', 'StocksListToolbar'],
  },
  {
    pattern: 'stock_moves',
    viewComponent: InteropViewComponent,
    viewArgs: ['StockMovesList', 'StockMovesListToolbar'],
  },
  {
    pattern: /^stock_moves\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['StockMoveDetails', 'StockMovesDetailsToolbar'],
  },
  {
    pattern: 'suppliers',
    viewComponent: InteropViewComponent,
    viewArgs: ['SuppliersList', 'SuppliersListToolbar'],
  },
  {
    pattern: /^suppliers\/.*$/,
    viewComponent: InteropViewComponent,
    viewArgs: ['SupplierDetails', 'SupplierDetailsToolbar'],
  },
  {
    pattern: 'wages',
    viewComponent: InteropViewComponent,
    viewArgs: ['WagesList', 'WagesListToolbar'],
  },
  {
    pattern: 'customer_payments',
    viewComponent: InteropViewComponent,
    viewArgs: ['CustomerPaymentsList', 'CustomerPaymentsListToolbar'],
  },
  {
    pattern: 'supplier_payments',
    viewComponent: InteropViewComponent,
    viewArgs: ['SupplierPaymentsList', 'SupplierPaymentsListToolbar'],
  },
  {
    pattern: 'other-data',
    viewComponent: InteropViewComponent,
    viewArgs: ['OtherDataPage', 'OtherDataToolbar'],
  },
];

@Component({
  selector: 'app-views',
  templateUrl: './views.component.html',
  styleUrls: ['./views.component.scss'],
})
export class ViewsComponent implements OnInit {
  public static instance: ViewsComponent;
  public views: AccessViewComponent[] = [];
  public toolbars: Toolbar[] = [];
  public printMode: boolean = false;

  @ViewChild('vcr', { read: ViewContainerRef }) vcr: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private zone: NgZone
  ) {
    ViewsComponent.instance = this;
  }

  get currentPage() {
    return Math.max(this.views.length - 1, 0);
  }

  ngOnInit() {
    this.openView(LoginViewComponent);
    window.reactInterop.onLocationChanged = (location) =>
      this.zone.run(() => {
        this.updateViewsList(location.pathname);
      });
  }

  findRouteDefinition(route: string, definitions?: RouteDefinition[]) {
    return (definitions || routeDefinitions).find((definition) => {
      return definition.pattern instanceof RegExp
        ? definition.pattern.test(route)
        : definition.pattern === route;
    });
  }

  updateViewsList(route: string) {
    const routeSegments = _.trim(route, '/').split('/').filter(Boolean);
    const routeIds = routeSegments.map((segment, index) =>
      routeSegments.slice(0, index + 1).join('/')
    );
    const routeViews = this.views.slice(2);
    const firstDifferentIndex = routeIds.findIndex(
      (routeId, index) =>
        routeViews[index] && routeId !== routeViews[index]._routeId
    );

    if (firstDifferentIndex >= 0 || routeViews.length !== routeIds.length) {
      // Close views that are not in the new route')
      routeViews.slice(firstDifferentIndex - 1).forEach(() => {
        this.closeView(false);
      });
      // Open views that are in the new route
      routeIds.slice(firstDifferentIndex - 1).forEach((routeId) => {
        const definition = this.findRouteDefinition(routeId);
        const view = this.openView(
          definition.viewComponent,
          ...definition.viewArgs
        );
        view._routeId = routeId;
      });
    }
  }

  createViewComponent(type) {
    if (!(type.prototype instanceof ViewBaseComponent)) {
      console.log('Bad view type :', type.name);
      return null;
    }
    let factory =
      this.componentFactoryResolver.resolveComponentFactory<AccessViewComponent>(
        type
      );
    let ref: ComponentRef<AccessViewComponent> =
      this.vcr.createComponent<AccessViewComponent>(factory);
    ref.instance._ref = ref;
    return ref ? ref.instance : null;
  }

  static openView(type, ...args) {
    if (this.instance) {
      return ViewsComponent.instance.openView(type, ...args);
    }
  }

  public updateChangeDetection() {
    if (this.views.length > 0) {
      for (let i = 0; i < this.views.length - 1; ++i)
        this.views[i].disableChangeDetection();
      this.views[this.views.length - 1].enableChangeDetection();
    }
  }
  public setWindowTitle(title: string) {
    if (ExternalService.available)
      ExternalService.setWindowTitle(
        title && title.length ? title : `${config.companyName} ERP`
      );
    document.title =
      `${config.companyName} ERP` +
      (title && title.length ? ' - ' + title : '');
  }
  public activateView() {
    if (this.views.length > 0) {
      let view: ViewBaseComponent = this.views[this.views.length - 1];
      view.onActivate();
      this.setWindowTitle(
        view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : ''
      );
    }
  }

  public openView(type, ...args) {
    let view: AccessViewComponent = this.createViewComponent(
      type
    ) as AccessViewComponent;
    return this.pushView(view, ...args);
  }

  public pushView(view: AccessViewComponent, ...args) {
    if (view) {
      this.toolbars.push(view.toolbar);
      this.views.push(view);
      view.viewLevel = this.views.length + 1;
      view.views = this;
      view.initView(...args);
      view.onActivate();
      this.setWindowTitle(
        view.toolbar && view.toolbar.viewTitle ? view.toolbar.viewTitle : ''
      );
      this.updateChangeDetection();
    }
    return view;
  }

  public get currentView() {
    if (!this.views || this.views.length == 0) return null;
    else return this.views[this.views.length - 1];
  }

  closeView(navigate = true) {
    let view: AccessViewComponent = this.views.pop();
    let viewRef: ViewRef = this.vcr.get(this.vcr.length - 1);
    let index: number = this.vcr.indexOf(viewRef);
    if (index > 0) this.vcr.remove(index);
    this.toolbars.pop();
    view._ref.destroy();
    this.updateChangeDetection();
    this.activateView();
    if (navigate) {
      window.reactInterop.navigate(
        '/' + this.views[this.views.length - 1]._routeId
      );
    }
  }

  static closeView() {
    return ViewsComponent.instance.closeView();
  }

  public static closeAllViews() {
    while (ViewsComponent.instance.views.length > 0)
      ViewsComponent.instance.closeView();
  }
}
