import { ClassManager } from './ClassManager.class';
import { DataService } from 'src/app/services/data/data.service';
import { LoadingPromise } from './LoadingPromise.class';
import { ObjectChildDefinition } from './ObjectChildDefinition.class';
import { ObjectDefinition } from './ObjectDefinition.class';
import { ObjectLinkDefinition } from './ObjectLinkDefinition.class';
import { ObjectModel3 } from './ObjectModel3.class';

export class DeleteSequence {
  public ids_to_delete: { [type: string]: string[] } = {};

  public baseSequence: DeleteSequence = null;
  public number: number = 0;
  public static nextNumber: number = 1;

  public constructor(objects: ObjectModel3[], baseSequence: DeleteSequence = null) {
    this.baseSequence = baseSequence;
    this.number = DeleteSequence.nextNumber++;
    this.addObjects(objects);
  }

  public addObjects(objects: ObjectModel3[]) {
    for (let i = 0; i < objects.length; ++i) if (objects[i] instanceof ObjectModel3) this.addObject(objects[i]);
  }

  public addObject(obj: ObjectModel3) {
    // console.log('adding object to delete:', obj);
    let type: string = ClassManager.getClassName(obj);
    let definition: ObjectDefinition = ClassManager.getClass(type).definition;
    let id_field: string = definition.database.id || 'id';
    let id = obj[id_field];
    if (id) {
      if (!this.ids_to_delete[type]) this.ids_to_delete[type] = [];
      if (!this.ids_to_delete[type].includes(id)) this.ids_to_delete[type].push(id);
      if (definition.children) {
        for (let childName in definition.children) {
          let childDef: ObjectChildDefinition = definition.children[childName];
          if (childDef.delete === true && obj[childName] instanceof ObjectModel3) this.addObject(obj[childName]);
        }
      }
      if (definition.links) {
        for (let linkName in definition.links) {
          let linkDef: ObjectLinkDefinition = definition.links[linkName];
          if (linkDef.delete === true && Array.isArray(obj[linkName])) this.addObjects(obj[linkName]);
        }
      }
    }
  }

  public static create(objects: ObjectModel3[]) {
    return new DeleteSequence(objects);
  }

  public delete() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (let className in this.ids_to_delete) {
        let currentClass: any = ClassManager.getClass(className);
        let definition: ObjectDefinition = currentClass.definition;
        let id_field: string = definition.database.id || 'id';
        promises.push(
          DataService.sendCommand('DELETE', {
            table: definition.database.table,
            id_field: id_field,
            ids: this.ids_to_delete[className],
          })
        );
      }
      Promise.all(promises).then(
        (result) => {
          console.log('delete result:', result);
          resolve(result);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
