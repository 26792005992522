import { CommonModule } from '@angular/common';
import { ComponentsModule } from 'src/app/components/components.module';
import { CustomersRemindersReportModel } from './classes/CustomersRemindersReportModel.class';
import { FormsModule } from '@angular/forms';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { MerchantOrdersReportModel } from './classes/MerchantOrdersReportModel.class';
import { MerchantResultsReportModel } from './classes/MerchantResultsReportModel.class';
import { ModuleBase } from '../module-base.class';
import { MonthlyAccountingNetMarginReportModel } from './classes/MonthlyAccountingNetMarginReportModel.class';
import { MonthlyAccountingRawMarginReportModel } from './classes/MonthlyAccountingRawMarginReportModel.class';
import { MonthlyAccountingSplitSummaryReportModel } from './classes/MonthlyAccountingSplitSummaryReportModel.class';
import { MonthlyChargesSplitReportModel } from './classes/MonthlyChargesSplitReportModel.class';
import { MonthlyNetMarginReportModel } from './classes/MonthlyNetMarginReportModel.class';
import { MonthlyOrdersSplitReportModel } from './classes/MonthlyOrdersSplitReportModel.class';
import { MonthlyPurchasesSplitReportModel } from './classes/MonthlyPurchasesSplitReportModel.class';
import { MonthlyRawMarginReportModel } from './classes/MonthlyRawMarginReportModel.class';
import { MonthlySalesSplitReportModel } from './classes/MonthlySalesSplitReportModel.class';
import { MonthlySplitSummaryReportModel } from './classes/MonthlySplitSummaryReportModel.class';
import { MonthlyVatSplitReportModel } from './classes/MonthlyVatBalanceReportModel.class';
import { NgModule } from '@angular/core';
import { PurchasesBySupplierReportModel } from './classes/PurchasesBySupplierReportModel.class';
import { ReportViewComponent } from '../reports/views/report-view/report-view.component';
import { SalesByCustomerReportModel } from './classes/SalesByCustomerReportModel.class';
import { SalesByNomenclatureReportModel } from './classes/SalesByNomenclatureReportModel.class';
import { UsersPerformancesReportModel } from './classes/UsersPerformancesReportModel.class';
import { YearlyChargesSplitReportModel } from './classes/YearlyChargesSplitReportModel.class';
import { YearlyOrdersSplitReportModel } from './classes/YearlyOrdersSplitReportModel.class';
import { YearlyPurchasesSplitReportModel } from './classes/YearlyPurchasesSplitReportModel.class';
import { YearlyResultReportModel } from './classes/YearlyResultReportModel.class';
import { YearlySalesSplitReportModel } from './classes/YearlySalesSplitReportModel.class';
import { YearlyVatBalanceReportModel } from './classes/YearlyVatBalanceReportModel.class';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule],
  declarations: [],
})
export class StatsModule extends ModuleBase {
  public static moduleName = 'stats';

  public static chartsColor: any = {
    list: ['#00a', '#0a0', '#a00', '#aa048a', '#0aa', '#dc0', '#555', '#a50', '#f55', '#851bc5', '#01cb8e', '#000000'],
  };

  public ngOnInit() {
    HomepageViewComponent.registerGroups({
      sales: {
        permission: 'home_groupe_ventes',
        children: [
          {
            name: 'Suivi commercial des clients',
            permission: 'home_bouton_ventes_suivi',
            component: ReportViewComponent,
            getArgs: () => [
              {
                purchases: CustomersRemindersReportModel.generate(),
              },
            ],
          },
        ],
      },
      stats: {
        name: 'Consolidations',
        permission: 'home_groupe_consolidations',
        backColor: 'rgb(112, 48, 160)',
        textColor: 'white',
        children: [
          {
            name: 'Achats par fournisseur',
            permission: 'home_bouton_consolidations_achats',
            component: ReportViewComponent,
            getArgs: () => [
              {
                purchases: PurchasesBySupplierReportModel.generate(),
              },
            ],
          },
          {
            name: 'Ventes par client',
            permission: 'home_bouton_consolidations_ventes_client',
            component: ReportViewComponent,
            getArgs: () => [
              {
                sales: SalesByCustomerReportModel.generate(),
              },
            ],
          },
          {
            name: 'Ventes par nomenclature',
            permission: 'home_bouton_consolidations_ventes_nomenc',
            component: ReportViewComponent,
            getArgs: () => [
              {
                purchases: SalesByNomenclatureReportModel.generate(),
              },
            ],
          },
          {
            name: 'Consolidations annuelles',
            permission: 'home_bouton_consolidations_annuelles',
            component: ReportViewComponent,
            getArgs: () => [
              {
                // 'summary': YearlySplitSummaryReportModel.generate(),
                orders: YearlyOrdersSplitReportModel.generate(),
                purchases: YearlyPurchasesSplitReportModel.generate(),
                sales: YearlySalesSplitReportModel.generate(),
                charges: YearlyChargesSplitReportModel.generate(),
                vat_balance: YearlyVatBalanceReportModel.generate(),
                results: YearlyResultReportModel.generate(),
                // 'net_margins': YearlyNetMarginReportModel.generate()
              },
            ],
          },
          {
            name: 'Consolidations périodiques',
            permission: 'home_bouton_consolidations_periodiques',
            component: ReportViewComponent,
            getArgs: () => [
              {
                summary: MonthlySplitSummaryReportModel.generate(),
                orders: MonthlyOrdersSplitReportModel.generate(),
                purchases: MonthlyPurchasesSplitReportModel.generate(),
                sales: MonthlySalesSplitReportModel.generate(),
                charges: MonthlyChargesSplitReportModel.generate(),
                vat_balance: MonthlyVatSplitReportModel.generate(),
                raw_margins: MonthlyRawMarginReportModel.generate(),
                net_margins: MonthlyNetMarginReportModel.generate(),
              },
            ],
          },
          {
            name: 'Consolidations comptables',
            permission: 'home_bouton_consolidations_periodiques_comptables',
            component: ReportViewComponent,
            getArgs: () => [
              {
                summary: MonthlyAccountingSplitSummaryReportModel.generate(),
                purchases: MonthlyPurchasesSplitReportModel.generate(),
                charges: MonthlyChargesSplitReportModel.generate(),
                vat_balance: MonthlyVatSplitReportModel.generate(),
                raw_margins: MonthlyAccountingRawMarginReportModel.generate(),
                net_margins: MonthlyAccountingNetMarginReportModel.generate(),
              },
            ],
          },
          {
            name: 'Commandes par représentant',
            permission: 'home_bouton_consolidations_commandes',
            backColor: 'rgb(198, 132, 247)',
            textColor: 'black',
            component: ReportViewComponent,
            getArgs: () => [
              {
                purchases: MerchantOrdersReportModel.generate(),
              },
            ],
          },
          {
            name: 'Commissions des représentants',
            permission: 'home_bouton_consolidations_commissions',
            backColor: 'rgb(198, 132, 247)',
            textColor: 'black',
            component: ReportViewComponent,
            getArgs: () => [
              {
                purchases: MerchantResultsReportModel.generate(),
              },
            ],
          },
          {
            name: 'Prestations',
            permission: 'home_bouton_consolidations_prestations',
            backColor: 'rgb(198, 132, 247)',
            textColor: 'black',
            component: ReportViewComponent,
            getArgs: () => [
              {
                performances: UsersPerformancesReportModel.generate(),
              },
            ],
          },
        ],
      },
    });

    return super.ngOnInit();
  }
}
