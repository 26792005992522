import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { AddressTextFieldComponent } from './address-text-field.component';
import { Address } from '../../modules/addresses/classes/Address.class';
import { analyzeAndValidateNgModules } from '@angular/compiler';

@Component({
  selector: 'app-address-selection-modal',
  templateUrl: './address-selection-modal.component.html',
  styleUrls: ['./address-selection-modal.component.css']
})
export class AddressSelectionModalComponent implements OnInit {

    public addresses: any[] = null;
    public selectedAddress: Address = null;
    public addressComponent: AddressTextFieldComponent = null;
    public resolve: (any) => {} = null;
    public reject: (any) => {} = null;

    constructor(private modalRef: BsModalRef) {}

    ngOnInit() {
    }

    selectButtonClick()
    {
        if (this.resolve) this.resolve(this.selectedAddress);
        this.modalRef.hide();
    }
    cancelButtonClick()
    {
        if (this.reject) this.reject(null);
        this.modalRef.hide();
    }

}
