import { ExternalService } from "src/app/services/external/external.service";


export class FilesystemUtil
{
    private static tagFunctions: any = {
        'CURRENT_YEAR': () => { return (new Date()).getFullYear(); },
        'DROPBOX': () => { return ExternalService.callSync('getDropboxDirectory'); }
    };

    public static resolveSpecialPath(path: string)
    {
        if (!path) return null;
        return path.replace(/\%([^\%]+)\%/g, (a, s) => FilesystemUtil.resolvePathTag(s));
    }

    public static resolvePathTag(tag: string)
    {
        let parts: string[] = tag.split('|');
        let args: string[] = parts.slice(1);
        console.log('parts:', parts);
        console.log('args', args);
        let func = FilesystemUtil.tagFunctions[parts[0]];
        console.log(func);
        console.log(typeof(func));
        if (func && typeof(func) === 'function') return func(...args);
        return '';
    }
}