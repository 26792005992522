import { ApiService } from 'src/app/services/api/api.service';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { User } from 'src/app/classes/credentials/User.class';

export class UsersPerformancesReportModel extends ReportModel {
  public title: string = 'PRESTATIONS';
  public settings: ReportSetting[] = [
    { name: 'year', title: 'Année', type: 'number', value: new Date().getFullYear() },
    { name: 'user', title: 'Utilisateur', type: 'list', value: null, listItems: [], listField: 'name' },
  ];

  public usersData: any = { items: [] };

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static merchantNameCellStyle: any = {
    'background-color': 'rgb(0,0,0)',
    text: 'rgb(0,176,240)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(0,176,240)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static headerTotalCellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(220, 230, 241)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: UsersPerformancesReportModel = new UsersPerformancesReportModel();
    if (settings) model.settings = settings;
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: any[] = [];
      let ids: string[] = null;
      if (!CredentialsService.isUserAllowed('stats_prestations_voirtoutlemonde')) {
        ids = [CredentialsService.loggedUser.id];
      }
      if (!this.usersData.items.length)
        promises.push(
          User.load(ids).then((result: User[]) => {
            this.getSetting('user').listItems = result;
            if (result.length > 0 && !this.getSetting('user').value) {
              this.getSetting('user').value = result[0];
              // this.regenerate();
            }
          })
        );

      Promise.all(promises).then(() => {
        const currentUser = this.getSetting('user').value;
        if (!currentUser) return;

        this.headerRows = [
          { cells: [{ value: 'PRESTATIONS', colSpan: 14, style: UsersPerformancesReportModel.titleCellStyle }] },
          {
            cells: [
              { value: currentUser.name, style: UsersPerformancesReportModel.merchantNameCellStyle },
              { value: 'Janvier', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Février', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Mars', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Avril', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Mai', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Juin', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Juillet', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Aout', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Septembre', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Octobre', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Novembre', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'Décembre', style: UsersPerformancesReportModel.month_CellStyle },
              { value: 'TOTAUX', style: UsersPerformancesReportModel.bodyTotal_CellStyle },
            ],
          },
        ];

        this.rows = [];
        this.rows.push(
          { cells: [{ value: 'Commandes', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: 'Factures logistiques', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: 'Notes de crédit logistiques', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: 'Factures commerciales', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: 'Notes de crédit commerciales', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: 'Documents U.K.', style: UsersPerformancesReportModel.month_CellStyle }] },
          { cells: [{ value: "Nombre d'interventions", style: UsersPerformancesReportModel.bodyTotal_CellStyle }] },
          { cells: [{ value: 'Total des prestations', style: UsersPerformancesReportModel.bodyTotal_CellStyle }] }
        );

        ApiService.callModule('reports', 'report', {
          query: 'monthly_performances',
          settings: {
            year_start: this.getSetting('year').value,
            year_end: this.getSetting('year').value,
            id_user: currentUser.id,
          },
        }).then(
          (result2: any) => {
            console.log('users performances results:', result2);
            let orders_arr: number[] = [];
            let log_inv_arr: number[] = [];
            let log_reim_arr: number[] = [];
            let com_inv_arr: number[] = [];
            let com_reim_arr: number[] = [];
            let total_arr: number[] = [];
            for (let i = 0; i < result2.details.length; ++i) {
              let month_str: any = result2.details[i]['month'];
              let month: number = parseFloat(month_str);
              let orders_str: any = result2.details[i]['orders'];
              let order: number = parseFloat(orders_str);
              let log_inv_str: any = result2.details[i]['logistics_invoices'];
              let log_inv: number = parseFloat(log_inv_str);
              let log_reim_str: any = result2.details[i]['logistics_reimbursements'];
              let log_reim: number = parseFloat(log_reim_str);
              let com_inv_str: any = result2.details[i]['commercial_invoices'];
              let com_inv: number = parseFloat(com_inv_str);
              let com_reim_str: any = result2.details[i]['commercial_reimbursements'];
              let com_reim: number = parseFloat(com_reim_str);
              let total_str: any = result2.details[i]['total'];
              let total: number = parseFloat(total_str);
              if (month >= 1) {
                if (order && order != 0) {
                  if (!orders_arr[month - 1]) orders_arr[month - 1] = 0;
                  orders_arr[month - 1] += order;
                }
                if (log_inv && log_inv != 0) {
                  if (!log_inv_arr[month - 1]) log_inv_arr[month - 1] = 0;
                  log_inv_arr[month - 1] += log_inv;
                }
                if (log_reim && log_reim != 0) {
                  if (!log_reim_arr[month - 1]) log_reim_arr[month - 1] = 0;
                  log_reim_arr[month - 1] += log_reim;
                }
                if (com_inv && com_inv != 0) {
                  if (!com_inv_arr[month - 1]) com_inv_arr[month - 1] = 0;
                  com_inv_arr[month - 1] += com_inv;
                }
                if (com_reim && com_reim != 0) {
                  if (!com_reim_arr[month - 1]) com_reim_arr[month - 1] = 0;
                  com_reim_arr[month - 1] += com_reim;
                }
                if (total && total != 0) {
                  if (!total_arr[month - 1]) total_arr[month - 1] = 0;
                  total_arr[month - 1] += total;
                }
              }
            }

            let orders_total: number = 0;
            let log_inv_total: number = 0;
            let log_reim_total: number = 0;
            let com_inv_total: number = 0;
            let com_reim_total: number = 0;
            let total_total: number = 0;
            // let total_docs: number = 0;
            let total_amount: number = 0;
            for (let i = 0; i < 12; ++i) {
              try {
                let order: number = orders_arr[i] || 0;
                let log_inv: number = log_inv_arr[i] || 0;
                let log_reim: number = log_reim_arr[i] || 0;
                let com_inv: number = com_inv_arr[i] || 0;
                let com_reim: number = com_reim_arr[i] || 0;
                let total: number = total_arr[i] || 0;
                // let docs: number = order + log_inv + log_reim + com_inv + com_reim + total;
                let amount: number = total * 18;
                this.rows[0].cells.push({
                  value: order && order != 0 ? NumberUtil.formatNumber(order, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[1].cells.push({
                  value: log_inv && log_inv != 0 ? NumberUtil.formatNumber(log_inv, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[2].cells.push({
                  value: log_reim && log_reim != 0 ? NumberUtil.formatNumber(log_reim, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[3].cells.push({
                  value: com_inv && com_inv != 0 ? NumberUtil.formatNumber(com_inv, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[4].cells.push({
                  value: com_reim && com_reim != 0 ? NumberUtil.formatNumber(com_reim, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[5].cells.push({
                  value: '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[6].cells.push({
                  value: total && total != 0 ? NumberUtil.formatNumber(total, 0, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[7].cells.push({
                  value: amount && amount != 0 ? NumberUtil.formatMoney(amount, '€', 2, '.') : '',
                  style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                });
                orders_total += order;
                log_inv_total += log_inv;
                log_reim_total += log_reim;
                com_inv_total += com_inv;
                com_reim_total += com_reim;
                total_total += total;
                // total_docs += docs;
                total_amount += amount;
              } catch {
                continue;
              }
            }

            this.rows[0].cells.push({
              value: orders_total && orders_total != 0 ? NumberUtil.formatNumber(orders_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[1].cells.push({
              value: log_inv_total && log_inv_total != 0 ? NumberUtil.formatNumber(log_inv_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[2].cells.push({
              value: log_reim_total && log_reim_total != 0 ? NumberUtil.formatNumber(log_reim_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[3].cells.push({
              value: com_inv_total && com_inv_total != 0 ? NumberUtil.formatNumber(com_inv_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[4].cells.push({
              value: com_reim_total && com_reim_total != 0 ? NumberUtil.formatNumber(com_reim_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[5].cells.push({
              value: '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            this.rows[6].cells.push({
              value: total_total && total_total != 0 ? NumberUtil.formatNumber(total_total, 0, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.month_CellStyle, ReportCell.moneyCellStyle),
            });
            // this.rows[7].cells.push({ value: total_docs && total_docs != 0 ? NumberUtil.formatNumber(total_docs, 0, '.') : '', style: ObjectUtil.merge(UsersPerformancesReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) });
            this.rows[7].cells.push({
              value: total_amount && total_amount != 0 ? NumberUtil.formatMoney(total_amount, '€', 2, '.') : '',
              style: ObjectUtil.merge(UsersPerformancesReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
            });

            this.createEmptyCells();
            resolve(true);
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      });
    });
  }
}
