import * as $ from 'jquery';

import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  NgZone,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

import Cleave from 'cleave.js';
import { Currency } from 'src/app/modules/other-data/classes/Currency.class';
import { NumberUtil } from '../../modules/utils/classes/NumberUtil.class';

@Component({
  selector: 'number-field',
  templateUrl: './number-field.component.html',
  styleUrls: ['./number-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberFieldComponent),
      multi: true,
    },
  ],
})
export class NumberFieldComponent implements ControlValueAccessor, AfterViewInit {
  @ViewChild('integerInput') integerInput: ElementRef;
  public _textValue: string = '0';
  private _oldValue: number = null;
  private _value: number = null;
  public inputWidth: number = null;

  @Input() inputSize: number = null;
  @Input() decimalsCount: number = 2;
  @Input() thousandSeparator: string = '.';
  @Input() unit: string = null;
  @Input() negativeColor: string = null;
  @Input() currency: Currency = null;
  @HostBinding('class.readonly') @Input() readonly: boolean = false;
  @Input() textAlign: string = null;

  @Output() calculate: EventEmitter<any> = new EventEmitter<any>();
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  public cleave: any = null;

  public get value(): number {
    return parseFloat('' + this._textValue.replace(/\./g, '').replace(/\,/g, '.'));
  }
  public set value(value: number) {
    this.setValue(value);
    this.propagateChange(value);
    this.change.next(this.value);
  }

  public get textValue() {
    return this._textValue;
  }
  public set textValue(value: string) {
    this._textValue = value;
    this.propagateChange(this.value);
    this.change.next(this.value);
  }

  public setValue(value: any) {
    if (!value) value = 0;
    //if (typeof value === 'number') value = ("" + value.toFixed(this.decimalsCount)).replace('.',',');
    if (typeof value !== 'number') value = parseFloat(value);
    this._textValue = NumberUtil.formatNumber(value, this.decimalsCount, '.');
    // let result = /^(\-?[0-9]*)(\,([0-9]+))?$/.exec(value);
    // if (!result) {
    //     this._textValue = '0,' + NumberUtil.zeroPadLeft(0, this.decimalsCount);
    // }
    // else {
    //     this._textValue = (result[1] ? result[1] : '0') + ',' + (result[3] ? result[3] : NumberUtil.zeroPadLeft(0, this.decimalsCount));
    // }
  }

  public get formattedInteger(): string {
    return NumberUtil.formatNumber(this.value, this.decimalsCount, '.');
    // let v: number = this.value;
    // let i: number = v < 0 ? Math.ceil(v) : Math.floor(v);
    // let s: string = Math.abs(i).toString();
    // let r: string = i < 0 ? '-' : '';
    // for(let j=0; j<s.length; ++j)
    // {
    //     if (j > 0 && (s.length - j) % 3 == 0) r += '.';
    //     r += s[j];
    // }
    // let d: number = Math.round( (v - i) * Math.pow(10, this.decimalsCount || 0) );
    // //s = d.toString().substr(2);
    // s = d.toString();
    // for(let i=s.length; i<this.decimalsCount; ++i) s+= '0';
    // if (s.length == 0) return r;
    // else return r + ',' + s;
  }

  formatFields() {
    this.ref.detach();
    this.zone.runOutsideAngular(() => {
      if (!this.readonly && this.integerInput) {
        // && this.decimalsInput)
        if (!this.cleave)
          this.cleave = new Cleave(this.integerInput.nativeElement, {
            numeral: true,
            numeralThousandsGroupStyle: 'thousand',
            numeralDecimalMark: ',',
            delimiter: '.',
            numeralDecimalScale: this.decimalsCount,
          });
        // if (!this.cleave) this.cleave = new Cleave(this.integerInput.nativeElement, {
        //     numeral: true,
        //     numeralThousandsGroupStyle: 'thousand',
        //     numeralDecimalMark: ',',
        //     delimiter: '.',
        //     numeralDecimalScale: this.decimalsCount
        // });
        if (!this._oldValue || this._oldValue != this._value) {
          //this.inputWidth = AutosizeInputService.resizeInput(this.integerInput.nativeElement, false);
          //if (this.decimalsCount > 0) AutosizeInputService.enableAutoSize(this.decimalsInput.nativeElement);
          this._oldValue = this._value;
        }
      }
    });
    this.ref.reattach();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      //AutosizeInputService.enableAutoSize(this.integerInput.nativeElement);
      this.formatFields();
    }, 0);
  }

  @HostListener('click') public hostClick(event: Event) {
    if (this.integerInput) $(this.integerInput.nativeElement).focus();
  }
  public inputClick(event: Event) {
    event.stopPropagation();
  }
  public onFocus(event: Event) {
    if (!this.readonly) $(event.currentTarget).select();
  }

  public keyUp(event: Event) {
    if (this.decimalsCount > 0 && this._textValue) {
      var index = this._textValue.indexOf(',');
      if (index > 0) {
        var nbDec = this._textValue.length - index - 1;
        this.textValue = this._textValue.substr(0, this._textValue.length - (nbDec - this.decimalsCount));
      }
    }
  }

  public keyDown(event: KeyboardEvent): boolean {
    if (this.readonly === true) return false;
    if (event.key === 'ArrowUp') {
      if (event.target == this.integerInput.nativeElement) ++this.value;
      this.formatFields();
      return false;
    } else if (event.key === 'ArrowDown') {
      if (event.target == this.integerInput.nativeElement) --this.value;
      this.formatFields();
      return false;
    } else if (!/^([0-9]*|-|Backspace|Delete|Arrow.*|Tab)$/.test(event.key)) {
      if (event.key == '.' || event.key == ',') {
        if (this._textValue.indexOf(',') < 0) this._textValue += ',';
        this.formatFields();
        return false;
      }
    }
    return true;
  }

  constructor(public elementRef: ElementRef, private zone: NgZone, private ref: ChangeDetectorRef) {}

  // ControlValueAccessor

  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}
  writeValue(value: any) {
    this._value = value;
    this.setValue(this._value);
  }
}
