import { Country } from '../../other-data/classes/Country.class';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { Currency } from '../../other-data/classes/Currency.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Supplier } from '../../suppliers/classes/Supplier.class';

export class Price extends ObjectModel3 {
  public supplier: Supplier = null;
  public buy_currency: Currency = null;
  public buy_xrate: number = 1;
  public sell_currency: Currency = null;
  public sell_xrate: number = 1;

  public show: number = 1;
  public quantity: number = null;
  public unit: string = null;

  public date: string = null;
  public reference: string = null;
  public unit_price: number = null;
  public internal_fees: number = null;
  public supplier_fees: number = null;
  public external_fees: number = null;
  public agent_fees: number = null;

  public sell_price: number = null;

  public invoicings: number = 1;
  public deliveries: number = 1;
  public storage: number = null;
  public productions: number = null;

  public remarks: string = null;

  constructor() {
    super(Price);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'prices',
      id: 'id',
    },
    values: {
      buy_xrate: { type: 'number' },
      sell_xrate: { type: 'number' },
      show: { type: 'number' },
      quantity: { type: 'number' },
      unit: { type: 'string' },
      date: { type: 'string' },
      reference: { type: 'string' },
      unit_price: { type: 'number' },
      internal_fees: { type: 'number' },
      supplier_fees: { type: 'number' },
      external_fees: { type: 'number' },
      agent_fees: { type: 'number' },
      sell_price: { type: 'number' },
      invoicings: { type: 'number' },
      deliveries: { type: 'number' },
      storage: { type: 'number' },
      productions: { type: 'number' },
      remarks: { type: 'string' },
    },
    children: {
      buy_currency: { type: 'Currency', clone: false, save: false, delete: false },
      sell_currency: { type: 'Currency', clone: false, save: false, delete: false },
      supplier: { type: 'Supplier', clone: false, save: false, delete: false },
    },
    links: {},
  };

  public get converted_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.internal_fees);
  }
  public set converted_internalFees(value: number) {
    this.internal_fees = CurrenciesService.currencyToEuro(this.sell_xrate, value);
  }
  public get converted_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.external_fees);
  }
  public set converted_externalFees(value: number) {
    this.external_fees = CurrenciesService.currencyToEuro(this.sell_xrate, value);
  }
  public get converted_agentFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.agent_fees);
  }
  public set converted_agentFees(value: number) {
    this.agent_fees = CurrenciesService.currencyToEuro(this.sell_xrate, value);
  }
  public get converted_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.sell_price);
  }
  public set converted_sellPrice(value: number) {
    this.sell_price = CurrenciesService.currencyToEuro(this.sell_xrate, value);
  }

  public get converted_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.supplier_fees);
  }
  public set converted_supplierFees(value: number) {
    this.supplier_fees = CurrenciesService.currencyToEuro(this.buy_xrate, value);
  }
  public get converted_unitPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.unit_price);
  }
  public set converted_unitPrice(value: number) {
    this.unit_price = CurrenciesService.currencyToEuro(this.buy_xrate, value);
  }

  public get totalUnitPrice(): number {
    if (this.quantity <= 0) return 0;
    return this.quantity * (this.unit_price ? this.unit_price * 1 : 0);
  }
  public get unitBuyPrice(): number {
    if (this.quantity <= 0) return 0;
    else
      return (
        this.unit_price * 1 +
        (this.supplier_fees ? this.supplier_fees * 1 : 0) /*+
                                         (this.external_fees ? this.external_fees*1 : 0)*/ /
          this.quantity
      );
  }
  public get totalBuyPrice(): number {
    if (this.quantity <= 0) return 0;
    return (
      this.quantity * this.unit_price +
      (this.supplier_fees ? this.supplier_fees * 1 : 0) /*+
                                                    (this.external_fees ? this.external_fees*1 : 0)*/
    );
  }
  public get unitCostPrice(): number {
    if (this.quantity <= 0) return 0;
    return this.totalCostPrice / this.quantity;
  }
  public get totalCostPrice(): number {
    return (
      this.totalBuyPrice +
      (this.internal_fees ? this.internal_fees * 1 : 0) +
      (this.external_fees ? this.external_fees * 1 : 0) +
      (this.agent_fees ? this.agent_fees * 1 : 0)
    );
  }
  public get totalSellPrice(): number {
    if (this.quantity <= 0) return 0;
    return this.quantity * (this.sell_price ? this.sell_price * 1 : 0);
  }

  public get profitAmount(): number {
    if (this.quantity <= 0) return 0;
    return (this.sell_price ? this.sell_price * 1 : 0) * this.quantity - this.totalCostPrice;
  }
  public get profitPercentage(): number {
    if (this.sell_price <= 0) return 0;
    return (this.sell_price / this.unitCostPrice - 1) * 100;
  }

  public get quantityAndUnit() {
    let arr: string[] = [];
    arr.push(NumberUtil.formatNumber(this.quantity || 0, null, '.'));
    if (this.unit) arr.push(this.unit);
    return arr.join(' ');
  }

  public get unit_buyPrice() {
    return this.quantity ? this.total_buyPrice / this.quantity : 0;
  }
  public get unit_costPrice() {
    return this.quantity ? this.total_costPrice / this.quantity : 0;
  }
  public get unit_supplierFees() {
    return this.quantity ? this.total_supplierFees / this.quantity : 0;
  }
  public get unit_externalFees() {
    return this.quantity ? this.total_externalFees / this.quantity : 0;
  }
  public get unit_agentFees() {
    return this.quantity ? this.total_agentFees / this.quantity : 0;
  }
  public get unit_internalFees() {
    return this.quantity ? this.total_internalFees / this.quantity : 0;
  }
  public get unit_sellPrice() {
    return this.sell_price;
  }
  public get unit_rawMargin() {
    return this.quantity ? this.total_rawMargin / this.quantity : 0;
  }
  public get unit_netMargin() {
    return this.quantity ? this.total_netMargin / this.quantity : 0;
  }

  public get converted_unit_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.unit_buyPrice);
  }
  public get converted_unit_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.unit_costPrice);
  }
  public get converted_unit_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.unit_supplierFees);
  }
  public get converted_unit_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.unit_externalFees);
  }
  public get converted_unit_agentFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.unit_agentFees);
  }
  public get converted_unit_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.unit_internalFees);
  }
  public get converted_unit_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.unit_sellPrice);
  }

  public get total_buyPrice() {
    return this.quantity ? this.unit_price * this.quantity + this.total_supplierFees : 0;
  }
  public get total_costPrice() {
    return this.total_buyPrice + this.total_externalFees + this.total_internalFees + this.total_agentFees;
  }
  public get total_supplierFees() {
    return this.supplier_fees;
  }
  public get total_externalFees() {
    return this.external_fees;
  }
  public get total_agentFees() {
    return this.agent_fees;
  }
  public get total_internalFees() {
    return this.internal_fees;
  }
  public get total_sellPrice() {
    return this.quantity ? this.unit_sellPrice * this.quantity : 0;
  }
  public get total_rawMargin() {
    return this.total_sellPrice - this.total_buyPrice;
  }
  public get total_netMargin() {
    return this.total_rawMargin - this.total_externalFees - this.total_internalFees - this.total_agentFees;
  }

  public get converted_total_buyPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.total_buyPrice);
  }
  public get converted_total_costPrice() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.total_costPrice);
  }
  public get converted_total_supplierFees() {
    return CurrenciesService.euroToCurrency(this.buy_xrate, this.total_supplierFees);
  }
  public get converted_total_externalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.total_externalFees);
  }
  public get converted_total_agentFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.total_agentFees);
  }
  public get converted_total_internalFees() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.total_internalFees);
  }
  public get converted_total_sellPrice() {
    return CurrenciesService.euroToCurrency(this.sell_xrate, this.total_sellPrice);
  }

  public get total_rawMarginPerc() {
    return this.total_buyPrice ? this.total_rawMargin / this.total_buyPrice : 0;
  }
  public get total_netMarginPerc() {
    return this.total_costPrice ? this.total_netMargin / this.total_costPrice : 0;
  }
  public get total_netMarkupPerc() {
    return this.total_sellPrice ? 100 * (1 - this.total_costPrice / this.total_sellPrice) : 0;
  }
}
