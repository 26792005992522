import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { MonthlyChargesSplitReportModel } from './MonthlyChargesSplitReportModel.class';
import { MonthlyNetMarginReportModel } from './MonthlyNetMarginReportModel.class';
import { MonthlyOrdersSplitReportModel } from './MonthlyOrdersSplitReportModel.class';
import { MonthlyPurchasesSplitReportModel } from './MonthlyPurchasesSplitReportModel.class';
import { MonthlyRawMarginReportModel } from './MonthlyRawMarginReportModel.class';
import { MonthlySalesSplitReportModel } from './MonthlySalesSplitReportModel.class';
import { MonthlyVatSplitReportModel } from './MonthlyVatBalanceReportModel.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class MonthlyAccountingSplitSummaryReportModel extends ReportModel {
  public title: string = 'Consolidations comptables';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(92,92,92)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(148,148,148)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 196, 196)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public ordersReport: MonthlyOrdersSplitReportModel = null;
  public purchasesReport: MonthlyPurchasesSplitReportModel = null;
  public salesReport: MonthlySalesSplitReportModel = null;
  public chargesReport: MonthlyChargesSplitReportModel = null;
  public vatBalanceReport: MonthlyVatSplitReportModel = null;
  public rawMarginReport: MonthlyRawMarginReportModel = null;
  public netMarginReport: MonthlyNetMarginReportModel = null;

  public static generate(settings: ReportSetting[] = null) {
    let model: MonthlyAccountingSplitSummaryReportModel = new MonthlyAccountingSplitSummaryReportModel();
    if (settings) model.settings = settings;
    model.ordersReport = MonthlyOrdersSplitReportModel.generate(settings);
    model.purchasesReport = MonthlyPurchasesSplitReportModel.generate(settings);
    model.salesReport = MonthlySalesSplitReportModel.generate(settings);
    model.chargesReport = MonthlyChargesSplitReportModel.generate(settings);
    model.vatBalanceReport = MonthlyVatSplitReportModel.generate(settings);
    model.rawMarginReport = MonthlyRawMarginReportModel.generate(settings);
    model.netMarginReport = MonthlyNetMarginReportModel.generate(settings);
    model.regenerate();
    return model;
  }

  public regenerate(): Promise<any> {
    return LoadingPromise.create((resolve, reject) => {
      this.ordersReport.settings = this.settings;
      this.purchasesReport.settings = this.settings;
      this.salesReport.settings = this.settings;
      this.chargesReport.settings = this.settings;
      this.vatBalanceReport.settings = this.settings;
      this.rawMarginReport.settings = this.settings;
      this.netMarginReport.settings = this.settings;
      Promise.all([
        this.ordersReport.regenerate(),
        this.purchasesReport.regenerate(),
        this.salesReport.regenerate(),
        this.chargesReport.regenerate(),
        this.vatBalanceReport.regenerate(),
        this.rawMarginReport.regenerate(),
        this.netMarginReport.regenerate(),
      ]).then(
        (result) => {
          this.headerRows = [
            {
              cells: [
                { value: '', style: null },
                // {value: "COMMANDES", colSpan: 4, style: MonthlyOrdersSplitReportModel.titleOrTotal_CellStyle},
                { value: 'ACHATS', colSpan: 1, style: MonthlyPurchasesSplitReportModel.titleOrTotal_CellStyle },
                // {value: "VENTES", colSpan: 5, style: MonthlySalesSplitReportModel.titleOrTotal_CellStyle},
                { value: 'CHARGES', colSpan: 1, style: MonthlyChargesSplitReportModel.titleOrTotal_CellStyle },
                { value: 'TVA', colSpan: 1, style: MonthlyVatSplitReportModel.titleOrTotal_CellStyle },
                { value: 'MARGE BRUTE', colSpan: 2, style: MonthlyRawMarginReportModel.titleOrTotal_CellStyle },
                { value: 'MARGE NETTE', colSpan: 2, style: MonthlyNetMarginReportModel.titleOrTotal_CellStyle },
              ],
            },
          ];

          this.rows = [];
          for (let i = 0; i < 18; ++i) {
            this.rows.push({
              cells: [
                {
                  value: [
                    this.getSetting('year_start').value,
                    'Janvier',
                    'Février',
                    'Mars',
                    '1er trimestre',
                    'Avril',
                    'Mai',
                    'Juin',
                    '2ème trimestre',
                    'Juillet',
                    'Aout',
                    'Septembre',
                    '3ème trimestre',
                    'Octobre',
                    'Novembre',
                    'Décembre',
                    '4ème trimestre',
                    'TOTAUX',
                    'Moy. mens.',
                  ][i],
                  style: [
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.month_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.titleOrTotal_CellStyle,
                    MonthlyAccountingSplitSummaryReportModel.subTotal_CellStyle,
                  ][i],
                },
                // this.ordersReport.rows[i].cells[1],
                // this.ordersReport.rows[i].cells[8],
                // this.ordersReport.rows[i].cells[9],
                // this.ordersReport.rows[i].cells[10],
                this.purchasesReport.rows[i].cells[1],
                // this.salesReport.rows[i].cells[1],
                // this.salesReport.rows[i].cells[4],
                // this.salesReport.rows[i].cells[8],
                // this.salesReport.rows[i].cells[9],
                // this.salesReport.rows[i].cells[10],
                this.chargesReport.rows[i].cells[1],
                this.vatBalanceReport.rows[i].cells[3],
                this.rawMarginReport.rows[i].cells[3],
                this.rawMarginReport.rows[i].cells[6],
                this.netMarginReport.rows[i].cells[4],
                this.netMarginReport.rows[i].cells[7],
              ],
            });
          }
          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
