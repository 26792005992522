import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, AfterViewInit, ViewChildren, QueryList, ChangeDetectorRef, NgZone, AfterContentInit, AfterViewChecked, AfterContentChecked, IterableDiffers, IterableDiffer } from '@angular/core';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { ColorModalComponent } from '../color-modal/color-modal.component';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';
import { ObjectModel2 } from '../../classes/objects/ObjectModel2.class';
import { TextUtil } from 'src/app/modules/utils/classes/TextUtil.class';
import { NumberUtil } from 'src/app/modules/utils/classes/NumberUtil.class';
import { NotificationsComponent } from '../notifications/notifications.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { LoadingComponent } from '../loading/loading.component';
import {ApiService} from '../../services/api/api.service';


@Component({
  selector: 'sql-data-grid',
  templateUrl: './sql-data-grid.component.html',
  styleUrls: ['./sql-data-grid.component.css']
})
export class SqlDataGridComponent implements OnInit, AfterContentInit {

    @Input() columns = null;
    @Input() headerColumns = null;
    @Input() actions = null;
    @Input() rowActions = null;
    @Input() queryName = null;
    @Input() sortArray = null;
    @Input() rowStyleFunction = null;
    @Input() editable: boolean = false;
    @Input() resizable: boolean = true;
    @Input() selectable: boolean = false;
    @Input() rowSelect: boolean = false;
    @Input() additionalHeadersHtml: string[] = null;
    @Input() fixedAdditionalHeadersHtml: string[] = null;
    @Input() showTotals: boolean = false;
    @Input() showTotalsFunctions: boolean = true;

    @Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter<any>();
    @Output('itemDblClick') itemDblClick: EventEmitter<any> = new EventEmitter<any>();
    @Output('itemClick') itemClick: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('moneyInputs') moneyInputs: QueryList<ElementRef>;
    @ViewChildren('percentageInputs') percentageInputs: QueryList<ElementRef>;

    limitValues = [ 10, 20, 30, 50, 100, 500 ];
    selectedPageSize = 10;
    @Input() pageSize = 10;
    currentPage = 0;
    @Input() showAll = false;
    public rowActionsItem = null;
    public hoverItem = null;
    @Input() public headerBackColor: string = 'darkgray';
    @Input() public headerTextColor: string = 'black';
    public items: any[] = null;

    constructor(public elem: ElementRef,
                private modalService: BsModalService,
                private zone: NgZone,
                private ref: ChangeDetectorRef,
                private _iterableDiffers: IterableDiffers) {
        this.iterableDiffer = this._iterableDiffers.find([]).create(null);
        $(elem.nativeElement).resize(() => { setTimeout(() => { this.resizeTableBody(); }, 0); });
    }

    public detectChanges()
    {
        this.ref.detectChanges();
    }

    private iterableDiffer: IterableDiffer<any>;
    ngDoCheck() {
        let changes = this.iterableDiffer.diff(this.items);
        if (changes) {
            this.regenerateFilteredArray();
            setTimeout(() => { this.resizeTableBody(); }, 0);
        }
    }

    ngOnInit() {
        this.selectedPageSize = this.pageSize;
        this.showAllChanged();
        this.runQuery();
    }

    getItemObject(item: any, column: any)
    {
        if (!item || !column) return null;
        let obj = item;
        let names: string[] = column.field.split('.');
        for(let i=0; obj && i<names.length; ++i) obj = obj[names[i]];
        return obj;
    }
    getItemValue(item: any, column: any)
    {
        if (!item || !column) return null;
        let obj = this.getItemObject(item, column);
        switch(column.type)
        {
            case 'foreign-list':
                if (!obj) return '';
                if (Array.isArray(obj)) {
                    let arr: string[] = [];
                    for(let i=0; i<obj.length; ++i) {
                        if (obj[i]) arr.push(obj[i][column.listField]);
                    }
                    return arr.join(', ');
                }
                else return obj[column.listField];
            case 'text':
            default:
                return obj;
        }
    }

    formatDate(value: string)
    {
        let date = new Date(value);
        if (!value || !date) return '';
        return DateTimeUtil.format(date, 'd/m/Y');
    }

    public addItems(objects: any, count: number = null)
    {
        if (typeof(objects) === 'function') {
            let new_objects = [];
            if (count == null) count = 1;
            for(let i=0; i<count; ++i) new_objects.push(new objects());
            objects = new_objects;
        }
        if (!Array.isArray(objects)) objects = [ objects ];
        for(let i=0; i<objects.length; ++i) this.items.push(objects[i]);
        this.regenerateFilteredArray();
        this.lastPage();
    }

    /* PAGINATION */

    get pagesCount() {
        if (!this.filteredItems) return 0;
        else if (this.showAll === true) return 1;
        else return Math.ceil((this.filteredItems.length)/this.pageSize);
    }

    prevPage() {
        --this.currentPage;
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }
    nextPage() {
        ++this.currentPage;
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }
    firstPage()
    {
        this.currentPage = 0;
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }
    lastPage()
    {
        this.currentPage = Math.max(this.pagesCount - 1, 0);
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }
    selectedPageSizeChanged() {
        let firstItem = this.currentPage * this.pageSize;
        this.pageSize = this.selectedPageSize;
        this.currentPage = Math.floor(firstItem / this.selectedPageSize);
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }
    showAllChanged() {
        if (this.showAll === true) {
            this.pageSize = this.filteredItems.length;
            this.lastPage();
        } else {
            this.pageSize = this.selectedPageSize;
            this.firstPage();
        }
        // setTimeout(() => { this.resizeTableBody(); }, 0);
    }


    public get pagedFilteredItems()
    {
        return this.filteredItems.slice(this.currentPage * this.pageSize, (this.currentPage + 1) * this.pageSize);
    }



    /* RESIZE COLUMNS */

    resizing = false;
    resizingTable = null;
    resizingColumn = null;
    resizingStart = 0;
    resizingEnd = 0;

    //columnWidthDelta = 0;
    columnWidthStart = 0;
    tableWidthStart = 0;

    resizeGripCapture(event: MouseEvent, column) {
        if (event.detail > 1) return this.resizeGripDblClick(event, column);
        this.resizing = true;
        this.resizingTable = $('table', this.elem.nativeElement);
        this.resizingColumn = column;
        this.resizingColumn.element = $(event.target).parent();
        this.resizingStart = event.screenX;
        this.resizingEnd = event.screenX;

        this.columnWidthStart = this.resizingColumn.width || 150;//this.resizingColumn.element.width();
        //this.columnWidthDelta = this.resizingColumn.element.outerWidth() - this.columnWidthStart;
        this.tableWidthStart = this.resizingTable.width();
    }

    resizeGripMove(event: MouseEvent) {
        this.resizingEnd = event.screenX;
        this.resizeGripApply();
    }

    resizeGripApply() {
        this.resizingColumn.width = Math.max(10, this.columnWidthStart + (this.resizingEnd - this.resizingStart));
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }

    resizeGripRelease(event: MouseEvent) {
        this.resizing = false;
        this.resizeGripApply();
    }

    resizeGripDblClick(event: MouseEvent, column) {
        this.resizingColumn = column;
        this.resizingColumn.element = $(event.target).parent();
        this.resizingColumn.width = null;
    }


    /* FILTERS */

    filters = [];
    filtersAll: boolean[] = [];
    filtering = null;
    inverseFilterWindow: boolean = false;
    public filterColumn: any = null;

    private uniqueValuesIndex: number = null;
    private uniqueValues: any[] = [];

    public get filterColumnIndex()
    {
        return this.columns.indexOf(this.filterColumn);
    }

    clearFilters(event, column)
    {
        if (!column) this.filters = [];
        else {
            let index: number = this.columns.indexOf(column);
            this.filters[index] = null;
            this.getUniqueValues(index, true);
        }
        this.filterColumn = null;
        this.regenerateFilteredArray();
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }

    public toggleAllFilter(index, event)
    {
        // let index: number = this.columns.indexOf(column);
        event.preventDefault();
        event.stopPropagation();
        setTimeout(() => {
            if (this.filtersAll[index] === true) {
                this.filtersAll[index] = false;
                this.filters[index] = [];
            }
            else
            {
                this.filtersAll[index] = true;
                this.filters[index] = this.getUniqueValues(index);
            }
            this.regenerateFilteredArray();
            setTimeout(() => { this.resizeTableBody(); }, 0);
        }, 0);
    }

    @ViewChild('filterElem') filterElem: ElementRef;
    public setFiltering(column: any, event: any)
    {
        if (!column) return;
        this.filterColumn = column;
        let index: number = this.columns.indexOf(column);
        this.getUniqueValues(index, true);
        this.filtering = column.field;
        setTimeout(() => {
            let buttonOffset: any = $(event.target).offset();
            let buttonWidth: number = $(event.target).width();
            let elemWidth: number = $(this.filterElem.nativeElement).width();
            let pageScroll: number = $(document).scrollLeft();
            if (buttonOffset.left - pageScroll < elemWidth) {
                $(this.filterElem.nativeElement).offset({ left: buttonOffset.left + buttonWidth, top: buttonOffset.top });
            } else {
                $(this.filterElem.nativeElement).offset({ left: buttonOffset.left - elemWidth , top: buttonOffset.top });
            }
        }, 0);
    }

    public _filteredItems: any[] = null;
    public get filteredItems()
    {
        if (!this._filteredItems) this.regenerateFilteredArray();
        return this._filteredItems;
    }

    public regenerateFilteredArray()
    {
        let r: any = [];
        if (this.items == null || this.filters == null) return this.items;
        for (let i=0; i<this.filters.length; ++i) {
            if (!this.filters[i]) this.filters[i] = [];
            let filter: any = this.filters[i];
            r[i] = [];
            for(let j=0; j<filter.length; ++j) {
                r[i].push(filter[j] ? TextUtil.createRegexFromString(filter[j].toString(), true, 'i') : filter[j]);
            }
        }
        let result: any[] = this.items.filter((element: any, index: number, array: any[]) => {
            let isOk: boolean = true;
            for (let i=0; isOk && i<this.filters.length; ++i) {
                if (r[i].length > 0)
                {
                    isOk = false;
                    let value: any = this.getItemValue(element, this.columns[i]);
                    if (value !== undefined)
                    {
                        if (typeof(value) === 'number') value = value.toString();
                        for(let j=0; !isOk && j<r[i].length; ++j) {
                            if (!r[i][j] || r[i][j] == '') isOk = (value == null || value == '');
                            else {
                                //console.log('comparing: ', r[i][j], 'to: ', value, ' => ', r[i][j]==value);
                                isOk = r[i][j].test(value);
                            }
                        }
                    }
                }
            }
            return isOk;
        });
        //console.log('regenerated filtered items:', result);
        this._filteredItems = result;
        return result;
    }

    toggleFilter(index, value: any, event: any)
    {
        // let index: number = this.columns.indexOf(column);
        event.preventDefault();
        event.stopPropagation();
        setTimeout(() => {
            if (!this.filters[index]) this.filters[index] = [];
            let filter: any = this.filters[index];
            if (!filter.includes(value)) filter.push(value);
            else ArrayUtil.removeElements(filter, [value]);
            this.regenerateFilteredArray();
            setTimeout(() => {
                if (this.currentPage >= this.pagesCount) this.lastPage();
                if (this.currentPage < 0) this.currentPage = 0;
                this.resizeTableBody();
            }, 0);
        }, 0);
    }

    public uniqueFilter: string[] = [];
    public getUniqueValues(index, forceReload: boolean = false)
    {
        if (index != this.uniqueValuesIndex || forceReload == true)
        {
        // let index: number = this.columns.indexOf(column);
            let items: any[] = this.filteredItems;//this.items;
            let unique: any[] = [];
            let r: RegExp = null;
            if (this.uniqueFilter[index]) r = TextUtil.createRegexFromString(this.uniqueFilter[index], false, 'i');
            if (Array.isArray(items))
            {
                for(let i=0; i<items.length; ++i) {
                    let value: any = this.getItemValue(items[i], this.columns[index]);
                    if (!unique.includes(value) && (!r || r.test(value))) unique.push(value);
                }
                unique.sort((a: any, b: any) => { return (b == a ? 0 : ((b > a || a == '' || a == null) ? -1 : 1)); });
            }
            this.uniqueValuesIndex = index;
            this.uniqueValues = unique;
        }
        return this.uniqueValues;
    }

    sortByClick(event, column, descending: boolean = false)
    {
        this.sortBy(column, descending);
        //this.filtering = null;
        event.stopPropagation();
    }

    sortBy(column, descending: boolean = false)
    {
        let arr = this.sortArray || this.items;
        // console.log('sorting by', column);
        arr.sort((a, b) => {
            let a_value: any = this.getItemValue(a, column);
            let b_value: any = this.getItemValue(b, column);
            return (descending ? -1 : 1) * (
                (a_value > b_value) ? 1 :
                ((a_value == b_value) ? 0 : -1)
            );
        });
        // console.log(arr);
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }


    /* COLOR INPUT */

    public colorModalRef: BsModalRef = null;
    colorClick(item: any, field: string)
    {
        let initialState: any = {
            'object': item,
            'field': field
        };
        this.colorModalRef = this.modalService.show(ColorModalComponent, { initialState, class: 'modal-sm' });
    }


    /* SELECTION */

    public selectedItems: any[] = [];

    setSelection(event, item)
    {
        console.log('setting selection to', item);
        if (event != null && event.ctrlKey === true) ArrayUtil.toggleItem(this.selectedItems, item);
        else this.selectedItems = [ item ];
        this.selectionChange.next(this.selectedItems);
    }

    selectionCheckboxClick(event, item)
    {
        event.stopPropagation();
        //event.preventDefault();
        ArrayUtil.toggleItem(this.selectedItems, item);
        console.log(this.selectedItems);
        this.selectionChange.next(this.selectedItems);
        // this.setSelection(event, item);
    }

    clearSelection()
    {
        this.selectedItems.splice(0, this.selectedItems.length);
        this.selectionChange.next(this.selectedItems);
    }



    /* EVENTS */

    itemRowClick(event, item)
    {
        if (!this.editable)
        {
            event.item = item;
            if (event.detail == 2) this.itemDblClick.next(event);
            else this.itemClick.next(event);
        }
    }
    onLongPress(event, item)
    {
        event.item = item;
        this.itemDblClick.next(event);
    }

    callColumnFunc(funcName: string, item: any, column: any)
    {
        if (funcName === 'change' && item instanceof ObjectModel2) { console.log('item changed:', item); item.changed = true; }
        if (column[funcName] && typeof(column[funcName]) === 'function') {
            this.zone.run(() => { column[funcName](item, column); });
        }
    }


    /* NUMBER FIELD */

    calculate(item: any, column: any)
    {
        if (column.calculate && typeof(column.calculate === 'function')) column.calculate(item);
    }


    /* ACTIONS */
    actionButtonClick(action: any, event)
    {
        if (action.onClick && typeof(action.onClick) === 'function') action.onClick(event);
    }

    /* ROW ACTIONS */
    rowActionClick(event, action, item)
    {
        event.stopPropagation();
        event.preventDefault();
        this.rowActionsItem = null;
        setTimeout(() => {
            if (action.click && typeof(action.click) === 'function') action.click(event, item);
        }, 0);
    }

    /* CHECKBOX */

    checkboxLabelClick(event, item, column)
    {
        if (item && column && column.field) {
            item[column.field] = !item[column.field];
            this.callColumnFunc('change', item, column);
        }
    }



    /* TOTALS */

    public columnTotalFunc: string[] = [];
    public columnTotalValue: string[] = [];
    public totalFunctions: any = {
        'Somme': (items: any[], column: any) => {
            if (items && items.length > 0 && column.type === 'number') {
                let total: number = 0;
                for(let i=0; i<items.length; ++i) total += this.getItemValue(items[i], column);
                return NumberUtil.formatNumber(total, column.decimalsCount, '.') + (column.unit ? (' ' + column.unit) : '');
            }
            else return '';
        },
        'Nombre non vides': (items: any[], column: any) => {
            if (items && items.length > 0) {
                let total: number = 0;
                for(let i=0; i<items.length; ++i) {
                    let value: any = this.getItemValue(items[i], column);
                    total += (!value || value == '') ? 0 : 1;
                }
                return NumberUtil.formatNumber(total, 0, '.');
            }
            else return '';
        }
    }

    public get totalFunctionNames()
    {
        return Object.keys(this.totalFunctions);
    }

    public calculateTotal(index, items, column)
    {
        let funcName: string = this.columnTotalFunc[index];
        if (funcName) this.columnTotalValue[index] = this.totalFunctions[funcName](items, column);
    }

    public toggleTotals()
    {
        this.showTotals = !this.showTotals;
        setTimeout(() => { this.resizeTableBody(); }, 0);
    }


    /* COPY */

    public copyMode: boolean = false;
    @ViewChild('copyElement') copyElement: ElementRef;

    public copyTable()
    {

        // let old_editable: boolean = this.editable;
        // let old_selectable: boolean = this.selectable;
        // let old_showTotals: boolean = this.showTotals;
        // this.editable = false;
        // this.selectable = false;
        // this.showTotalsFunctions = false;
        LoadingComponent.push();
        this.copyMode = true;
        setTimeout(() => {
            const selection = window.getSelection();
            const range = document.createRange();
            range.selectNodeContents(this.copyElement.nativeElement);
            selection.removeAllRanges();
            selection.addRange(range);
            document.execCommand('copy');
            selection.removeAllRanges();
            NotificationsComponent.push({
                type: 'success',
                summary: 'Le contenu de la grille a été copié dans la presse-papiers.',
                title: 'Contenu copié'
            });
            // this.editable = old_editable;
            // this.selectable = old_selectable;
            // this.showTotalsFunctions = true;
            this.copyMode = false;
            LoadingComponent.pop();
        }, 0);
    }



    /* FIXED COLUMNS */


    @Input() fixedColumnsCount: number = 0;

    public get fixedHeaderColumns()
    {
        if (this.fixedColumnsCount <= 0) return [];
        let cols: any = this.headerColumns || this.columns;
        return cols.slice(0, this.fixedColumnsCount);
    }

    public get fixedColumns()
    {
        if (this.fixedColumnsCount <= 0) return [];
        return this.columns.slice(0, this.fixedColumnsCount);
    }

    public get scrollableHeaderColumns()
    {
        let cols: any = this.headerColumns || this.columns;
        if (this.fixedColumnsCount <= 0) return cols;
        return cols.slice(this.fixedColumnsCount, cols.length);
    }

    public get scrollableColumns()
    {
        if (this.fixedColumnsCount <= 0) return this.columns;
        return this.columns.slice(this.fixedColumnsCount, this.columns.length);
    }

    @ViewChild('fixedCell') fixedCell: ElementRef;
    @ViewChild('tableBody') tableBody: ElementRef;
    @ViewChild('asideElem') asideElem: ElementRef;
    @ViewChild('headerElem') headerElem: ElementRef;
    @ViewChild('tableContainer') tableContainer: ElementRef;

    private containerWidth: number = 0;
    private containerHeight: number = 0;
    private headerPadding: number = 0;

    resizeTableBody()
    {
        var fixedCellWidth = $(this.fixedCell.nativeElement).width();
        var asideWidth = $(this.asideElem.nativeElement).width();
        var height = $(this.tableContainer.nativeElement).height() - $(this.headerElem.nativeElement).height();
        var width = $(this.tableContainer.nativeElement).width() - asideWidth;
        if (height != this.containerHeight)
        {
            this.containerHeight = height;
            $(this.tableBody.nativeElement).css('height', (height)+'px');
        }
        if (width != this.containerWidth)
        {
            this.containerWidth = width;
            $(this.tableBody.nativeElement).css('width', width+'px');
        }
        if (fixedCellWidth != this.headerPadding)
        {
            this.headerPadding = fixedCellWidth;
            $(this.headerElem.nativeElement).css('padding-left', fixedCellWidth+'px');
        }
    }
    ngAfterContentInit()
    {
        setTimeout(() => {
            let $body = $(this.tableBody.nativeElement);
            let $sidebar = $(this.asideElem.nativeElement);
            let $sidebarTable = $('table', this.asideElem.nativeElement);
            let $header = $(this.headerElem.nativeElement);
            this.resizeTableBody();
            $body.scroll(() => {
                $sidebarTable.css('margin-top', -$body.scrollTop());
                $header.css('margin-left', -$body.scrollLeft());
            });
            $sidebar.bind('mousewheel DOMMouseScroll', (event) => {
                let mev: WheelEvent = event.originalEvent as WheelEvent;
                if (mev && mev.deltaY != 0) $body.scrollTop($body.scrollTop()+mev.deltaY);
            });
        }, 0);
    }

    private runQuery() {
        ApiService.callModule('queries', 'run', {
            'query_name': this.queryName
        }).then(
            (result) => {
                this.items = result.details;
                console.log('items:', this.items);
            },
            (err) => {
                console.error(err);
            }
        );
    }
}
