import { Component, OnInit, forwardRef, Input, ElementRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { Address } from '../../modules/addresses/classes/Address.class';
import { BsModalService } from 'ngx-bootstrap';
import { AddressSelectionModalComponent } from './address-selection-modal.component';

@Component({
  selector: 'address-text-field',
  templateUrl: './address-text-field.component.html',
  styleUrls: ['./address-text-field.component.css'],
  providers: [
    { 
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressTextFieldComponent),
      multi: true
    }
  ]
})
export class AddressTextFieldComponent implements ControlValueAccessor {

    @Input() addresses: any[] = [];
    @Input() prefix: string = null;
    @Input() nullText: string = "(Aucune)";
    @Input() allowNull: boolean = true;
    @Output() addressChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() textChange: EventEmitter<any> = new EventEmitter<any>();
    @Input() addressFunction: (addr: Address) => string = null;

    public _address: string = null;
    public selectedAddress: Address = null;

    constructor(public elementRef: ElementRef,
                private modalService: BsModalService) {}

    addressTextChange(event)
    {
        this.selectedAddress = null;
        this.propagateChange(this._address);
        this.addressChange.next(this.selectedAddress);
        this.textChange.next(this._address);
    }

    showSelectAddressModal()
    {
        this.modalService.show(AddressSelectionModalComponent, { initialState: {
            addressComponent: this, addresses: this.addresses,
            resolve: (addr: Address) => { this.changeSelectedAddress(addr); }
        }});
    }
    changeSelectedAddress(addr: Address)
    {
        this.selectedAddress = addr;
        if (addr != null) this._address = this.addressFunction ? this.addressFunction(addr) : addr.getFullAddress();
        this.propagateChange(this._address);
        this.addressChange.next(this.selectedAddress);
        this.textChange.next(this._address);
    }

    // ControlValueAccessor
    
    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any) {
        this._address = value;
        this.selectedAddress = null;
    }

}
