<ul class="log-entries">
    <li *ngFor="let entry of logEntries" class="log-entry">
        <i class="fa" [ngClass]="{ 'fa-info': entry.type == 0,
                                   'fa-exclamation-triangle': entry.type == 1,
                                   'fa-times-circle': entry.type == 2,
                                   'fa-times': entry.type == 4 }"></i>
        <span>{{ entry.dateString }}</span>
        <span>[{{ entry.type == -1 ? 'TRACE' : entry.type == 0 ? 'INFO' : entry.type == 1 ? 'WARNING' :
                 entry.type == 2 ? 'ERROR' : entry.type == 4 ? 'CRITICAL' : 'UNKNOWN' }}]</span>
        <span *ngFor="let arg of entry.argsStrings" [attr.class]="'log-content ' + arg.class" [innerHTML]="arg.html"></span>
        <span *ngIf="entry.filename" class="log-filename">{{ entry.filename }}</span>
    </li>
</ul>
