<div class="order-container">
  <div class="order-container-columns">
    <info-block
      #selectBlockComponent
      [block]="selectBlock"
      [data]="instance"
      [readonly]="!writeAccess || orderCopy.id"
    ></info-block>
    <info-block
      #shortageBlockComponent
      [block]="shortageBlock"
      [data]="selectedSale || {}"
      [readonly]="true"
    ></info-block>
    <info-block #orderBlockComponent [block]="orderBlock" [data]="orderCopy" [readonly]="!writeAccess"></info-block>
    <info-block [block]="marginsBlock" #marginsBlockComponent [data]="orderCopy" [readonly]="true"></info-block>
    <info-block
      [block]="stockBlock"
      #stockBlockComponent
      [data]="orderCopy"
      [readonly]="!writeAccess"
      *ngIf="orderCopy.deliveries == 1"
    ></info-block>
    <info-block
      [block]="accountingBlock"
      #accountingBlockComponent
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>

    <info-block #saleRemarksBlockComponent [block]="saleRemarksBlock" [data]="orderCopy" [readonly]="true"></info-block>
    <info-block
      #orderRemarksBlockComponent
      [block]="orderRemarksBlock"
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>
  </div>

  <div class="order-container-columns">
    <info-block
      #customerBlockComponent
      [block]="customerBlock"
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #ordinatorBlockComponent
      [block]="ordinatorBlock"
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #invoicingBlockComponent
      [block]="invoicingBlock"
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #deliveryBlockComponent
      [block]="deliveryBlock"
      [data]="orderCopy"
      [readonly]="!writeAccess"
    ></info-block>
    <info-block
      #articleBlockComponent
      *ngIf="orderCopyArticleIsArticle || !orderCopy"
      [block]="articleBlock"
      [data]="orderCopyArticle"
      [readonly]="true"
    ></info-block>
    <info-block
      #deviceBlockComponent
      *ngIf="orderCopyArticleIsDevice"
      [block]="deviceBlock"
      [data]="orderCopyArticle"
      [readonly]="true"
    ></info-block>
    <info-block
      #serviceBlockComponent
      *ngIf="orderCopyArticleIsService"
      [block]="serviceBlock"
      [data]="orderCopyArticle"
      [readonly]="true"
    ></info-block>
  </div>
</div>
