import { Page } from "../../printing/classes/Page.class";
import { Table } from "../../printing/classes/Table.class";
import { PrintableElement } from "../../printing/classes/PrintableElement.class";
import { PrintingModel } from "../../printing/classes/PrintingModel.class";
import { InfoBlock } from "../../../components/info-block/classes/InfoBlock.class";
import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";
import { InfoBlockComponent } from "../../../components/info-block/info-block.component";
import { InfoBlockField } from "../../../components/info-block/classes/InfoBlockField.class";
// import { Price } from "../../sales/classes/Price.class";
import { Invoice } from "../../invoicing/classes/Invoice.class";
import { NumberUtil } from "../../utils/classes/NumberUtil.class";
import { Order } from "../../orders/classes/Order.class";
import { Deadline } from "../../other-data/classes/Deadline.class";
import { StockMove } from "../../stocks/classes/StockMove.class";
import { ReminderType } from "./ReminderType.class";
import { Address } from "../../addresses/classes/Address.class";
import { PrintingUtil } from "../../utils/classes/PrintingUtil.class";

export class PaymentReminderPrintingModel extends PrintingModel
{
    public typesData: any = { items: [] };
    public contactsData: any = { items: [] };

    public invoicing_address_text: string = null;
    public invoicing_contact: string = null;
    public reminders_emails: string = null;
    public _invoicingContact: Address = null;
    public _invoicingAddress: Address = null;
    private invoicingAddressInfoBlockField: InfoBlockField = {
        title: 'Adresse', field: 'invoicing_address_text', type: 'address-text',
        listItems: { items: [] }, listField: 'title', nullText: '(Aucun)',
        addressFunction: (addr: Address) => { return addr.getPostalAddress(); },
        textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
            this.regenerate();
        },
        addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, addr: Address) => {
            this._invoicingAddress = addr;
            if (addr)
            {
                this.invoicing_address_text = addr.getPostalAddress();
                if (addr.name1 || addr.name2) {
                    this._invoicingContact = addr;
                    this.invoicing_contact = addr.contactName;
                }
                this.updateInvoicingMails();
            }
            this.regenerate();
        },
        comment: 'Sélectionnez ici l\'adresse de facturation (choix via l\'icone ci-contre), ou entrez une adresse manuellement.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ \'E-mails\' ci-dessous, et le champ \'Service - Nom du contact\' si des informations de service/contact sont présents dans l\'adresse sélectionnée.'
    }
    public updateInvoicingMails()
    {
        // reminders mails
        let mails: string[] = this._invoicingAddress ? this._invoicingAddress.getAccountingMailsArray() : [];
        if (this._invoicingContact && this._invoicingContact !== this._invoicingAddress) mails = mails.concat(this._invoicingContact.getMailsArray());
        let unique_mails: string[] = [];
        for(let i=0; i<mails.length; ++i) if (!unique_mails.includes(mails[i])) unique_mails.push(mails[i]);
        this.reminders_emails = unique_mails.join('; ');
    }

    public optionsBlock: InfoBlock = {
        fields: [
            {
                title: 'Langue', field: 'lang', type: 'select', selectOptions: [
                    { text: '(Selon client)', value: '' },
                    { text: 'Français', value: 'fr' },
                    { text: 'Anglais', value: 'en' },
                    { text: 'Néerlandais', value: 'nl' }
                ], change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
                    this.regenerate();
                }
            },
            {
                title: 'Type de rappel', field: 'reminder_type', type: 'foreign-list', listField: 'name',
                listItems: this.typesData, multiSelect: false,
                change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
                    this.regenerate();
                }
            },

            { title: 'Service - Nom du contact', field: 'invoicing_contact', type: 'combo-box', disableFiltering: true,
              listItems: this.contactsData, listField: 'addressContactLine', nullText: '(Aucun)',
              select: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
                  this._invoicingContact = event;
                  this.updateInvoicingMails();
                  this.regenerate();
              },
              change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, object: any, event: any) => {
                  this.regenerate();
              },
              comment: 'Sélectionnez ou entrez ici le nom du contact, si besoin, pour la facturation.<br/><br/>REMARQUE : Cela modifiera automatiquement le champ \'E-mails\' ci-dessous !'
            },
            this.invoicingAddressInfoBlockField,
            { title: 'E-mails réclamation', field: 'reminders_emails',
              comment: 'Entrez ici les e-mails (séparés par des <b>;</b> ) auxquels seront envoyées les rappels de paiement'
            }
        ]
    }

    public invoices: Invoice[] = [];
    public lang: string = '';
    public reminder_type: ReminderType = null;
    // public delivery_address: string = null;
    // public delivery_address: string = null;
    // public invoiceing_address: string = null;
    // public send_address: string = null;
    //public hide_customer: boolean = false;

    public static generate(invoices: Invoice[]): PaymentReminderPrintingModel
    {
        //if (object instanceof StockMove) object = object.invoice;
        let model: PaymentReminderPrintingModel = new PaymentReminderPrintingModel();
        model.invoices = invoices;
        if (invoices && invoices.length > 0)
        {
            model.invoicing_address_text = invoices[0].order.invoicing_address_text;
            model.invoicing_contact = invoices[0].order.invoicing_contact;
            model.reminders_emails = invoices[0].order.reminders_emails;
            model.contactsData.items = invoices[0].customer.contacts;
            model.invoicingAddressInfoBlockField.listItems.items = invoices[0].customer.invoicingAddressesList;
        }
        ReminderType.load().then(
            (result: ReminderType[]) => {
                model.typesData.items = result;
                if (!model.reminder_type) {
                    model.reminder_type = result[0];
                    model.regenerate();
                } },
            (err) => { console.error(err); }
        );
        return model.regenerate();
    }

    public get invoice() {
        if (this.invoices instanceof StockMove) return this.invoices.invoice;
        else if (this.invoices instanceof Invoice) return this.invoices;
        else return null;
    }

    public regenerate(): PaymentReminderPrintingModel
    {
        let invoices: Invoice[] = this.invoices;

        let pageWidth = 210;
        let pageHeight = 295;

        let pageLeftMargin = 10;
        let pageRightMargin = 10;
        let pageTopMargin = 10;
        let pageBottomMargin = 10;

        let invoice: Invoice = this.invoice;
        let order: Order = invoices[0].order;

        // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
        // if (!this.invoiceing_address && order.invoiceing_address) this.invoiceing_address = order.invoiceing_address;
        // if (!this.send_address && order.send_address) this.send_address = order.send_address;

        this.pages = [];

        if (order)
        {

            let lang: string = this.lang;
            if (!lang || lang == '')
            {
                if (order.customer.lang) lang = order.customer.lang.code;
                else lang = 'fr';
            }

            this.saveFilename = 'Rappel de paiement ' + order.customer.number + ' - ' + DateTimeUtil.toDateString(new Date()) + '.pdf';
            this.mailFilename = {
                                    'fr': 'Rappel de paiement ',
                                    'nl': 'Herinnering van betaling ',
                                    'en': 'Payment reminder '
                                }[lang] + DateTimeUtil.toDateString(new Date()) + '.pdf';

            let totalDue: number = 0;
            let totalAmount: number = 0;
            let rows: PrintableElement[] = [
                {
                    style: {
                        'display': 'table-row',
                        'height': '1mm'
                    },
                    children: [
                        {
                            style: {
                                'display': 'table-cell',
                                // 'width': '33%',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'text-align': 'center',
                                'font-weight': 'bold',
                                'color': 'rgba(0,156,216,1.0)',
                                'white-space': 'pre',
                                'vertical-align': 'middle',
                            },
                            html: ({
                                'fr': 'N° DE FACTURE',
                                'en': 'INVOICE NO.',
                                'nl': 'FACTUUR NR.'
                            })[lang]
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'text-align': 'center',
                                'font-weight': 'bold',
                                'color': 'rgba(0,156,216,1.0)',
                                'white-space': 'pre',
                                'vertical-align': 'middle',
                            },
                            html: ({
                                'fr': 'DATE DE\nFACTURE',
                                'en': 'INVOICE\nDATE',
                                'nl': 'FACTUUR\nDATUM'
                            })[lang]
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'text-align': 'center',
                                'font-weight': 'bold',
                                'color': 'rgba(0,156,216,1.0)',
                                'white-space': 'pre',
                                'vertical-align': 'middle',
                            },
                            html: ({
                                'fr': 'ÉCHÉANCE',
                                'en': 'DEADLINE',
                                'nl': 'TERMIJN'
                            })[lang]
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'text-align': 'center',
                                'font-weight': 'bold',
                                'color': 'rgba(0,156,216,1.0)',
                                'white-space': 'pre',
                                'vertical-align': 'middle',
                            },
                            html: ({
                                'fr': 'DATE\nD\'ÉCHÉANCE',
                                'en': 'DEADLINE\nDATE',
                                'nl': 'TERMIJN-\nDATUM'
                            })[lang]
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'text-align': 'center',
                                'font-weight': 'bold',
                                'color': 'rgba(0,156,216,1.0)',
                                'white-space': 'pre',
                                'vertical-align': 'middle',
                            },
                            html: ({
                                'fr': 'JOURS\nÉCHUS',
                                'en': 'DUE\nDAYS',
                                'nl': 'ANTAAL\nDAGEN'
                            })[lang]
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'background-color': 'rgba(217,217,217,1)',
                                'border': '.25mm solid rgba(0,0,0,1)',
                                'position': 'relative',
                                'text-align': 'center',
                                'vertical-align': 'middle',
                                'color': 'rgba(0,156,216,1.0)',
                                'font-weight': 'bold',
                            },
                            html: ({
                                'fr': 'MONTANT',
                                'en': 'AMOUNT',
                                'nl': 'BEDRAG'
                            })[lang]
                        }
                    ]
                }
            ];
            for(let i=0; i<invoices.length; ++i) {
                rows.push({
                    style: {
                        'display': 'table-row',
                        'height': (i == invoices.length - 1) ? 'auto' : '1mm'
                    },
                    children: [
                        {
                            style: {
                                'display': 'table-cell',
                                // 'width': '33%',
                                'text-align': 'left',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm'
                            },
                            html: invoices[i].number,
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'text-align': 'center',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm'
                            },
                            html: DateTimeUtil.format(new Date(invoices[i].date), 'd/m/Y')
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'text-align': 'center',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm'
                            },
                            html: invoices[i].deadline,
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'text-align': 'center',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm'
                            },
                            html: DateTimeUtil.format(new Date(invoices[i].expiration), 'd/m/Y')
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'text-align': 'center',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm',
                            },
                            html: invoices[i].daysDue
                        },
                        {
                            style: {
                                'display': 'table-cell',
                                'text-align': 'right',
                                'border-left': '.25mm solid rgba(0,0,0,1)',
                                'border-right': '.25mm solid rgba(0,0,0,1)',
                                'vertical-align': 'top',
                                // 'height': '50mm',
                                'padding': '1mm'
                            },
                            html: NumberUtil.formatMoney(invoices[i].converted_totalWithTax, invoices[i].sell_currency.symbol, 2, '.')
                        },
                    ]
                });
                if (invoices[i].daysDue <= 0) totalDue += invoices[i].converted_totalWithTax;
                totalAmount += invoices[i].converted_totalWithTax;
            }



            let page: Page = {

                width: pageWidth,
                height: pageHeight,

                leftMargin: pageLeftMargin,
                rightMargin: pageRightMargin,
                topMargin: pageTopMargin,
                bottomMargin: pageBottomMargin,

                style: {
                    'font-family': 'Calibri',
                    'font-size': '10pt',
                    'width': (pageWidth - pageLeftMargin - pageRightMargin) + 'mm',
                    'height': (pageHeight - pageTopMargin - pageBottomMargin) + 'mm',
                    'margin-top': pageTopMargin + 'mm',
                    'margin-left': pageLeftMargin + 'mm',
                    'margin-right': pageRightMargin + 'mm',
                    'margin-bottom': pageBottomMargin + 'mm',
                },

                headerSize: 33,
                headerElements: [
                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '1mm',
                            'width': '85mm',
                            'height': '17mm',
                            'border': '.5mm solid rgba(0, 176, 240, 1.0)',
                            'border-radius': '2.5mm',
                            'line-height': '4mm'
                        },
                        children: [
                            {
                                style: {
                                    'font-size': '20pt',
                                    'font-weight': 'bold',
                                    'text-align': 'center',
                                    'width': '100%',
                                    'line-height': '7.5mm',
                                    'margin-top': '1.5mm',
                                    'margin-bottom': '1mm',
                                    'vertical-align': 'top'
                                },
                                html: ({
                                    'fr': 'RAPPEL DE PAIEMENT',
                                    'en': 'PAYMENT REMINDER',
                                    'nl': 'BETALINGSHERINNERING'
                                }[lang])
                            },
                            // {
                            //     style: {
                            //         'display': 'flex',
                            //         'flex-direction': 'row'
                            //     },
                            //     children: [
                            //         {
                            //             style: {
                            //                 'width': '20%',
                            //                 'text-align': 'right',
                            //                 'padding': '0 1mm'
                            //             },
                            //             html: ({
                            //                     'fr': 'N° :',
                            //                     'en': 'No :',
                            //                     'nl': 'Nr :'
                            //                    } [lang])
                            //         },
                            //         {
                            //             style: {
                            //                 'width': '80%',
                            //                 'padding': '0 1mm',
                            //                 'font-weight': 'bold'
                            //             },
                            //             html: invoice.number
                            //         }
                            //     ]
                            // },
                            {
                                style: {
                                    'display': 'flex',
                                    'flex-direction': 'row'
                                },
                                children: [
                                    {
                                        style: {
                                            'width': '20%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'Date :',
                                                'en': 'Date :',
                                                'nl': 'Datum :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '30%',
                                            'padding': '0 1mm'
                                        },
                                        html: DateTimeUtil.format(new Date(), 'd/m/Y')
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'N° client :',
                                                'en': 'Cust. No :',
                                                'nl': 'Klant Nr :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'padding': '0 1mm'
                                        },
                                        html: order.customer.number
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        style: {
                            'position': 'absolute',
                            'left': '0mm',
                            'top': '0mm',
                            'width': '50mm',
                            'height': '30mm',
                            'background-image': 'url(assets/img/logo_documents.png)',
                            'background-position': 'center center',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat'
                        },
                        html: ''
                    },
                    {
                        style: {
                            'font-size': '20pt',
                            'font-weight': 'bold',
                            'text-align': 'center',
                            'position': 'absolute',
                            'left': '0',
                            'top': '46mm',
                            'padding': '0 1mm',
                            'width': '90mm',
                            'background-color': (this.reminder_type ? this.reminder_type.back_color : 'rgb(255,255,255)'),
                            'color': (this.reminder_type ? this.reminder_type.text_color : 'rgb(0,0,0)'),
                            'text-decoration': 'underline'
                        },
                        html: (this.reminder_type ? {
                            'fr': this.reminder_type.name,
                            'en': this.reminder_type.name_en,
                            'nl': this.reminder_type.name_nl,
                        }[lang] : '')
                    }
                ],

                bodyElements: [
                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '0mm',
                            'width': '85mm',
                            'height': '25m',
                            'line-height': '4mm'
                        },
                        children: PrintingUtil.formatAddress(order.customer.name, this.invoicing_contact, this.invoicing_address_text)
                    },





                    {
                        style: {
                            'display': 'block',
                            'white-space': 'pre-wrap',
                            'font-size': '11pt',
                            'margin-top': '30mm'
                        },
                        html: ({
                            'fr': 'Madame, Monsieur,\n\nPar la présente, nous tenons à vous informer que nous n\'avons toujours pas reçu le(s) paiement(s) détaillé(s) ci-dessous :',
                            'en': 'Dear customer,\n\nWe would like to inform you that we still haven\'t receive any payment for the following invoice(s) :',
                            'nl': 'Best klant,\n\nHierbij delen wij u mede dat wij nog geen betaling mogen ontvangen voor de volgende factur(en) :'
                        })[lang]
                    },



                    {
                        style: {
                            'flex': '1',
                            'margin-top': '5mm',
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table',
                                    'width': '100%',
                                    'height': '100%',
                                    'border-collapse': 'collapse',
                                    'font-size': '11pt',
                                    'border-bottom': '0.25mm solid rgba(0, 0, 0, 1)'
                                },
                                children: rows
                            }
                        ]
                    },





                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse',
                            'margin-top': '3mm'
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row',
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                        },
                                        html: '' /*({
                                            'fr': 'TOTAL ÉCHU : ',
                                            'en': 'TOTAL OVERDUE : ',
                                            'nl': ''
                                        }[lang])*/
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                            'color': 'rgba(255,0,0,1)'
                                        },
                                        html: '' //NumberUtil.formatMoney(totalDue, '€', 2, '.')
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                        },
                                        html: '' /*({
                                            'fr': 'TOTAL À ÉCHOIR : ',
                                            'en': 'TOTAL TO PAY : ',
                                            'nl': ''
                                        }[lang])*/
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                            'color': 'rgba(255,0,0,1)'
                                        },
                                        html: '' //(totalAmount - totalDue > 0) ? NumberUtil.formatMoney(totalAmount - totalDue, '€', 2, '.') : ''
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                        },
                                        html: ({
                                            'fr': 'TOTAL À PAYER : ',
                                            'en': 'TOTAL TO PAY : ',
                                            'nl': 'TOTAAL TE BETALEN : '
                                        }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'text-align': 'right',
                                            'font-weight': 'bold',
                                            'font-size': '11pt',
                                            'color': 'rgba(255,0,0,1)'
                                        },
                                        html: totalAmount > 0 ? NumberUtil.formatMoney(totalAmount, invoices[0].sell_currency.symbol, 2, '.') : ''
                                    },
                                ]
                            }
                        ]
                    },


                    {
                        style: {
                            'display': 'block',
                            'padding': '.5mm 1mm',
                            'white-space': 'pre-wrap',
                            'font-size': '11pt',
                            'margin-top': '5mm'
                        },
                        html: ({
                            'fr': 'Merci de bien vouloir verser la somme échue de # sur notre compte banquaire ING numéro\n363-0777612-03 sous huitaine.',
                            'en': 'Thank you for paying the amount of #, within the next 8 days, on our ING bank account no.\n363-0777612-03.',
                            'nl': 'Dank U voor betaling de bedrag van #, over een week, naar uw bankrekening nr. 363-0777612-03.'
                        })[lang].replace('#', NumberUtil.formatMoney(totalDue, invoices[0].sell_currency.symbol, 2, '.'))
                    },

                ],

                footerSize: 63,
                footerElements: [
                    {
                        style: {
                            'display': 'block',
                            'width': '20%',
                            'padding': '.5mm 1mm',
                            'text-align': 'center',
                            'white-space': 'pre',
                            'position': 'relative',
                            'margin-left': '152mm'
                        },
                        children: [
                            {
                                style: {
                                    'text-align': 'center',
                                    'white-space': 'pre',
                                    'margin-top': '15mm'
                                },
                                html: ({
                                    'fr': 'Le service logistique',
                                    'en': 'The logistics service',
                                    'nl': 'De logistieke dienst',
                                })[lang]
                            },
                            {
                                style: {
                                    'font-weight': 'bold',
                                    'text-align': 'center',
                                    'margin-bottom': '5mm'
                                },
                                html: "Gillian Townley"
                            },
                            {
                                style: {
                                    'position': 'absolute',
                                    'left': '2mm',
                                    'top': '5mm',
                                    'width': '35mm',
                                    'height': '8.4mm',
                                    'background-image': 'url(assets/img/signature_gill.png)',
                                    'background-size': '100% 100%'
                                }
                            }
                        ]
                    },


                    PrintingUtil.getFooter(null, lang)
                ]

            };

            this.pages.push(page);

        }
        return this;
    }
}
