import { LoadingComponent } from "src/app/components/loading/loading.component";

export class LoadingPromise
{
    public static create<T>(executor: (resolve: (value?: T | PromiseLike<T>) => void, reject: (reason?: any) => void) => void,
                            message: string = null)
    {
        LoadingComponent.push(message);
        let p = new Promise<T>(executor);
        p.then(
            (result) => { LoadingComponent.pop(); },
            (err) => { LoadingComponent.pop(); }
        );
        return p;
    }
}