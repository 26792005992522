import { NgModule, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomersImportViewComponent } from './views/customers-import-view/customers-import-view.component';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { ModuleBase } from '../module-base.class';
import { DatabaseImportViewComponent } from './views/database-import-view/database-import-view.component';
import { FormsModule } from '../../../../node_modules/@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule
    ],
    declarations: [
        CustomersImportViewComponent,
        DatabaseImportViewComponent
    ],
    exports: [
        CustomersImportViewComponent,
        DatabaseImportViewComponent
    ],
    entryComponents: [
        CustomersImportViewComponent,
        DatabaseImportViewComponent
    ]
})
export class DatabaseImportModule extends ModuleBase {

    ngOnInit() {
        HomepageViewComponent.registerGroups({
            'database': {
                'name': "Base de données",
                'permission': 'homepage_database_group',
                'children': [
                    {
                        'name': "Importer des données",
                        'permission': 'homepage_database_import_button',
                        'component': DatabaseImportViewComponent
                    }
                ]
            }
        });

        return super.ngOnInit();
    }

}
