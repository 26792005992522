import * as $ from 'jquery';
import * as moment from 'moment';

import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { ApiService } from '../../../../services/api/api.service';
import { CredentialsService } from 'src/app/services/credentials/credentials.service';
import { DataGridComponent } from '../../../../components/data-grid/data-grid.component';
import { DateTimeUtil } from '../../../utils/classes/DateTimeUtil.class';
import { Deadline } from '../../../other-data/classes/Deadline.class';
import { DeviceInstance } from '../../../devices/classes/DeviceInstance.class';
import { DialogButton } from '../../../../components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from '../../../../components/dialogs/dialogs.component';
import { ExternalService } from '../../../../services/external/external.service';
import { Invoice } from '../../../invoicing/classes/Invoice.class';
import { LoadingPromise } from '../../../../classes/objects/LoadingPromise.class';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { config } from '../../../../classes/config';

@Component({
  selector: 'app-bob-sales-view',
  templateUrl: './bob-sales-view.component.html',
  styleUrls: ['./bob-sales-view.component.css'],
})
export class BobSalesViewComponent extends AccessViewComponent {
  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'BOB - Exporter les ventes',
    data: this,
    elements: [
      {
        type: 'button',
        name: 'generateFilesButtons',
        text: 'Envoyer<br/>les fichiers',
        icon: ' far fa-mail',
        click: function (view: BobSalesViewComponent) {
          view.generateFiles();
        },
        visible: true,
      },
      { type: 'spacing' },
    ],
  };

  public fromDate: string = null;
  public toDate: string = null;
  public data: any[] = [];

  public gridColumns = [
    { title: 'Facture', field: 'full_number', width: 200, readonly: true },
    { title: 'Journal', field: 'journal', width: 90, readonly: true },
    // { title: 'Année fiscale', field: 'fiscal_year', width: 90, readonly: true },
    { title: 'Année', field: 'year', width: 90, readonly: true },
    { title: 'Mois', field: 'month', width: 90, readonly: true },
    { title: 'N°', field: 'number', width: 90, readonly: true },
    { title: 'Date', field: 'date', type: 'date', width: 130, readonly: true },
    // { title: 'Type', field: 'type', readonly: true },
    { title: 'Client', field: 'customer_name', readonly: true },
    { title: 'ID Client', field: 'customer', readonly: true },
    { title: "Date\nd'échéance", field: 'due_date', type: 'date', width: 130, readonly: true },
    { title: 'Escompte', field: 'discount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
    { title: "Date\nd'échéance\nde l'escompte", field: 'discount_due_date', type: 'date', width: 130, readonly: true },
    { title: 'Devise', field: 'currency', width: 90, readonly: true },
    { title: 'Cours de\nla devise', field: 'xrate', type: 'number', decimalsCount: 8, width: 150, readonly: true },
    // { title: 'Montant en\ndevise', field: 'currency_amount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
    { title: 'Montant en\neuros', field: 'total_amount', type: 'number', decimalsCount: 2, width: 150, readonly: true },
    { title: 'Remarque interne\nou commentaire', field: 'internal_remark', width: 300 },
    { title: 'Remarque externe\nou communication', field: 'external_remark', width: 300 }, // n° fac. fourn (achats) + montant en £ (+ taux de change)
    // { title: 'Total HTVA', field: 'amount', type: 'number', decimalsCount: 2, width: 150 },
    // { title: 'TVA', field: 'vat', type: 'number', decimalsCount: 2, width: 150 },
    // { title: 'Total TVAC', field: 'total', type: 'number', decimalsCount: 2, width: 150 }
  ];

  @ViewChild('grid') grid: DataGridComponent;

  public ngOnInit() {
    this.fromDate = moment(new Date()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    this.toDate = moment(new Date()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.generateReport();
  }

  public generateReport() {
    ApiService.callModule('bob', 'salesData', {
      from_date: this.fromDate,
      to_date: this.toDate,
    }).then(
      (result) => {
        if (result.result === 'success' && result.details) {
          this.data = result.details;
          console.log('result:', result.details);
          for (let i = 0; i < this.data.length; ++i) {
            let invoice: any = this.data[i];
            invoice.due_date = Deadline.getFinalDate(invoice.deadline, new Date(invoice.date));
            invoice.discount_due_date =
              invoice.discount_days > 0
                ? Deadline.getFinalDate(invoice.discount_days + ' jours', new Date(invoice.date))
                : null;
          }
        } else console.error(result);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public generateFiles() {
    let error_messages: string[] = [];
    let data = this.grid.filteredItems;
    console.log(data);
    // return;
    if (data) {
      for (let i = 0; i < data.length; ++i) {
        let invoice: any = data[i];
        if (!invoice.customer || !invoice.customer.length)
          error_messages.push('Aucun ID spécifié pour le client ' + invoice.customer_name);
        if (!invoice.internal_remark || !invoice.internal_remark.length)
          error_messages.push('Remarque interne vide pour la facture ' + invoice.full_number);
        if (!invoice.external_remark || !invoice.external_remark.length)
          error_messages.push('Remarque externe vide pour la facture ' + invoice.full_number);
      }
    }
    if (!error_messages.length) {
      if (ExternalService.available) {
        LoadingPromise.create<any>((resolve, reject) => {
          let post_data = 'list_json=' + encodeURIComponent(JSON.stringify(data));
          let promises: any[] = [];
          let filenames: string[] = [];
          promises.push(
            ExternalService.saveAsTempFilename(
              'VentesEnt.txt',
              config.apiUrl + 'modules/bob/generateSalesDocuments.php',
              'POST',
              post_data
            ).then(
              (result) => {
                filenames.push(result + '|' + 'VentesEnt.txt');
              },
              (err) => {
                console.error(err);
              }
            )
          );
          promises.push(
            ExternalService.saveAsTempFilename(
              'VentesImp.txt',
              config.apiUrl + 'modules/bob/generateSalesAmounts.php',
              'POST',
              post_data
            ).then(
              (result) => {
                filenames.push(result + '|' + 'VentesImp.txt');
              },
              (err) => {
                console.error(err);
              }
            )
          );
          promises.push(
            ExternalService.saveAsTempFilename(
              'Clients.txt',
              config.apiUrl + 'modules/bob/generateSalesCustomers.php',
              'POST',
              post_data
            ).then(
              (result) => {
                filenames.push(result + '|' + 'Clients.txt');
              },
              (err) => {
                console.error(err);
              }
            )
          );
          // promises.push(ExternalService.saveAsTempFilename('VentesEnt.sch', config.apiUrl + 'modules/bob/VentesEnt.sch', 'GET', null).then(
          //     (result) => { filenames.push(result + '|' + 'VentesEnt.sch'); },
          //     (err) => { console.error(err); },
          // ));
          // promises.push(ExternalService.saveAsTempFilename('VentesImp.sch', config.apiUrl + 'modules/bob/VentesImp.sch', 'GET', null).then(
          //     (result) => { filenames.push(result + '|' + 'VentesImp.sch'); },
          //     (err) => { console.error(err); },
          // ));
          // promises.push(ExternalService.saveAsTempFilename('Clients.sch', config.apiUrl + 'modules/bob/Clients.sch', 'GET', null).then(
          //     (result) => { filenames.push(result + '|' + 'Clients.sch'); },
          //     (err) => { console.error(err); },
          // ));
          Promise.all(promises).then(
            (result) => {
              ExternalService.createMail(
                'cabinet.delcominette@gmail.com',
                null,
                null,
                'Import des ventes dans BOB',
                '<p>Bonjour Madame Delcominette,</p>' +
                  "<p>Veuillez trouver en pièce jointe les fichiers nécessaires à l'import des ventes dans BOB.</p>" +
                  '<p>Cordialement,</p>',
                filenames,
                CredentialsService.loggedMerchant.outlook_signature
              ).then(
                (result) => {
                  resolve(result);
                },
                (err) => {
                  console.error(err);
                  reject(err);
                }
              );
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        });
      } else {
        $('#list_json').val(JSON.stringify(data));
        $('#json_form')
          .attr('action', config.apiUrl + 'modules/bob/generateSalesDocuments.php')
          .submit();
        $('#json_form')
          .attr('action', config.apiUrl + 'modules/bob/generateSalesAmounts.php')
          .submit();
        $('#json_form')
          .attr('action', config.apiUrl + 'modules/bob/generateSalesCustomers.php')
          .submit();
      }
    } else {
      DialogsComponent.display({
        title: 'Erreur de données',
        message:
          '<p>Veuillez corriger les erreurs suivantes pour pouvoir générer les données :</p><ul><li>' +
          error_messages.join('</li><li>') +
          '</li></ul>',
        icon: 'error',
        buttons: DialogButton.okOnlyButtons,
      });
    }
  }
}
