import { v4 } from 'uuid';

export class ObjectModel {
  public id: string = '';

  public currentClass: any = null;
  public instance: ObjectModel = null;

  constructor(currentClass: any) {
    this.currentClass = currentClass;
    this.instance = this;
  }

  // public assignFromObject(obj: any) {
  //     if (obj.id) this.id = +obj.id || 0;
  //     this.register(obj.id);
  //     return this;
  // }

  public static getInstances(currentClass: any) {
    if (!currentClass.instances) currentClass.instances = {};
    return currentClass.instances;
  }
  public static getLoadedIds(currentClass: any) {
    if (!currentClass.loaded_ids) currentClass.loaded_ids = [];
    return currentClass.loaded_ids;
  }
}
