import { ApiService } from 'src/app/services/api/api.service';
import { ClassManager } from './ClassManager.class';
import { DataService } from 'src/app/services/data/data.service';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { LoadingPromise } from './LoadingPromise.class';
import { ObjectChildDefinition } from './ObjectChildDefinition.class';
import { ObjectDefinition } from './ObjectDefinition.class';
import { ObjectLinkDefinition } from './ObjectLinkDefinition.class';
import { ObjectModel3 } from './ObjectModel3.class';
import { ObjectValueDefinition } from './ObjectValueDefinition.class';

export class SaveSequence {
  // this loadsequence
  public objects_to_create: { [type: string]: ObjectModel3[] } = {};
  public objects_to_save: { [type: string]: ObjectModel3[] } = {};

  // base loadsequence
  public saving_ids: { [type: string]: string[] } = {};
  public saved_objects: { [type: string]: ObjectModel3[] } = {};

  public baseSequence: SaveSequence = null;
  public number: number = 0;
  public static nextNumber: number = 1;

  public constructor(objects: ObjectModel3[], baseSequence: SaveSequence = null) {
    this.baseSequence = baseSequence;
    this.number = SaveSequence.nextNumber++;
    this.addObjects(objects);
  }

  public addObjects(objects: ObjectModel3[]) {
    for (let i = 0; i < objects.length; ++i) if (objects[i] instanceof ObjectModel3) this.addObject(objects[i]);
  }

  public addObject(obj: ObjectModel3) {
    let added: boolean = false;
    let type: string = ClassManager.getClassName(obj);
    let definition: ObjectDefinition = ClassManager.getClass(type).definition;
    let id_field: string = definition.database.id || 'id';
    let id: string = obj[id_field] || '';
    if (!this.objects_to_save[type]) this.objects_to_save[type] = [];
    if (!this.objects_to_create[type]) this.objects_to_create[type] = [];
    if (id) {
      if (!this.saving_ids[type]) this.saving_ids[type] = [];
      if (!this.saving_ids[type].includes(id)) {
        // console.log('adding object to save:', obj);
        added = true;
        this.objects_to_save[type].push(obj);
        this.saving_ids[type].push(id);
      }
    } else {
      if (!this.objects_to_create[type].includes(obj)) {
        added = true;
        // console.log('adding object to create:', obj);
        this.objects_to_create[type].push(obj);
        this.objects_to_save[type].push(obj);
      }
    }
    if (added === true) {
      if (definition.children) {
        for (let childName in definition.children) {
          let childDef: ObjectChildDefinition = definition.children[childName];
          if (childDef.save === true && obj[childName] instanceof ObjectModel3) this.addObject(obj[childName]);
        }
      }
      if (definition.links) {
        for (let linkName in definition.links) {
          let linkDef: ObjectLinkDefinition = definition.links[linkName];
          if (linkDef.save === true && Array.isArray(obj[linkName])) this.addObjects(obj[linkName]);
        }
      }
    }
  }

  public static create(objects: ObjectModel3[]) {
    return new SaveSequence(objects);
  }

  public createObjects() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (let className in this.objects_to_create) {
        let objects: ObjectModel3[] = this.objects_to_create[className];
        if (objects.length > 0) {
          let currentClass: any = ClassManager.getClass(className);
          let definition: ObjectDefinition = currentClass.definition;
          let instances: ObjectDefinition = ClassManager.getInstances(currentClass);
          let id_field: string = definition.database.id || 'id';
          promises.push(
            DataService.sendCommand('CREATE', {
              table: definition.database.table,
              count: objects.length,
            }).then(
              (result: any) => {
                if (result.result === 'success') {
                  for (let i = 0; i < objects.length; ++i) {
                    let id: string = result.details[i];
                    objects[i][id_field] = id;
                    instances[id] = objects[i];
                    if (!this.saving_ids[className]) this.saving_ids[className] = [];
                    this.saving_ids[className].push(id);
                  }
                } else reject(result);
              },
              (err) => {
                reject(err);
              }
            )
          );
        }
      }
      Promise.all(promises).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public updateObjects() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: Promise<any>[] = [];
      for (let className in this.objects_to_save) {
        let objects: ObjectModel3[] = this.objects_to_save[className];
        if (objects.length > 0) {
          let definition: ObjectDefinition = ClassManager.getClass(className).definition;
          let data: any[] = [];
          for (let i = 0; i < objects.length; ++i) data.push(this.getObjectData(objects[i]));
          promises.push(
            DataService.sendCommand('UPDATE', {
              definition: definition,
              data: data,
            }).then(
              (result) => {
                for (let i = 0; i < objects.length; ++i) objects[i].changed = false;
              },
              (err) => {
                console.error(err);
              }
            )
          );
        }
      }
      Promise.all(promises).then(
        (result) => {
          resolve(result);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  public save() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let promises: any[] = [];
      // promises.push(ApiService.sync());
      Promise.all(promises).then(
        (result) => {
          this.save2().then(
            (result2) => {
              resolve(result2);
            },
            (err2) => {
              reject(err2);
            }
          );
        },
        (err) => {
          console.warn('cannot sync before save:', err);
          this.save2().then(
            (result2) => {
              resolve(result2);
            },
            (err2) => {
              reject(err2);
            }
          );
        }
      );
    });
  }

  public save2() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.createObjects().then(
        (result) => {
          this.updateObjects().then(
            (result) => {
              for (let className in this.objects_to_save) {
                let currentClass: any = ClassManager.getClass(className);
                let instances: { [id: string]: ObjectModel3 } = ClassManager.getInstances(currentClass);
                let id_field: string = currentClass.definition.database.id;
                for (let i = 0; i < this.objects_to_save[className].length; ++i) {
                  let obj: ObjectModel3 = this.objects_to_save[className][i];
                  let id: string = obj[id_field] || '';
                  if (id) {
                    if (instances[id]) obj.clone(true, instances[id]);
                    else instances[id] = obj;
                  }
                }
              }
              resolve(result);
            },
            (err) => {
              console.error(err);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }

  public getObjectData(obj: ObjectModel3) {
    let className: string = ClassManager.getClassName(obj);
    let definition: ObjectDefinition = ClassManager.getClass(className).definition;
    let id_field: string = definition.database.id || 'id';
    let data: any = {};
    data[id_field] = obj[id_field] || '';
    if (definition.values) {
      for (let valueName in definition.values) {
        let valueDef: ObjectValueDefinition = definition.values[valueName];
        if (valueDef.ignore_save !== true) {
          if (obj[valueName] == null || obj[valueName] == undefined) data[valueName] = null;
          else if (valueDef.type == 'number' && isNaN(obj[valueName])) data[valueName] = null;
          else if (valueDef.type == 'number') data[valueName] = 'INT:' + obj[valueName];
          else if (valueDef.type == 'html') data[valueName] = 'HTML:' + obj[valueName];
          else if (valueDef.type == 'string')
            data[valueName] = obj[valueName] == null ? null : 'TEXT:' + obj[valueName];
          else if (valueDef.type == 'json')
            data[valueName] = obj[valueName] == null ? null : 'TEXT:' + JSON.stringify(obj[valueName]);
          else if (valueDef.type == 'datetime')
            data[valueName] = 'DATETIME:' + DateTimeUtil.toDatabaseDateTimeString(obj[valueName] as Date);
          else if (valueDef.type == 'date')
            data[valueName] = 'DATE:' + DateTimeUtil.toDatabaseDateString(obj[valueName] as Date);
          else data[valueName] = obj[valueName];
        }
      }
    }
    if (definition.children) {
      for (let childName in definition.children) {
        let childDef: ObjectChildDefinition = definition.children[childName];
        // if (childDef.save === true)
        // {
        let childClass: any = ClassManager.getClass(childDef.type);
        let columnName: string = childDef.db_column || 'id_' + childName;
        let child: ObjectModel3 = obj[childName];
        let id_field: string = childClass.definition.database.id || 'id';
        if (child) {
          let child_id: string = child[id_field] || '';
          data[columnName] = child_id || null;
        } else {
          data[columnName] = null;
        }
        // }
      }
    }
    if (definition.links) {
      for (let linkName in definition.links) {
        let linkDef: ObjectLinkDefinition = definition.links[linkName];
        // if (linkDef.save === true)
        // {
        let linkClass: any = ClassManager.getClass(linkDef.type);
        let columnName: string = linkName; //linkDef.db_column || ('id_' + linkName);
        let links: ObjectModel3[] = obj[linkName];
        let id_field: string = linkClass.definition.database.id || 'id';
        let link_ids: string[] = [];
        if (links)
          for (let i = 0; i < links.length; ++i) {
            let id = links[i][id_field] || '';
            if (id) link_ids.push(id);
          }
        data[columnName] = link_ids.length > 0 ? link_ids.join(',') : null;
        // }
      }
    }
    return data;
  }
}
