import { DataService } from "../data.service";

export class DataCommand
{
    public command: string = null;
    public data: string = null;

    public resolve: any = null;
    public reject: any = null;

    public constructor(command: string, data: string, resolve: any = null, reject: any = null)
    {
        this.command = command;
        this.data = data;
        this.resolve = resolve;
        this.reject = reject;
    }

    public send()
    {
        return DataService.sendCommand(this.command, this.data);
    }
}
