<div class="container">

    <div class="container-column">

        <h3>Options</h3>

        <info-block #optionsBlockComponent *ngIf="model" [block]="model.optionsBlock" [data]="model"></info-block>

    </div>

    <div class="container-column">

        <h3>Aperçus</h3>

        <div>
            <ul class="tabs">
                <li *ngFor="let number of customers" class="tab" [ngClass]="{'active': selectedPreview == number}" (click)="setPreview(number)">{{number}}</li>
            </ul>
            <div *ngIf="models_by_customer[selectedPreview]">
                <printable-page #pagesElements *ngFor="let page of models_by_customer[selectedPreview].pages" [page]="page" [ngStyle]="{'width': page.width+'mm', 'height': page.height+'mm'}"></printable-page>
            </div>
        </div>
        <ng-template #no_preview>(Aperçu non disponible)</ng-template>

    </div>

</div>
