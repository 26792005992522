import { ArrayUtil } from './ArrayUtil.class';
import { cloneDeep } from 'lodash';

export class ObjectUtil {
  public static cloneObject(obj: any, deepClone: boolean = false, cloneArrayItems: boolean = false): any {
    let copy = {};
    let keys = Object.keys(obj);
    for (let i = 0; i < keys.length; ++i) {
      let key = keys[i];
      let value = obj[key];
      if (deepClone) {
        if (Array.isArray(value)) {
          console.log('cloning object array', key);
          value = ArrayUtil.cloneArray(value, deepClone, deepClone);
        } else if (typeof value === 'object') {
          console.log('cloning object key', key);
          value = ObjectUtil.cloneObject(value, deepClone, cloneArrayItems);
        }
      }
      copy[key] = value;
    }
    return copy;
  }

  public static merge(...args) {
    return Object.assign({}, ...args);
  }

  public static cloneObjectOrLodash(obj: any) {
    return typeof obj.clone === 'function' ? obj.clone(true) : cloneDeep(obj);
  }
}
