<data-grid *ngIf="model && model.gridColumns && model.gridItems" id="report-grid"
           [items]="model.gridItems" [columns]="model.gridColumns"
           [showAll]="true" [ngStyle]="model.gridStyle">
</data-grid>
<table *ngIf="model && (!model.gridColumns || !model.gridItems)" class="report-table" [ngStyle]="model.tableStyle" [ngClass]="">
    <tr class="report-header-row" *ngFor="let headerRow of model.headerRows">
        <th *ngIf="model.hasRowTitles">&nbsp;</th>
        <th *ngFor="let cell of headerRow.cells" class="report-column-header"
            [innerHTML]="cell ? cell.value : null" [ngStyle]="cell ? cell.style : null"
            [attr.rowspan]="cell ? cell.rowSpan || 1 : 1" [attr.colspan]="cell ? cell.colSpan || 1 : 1"></th>
    </tr>
    <tr *ngFor="let row of model.rows; let r = index;" class="report-row">
        <th class="report-row-header" *ngIf="model.hasRowTitles">{{ row.title }}</th>
        <td class="report-cell" *ngFor="let cell of row.cells; let c = index;"
            [innerHTML]="cell ? cell.value : null" [ngStyle]="cell ? cell.style : null"
            [attr.rowspan]="cell ? cell.rowSpan || 1 : 1" [attr.colspan]="cell ? cell.colSpan || 1 : 1"></td>
    </tr>
</table>
<!--<div class="chart-container" *ngIf="model && model.chart">-->
<!--    <ngx-charts-bar-horizontal *ngIf="model.chart.type == 'bar-horizontal'" [results]="model.chart.data"-->
<!--        [scheme]="'picnic'" [schemeType]="'ordinal'"-->
<!--        [view]="model.chart.view" [xAxis]="model.chart.xAxis" [yAxis]="model.chart.yAxis"-->
<!--        ></ngx-charts-bar-horizontal>-->
<!--</div>-->
<div class="chart-container" *ngFor="let chart of model.charts; let index=index" id="chartContainer{{index}}"
     [ngStyle]="{'width': chart.container_width+'px', 'height': chart.container_height+'px'}"
     [ngClass]="{'chart-container-inline': chart.inline}">
</div>
