import { ObjectModel2 } from "../../../classes/objects/ObjectModel2.class";
import { ObjectDefinition } from "../../../classes/objects/ObjectDefinition.class";
import { Nomenclature } from "./Nomenclature.class";
import { ArticleBase } from "./ArticleBase.class";


export class Article extends ArticleBase
{
    public get format(): string { return this.field1; };
    public get material(): string { return this.field2; };
    public get material2(): string { return this.field3; };
    public get printing(): string { return this.field4; };
    public get diameter(): string { return this.field5; };
    public get presentation(): string { return this.field6; };
    public get labels_count(): string { return this.field7; };
    public get space(): string { return this.field8; };
    public get rolling_direction(): string { return this.field9; };
    public get width(): string { return this.field10; };
    public get pinfeed(): string { return this.field11; };
    public get perforations(): string { return this.field12; };
    public get other(): string { return this.field13; };
    public get packaging(): string { return this.field14; };
    public get picture(): string { return this.field15; };

    public set format(value: string) { this.field1 = value; };
    public set material(value: string) { this.field2 = value; };
    public set material2(value: string) { this.field3 = value; };
    public set printing(value: string) { this.field4 = value; };
    public set diameter(value: string) { this.field5 = value; };
    public set presentation(value: string) { this.field6 = value; };
    public set labels_count(value: string) { this.field7 = value; };
    public set space(value: string) { this.field8 = value; };
    public set rolling_direction(value: string) { this.field9 = value; };
    public set width(value: string) { this.field10 = value; };
    public set pinfeed(value: string) { this.field11 = value; };
    public set perforations(value: string) { this.field12 = value; };
    public set other(value: string) { this.field13 = value; };
    public set packaging(value: string) { this.field14 = value; };
    public set picture(value: string) { this.field15 = value; };

    constructor()
    {
        super();
        this.type = 0;
    }

    public static load(ids: string[] = null, orderBy: string[] = null, groupBy: string[] = null,
        deleted: boolean = false, conditions: string = null, forceReload: boolean = false)
    {
        if (conditions != null) conditions = '(' + conditions + ') AND type = 0';
        else conditions = 'type = 0';
        return super.load(ids, orderBy, groupBy, deleted, conditions, forceReload);
    }
}
