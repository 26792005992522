import { Component, OnInit, Input, Output, Inject, ViewChild, EventEmitter, forwardRef, ElementRef } from '@angular/core';
import { NgModel, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'nullable-field',
  templateUrl: './nullable-field.component.html',
  styleUrls: ['./nullable-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NullableFieldComponent),
      multi: true
    }
  ]
})
export class NullableFieldComponent implements ControlValueAccessor {

    @Input() type: string = null;
    @Input() nullText: string = 'Aucune valeur';
    @Input() allowBlankValues: boolean = true;
    @Output('change') change: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('inputElement') public inputElement: ElementRef;

    constructor(public elementRef: ElementRef) { }

    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any) {
        if (value !== undefined) this.value = value;
    }

    @Input() _value: any = null;
    private _hasValue: boolean = false;

    get value() { return this._hasValue ? this._value : null; }
    set value(value) {
        this._value = value;
        this._hasValue = (this._value != null);
        this.propagateChange(this.value);
        //this.change.next(this.value);
    }
    get hasValue() { return this._hasValue; }
    set hasValue(value) {
        this._hasValue = value;
        this.propagateChange(this.value);
        //this.change.next(this.value);
    }

    checkboxChanged(event, value = undefined)
    {
        if (value != undefined) this.hasValue = value;
        if (this._hasValue === true || value === true) $('input.nullable-field-input', $(event.target).closest('nullable-field')).focus();
        this.change.next(this.value);
    }
    textChanged(event)
    {
        this.change.next(this.value);
    }

    onBlur(event)
    {
        if (this.allowBlankValues !== true && (this.value === '' || !this.value)) this.value = null;
    }
}
