import { Component, OnInit, ViewChild } from '@angular/core';
import { InfoBlock } from 'src/app/components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from 'src/app/components/info-block/info-block.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';
import { Form } from 'src/app/classes/forms/Form.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { ViewsComponent } from 'src/app/components/views/views.component';
import { FormValidator } from 'src/app/classes/forms/FormValidator.class';
import { AccessViewComponent } from 'src/app/components/views/access-view.component';
import { DateTimeUtil } from 'src/app/modules/utils/classes/DateTimeUtil.class';
import { ItRequest } from '../../classes/ItRequest.class';
import { User } from 'src/app/classes/credentials/User.class';
import {CredentialsService} from "../../../../services/credentials/credentials.service";

@Component({
  selector: 'app-it-request-view',
  templateUrl: './it-request-view.component.html',
  styleUrls: ['./it-request-view.component.css']
})
export class ItRequestViewComponent extends AccessViewComponent {

    public toolbar: Toolbar = {
        class: 'toolbar-big',
        viewTitle: 'Requête IT',
        data: this,
        onPrevPage: () => {
            if (this.requestCopy && this.requestCopy.changed)
            {
                DialogsComponent.display({
                    icon: 'question',
                    title: 'Enregistrer les modifications',
                    message: 'Des modifications ont été effectuées.<br/>Voulez-vous enregistrer les modifications ?',
                    buttons: DialogButton.yesNoCancelButtons
                }).then((result: any) => {
                    if (result === DialogButton.RESULT_YES) {
                        this.save().then(
                            (result2: any) => { ViewsComponent.closeView(); }
                        );
                    }
                    else if (result !== DialogButton.RESULT_CANCEL) ViewsComponent.closeView();
                });
                return false;
            }
            return true;
        },
        elements: [
            { type: 'separator' },
            {
                type: 'button',
                name: 'saveButton',
                text: 'Enregistrer',
                icon: 'save',
                click: function(view: ItRequestViewComponent) {
                    view.save().then((result) => {
                        view.updateView(view.requestCopy);
                    });
                },
                visible: true
            },
            { type: 'spacing' }
        ]
    };


    public request: ItRequest = null;
    public requestCopy: ItRequest = null;

    @ViewChild('requestBlockComponent') requestBlockComponent: InfoBlockComponent;
    @ViewChild('responseBlockComponent') responseBlockComponent: InfoBlockComponent;
    @ViewChild('validationBlockComponent') validationBlockComponent: InfoBlockComponent;

    public get loggedUser(): User { return CredentialsService.loggedUser; }
    public get validationPermission(): boolean { return CredentialsService.isUserAllowed('it_requests_validation', this.loggedUser); }
    public get responsePermission(): boolean { return CredentialsService.isUserAllowed('it_requests_response', this.loggedUser); }

    public requestBlock: InfoBlock = {
        title: 'Requête',
        backColor: 'rgb(0, 177, 136)',
        textColor: 'white',
        fields: [
            { title: 'Date', field: 'date', type: 'date' },
            { title: 'Sujet', field: 'subject' },
            { title: 'Description', field: 'description', type: 'textarea' }
        ]
    };
    public responseBlock: InfoBlock = {
        title: 'Réponse',
        backColor: 'rgb(0, 150, 115)',
        textColor: 'white',
        fields: [
            { title: 'Date', field: 'response_date', type: 'date' },
            { title: 'Heures estimées', field: 'estimation', type: 'number', textAlign: 'left' },
            { title: 'Réponse', field: 'response', type: 'textarea' },
        ]
    };
    public validationBlock: InfoBlock = {
        title: 'Validation',
        backColor: 'rgb(0, 95, 73)',
        textColor: 'white',
        fields: [
            { title: 'Date', field: 'validation_date', type: 'date' },
            { title: 'Validé', field: 'validated', type: 'checkbox' }
        ]
    };

    ngOnInit()
    {
    }

    initView(request: ItRequest)
    {
        console.log('it_request init view with:', request);
        if (request == null) {
            request = new ItRequest();
            request.date = DateTimeUtil.toDateString(new Date());
            request.user = CredentialsService.loggedUser;
        }
        this.request = request;
        this.requestCopy = this.request.clone(true);
        this.updateView(this.requestCopy);
    }
    updateView(request: ItRequest)
    {
    }


    validateForm()
    {
        console.log('validating form for request:', this.requestCopy);
        let form: Form = {
            fields: [
                {
                    name: 'Sujet de la requête',
                    type: 'string',
                    data: this.requestCopy,
                    field: 'subject',
                    pattern: /.+/g,
                    element: this.requestBlockComponent.getElement('subject')
                },
                {
                    name: 'Description de la requête',
                    type: 'string',
                    data: this.requestCopy,
                    field: 'description',
                    pattern: /.+/g,
                    element: this.requestBlockComponent.getElement('description')
                },
                {
                    name: 'Date de la requête',
                    type: 'string',
                    data: this.requestCopy,
                    field: 'date',
                    pattern: /.+/g,
                    element: this.requestBlockComponent.getElement('date')
                }
            ]
        };
        let result = FormValidator.validateForm(form);
        FormValidator.showFormInvalidFields(form, result);
        if (result !== true)
        {
            FormValidator.showNotification(result);
            return false;
        }
        else return true;
    }

    save()
    {
        return new Promise<any>((resolve, reject) => {
            this.requestCopy.save2().then(
                (result) => {
                    this.requestCopy.clone(true, this.request);
                    this.initView(this.request);
                    resolve(result);
                },
                (err) => { reject(err); }
            );
        });
    }

}
