import { ObjectDefinition } from '../objects/ObjectDefinition.class';
import { ObjectModel3 } from '../objects/ObjectModel3.class';

export class UserProfile extends ObjectModel3 {
  public name: string = null;
  public admin: number = 0;

  constructor() {
    super(UserProfile);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'userprofiles',
      id: 'id',
    },
    values: {
      name: { type: 'string' },
      admin: { type: 'number' },
    },
    children: {},
    links: {},
  };
}
