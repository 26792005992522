import { Component, OnInit } from '@angular/core';
import { ReportSetting } from '../../classes/ReportSetting.class';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';

@Component({
  selector: 'app-report-settings-view',
  templateUrl: './report-settings-view.component.html',
  styleUrls: ['./report-settings-view.component.css']
})
export class ReportSettingsViewComponent extends DialogComponent {

    public settings: ReportSetting[] = [];

}
