import * as moment from 'moment';


export class DateTimeUtil
{
    /* Convert a Date object to a '0000-00-00 00:00:00' formatted string */
    public static toDatabaseDateTimeString(date: Date)
    {
        if (!date) return null;
        return ("0000" + date.getFullYear()).slice(-4) + '-' +
               ("00" + (date.getMonth() + 1)).slice(-2) + '-' +
               ("00" + date.getDate()).slice(-2) + ' ' +
               ("00" + date.getHours()).slice(-2) + ':' +
               ("00" + date.getMinutes()).slice(-2) + ':' +
               ("00" + date.getSeconds()).slice(-2);
    }
    /* Convert a Date object to a '0000-00-00' formatted string */
    public static toDatabaseDateString(date: Date)
    {
        if (!date) return null;
        return ("0000" + date.getFullYear()).slice(-4) + '-' +
               ("00" + (date.getMonth() + 1)).slice(-2) + '-' +
               ("00" + date.getDate()).slice(-2);
    }
    /* Convert a Date object to a '0000-00-00' formatted string */
    public static toDateString(date: Date)
    {
        if (!date) return null;
        return ("0000" + date.getFullYear()).slice(-4) + '-' +
               ("00" + (date.getMonth() + 1)).slice(-2) + '-' +
               ("00" + date.getDate()).slice(-2);
    }
    /* Convert a Date object to a specific format */
    public static format(date: Date, format: string): string
    {
        if (!date) return null;
        return format.replace('Y', ('0000'+date.getFullYear()).slice(-4))
                     .replace('m', ('00'+(date.getMonth() + 1)).slice(-2))
                     .replace('d', ('00'+date.getDate()).slice(-2))
                     .replace('H', ('00'+date.getHours()).slice(-2))
                     .replace('i', ('00'+date.getMinutes()).slice(-2))
                     .replace('s', ('00'+date.getSeconds()).slice(-2));
    }

    public static addDays(date: Date, days: number)
    {
        let m = moment(date);
        return m.add(days, 'days').toDate();
    }

    public static daysBetween(date1: Date | string, date2: Date | string, roundUp: boolean = true): number
    {
        if (!date1 || !date2) return null;
        let _date1: Date = (typeof(date1) === 'string') ? DateTimeUtil.parse(date1) : date1;
        let _date2: Date = (typeof(date2) === 'string') ? DateTimeUtil.parse(date2) : date2;
        if (!_date2 || !_date1) return null;
        return moment(_date2).diff(moment(_date1), 'days');
        //return (roundUp ? Math.ceil : Math.floor)((_date2.valueOf() - _date1.valueOf()) / (1000 * 3600 * 24));
    }

    public static parse(str: string)
    {
        let date: Date = null;
        let arr: RegExpExecArray = /([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4})/.exec(str);
        if (arr && arr.length == 4)
        {
            let d: number = parseInt(arr[1]);
            let m: number = parseInt(arr[2]);
            let y: number = parseInt(arr[3]);
            if (d > 0 && m > 0 && y > 0) date = new Date(y+'-'+m+'-'+d);
        }
        else date = new Date(str);
        return date;
    }

    public static dateDifference(date1: Date | string, date2: Date | string)
    {
        if (typeof(date1) === 'string') date1 = DateTimeUtil.parse(date1);
        if (typeof(date2) === 'string') date2 = DateTimeUtil.parse(date2);
        let value1: number = date1.valueOf();
        let value2: number = date2.valueOf();
        return value2 - value1;
    }

    public static getCurrentYear() {
        return (new Date()).getFullYear()
    }
}
