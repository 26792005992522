<div class="toolbars" [ngStyle]="{ 'margin-left': printMode ? '' : -100 * currentPage + '%' }">
  <app-toolbar *ngFor="let toolbar of toolbars" [toolbar]="toolbar"></app-toolbar>
</div>
<div
  class="views"
  [ngClass]="{ 'print-mode': printMode }"
  [ngStyle]="{ 'margin-left': printMode ? '' : -100 * currentPage + '%' }"
>
  <div #vcr class="vcr-element"></div>
</div>
