import {Currency} from '../../other-data/classes/Currency.class';

export class NumberUtil
{
    public static zeroPadLeft(value: number, length: number = 1)
    {
        let minus: boolean = false;
        if (value < 0) {
            minus = true;
            value = -value;
        }
        let value_string: string = '' + value;
        for(let i=value_string.length; i<length; ++i) value_string = '0' + value_string;
        return minus ? '-' : '' + value_string;
    }
    public static zeroPadRight(value: number, length: number = 1)
    {
        let minus: boolean = false;
        if (value < 0) {
            minus = true;
            value = -value;
        }
        let value_string: string = '' + value;
        for(let i=value_string.length; i<length; ++i) value_string += '0';
        return minus ? '-' : '' + value_string;
    }

    public static round(value: number, decimals: number = 0)
    {
        let shift: number = Math.pow(10, decimals);
        return Math.round(value * shift) / shift;
    }

    public static formatNumber(value: number, decimals: number = 2, thousandsSplitter: string = '.')
    {
        if (value == null) value = 0.0;
        let result = /^(\-?)([0-9]*)(\.([0-9]*))?/.exec(decimals ? value.toFixed(decimals) : ('' + value));
        let formatted = '';
        if (!thousandsSplitter || thousandsSplitter.length == 0) formatted = result[2];
        else
        {
            let num: string = (result[2] && result[2].length > 0) ? result[2] : '0';
            let len: number = num.length;
            for(let i=0; i<len; ++i)
            {
                let c: string = num.charAt(i);
                if ((len - i) % 3 == 0 && i > 0) formatted += thousandsSplitter;
                formatted += c;
            }
        }
        return result[1] + formatted + (result[4] ? ',' + result[4] : '');
    }

    public static formatMoney(value: number, currency: string = '€', decimals: number = 2, thousandsSplitter: string  ='.')
    {
        return NumberUtil.formatNumber(value, decimals, thousandsSplitter) + (currency ? ' ' + currency : '');
    }



    // CONVERT NUMBER TO LETTERS
    /**
     * Convert an integer to its words representation
     *
     * @author McShaman (https://stackoverflow.com/users/788657/mcshaman)
     * @source https://stackoverflow.com/questions/14766951/convert-digits-into-words-with-javascript
     */
    private static wordsLangs: any = {
        'en': {
            units: ['zero', 'one', 'two', 'three', 'four', 'five', 'six', 'seven', 'eight', 'nine', 'ten', 'eleven', 'twelve', 'thirteen', 'fourteen', 'fifteen', 'sixteen', 'seventeen', 'eighteen', 'nineteen'],
            tenths: ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety'],
            scales: ['hundred', 'thousand', 'million', 'billion', 'trillion', 'quadrillion', 'quintillion', 'sextillion', 'septillion', 'octillion', 'nonillion', 'decillion', 'undecillion', 'duodecillion', 'tredecillion', 'quatttuor-decillion', 'quindecillion', 'sexdecillion', 'septen-decillion', 'octodecillion', 'novemdecillion', 'vigintillion', 'centillion'],
            and_units: ['', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#' ],
            showOneForScales: [ true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ],
            // showOneForStart: [ true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ]
            // and: 'and'
        },
        'fr': {
            scales: ['cent', 'mille', 'million', 'millard', 'billion', 'billiard', 'trillion', 'trilliard', 'quadrillion'],
            plurals: ['cent', 'mille', 'millions', 'milliards', 'billions', 'billiards', 'trillions', 'trilliards', 'quadrillions'],
            tenths: ['', '', 'vingt', 'trente', 'quarante', 'cinquante', 'soixante', 'septante', 'quatre-vingt', 'nonante'],
            units: ['zéro', 'un', 'deux', 'trois', 'quatre', 'cinq', 'six', 'sept', 'huit', 'neuf', 'dix', 'onze', 'douze', 'treize', 'quatorze', 'quinze', 'seize', 'dix-sept', 'dix-huit', 'dix-neuf'],
            and_units: ['', 'et', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#', '#-#' ],
            exceptions: { 81: 'quatre-vingt-un' },
            postProcess: (value: number, numbers: string, text: string) => {
                if ((value % 1000 != 0 && value % 100 == 0) || value % 100 == 80) return text + 's';
                else return text;
            },
            showOneForScales: [ false, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ],
            // showOneForStart: [ false, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ]
        },
        'nl': {
            units: ['zero', 'een', 'twee', 'drie', 'vier', 'vijf', 'zes', 'zeven', 'acht', 'negen', 'tien', 'elf', 'twaalf', 'dertien', 'viertien', 'vijftien', 'zestien', 'zeventien', 'achttien', 'negentien'],
            tenths: ['', '', 'twintig', 'dertig', 'veertig', 'vijftig', 'zestig', 'zeventig', 'tachtig', 'negentig'],
            scales: ['#honderd#', '#duizend', 'miljoen', 'miljard', 'biljoen', 'biljard', 'triljoen', 'triljard'],
            and_units: ['', '#en#', '#ën#', '#ën#', '#en#', '#en#', '#en#', '#en#', '#en#', '#en#' ],
            unitsBeforeTenths: true,
            showOneForScales: [ false, false, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ],
            // showOneForStart: [ true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true, true ]
        }
    };


    public static splitNumberGroups(number: string)
    {
        let groups: string[] = [];
        let reminder = (number.length % 3);
        let count = Math.floor(number.length / 3);
        if (reminder > 0) groups.push(number.substr(0, reminder));
        for(let i=0; i<count; ++i) groups.push(number.substr(i*3 + reminder, 3));
        return groups;
    }


    public static groupToWords(group: string, lang: string, scale: number = 0)
    {
        group = ('000'+group).substr(-3);
        let wl: any = this.wordsLangs[lang];
        let u: number = parseInt(group.substr(2, 1));
        let t: number = parseInt(group.substr(1, 1));
        let h: number = parseInt(group.substr(0, 1));
        let total: number = parseInt(group);
        let words: string[] = [];
        if (wl.exceptions && wl.exceptions[total] != undefined) words.push(wl.exceptions[total]);
        else {
            if (t == 1) {
                t = 0;
                u += 10;
            }
            if (total != 1 || wl.showOneForScales[scale] || scale == 0) {
                if (h > 0) {
                    if (h > 1 || wl.showOneForScales[0]) words.push(wl.units[h]);
                    words.push(h > 1 && wl.plurals ? wl.plurals[0] : wl.scales[0]);
                }
                if (wl.exceptions && wl.exceptions[t * 10 + u] != undefined) words.push(wl.exceptions[t * 10 + u]);
                else {
                    if (wl.unitsBeforeTenths) {
                        if (u > 0) words.push(wl.units[u]);
                        if (t > 0 && u > 0 && wl.and_units) words.push(wl.and_units[u]);
                        if (t > 0) words.push(wl.tenths[t]);
                    } else {
                        if (t > 0) words.push(wl.tenths[t]);
                        if (t > 0 && u > 0 && wl.and_units) words.push(wl.and_units[u]);
                        if (u > 0) words.push(wl.units[u]);
                    }
                }
            }
        }
        if (scale > 0 && total > 0) words.push(total > 1 && wl.plurals ? wl.plurals[scale] : wl.scales[scale]);
        return words.join(' ');
    }


    public static toWords(val: number, lang: string)
    {
        let wl: any = this.wordsLangs[lang];
        if (val == 0) return wl.units[0];
        // console.log('converting to words:', val);
        let groups: string[] = NumberUtil.splitNumberGroups(val.toString());
        // console.log('groups:', groups);
        let groups_words: string[] = [];
        for(let i=0; i<groups.length; ++i) {
            let scale: number = groups.length - i - 1;
            let words: string = NumberUtil.groupToWords(groups[i], lang, scale);
            // console.log(scale, ':', groups[i], '=>', words);
            groups_words.push(words);
        }
        let final_string: string = groups_words.join(' ').replace(/\s*#\s*/g, '').trim();
        if (wl.postProcess) final_string = wl.postProcess(val, val.toString(), final_string);
        // console.log('final string:', final_string);
        return final_string;
    }

    public static moneyToWords(amount: number, lang: string, currency: Currency)
    {
        let euros: number = Math.floor(amount);
        let cents: number = Math.round((amount - euros) * 100);
        let and_words: any = { 'fr': 'et', 'nl': 'en', 'en': 'and' };
        let euro_words: any = { 'fr': currency.getSingularUnit('fr'), 'nl': currency.getSingularUnit('nl'), 'en': currency.getSingularUnit('en') };
        let euro_words_plural: any = { 'fr': currency.getPluralUnit('fr'), 'nl': currency.getPluralUnit('nl'), 'en': currency.getPluralUnit('en') };
        let cent_words: any = { 'fr': currency.getSingularCent('fr'), 'nl': currency.getSingularCent('nl'), 'en': currency.getSingularCent('en') };
        let cent_words_plural: any = { 'fr': currency.getPluralCent('fr'), 'nl': currency.getPluralCent('nl'), 'en': currency.getPluralCent('en') };

        return [
            NumberUtil.toWords(euros, lang),
            (euros == 1 ? euro_words : euro_words_plural)[lang]
        ]
        .concat(
            cents == 0 ? [] : [
                and_words[lang],
                NumberUtil.toWords(cents, lang),
                (euros == 1 ? cent_words : cent_words_plural)[lang]
            ]
        )
        .join(' ');
    }
}
