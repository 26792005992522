<h3>Paramètres</h3>
<div class="bob-settings">
    <label for="bob-sales-from-date">Du</label>
    <input type="date" id="bob-sales-from-date" [(ngModel)]="fromDate" />
    <label for="bob-sales-to-date">au</label>
    <input type="date" id="bob-sales-to-date" [(ngModel)]="toDate" />
    <button class="btn btn-default" (click)="generateReport()">
        <i class="fas fa-table"></i>
        <span>Régénérer la liste</span>
    </button>
</div>

<h3>Résultat</h3>
<data-grid id="bob-sales" #grid [columns]="gridColumns" [items]="data" [editable]="true" [fixedColumnsCount]="1">
</data-grid>

<form id="json_form" style="display: none" target="_blank" method="POST" enctype="multipart/form-data">
    <textarea id="list_json" name="list_json"></textarea>
</form>
