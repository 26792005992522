import { Component, OnInit } from '@angular/core';
import { Notification } from './classes/Notification.class';
import { ArrayUtil } from '../../modules/utils/classes/ArrayUtil.class';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

    private static _instance: NotificationsComponent = null;
    public static getInstance()
    {
        return NotificationsComponent._instance;
    }

    private static notifications: Notification[] = [];

    constructor() { }

    ngOnInit() {
        NotificationsComponent._instance = this;
    }

    removeNotification(notification: Notification)
    {
        notification.alpha = 0;
        setTimeout((notif: Notification) => {
            ArrayUtil.removeElements(NotificationsComponent.notifications, [notification]);
        }, 250, notification);
    }

    get notifications()
    {
        return NotificationsComponent.notifications;
    }

    getText(notif: Notification)
    {
        return (typeof(notif.content) === 'string' ? notif.content.replace(/<[^>]+>/g, '') : notif.content);
    }

    public static push(notification: Notification)
    {
        notification.alpha = 0;
        NotificationsComponent.notifications.push(notification);
        setTimeout(() => {
            notification.alpha = .75;
            NotificationsComponent.getInstance().closeTimeout(notification);
        }, 0);
    }

    public keepOpen(notification: Notification)
    {
        if (notification.timeout) {
            clearTimeout(notification.timeout);
            notification.timeout = null;
        }
    }

    public closeTimeout(notification: Notification)
    {
        if (notification.timeout) clearTimeout(notification.timeout);
        notification.timeout = setTimeout((notif: Notification) => { this.removeNotification(notif); }, 3000, notification);
    }

}
