import { Component, OnInit } from '@angular/core';

import { ClassManager } from 'src/app/classes/objects/ClassManager.class';
import { Setting } from 'src/app/modules/settings/classes/Setting.class';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  public static instance: SettingsComponent = null;
  public settings: any = {};

  constructor() {
    SettingsComponent.instance = this;
  }

  ngOnInit() {
    ClassManager.registerClass('Setting', Setting);
  }

  public static reload() {
    return Setting.load().then(
      (result: Setting[]) => {
        SettingsComponent.instance.settings = {};
        for (let i = 0; i < result.length; ++i) SettingsComponent.instance.settings[result[i].name] = result[i];
      },
      (err) => {
        console.error(err);
      }
    );
  }

  public static get(name: string) {
    let setting: Setting = SettingsComponent.instance.settings[name];
    if (!setting) return null;
    return setting.value;
  }
}
