import { ApiService } from 'src/app/services/api/api.service';

export class ServerApi {
  public static callModule(moduleName: string, functionName: string, params: any = {}) {
    // return new Promise<any>((resolve, reject) => {
    //     LoadingComponent.push();
    //     HttpUtil.post(AppConfig.baseUrl + 'modules/' + moduleName + '/' + functionName + '.php', params).subscribe(
    //         function(result: any) { LoadingComponent.pop(); if (result.result === 'success') resolve(result); else reject(result); },
    //         function(err) { LoadingComponent.pop(); console.error('api modulecall error:', err); reject(err); }
    //     );
    // });
    return ApiService.callModule(moduleName, functionName, params);
  }
}
