import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';

export class SalesByCustomerReportModel extends ReportModel {
  public title: string = 'RÉPARTITION DES VENTES ET DES PAIEMENTS PAR CLIENT (H.T.V.A.)';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année de début', type: 'number', value: new Date().getFullYear() },
    { name: 'year_end', title: 'Année de fin', type: 'number', value: new Date().getFullYear() },
    {
      name: 'order_by',
      title: 'Trier par',
      type: 'list',
      listField: 'text',
      listItems: [
        { text: 'Nom', value: '`name`' },
        { text: 'Rang', value: '-`rank` DESC' },
        { text: 'Balance', value: 'SUM(t.balance) DESC' },
        { text: 'Payé', value: 'SUM(t.paid) DESC' },
        { text: 'A échoir', value: 'SUM(t.balance)-SUM(t.paid) DESC' },
      ],
      value: { text: 'Nom', value: '`name`' },
    },
  ];

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public dataPoints: any[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static headerCellStyle: any = {
    'background-color': 'rgb(141,180,226)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static name_CellStyle: any = {
    color: 'black',
    'font-weight': 'bold',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 189, 151)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: SalesByCustomerReportModel = new SalesByCustomerReportModel();
    if (settings) model.settings = settings;
    //model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      this.headerRows = [
        {
          cells: [
            {
              value: 'RÉPARTITION DES VENTES ET PAIEMENTS PAR CLIENT (H.T.V.A.)',
              colSpan: 6,
              style: SalesByCustomerReportModel.titleCellStyle,
            },
          ],
        },
        {
          cells: [
            { value: 'Noms des clients', rowSpan: 2, style: SalesByCustomerReportModel.headerCellStyle },
            { value: 'Balance', style: SalesByCustomerReportModel.headerCellStyle },
            // { value: 'Perçus et pertes\net profits', style: SalesByCustomerReportModel.headerCellStyle },
            // { value: 'A réclamer', style: SalesByCustomerReportModel.headerCellStyle },
            { value: 'A échoir', style: SalesByCustomerReportModel.headerCellStyle },
            { value: 'Marge nette', style: SalesByCustomerReportModel.headerCellStyle },
            { value: 'Taux de marque (%)', style: SalesByCustomerReportModel.headerCellStyle },
            { value: 'Rang', style: SalesByCustomerReportModel.headerCellStyle },
          ],
        },
      ];

      ApiService.callModule('reports', 'report', {
        query: 'customers_balance',
        settings: {
          year_start: this.getSetting('year_start').value,
          year_end: this.getSetting('year_end').value,
          order_by: this.getSetting('order_by').value ? this.getSetting('order_by').value.value || 'name' : 'name',
        },
      }).then(
        (result2) => {
          console.log('result:', result2);
          let balance_total: number = 0;
          let paid_total: number = 0;
          let to_claim_total: number = 0;
          let to_go_total: number = 0;
          let margin_total: number = 0;
          let sell_total: number = 0;
          let cost_total: number = 0;
          let grouped: any = {};
          this.rows = [];
          this.dataPoints = [];
          for (let i = 0; i < result2.details.length; ++i) {
            let balance_str: string = result2.details[i].balance;
            let paid_str: string = result2.details[i].paid;
            let to_claim_str: string = result2.details[i].to_claim;
            let balance: number = parseFloat(balance_str) || 0;
            let paid: number = parseFloat(paid_str) || 0;
            let to_claim: number = parseFloat(to_claim_str) || 0;
            let to_go: number = balance - paid - to_claim;
            let margin_str: string = result2.details[i].net_margin;
            let margin: number = parseFloat(margin_str) || 0;
            let sell_str: string = result2.details[i].sell_price;
            let sell: number = parseFloat(sell_str) || 0;
            let cost_str: string = result2.details[i].cost_price;
            let cost: number = parseFloat(cost_str) || 0;
            result2.details[i].balance = balance;
            result2.details[i].paid = paid;
            result2.details[i].to_claim = to_claim;
            result2.details[i].to_go = to_go;
            result2.details[i].margin = margin;
            result2.details[i].sell = sell;
            result2.details[i].cost = cost;
            balance_total += balance;
            paid_total += paid;
            to_claim_total += to_claim;
            to_go_total += to_go;
            margin_total += margin;
            sell_total += sell;
            cost_total += cost;
            let id_parent: string = result2.details[i].id_parent;
            if (id_parent) {
              if (!grouped[id_parent])
                grouped[id_parent] = {
                  balance: 0,
                  paid: 0,
                  to_claim: 0,
                  to_go: 0,
                  margin: 0,
                  sell: 0,
                  cost: 0,
                };
              grouped[id_parent].balance += balance;
              grouped[id_parent].paid += paid;
              grouped[id_parent].to_claim += to_claim;
              grouped[id_parent].to_go += to_go;
              grouped[id_parent].margin += margin;
              grouped[id_parent].sell += sell;
              grouped[id_parent].cost += cost;
              grouped[id_parent].id = id_parent;
              grouped[id_parent].name = String(result2.details[i].parent_name) + ' (TOTAL)';
            }
          }
          console.log('grouped:', grouped);
          for (let i = 0; i < result2.details.length; ++i) {
            let id: string = result2.details[i].id_parent;
            if (grouped[id]) {
              if (grouped[id].balance == 0) delete grouped[id];
            }
          }
          for (var id in grouped) result2.details.push(grouped[id]);
          result2.details.sort((a: any, b: any) => {
            return b.balance - a.balance;
          });
          let _rank = 1;
          for (var i = 0; i < result2.details.length; ++i) {
            result2.details[i].rank = result2.details[i].balance == 0 ? 0 : _rank++;
          }
          result2.details.sort((a: any, b: any) => {
            switch (this.getSetting('order_by').name) {
              case 'Nom':
                return !a.name ? -1 : !b.name ? 1 : a.name.localeCompare(b.name);
              case 'Rang':
                return b.rank - a.rank;
              case 'Payé':
                return b.paid - a.paid;
              case 'A échoir':
                return b.to_go - a.to_go;
              default:
                break; //case 'Balance'
            }
          });
          for (let i = 0; i < result2.details.length; ++i) {
            let name: string = result2.details[i].name;
            let balance: number = result2.details[i].balance;
            let paid: number = result2.details[i].paid;
            let to_claim: number = result2.details[i].to_claim;
            let to_go: number = result2.details[i].to_go;
            let margin: number = result2.details[i].margin;
            let sell: number = result2.details[i].sell;
            let cost: number = result2.details[i].cost;
            let margin_perc: number = sell != 0 ? 100 * (1 - cost / sell) : 0;
            // let margin_perc: number = balance != 0 ? 100*margin/balance : 0;
            let rank: number = result2.details[i].rank;
            this.rows.push({
              cells: [
                { value: name, style: SalesByCustomerReportModel.name_CellStyle },
                {
                  value: balance && balance != 0 ? NumberUtil.formatMoney(balance, '€', 2, '.') : '',
                  style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
                // { value: paid && paid != 0 ? NumberUtil.formatMoney(paid, '€', 2, '.') : '', style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                // { value: to_claim && to_claim != 0 ? NumberUtil.formatMoney(to_claim, '€', 2, '.') : '', style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle) },
                {
                  value: to_go && to_go != 0 ? NumberUtil.formatMoney(to_go, '€', 2, '.') : '',
                  style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
                {
                  value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '',
                  style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
                {
                  value: margin_perc && margin_perc != 0 ? NumberUtil.formatMoney(margin_perc, '%', 1, '.') : '',
                  style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
                {
                  value: rank && rank != 0 ? rank.toString() : '',
                  style: ObjectUtil.merge(SalesByCustomerReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                },
              ],
            });
            if (balance > 0) this.dataPoints.push({ value: balance, name: name });
          }

          let margin_perc_total: number = sell_total != 0 ? 100 * (1 - cost_total / sell_total) : 0;
          // let margin_perc_total: number = balance_total != 0 ? 100*margin_total/balance_total : 0;
          this.headerRows.push({
            cells: [
              {
                value: balance_total && balance_total != 0 ? NumberUtil.formatMoney(balance_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              // { value: paid_total && paid_total != 0 ? NumberUtil.formatMoney(paid_total, '€', 2, '.') : '', style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) },
              // { value: to_claim_total && to_claim_total != 0 ? NumberUtil.formatMoney(to_claim_total, '€', 2, '.') : '', style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle) },
              {
                value: to_go_total && to_go_total != 0 ? NumberUtil.formatMoney(to_go_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: margin_total && margin_total != 0 ? NumberUtil.formatMoney(margin_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value:
                  margin_perc_total && margin_perc_total != 0
                    ? NumberUtil.formatMoney(margin_perc_total, '%', 1, '.')
                    : '',
                style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
              {
                value: '',
                style: ObjectUtil.merge(SalesByCustomerReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              },
            ],
          });

          this.charts = [];

          this.charts.push({
            type: am4charts.XYChart,
            language: {
              locale: {
                _decimalSeparator: ',',
                _thousandSeparator: '.',
              },
            },
            inline: true,
            titles: [{ text: 'Ventes par client', fontSize: 25, marginBottom: 30 }],
            series: [
              {
                type: 'ColumnSeries',
                dataFields: { valueX: 'value', categoryY: 'name' },
                bullets: [
                  {
                    type: 'LabelBullet',
                    label: {
                      text: '{value.formatNumber("#,###.00")}',
                      truncate: false,
                      hideOversized: false,
                      horizontalCenter: 'left',
                      dx: 10,
                    },
                  },
                ],
              },
            ],
            maskBullets: false,
            data: this.dataPoints,
            xAxes: [{ type: 'ValueAxis', dataFields: { value: 'value', category: 'name' } }],
            yAxes: [
              {
                type: 'CategoryAxis',
                dataFields: { value: 'value', category: 'name' },
                renderer: {
                  minGridDistance: 10,
                  // minLabelPosition: -0.1,
                  // maxLabelPosition: 1.1
                },
                // startLocation: -0.5,
                // endLocation: 1.5
              },
            ],
            container_width: 800,
            container_height: 250 + 25 * this.dataPoints.length,
            events: {
              beforedatavalidated: function (ev) {
                console.log('beforedatavalidated event:', ev);
                ev.target.data.sort((a, b) => {
                  return a.value - b.value;
                });
              },
            },
          });

          resolve(true);
        },
        (err) => {
          console.error(err);
          reject(err);
        }
      );
    });
  }
}
