import * as am4charts from '@amcharts/amcharts4/charts';

import { Accounting } from '../../other-data/classes/Accounting.class';
import { ApiService } from 'src/app/services/api/api.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';

export class MonthlyChargesSplitReportModel extends ReportModel {
  public title: string = 'REPARTITION DES CHARGES';
  public settings: ReportSetting[] = [
    { name: 'accounting', title: 'Comptabilité', type: 'list', value: null, listItems: [], listField: 'name' },
    { name: 'year_start', title: 'Année', type: 'number', value: new Date().getFullYear() },
    // { name: 'year_end', title: 'Année de fin', type: 'number', value: (new Date()).getFullYear() }
  ];

  public accountingsData: any = { items: [] };

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];
  public tri_points: any[] = [];

  public static titleOrTotal_CellStyle: any = {
    'background-color': 'rgb(73,69,41)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static subTotal_CellStyle: any = {
    'background-color': 'rgb(148,138,84)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(196, 189, 151)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MonthlyChargesSplitReportModel = new MonthlyChargesSplitReportModel();
    if (settings) model.settings = settings;
    model.regenerate();
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      let promises: any[] = [];
      if (!this.accountingsData.items || !this.accountingsData.items.length)
        promises.push(
          Accounting.load(null).then((result: Accounting[]) => {
            this.accountingsData = result;
            let setting = this.getSetting('accounting');
            if (setting) {
              setting.listItems = result;
              if (result.length > 0 && !setting.value) {
                setting.value = result[0];
                // this.regenerate();
              }
            }
          })
        );

      Promise.all(promises).then((result) => {
        this.headerRows = [
          {
            cells: [
              {
                value: 'REPARTITION DES CHARGES : MENSUELLE ET TRIMESTRIELLE',
                colSpan: 4,
                style: MonthlyChargesSplitReportModel.titleOrTotal_CellStyle,
              },
            ],
          },
        ];

        let setting = this.getSetting('accounting');
        ApiService.callModule('reports', 'report', {
          query: 'monthly_charges',
          settings: {
            year_start: this.getSetting('year_start').value,
            year_end: this.getSetting('year_start').value,
            accounting: setting && setting.value ? setting.value.id : '',
          },
        }).then(
          (result2: any) => {
            console.log('MONTHLY CHARGES RESULT:', result2);
            // (results: any[]) => {
            let amount_month: number = 0;
            let amount_year: number = 0;
            let amounts: number[] = [];
            let vat_month: number = 0;
            let vat_year: number = 0;
            let vats: number[] = [];
            let total_month: number = 0;
            let total_year: number = 0;
            let totals: number[] = [];
            let month_count: number = 0;
            for (let i = 0; i < result2.details.length; ++i) {
              let month_str: any = result2.details[i]['month'];
              let month: number = parseFloat(month_str);
              let amount_str: any = result2.details[i]['amount'];
              let amount: number = parseFloat(amount_str);
              let vat_str: any = result2.details[i]['vat'];
              let vat: number = parseFloat(vat_str);
              let total_str: any = result2.details[i]['total'];
              let total: number = parseFloat(total_str);
              if (month >= 1) {
                if (amount && amount != 0) {
                  if (!amounts[month - 1]) amounts[month - 1] = 0;
                  amounts[month - 1] += amount;
                }
                if (vat && vat != 0) {
                  if (!vats[month - 1]) vats[month - 1] = 0;
                  vats[month - 1] += vat;
                }
                if (total && total != 0) {
                  if (!totals[month - 1]) totals[month - 1] = 0;
                  totals[month - 1] += total;
                }
              }
            }

            this.rows = [
              {
                cells: [
                  {
                    value: this.getSetting('year_start').value,
                    style: MonthlyChargesSplitReportModel.subTotal_CellStyle,
                  },
                  { value: 'H.T.V.A.', style: MonthlyChargesSplitReportModel.month_CellStyle },
                  { value: 'T.V.A.', style: MonthlyChargesSplitReportModel.month_CellStyle },
                  { value: 'T.V.A.C.', style: MonthlyChargesSplitReportModel.month_CellStyle },
                ],
              },
            ];

            for (let i = 0; i < 12; ++i) {
              try {
                let amount: number = amounts[i] || 0;
                let vat: number = vats[i] || 0;
                let total: number = totals[i] || 0;
                this.rows.push({
                  cells: [
                    {
                      value: [
                        'Janvier',
                        'Février',
                        'Mars',
                        'Avril',
                        'Mai',
                        'Juin',
                        'Juillet',
                        'Aout',
                        'Septembre',
                        'Octobre',
                        'Novembre',
                        'Décembre',
                      ][i],
                      style: MonthlyChargesSplitReportModel.month_CellStyle,
                    },
                    {
                      value: amount && amount != 0 ? NumberUtil.formatMoney(amount, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyChargesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                    },
                    {
                      value: vat && vat != 0 ? NumberUtil.formatMoney(vat, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyChargesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                    },
                    {
                      value: total && total != 0 ? NumberUtil.formatMoney(total, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MonthlyChargesSplitReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                    },
                  ],
                });
                this.points.push({ name: month_names[i], month: i });
                this.points[i].amount = amount;
                this.points[i].vat = vat;
                this.points[i].total = total;

                if (amount != 0) ++month_count;
                amount_month += amount;
                vat_month += vat;
                total_month += total;
                amount_year += amount;
                vat_year += vat;
                total_year += total;
                if ((i + 1) % 3 == 0) {
                  let j: number = Math.floor(i / 3);
                  this.rows.push({
                    cells: [
                      {
                        value: ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'][
                          Math.floor(i / 3)
                        ],
                        style: MonthlyChargesSplitReportModel.subTotal_CellStyle,
                      },
                      {
                        value:
                          amount_month && amount_month != 0 ? NumberUtil.formatMoney(amount_month, '€', 2, '.') : '',
                        style: ObjectUtil.merge(
                          MonthlyChargesSplitReportModel.subTotal_CellStyle,
                          ReportCell.moneyCellStyle
                        ),
                      },
                      {
                        value: vat_month && vat_month != 0 ? NumberUtil.formatMoney(vat_month, '€', 2, '.') : '',
                        style: ObjectUtil.merge(
                          MonthlyChargesSplitReportModel.subTotal_CellStyle,
                          ReportCell.moneyCellStyle
                        ),
                      },
                      {
                        value: total_month && amount_month != 0 ? NumberUtil.formatMoney(total_month, '€', 2, '.') : '',
                        style: ObjectUtil.merge(
                          MonthlyChargesSplitReportModel.subTotal_CellStyle,
                          ReportCell.moneyCellStyle
                        ),
                      },
                    ],
                  });
                  this.tri_points.push({ name: tri_month_names[j], month: j });
                  this.tri_points[j].amount = amount_month;
                  this.tri_points[j].vat = vat_month;
                  this.tri_points[j].total = total_month;
                  amount_month = 0;
                  vat_month = 0;
                  total_month = 0;
                }
              } catch {
                continue;
              }
            }

            this.rows.push({
              cells: [
                { value: 'TOTAUX', style: MonthlyChargesSplitReportModel.titleOrTotal_CellStyle },
                {
                  value: amount_year && amount_year != 0 ? NumberUtil.formatMoney(amount_year, '€', 2, '.') : '',
                  style: ObjectUtil.merge(
                    MonthlyChargesSplitReportModel.titleOrTotal_CellStyle,
                    ReportCell.moneyCellStyle
                  ),
                },
                {
                  value: vat_year && vat_year != 0 ? NumberUtil.formatMoney(vat_year, '€', 2, '.') : '',
                  style: ObjectUtil.merge(
                    MonthlyChargesSplitReportModel.titleOrTotal_CellStyle,
                    ReportCell.moneyCellStyle
                  ),
                },
                {
                  value: total_year && total_year != 0 ? NumberUtil.formatMoney(total_year, '€', 2, '.') : '',
                  style: ObjectUtil.merge(
                    MonthlyChargesSplitReportModel.titleOrTotal_CellStyle,
                    ReportCell.moneyCellStyle
                  ),
                },
              ],
            });

            let amount_avg: number = month_count > 0 ? amount_year / month_count : 0;
            let vat_avg: number = month_count > 0 ? vat_year / month_count : 0;
            let total_avg: number = month_count > 0 ? total_year / month_count : 0;
            this.rows.push({
              cells: [
                { value: 'Moy. mens.', style: MonthlyChargesSplitReportModel.subTotal_CellStyle },
                {
                  value: amount_avg && amount_avg != 0 ? NumberUtil.formatMoney(amount_avg, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MonthlyChargesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                },
                {
                  value: vat_avg && vat_avg != 0 ? NumberUtil.formatMoney(vat_avg, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MonthlyChargesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                },
                {
                  value: total_avg && total_avg != 0 ? NumberUtil.formatMoney(total_avg, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MonthlyChargesSplitReportModel.subTotal_CellStyle, ReportCell.moneyCellStyle),
                },
              ],
            });

            this.createEmptyCells();

            this.charts = [];

            this.charts.push({
              type: am4charts.XYChart,
              inline: false,
              titles: [{ text: 'Répartition mensuelle des charges', fontSize: 25, marginBottom: 30 }],
              colors: StatsModule.chartsColor,
              series: [
                {
                  type: 'ColumnSeries',
                  name: 'HTVA',
                  dataFields: { valueY: 'amount', categoryX: 'name' },
                  yAxis: 'numbers',
                  stacked: true,
                },
                {
                  type: 'ColumnSeries',
                  name: 'TVA',
                  dataFields: { valueY: 'vat', categoryX: 'name' },
                  yAxis: 'numbers',
                  stacked: true,
                },
                // { type: 'LineSeries', name: 'TVAC', dataFields: { valueY: 'total', categoryX: 'name' },
                //     bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }], yAxis: 'numbers' }
              ],
              // maskBullets: false,
              data: this.points,
              yAxes: [
                {
                  type: 'ValueAxis',
                  id: 'numbers',
                  dataFields: { value: 'value', category: 'name' },
                  title: { text: 'Montants' },
                },
              ],
              xAxes: [{ type: 'CategoryAxis', dataFields: { value: 'value', category: 'name' } }],
              legend: {
                numberFormatter: {
                  numberFormat: '#.',
                },
              },
              container_width: 1000,
              container_height: 480,
              events: {
                beforedatavalidated: function (ev) {
                  console.log('beforedatavalidated event:', ev);
                  ev.target.data.sort((a, b) => {
                    return a.month - b.month;
                  });
                },
              },
            });

            this.charts.push({
              type: am4charts.XYChart,
              inline: false,
              colors: StatsModule.chartsColor,
              titles: [{ text: 'Répartition trimestrielle des charges', fontSize: 25, marginBottom: 30 }],
              series: [
                {
                  type: 'ColumnSeries',
                  name: 'HTVA',
                  dataFields: { valueY: 'amount', categoryX: 'name' },
                  yAxis: 'numbers',
                  stacked: true,
                },
                {
                  type: 'ColumnSeries',
                  name: 'TVA',
                  dataFields: { valueY: 'vat', categoryX: 'name' },
                  yAxis: 'numbers',
                  stacked: true,
                },
                // { type: 'LineSeries', name: 'TVAC', dataFields: { valueY: 'total', categoryX: 'name' },
                //     bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }], yAxis: 'numbers' }
              ],
              // maskBullets: false,
              data: this.tri_points,
              yAxes: [
                {
                  type: 'ValueAxis',
                  id: 'numbers',
                  dataFields: { value: 'value', category: 'name' },
                  title: { text: 'Montants' },
                },
              ],
              xAxes: [{ type: 'CategoryAxis', dataFields: { value: 'value', category: 'name' } }],
              legend: {
                numberFormatter: {
                  numberFormat: '#.',
                },
              },
              container_width: 1000,
              container_height: 480,
              events: {
                beforedatavalidated: function (ev) {
                  console.log('beforedatavalidated event:', ev);
                  ev.target.data.sort((a, b) => {
                    return a.month - b.month;
                  });
                },
              },
            });

            resolve(true);
          },
          (err) => {
            console.error(err);
            reject(err);
          }
        );
      });
    });
  }
}
