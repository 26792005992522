import { Component, OnInit, Input, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { PrintableElement } from '../../classes/PrintableElement.class';
import { BorderStyle } from '../../classes/BorderStyle.class';
import { PrintablePageComponent } from '../printable-page/printable-page.component';
import { TextUtil } from '../../../utils/classes/TextUtil.class';
import * as $ from 'jquery';

@Component({
  selector: 'printable-element',
  templateUrl: './printable-element.component.html',
  styleUrls: ['./printable-element.component.css']
})
export class PrintableElementComponent implements OnInit {

    @Input() element: PrintableElement = null;
    @ViewChildren('childElement') childrenElements: QueryList<PrintableElementComponent>;

    constructor(public elementRef: ElementRef) { }

    ngOnInit() {
    }

    public borderStyle(border: BorderStyle)
    {
        let arr = [];
        if (border.style) arr.push(border.style);
        if (border.width) arr.push(border.width);
        if (border.color) arr.push(border.color);
        return arr.join(' ');
    }

    public static getModel(settings: any, elemComponent: PrintableElementComponent, pageComponent: PrintablePageComponent, parentModel: any = null)
    {
        let model = {
            style: elemComponent.element.style,
            html: elemComponent.element.html,
            image: elemComponent.element.image,
            infos: {},
            elements: []
        };

        let pageElem = pageComponent.elementRef.nativeElement;
        let elem = elemComponent.elementRef.nativeElement;

        if (model.html) model.html = $(elem).text();

        let pageOffset = $(pageElem).offset();
        let xRatio = pageComponent.page.width / $(pageElem).width();
        let yRatio = pageComponent.page.height / $(pageElem).height();
        let elemOffset = $(elem).offset();

        /*let _top = TextUtil.isMmSize(elem.style.top) ? parseFloat(TextUtil.left(elem.style.top, -2)) : null;
        let _left = TextUtil.isMmSize(elem.style.left) ? parseFloat(TextUtil.left(elem.style.left, -2)) : null;
        let _right = TextUtil.isMmSize(elem.style.right) ? parseFloat(TextUtil.left(elem.style.right, -2)) : null;
        let _bottom = TextUtil.isMmSize(elem.style.bottom) ? parseFloat(TextUtil.left(elem.style.bottom, -2)) : null;
        let _width = TextUtil.isMmSize(elem.style.width) ? parseFloat(TextUtil.left(elem.style.width, -2)) : null;
        let _height = TextUtil.isMmSize(elem.style.height) ? parseFloat(TextUtil.left(elem.style.height, -2)) : null;

        if (parentModel) {
            if (_top && parentModel.infos.top) _top += parentModel.infos.top * 10;
            if (_left && parentModel.infos.left) _left += parentModel.infos.left * 10;
            if (_right && parentModel.infos.right) _right = parentModel.infos.right * 10 - _right;
            if (_bottom && parentModel.infos.bottom) _bottom = parentModel.infos.bottom * 10 - _bottom;
        }*/

/*        if (!_width) {
            if (_left != null && _right != null) _width = _right - _left;
            else _width = $(elem).width() / settings.mm_width;
        }
        if (!_height) {
            if (_top != null && _bottom != null) _height = _top - _top;
            else _height = $(elem).height() / settings.mm_height;
        }
        if (!_left) {
            if (_width != null && _right != null) _left = _right - _width;
            else _left = (elemOffset.left - pageOffset.left) / settings.mm_width;
        }
        if (!_top) {
            if (_height != null && _bottom != null) _top = _bottom - _height;
            else _top = (elemOffset.top - pageOffset.top) / settings.mm_height;
        }*/

        let _top = (elemOffset.top - pageOffset.top) * yRatio;
        let _left = (elemOffset.left - pageOffset.left) * xRatio;
        let _width = $(elem).width() * xRatio;
        let _height = $(elem).height() * yRatio;

        model.infos['top'] = _top;
        model.infos['left'] = _left;
        model.infos['width'] = _width;
        model.infos['height'] = _height;

        let _outer_width = $(elem).outerWidth(false) * xRatio;
        let _outer_height = $(elem).outerHeight(false) * yRatio;
        let _outer_width_with_margin = $(elem).outerWidth(true) * xRatio;
        let _outer_height_with_margin = $(elem).outerHeight(true) * yRatio;

        model.infos['outer_width'] = _outer_width;
        model.infos['outer_height'] = _outer_height;
        model.infos['outer_width_with_margin'] = _outer_width_with_margin;
        model.infos['outer_height_with_margin'] = _outer_height_with_margin;

        let _margin_top = TextUtil.isMmSize(elem.style.marginTop) ? parseFloat(TextUtil.left(elem.style.marginTop, -2)) : null;
        if (!_margin_top) _margin_top = parseFloat($(elem).css('margin-top')) / settings.mm_height;
        model.infos['margin-top'] = _margin_top;
        let _margin_left = TextUtil.isMmSize(elem.style.marginLeft) ? parseFloat(TextUtil.left(elem.style.marginLeft, -2)) : null;
        if (!_margin_left) _margin_left = parseFloat($(elem).css('margin-left')) / settings.mm_height;
        model.infos['margin-left'] = _margin_left;
        let _margin_right = TextUtil.isMmSize(elem.style.marginRight) ? parseFloat(TextUtil.left(elem.style.marginRight, -2)) : null;
        if (!_margin_right) _margin_right = parseFloat($(elem).css('margin-right')) / settings.mm_height;
        model.infos['margin-right'] = _margin_right;
        let _margin_bottom = TextUtil.isMmSize(elem.style.marginBottom) ? parseFloat(TextUtil.left(elem.style.marginBottom, -2)) : null;
        if (!_margin_bottom) _margin_bottom = parseFloat($(elem).css('margin-bottom')) / settings.mm_height;
        model.infos['margin-bottom'] = _margin_bottom;

        let _padding_top = TextUtil.isMmSize(elem.style.paddingTop) ? parseFloat(TextUtil.left(elem.style.paddingTop, -2)) : null;
        if (!_padding_top) _padding_top = parseFloat($(elem).css('padding-top')) / settings.mm_height;
        model.infos['padding-top'] = _padding_top;
        let _padding_left = TextUtil.isMmSize(elem.style.paddingLeft) ? parseFloat(TextUtil.left(elem.style.paddingLeft, -2)) : null;
        if (!_padding_left) _padding_left = parseFloat($(elem).css('padding-left')) / settings.mm_height;
        model.infos['padding-left'] = _padding_left;
        let _padding_right = TextUtil.isMmSize(elem.style.paddingRight) ? parseFloat(TextUtil.left(elem.style.paddingRight, -2)) : null;
        if (!_padding_right) _padding_right = parseFloat($(elem).css('padding-right')) / settings.mm_height;
        model.infos['padding-right'] = _padding_right;
        let _padding_bottom = TextUtil.isMmSize(elem.style.paddingBottom) ? parseFloat(TextUtil.left(elem.style.paddingBottom, -2)) : null;
        if (!_padding_bottom) _padding_bottom = parseFloat($(elem).css('padding-bottom')) / settings.mm_height;
        model.infos['padding-bottom'] = _padding_bottom;

        let _line_height = TextUtil.isMmSize(elem.style.lineHeight) ? parseFloat(TextUtil.left(elem.style.lineHeight, -2)) : null;
        if (!_line_height) _line_height = parseFloat($(elem).css('line-height')) / settings.mm_height;
        //let _line_height = parseFloat($(elem).css('line-height')) / settings.mm_height;
        if (!_line_height && parentModel && parentModel.infos['line-height']) _line_height = parentModel.infos['line-height'];
        model.infos['line-height'] = _line_height;
        let _border_radius = TextUtil.isMmSize(elem.style.borderRadius) ? parseFloat(TextUtil.left(elem.style.borderRadius, -2)) : null;
        if (!_border_radius) _border_radius = parseFloat($(elem).css('border-radius')) / settings.mm_height;
        model.infos['border-radius'] = _border_radius;

        let _font_size = TextUtil.isPtSize(elem.style.fontSize) ? parseFloat(TextUtil.left(elem.style.fontSize, -2)) : null;
        if (!_font_size && parentModel && parentModel.infos['font-size']) _font_size = parentModel.infos['font-size'];
        else if (!_font_size) _font_size = 10;
        model.infos['font-size'] = _font_size;

        let _font_family = elem.style.fontFamily;
        if (!_font_family || _font_family == '') _font_family = parentModel.infos['font-family'] ? parentModel.infos['font-family'] : 'Arial';
        model.infos['font-family'] = _font_family;

        let _text_color = elem.style.color;
        if (!_text_color || _text_color == '') _text_color = parentModel.infos['color'] ? parentModel.infos['color'] : 'rgba(0,0,0,1)';
        model.infos['color'] = _text_color;
        let _back_color = elem.style.backgroundColor;
        if (!_back_color || _back_color == '') _back_color = parentModel.infos['backround-color'] ? parentModel.infos['background-color'] : 'none';
        model.infos['background-color'] = _back_color;

        let _font_weight = elem.style.fontWeight;
        if (!_font_weight || _font_weight == '') _font_weight = parentModel.infos['font-weight'] ? parentModel.infos['font-weight'] : 'inherit';
        model.infos['font-weight'] = _font_weight;
        let _font_style = elem.style.fontStyle;
        if (!_font_style || _font_style == '') _font_style = parentModel.infos['font-style'] ? parentModel.infos['font-style'] : 'inherit';
        model.infos['font-style'] = _font_style;
        let _text_decoration = elem.style.textDecoration;
        if (!_text_decoration || _text_decoration == '') _text_decoration = parentModel.infos['text-decoration'] ? parentModel.infos['text-decoration'] : 'inherit';
        model.infos['text-decoration'] = _text_decoration;

        let _text_align = elem.style.textAlign;
        if (!_text_align || _text_align == '') _text_align = parentModel.infos['text-align'] ? parentModel.infos['text-align'] : 'inherit';
        model.infos['text-align'] = _text_align;

        if (model.image) {
            model.infos['image_width'] = $('img', elem).outerWidth(false) * xRatio;
            model.infos['image_height'] = $('img', elem).outerHeight(false) * yRatio;
        }

        let children = elemComponent.childrenElements.toArray();
        for(let i=0; i<children.length; ++i) model.elements.push(PrintableElementComponent.getModel(settings, children[i], pageComponent, model));

        return model;
    }

}
