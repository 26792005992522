import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from 'src/app/services/api/api.service';
import { CredentialsService } from '../../../services/credentials/credentials.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';

export class MerchantResultsReportModel extends ReportModel {
  public title: string = 'COMMISSIONS DES REPRÉSENTANTS';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année', type: 'number', value: new Date().getFullYear() },
    {
      name: 'merchant',
      title: 'Représentant',
      type: 'list',
      value: null,
      listItems: [],
      listField: 'numberAndName',
    },
  ];

  public merchantsData: any = { items: [] };

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static merchantNameCellStyle: any = {
    'background-color': 'rgb(0,0,0)',
    text: 'rgb(0,176,240)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(0,176,240)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static headerTotalCellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(220, 230, 241)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MerchantResultsReportModel = new MerchantResultsReportModel();
    if (settings) model.settings = settings;
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      let promises: any[] = [];
      if (!this.merchantsData.items.length)
        promises.push(
          Merchant.load(null, ['number']).then((result: Merchant[]) => {
            const filteredList = CredentialsService.isUserAllowed('stats_commissionsrepresentants_voirtoutlemonde')
              ? result
              : result.filter((m) => m.user && m.user.id === CredentialsService.loggedUser.id);
            this.getSetting('merchant').listItems = filteredList;
            if (filteredList.length > 0 && !this.getSetting('merchant').value) {
              this.getSetting('merchant').value = filteredList[0];
            }
          })
        );

      Promise.all(promises).then(
        (result) => {
          this.headerRows = [
            {
              cells: [
                {
                  value: 'COMMISSIONS DES REPRÉSENTANTS',
                  colSpan: 14,
                  style: MerchantResultsReportModel.titleCellStyle,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.getSetting('merchant').value.numberAndName,
                  style: MerchantResultsReportModel.merchantNameCellStyle,
                },
                { value: 'Janvier', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Février', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Mars', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Avril', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Mai', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Juin', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Juillet', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Aout', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Septembre', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Octobre', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Novembre', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'Décembre', style: MerchantResultsReportModel.month_CellStyle },
                { value: 'TOTAUX', style: MerchantResultsReportModel.bodyTotal_CellStyle },
              ],
            },
          ];

          this.rows = [];
          this.rows.push(
            {
              cells: [{ value: "Prix d'achat", style: MerchantResultsReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Frais', style: MerchantResultsReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Prix de revient', style: MerchantResultsReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Prix de vente', style: MerchantResultsReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Marge nette', style: MerchantResultsReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Nombre de factures payées', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
            },
            {
              cells: [{ value: 'Total commissions', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
            }
          );

          if (CredentialsService.isUserAllowed('stats_commissionsrepresentants_voirtotaux'))
            this.rows.push(
              {
                cells: [{ value: 'Coûts fixes', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
              },
              {
                cells: [{ value: 'Charges à imputer', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
              },
              {
                cells: [{ value: 'Notes de crédit à imputer', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
              },
              {
                cells: [{ value: 'Commissions à percevoir', style: MerchantResultsReportModel.bodyTotal_CellStyle }],
              }
            );

          ApiService.callModule('reports', 'report', {
            query: 'merchant_result',
            settings: {
              year_start: this.getSetting('year_start').value,
              year_end: this.getSetting('year_start').value,
              id_merchant: this.getSetting('merchant').value.id,
            },
          }).then(
            (result2: any) => {
              // console.log('results:', results);
              let amount_month: number = 0;
              let total_month: number = 0;
              let charges_month: number = 0;
              let amount_year: number = 0;
              let charges_year: number = 0;
              let buy_arr: number[] = [];
              let ifees_arr: number[] = [];
              let efees_arr: number[] = [];
              let mfees_arr: number[] = [];
              let cost_arr: number[] = [];
              let sell_arr: number[] = [];
              let margin_arr: number[] = [];
              let count_arr: number[] = [];
              let charges_arr: number[] = [];
              let amounts_with_tax: number[] = [];
              let month_count: number = 0;
              // for(let j=0; j<results.length; ++j)
              // {
              // let result2: any = results[j];
              for (let i = 0; i < result2.details.length; ++i) {
                let month_str: any = result2.details[i]['month'];
                let month: number = parseFloat(month_str);
                let buy_str: any = result2.details[i]['buy_price'];
                let buy: number = parseFloat(buy_str);
                let ifees_str: any = result2.details[i]['internal_fees'];
                let ifees: number = parseFloat(ifees_str);
                let efees_str: any = result2.details[i]['external_fees'];
                let efees: number = parseFloat(efees_str);
                let mfees_str: any = result2.details[i]['merchant_fees'];
                let mfees: number = parseFloat(mfees_str);
                let cost_str: any = result2.details[i]['cost_price'];
                let cost: number = parseFloat(cost_str);
                let sell_str: any = result2.details[i]['sell_price'];
                let sell: number = parseFloat(sell_str);
                let margin_str: any = result2.details[i]['net_margin'];
                let margin: number = parseFloat(margin_str);
                let count_str: any = result2.details[i]['count'];
                let count: number = parseFloat(count_str);
                let charge_str: any = result2.details[i]['charge'];
                let charge: number = parseFloat(charge_str);
                if (month >= 1) {
                  if (buy && buy != 0) {
                    if (!buy_arr[month - 1]) buy_arr[month - 1] = 0;
                    buy_arr[month - 1] += buy;
                  }
                  if (ifees && ifees != 0) {
                    if (!ifees_arr[month - 1]) ifees_arr[month - 1] = 0;
                    ifees_arr[month - 1] += ifees;
                  }
                  if (efees && efees != 0) {
                    if (!efees_arr[month - 1]) efees_arr[month - 1] = 0;
                    efees_arr[month - 1] += efees;
                  }
                  if (mfees && mfees != 0) {
                    if (!mfees_arr[month - 1]) mfees_arr[month - 1] = 0;
                    mfees_arr[month - 1] += mfees;
                  }
                  if (cost && cost != 0) {
                    if (!cost_arr[month - 1]) cost_arr[month - 1] = 0;
                    cost_arr[month - 1] += cost;
                  }
                  if (sell && sell != 0) {
                    if (!sell_arr[month - 1]) sell_arr[month - 1] = 0;
                    sell_arr[month - 1] += sell;
                  }
                  if (margin && margin != 0) {
                    if (!margin_arr[month - 1]) margin_arr[month - 1] = 0;
                    margin_arr[month - 1] += margin;
                  }
                  if (count && count != 0) {
                    if (!count_arr[month - 1]) count_arr[month - 1] = 0;
                    count_arr[month - 1] += count;
                  }
                  if (charge && charge != 0) {
                    if (!charges_arr[month - 1]) charges_arr[month - 1] = 0;
                    charges_arr[month - 1] += charge;
                  }
                }
              }
              // }

              let buy_total: number = 0;
              let ifees_total: number = 0;
              let efees_total: number = 0;
              let mfees_total: number = 0;
              let cost_total: number = 0;
              let sell_total: number = 0;
              let margin_total: number = 0;
              let count_total: number = 0;
              let charges_total: number = 0;
              let minimum_total: number = 0;
              let gain_total: number = 0;
              let result_total: number = 0;
              for (let i = 0; i < 12; ++i) {
                try {
                  const _merchant: Merchant = this.getSetting('merchant').value as Merchant;
                  let buy: number = buy_arr[i] || 0;
                  let ifees: number = ifees_arr[i] || 0;
                  let efees: number = efees_arr[i] || 0;
                  let mfees: number = mfees_arr[i] || 0;
                  let cost: number = cost_arr[i] || 0;
                  let sell: number = sell_arr[i] || 0;
                  let margin: number = margin_arr[i] || 0;
                  let count: number = count_arr[i] || 0;
                  let charge: number = charges_arr[i] || 0;
                  let minimum: number = buy > 0 ? _merchant.base || 0 : 0;

                  let gain: number = 0;
                  if (_merchant.is_employee) {
                    const margin_overflow = margin_total + margin - (_merchant.start_margin || 0);
                    gain = Math.max(0, Math.min(margin, margin_overflow));
                  } else gain = margin;
                  gain *= (_merchant.margin || 0) / 100;

                  let result: number = gain - charge - minimum - mfees;
                  let r: number = 0;
                  this.rows[r++].cells.push({
                    value: buy && buy != 0 ? NumberUtil.formatMoney(buy, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: ifees + efees != 0 ? NumberUtil.formatMoney(ifees + efees, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: cost && cost != 0 ? NumberUtil.formatMoney(cost, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: sell && sell != 0 ? NumberUtil.formatMoney(sell, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: count && count != 0 ? NumberUtil.formatNumber(count, 0, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[r++].cells.push({
                    value: gain && gain != 0 ? NumberUtil.formatMoney(gain, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                  });
                  if (CredentialsService.isUserAllowed('stats_commissionsrepresentants_voirtotaux')) {
                    this.rows[r++].cells.push({
                      value: minimum && minimum != 0 ? NumberUtil.formatMoney(minimum, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                    });
                    this.rows[r++].cells.push({
                      value: charge && charge != 0 ? NumberUtil.formatMoney(charge, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MerchantResultsReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                    });
                    this.rows[r++].cells.push({
                      value: mfees && mfees != 0 ? NumberUtil.formatMoney(mfees, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                    });
                    this.rows[r++].cells.push({
                      value: result && result != 0 ? NumberUtil.formatMoney(result, '€', 2, '.') : '',
                      style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                    });
                  }
                  this.points.push({ name: month_names[i], month: i });
                  this.points[i].buy = buy;
                  this.points[i].ifees = ifees + efees;
                  // this.points[i].efees = efees;
                  this.points[i].mfees = mfees;
                  this.points[i].cost = cost;
                  this.points[i].sell = sell;
                  this.points[i].margin = margin;
                  this.points[i].count = count;
                  this.points[i].charges = count;
                  this.points[i].gain = count;
                  buy_total += buy;
                  ifees_total += ifees;
                  efees_total += efees;
                  mfees_total += mfees;
                  cost_total += cost;
                  sell_total += sell;
                  margin_total += margin;
                  count_total += count;
                  charges_total += charge;
                  minimum_total += minimum;
                  gain_total += gain;
                  result_total += result;
                } catch (error) {
                  console.error('MerchantResultsReportModel:', error);
                  continue;
                }
              }

              let r: number = 0;
              this.rows[r++].cells.push({
                value: buy_total && buy_total != 0 ? NumberUtil.formatMoney(buy_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value:
                  ifees_total + efees_total != 0 ? NumberUtil.formatMoney(ifees_total + efees_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value: cost_total && cost_total != 0 ? NumberUtil.formatMoney(cost_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value: sell_total && sell_total != 0 ? NumberUtil.formatMoney(sell_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value: margin_total && margin_total != 0 ? NumberUtil.formatMoney(margin_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value: count_total && count_total != 0 ? NumberUtil.formatNumber(count_total, 0, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[r++].cells.push({
                value: gain_total && gain_total != 0 ? NumberUtil.formatMoney(gain_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              });
              if (CredentialsService.isUserAllowed('stats_commissionsrepresentants_voirtotaux')) {
                this.rows[r++].cells.push({
                  value: minimum_total && minimum_total != 0 ? NumberUtil.formatMoney(minimum_total, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[r++].cells.push({
                  value: charges_total && charges_total != 0 ? NumberUtil.formatMoney(charges_total, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[r++].cells.push({
                  value: mfees_total && mfees_total != 0 ? NumberUtil.formatMoney(mfees_total, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
                });
                this.rows[r++].cells.push({
                  value: result_total && result_total != 0 ? NumberUtil.formatMoney(result_total, '€', 2, '.') : '',
                  style: ObjectUtil.merge(MerchantResultsReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
                });
              }

              this.createEmptyCells();

              this.charts = [];

              this.charts.push({
                type: am4charts.XYChart,
                inline: false,
                colors: StatsModule.chartsColor,
                titles: [
                  {
                    text: 'Détail mensuel des commandes (' + this.getSetting('merchant').value.name + ')',
                    fontSize: 25,
                    marginBottom: 30,
                  },
                ],
                series: [
                  {
                    type: 'ColumnSeries',
                    name: "Prix d'achat",
                    dataFields: { valueY: 'buy', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Frais',
                    dataFields: { valueY: 'ifees', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  // { type: 'ColumnSeries', name: 'Frais ext. / GS', dataFields: { valueY: 'efees', categoryX: 'name' },
                  //     yAxis: 'amounts' },
                  {
                    type: 'ColumnSeries',
                    name: 'Prix de revient',
                    dataFields: { valueY: 'cost', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Prix de vente',
                    dataFields: { valueY: 'sell', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Marge nette',
                    dataFields: { valueY: 'margin', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  ...(CredentialsService.isUserAllowed('stats_commissionsrepresentants_voirtotaux')
                    ? [
                        {
                          type: 'ColumnSeries',
                          name: 'Commissions',
                          dataFields: { valueY: 'gain', categoryX: 'name' },
                          yAxis: 'amounts',
                        },
                        {
                          type: 'ColumnSeries',
                          name: 'Charges à imputer',
                          dataFields: { valueY: 'charges', categoryX: 'name' },
                          yAxis: 'amounts',
                        },
                        {
                          type: 'ColumnSeries',
                          name: 'NC à imputer',
                          dataFields: { valueY: 'mfees', categoryX: 'name' },
                          yAxis: 'amounts',
                        },
                      ]
                    : []),
                  {
                    type: 'LineSeries',
                    name: 'Nombre de factures',
                    dataFields: { valueY: 'count', categoryX: 'name' },
                    bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                    yAxis: 'numbers',
                  },
                ],
                // maskBullets: false,
                data: this.points,
                yAxes: [
                  {
                    type: 'ValueAxis',
                    id: 'amounts',
                    dataFields: { value: 'value', category: 'name' },
                    title: { text: 'Montants' },
                  },
                  {
                    type: 'ValueAxis',
                    id: 'numbers',
                    dataFields: { value: 'value', category: 'name' },
                    title: { text: 'Nombre' },
                    renderer: { opposite: true },
                  },
                ],
                xAxes: [
                  {
                    type: 'CategoryAxis',
                    dataFields: { value: 'value', category: 'name' },
                    renderer: { cellStartLocation: 0.15, cellEndLocation: 0.85 },
                  },
                ],
                legend: {},
                container_width: 1200,
                container_height: 480,
                events: {
                  beforedatavalidated: function (ev) {
                    console.log('beforedatavalidated event:', ev);
                    ev.target.data.sort((a, b) => {
                      return a.month - b.month;
                    });
                  },
                },
              });

              resolve(true);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }
}
