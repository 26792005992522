<div class="incident-container">

    <div class="incident-container-columns">

        <info-block #selectBlockComponent [block]="selectBlock" [data]="instance"></info-block>
        <info-block #orderBlockComponent [block]="orderBlock" [data]="incidentCopy"></info-block>
        <info-block #incidentBlockComponent [block]="incidentBlock" [data]="incidentCopy"></info-block>
        <info-block #closedBlockComponent [block]="closedBlock" [data]="incidentCopy"></info-block>
        <info-block [block]="internalNotesBlock" [data]="incidentCopy"></info-block>

    </div>

    <div class="incident-container-columns">

        <info-block #articleBlockComponent *ngIf="(selectedOrder && selectedOrder.sale && selectedOrder.sale.article && selectedOrder.sale.article.type == 0) || !selectedOrder || !selectedOrder.sale || !selectedOrder.sale.article"
                    [block]="articleBlock" [data]="(selectedOrder && selectedOrder.sale) ? selectedOrder.sale.article : null" [readonly]="true"></info-block>
        <info-block #deviceBlockComponent *ngIf="(selectedOrder && selectedOrder.sale && selectedOrder.sale.article && selectedOrder.sale.article.type == 1)"
                    [block]="deviceBlock" [data]="selectedOrder.sale ? selectedOrder.sale.article : null" [readonly]="true"></info-block>

    </div>

</div>
