import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, ElementRef } from '@angular/core';
import { QueryList } from '@angular/core';
import { InfoBlockField } from './classes/InfoBlockField.class';
import { InfoBlock } from './classes/InfoBlock.class';
import { ObjectModel2 } from '../../classes/objects/ObjectModel2.class';
import * as $ from 'jquery';
import {InfoBlockFieldSelectOptions} from './classes/InfoBlockFieldSelectOptions.class';
import { DateTimeUtil } from '../../modules/utils/classes/DateTimeUtil.class';

@Component({
    selector: 'info-block',
    templateUrl: './info-block.component.html',
    styleUrls: ['./info-block.component.css']
})
export class InfoBlockComponent implements OnInit {

    _block: InfoBlock = null
    _data: any = null

    @Input() set block(val: InfoBlock) {
        this._block = val;
        this.objectLoaded();
    }
    get block(): InfoBlock { return this._block; }
    
    @Input() set data(val: any) {
        this._data = val;
        this.objectLoaded();
    }
    get data(): any { return this._data; }
    
    @Input() readonly: boolean = false;

    @Input() backColor: any = null;
    @Input() textColor: any = null;
    @Input() title: string = null;

    @Input() checkable: boolean = false;
    @Output() selectedChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() click: EventEmitter<any> = new EventEmitter<any>();

    @ViewChildren('blockInput') blockInput: QueryList<ElementRef>;
    @ViewChildren('inputElements') inputElements: QueryList<ElementRef>;

    constructor() { }

    ngOnInit() {
    }

    objectLoaded() {
        // console.log('data:', this.data);
        if (this.block && typeof this.block.objectLoaded === 'function') this.block.objectLoaded(this.data);
    }

    selectData()
    {
        if (this.data) {
            this.data.selected = !this.data.selected;
            this.selectedChange.next(this.data);
        }
    }


    getItemObject(item: any, field: any)
    {
        let obj = item;
        let names: string[] = field.field.split('.');
        for(let i=0; obj && i<names.length; ++i) obj = obj[names[i]];
        return obj;
    }
    getItemValue(item: any, field: any)
    {
        let obj = this.getItemObject(item, field);
        switch(field.type)
        {
            case 'foreign-list':
                if (!obj) return '';
                if (Array.isArray(obj)) {
                    let arr: string[] = [];
                    for(let i=0; i<obj.length; ++i) {
                        if (obj[i]) arr.push(obj[i][field.listField]);
                    }
                    return arr.join(', ');
                }
                else return obj[field.listField];
            case 'text':
            default:
                return obj;
        }
    }
    getDateValue(item: any, field: any) {
      const value = this.getItemValue(item, field);
      const d = value ? new Date(value) : null;
      return d ? DateTimeUtil.format(d, 'd-m-Y') : '';
    }

    setItemValue(item: any, field: string, value: any)
    {
        let index: number = field.indexOf('.');
        if (index >= 0)
        {
            let current_field: string = field.substring(0, index);
            let next_field: string = field.substring(index+1);
            let next_item: any = item[current_field];
            return this.setItemValue(next_item, next_field, value);
        }
        else item[field] = value;
    }

    callBlockFunc(funcName, block, obj, ...args)
    {
        if (block[funcName] && typeof(block[funcName]) === 'function') block[funcName](this, block, obj, ...args);
    }
    callFieldFunc(funcName, block, field, obj, ...args)
    {
        if (funcName === 'change' && obj instanceof ObjectModel2) obj.changed = true;
        if (field[funcName] && typeof(field[funcName]) === 'function') field[funcName](this, block, field, obj, ...args);
    }

    setFocus(fieldName: string)
    {
        let element: ElementRef = this.getElement(fieldName);
        if (element) $(element.nativeElement).focus();
    }

    getElement(fieldName: string): ElementRef
    {
        if (this.blockInput)
        {
            let inputs = this.blockInput.toArray();
            for(let i=0; i<inputs.length; ++i)
            {
                let item: ElementRef;
                if (inputs[i] instanceof ElementRef) item = inputs[i] as ElementRef;
                else if (inputs[i]['elementRef']) item = inputs[i]['elementRef'];
                if (item != null) {
                    if ($(item.nativeElement).attr('data-field') === fieldName) return item;
                }
            }
        }
        return null;
    }
    getInputElement(fieldName: string): ElementRef
    {
        let item: ElementRef;
        if (this.inputElements)
        {
            let inputs = this.inputElements.toArray();
            for(let i=0; i<inputs.length; ++i)
            {
                if (inputs[i] instanceof ElementRef) item = inputs[i] as ElementRef;
                if (item != null) {
                    if ($(item.nativeElement).attr('data-field') === fieldName) return item;
                }
            }
        }
        if (this.blockInput)
        {
            let inputs = this.blockInput.toArray();
            for(let i=0; i<inputs.length; ++i)
            {
                if (inputs[i] instanceof ElementRef) item = inputs[i] as ElementRef;
                else if (inputs[i]['elementRef']) item = inputs[i]['elementRef'];
                if (item != null) {
                    if ($(item.nativeElement).attr('data-field') === fieldName && inputs[i]['inputElement'])
                        return inputs[i]['inputElement'];
                }
            }
        }
        return null;
    }
    getField(fieldName: string): InfoBlockField
    {
        for(let i=0; i<this.block.fields.length; ++i)
        {
            let field: InfoBlockField = this.block.fields[i];
            if (field.field === fieldName) return field;
        }
        return null;
    }

    getForeignListText(data: any, field: InfoBlockField)
    {
        let obj: any = data[field.field];
        if (obj)
        {
            if (Array.isArray(obj)) {
                let arr: string[] = [];
                for(let i=0; i<obj.length; ++i) {
                    if (obj[i]) arr.push(obj[i][field.listField]);
                }
                return arr.join(', ');
            }
            else if (obj[field.listField]) return obj[field.listField];
        }
        return '';
    }

    focusField(event, field)
    {
        $(event.target).select();
    }
    getFocusFieldName()
    {
        let item: ElementRef;
        if (this.inputElements)
        {
            let inputs = this.inputElements.toArray();
            for(let i=0; i<inputs.length; ++i)
            {
                if (inputs[i] instanceof ElementRef) item = inputs[i] as ElementRef;
                if (item != null) {
                    let fieldName: string = $(item.nativeElement).attr('data-field');
                    if ($(item).is(':focus')) return fieldName;
                }
            }
        }
        if (this.blockInput)
        {
            let inputs = this.blockInput.toArray();
            for(let i=0; i<inputs.length; ++i)
            {
                if (inputs[i] instanceof ElementRef) item = inputs[i] as ElementRef;
                else if (inputs[i]['elementRef']) item = inputs[i]['elementRef'];
                if (item != null) {
                    let fieldName: string = $(item.nativeElement).attr('data-field');
                    if (inputs[i]['inputElement'] && inputs[i]['inputElement'].is(':focus')) return fieldName;
                }
            }
        }
        return null;
    }

    checkboxLabelClick(event, block, field, obj, ...args)
    {
        if (this.data && !this.readonly && !field.readonly) {
            this.data[field.field] = !this.data[field.field];
            this.callFieldFunc('change', block, field, obj, ...args)
        }
    }

    filterForeignList(block: InfoBlock, field: InfoBlockField, data: any) {
        let items = [];
        if (!field.textFilter || !field.textFilter.length) items = field.listItems.items;
        else items = field.listItems.items.filter((item: any[]) => (typeof(item[field.listField]) !== 'undefined' && item[field.listField].toUpperCase().indexOf(field.textFilter.toUpperCase()) >= 0));
        return items;
    }

    blurSearchableTextField(block: InfoBlock, field: InfoBlockField, data: any)
    {
        let items: any[] = this.filterForeignList(block, field, data);
        this.data[field.field] = items && items.length > 0 ? items[0] : null;
        this.callFieldFunc('change', block, field, data, null);
    }
}
