import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-color-modal',
  templateUrl: './color-modal.component.html',
  styleUrls: ['./color-modal.component.css']
})
export class ColorModalComponent implements OnInit {

    public object: any = null;
    public field: string = null;

    constructor(private modalRef: BsModalRef) { }

    ngOnInit() {
    }

    get color()
    {
        return (this.field ? this.object[this.field] : this.object['color']) || 'rgba(192,192,192,1)';
    }
    set color(value: string)
    {
        if (value.startsWith('#')) value = this.hexToRgbA(value);
        this.object[this.field || 'color'] = value;
        if (this.object['changed'] != undefined) this.object['changed'] = true;
    }

    hexToRgbA(hex){
        var c;
        if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
            c= hex.substring(1).split('');
            if(c.length== 3){
                c= [c[0], c[0], c[1], c[1], c[2], c[2]];
            }
            c= '0x'+c.join('');
            return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
        }
        else return 'rgba(192,192,192,1)';
    }

    selectColor(event)
    {
        this.modalRef.hide();
    }

}
