import { Component, OnInit, Input, Output, Inject, ViewChild, EventEmitter, forwardRef, ElementRef } from '@angular/core';
import { NgModel, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as $ from 'jquery';

@Component({
  selector: 'rolling-direction-field',
  templateUrl: './rolling-direction-field.component.html',
  styleUrls: ['./rolling-direction-field.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RollingDirectionFieldComponent),
      multi: true
    }
  ]
})
export class RollingDirectionFieldComponent implements ControlValueAccessor {

    availableValues: number[] = [ 0, 1, 2, 3, 4, 5, 6, 7, 8];
    formId: number = Math.round(1000 + Math.random() * 9000);
    @Output('change') change: EventEmitter<any> = new EventEmitter<any>();

    constructor(public elementRef: ElementRef) {
    }

    propagateChange = (_: any) => {};
    registerOnChange(fn) { this.propagateChange = fn; }
    registerOnTouched() {}
    writeValue(value: any) {
        if (value !== undefined) this.value = value;
    }

    @Input() _value: any = null;
    get value() { return this._value; }
    set value(value) {
        this._value = value;
        this.propagateChange(this.value);
    }
}
