import { Component, OnInit } from '@angular/core';
import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Toolbar } from 'src/app/components/toolbar/classes/Toolbar.class';

@Component({
  selector: 'database-import-view',
  templateUrl: './database-import-view.component.html',
  styleUrls: ['./database-import-view.component.css']
})
export class DatabaseImportViewComponent extends AccessViewComponent {

    // public toolbar: Toolbar = {
    //     viewTitle: 'Importer des données',
    //     elements: {
    //         'importTypeSelect': {
    //             type: 'select',
    //             value: 'customers',
    //             options: [
    //                 { text: "Clients", value: 'customers' },
    //                 { text: "Fournisseurs", value: 'suppliers' }
    //             ]
    //         }
    //     }
    // };

}
