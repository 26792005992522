<div class="goals-list-view-container">
  <div class="settings">
    <label for="selectedMerchant">
      <span>Représentant : </span>
      <select
        name="selectedMerchant"
        id="selectedMerchant"
        [(ngModel)]="selectedMerchant"
        (change)="updateBudgetsTable()"
      >
        <option *ngFor="let merchant of merchants" [ngValue]="merchant">
          {{ merchant.numberAndName }}
        </option>
      </select>
    </label>
    <label for="selectedYear">
      <span>Année : </span>
      <select
        name="selectedYear"
        id="selectedYear"
        [(ngModel)]="selectedYear"
        (change)="updateBudgetsTable()"
      >
        <option *ngFor="let year of years">{{ year }}</option>
      </select>
    </label>
  </div>

  <div class="budget-container">
    <data-grid2
      id="goals-list"
      #grid
      [columns]="bodyColumns"
      [selectable]="true"
      [items]="goalsData.items"
      [editable]="writeAccess"
      (activeCellsChanged)="onActiveCellsChanged($event)"
      [fixedColumnsCount]="2"
    >
    </data-grid2>

    <div class="details-container" #detailsContainer></div>
  </div>
</div>
