<div *ngIf="excel">
    <div class="excel-options">
        <label for="selectedSheet">
            <span>Feuille :</span>
            <select id="selectedSheet" [(ngModel)]="selectedSheet">
                <option *ngFor="let sheet of sheets" [value]="sheet">{{ sheet }}</option>
            </select>
        </label>
        <label for="slipLines">
            <span>Ignorer</span>
            <input id="skipLines" type="number" min="0" [(ngModel)]="skipLines" />
            <span>ligne(s)</span>
        </label>
    </div>
    <table *ngIf="selectedSheet">
        <thead>
            <tr>
                <th *ngFor="let column of excel.sheets[selectedSheet].columns">
                    <select [(ngModel)]="selectedFields[column]">
                        <option *ngFor="let field of fields" [value]="field.name">{{ field.description }}</option>
                    </select>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let row of excel.sheets[selectedSheet].rows">
                <td *ngFor="let column of excel.sheets[selectedSheet].columns">{{ row[column] }}</td>
            </tr>
        </tbody>
    </table>
    <button class="btn btn-primary" (click)="import()">Importer !</button>
</div>
