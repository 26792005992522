import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoggerViewComponent } from './views/logger-view/logger-view.component';
import { Toolbar } from '../../components/toolbar/classes/Toolbar.class';
import { ModuleBase } from '../module-base.class';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        LoggerViewComponent
    ],
    exports: [
        LoggerViewComponent
    ],
    entryComponents: [
        LoggerViewComponent
    ],
})
export class LoggerModule extends ModuleBase {
    
    public static moduleName = 'logger';
    
    public ngOnInit()
    {
        // Toolbar.registerMenuItem({
        //     name: 'Logs',
        //     icon: 'terminal',
        //     viewComponent: LoggerViewComponent
        // });
        return super.ngOnInit();
    }
    
}

