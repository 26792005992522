import { Config } from './types';

export const config: Config = {
  db_version: 4,
  apiUrl: 'http://graphiseal.local/api',
  baseUrl: 'http://graphiseal.local/',
  companyName: 'GraphiSeal',
  mailBcc: ['ct@graphiseal.com'],
  orderMailBcc: ['ct@graphiseal.com'],
  orderMail: 'stock@graphiseal.com',
  tcPdfPath: '%DROPBOX%\\Gestion de graphiseal\\CONDITIONS GÉNÉRALES ACHATS.pdf',
  interop: {
    enabled: false,
    lists: {
      articles: false,
      customers: false,
      incidents: false,
      nomenclatures: false,
      orders: false,
      sales: false,
      suppliers: false,
      stocks: false,
      invoices: false,
      reimbursements: false,
      devices: false,
      wages: false,
      payments: false,
    },
  },
};

export const initConfig = async () => {
  try {
    const response = await fetch('assets/config.json');
    const partialConfig = await response.json();
    console.log('Config loaded from file:', partialConfig);
    Object.assign(config, partialConfig);
  } catch (err) {
    console.error('Error while loading config:', err);
  }

  // Check if URLs ends with '/'
  if (!config.apiUrl.endsWith('/')) config.apiUrl += '/';
  if (!config.baseUrl.endsWith('/')) config.baseUrl += '/';

  console.log('Final config:', config);
  return config;
};
