import { Article } from '../../articles/classes/Article.class';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { InfoBlock } from '../../../components/info-block/classes/InfoBlock.class';
import { InfoBlockComponent } from '../../../components/info-block/info-block.component';
import { InfoBlockField } from '../../../components/info-block/classes/InfoBlockField.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { Order } from '../../orders/classes/Order.class';
import { Page } from '../../printing/classes/Page.class';
import { PrintingModel } from '../../printing/classes/PrintingModel.class';
import { PrintingUtil } from '../../utils/classes/PrintingUtil.class';
import { StockMove } from './StockMove.class';
import { config } from '../../../classes/config';

export class StockMovePrintingModel extends PrintingModel {
  public deliveryAddressField: InfoBlockField = {
    title: 'Adresse de livraison',
    field: 'delivery_address',
    type: 'address-text',
    listItems: { items: [] },
    textChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
    addressChange: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
      this.regenerate();
    },
  };
  public optionsBlock: InfoBlock = {
    fields: [
      {
        title: 'Langue',
        field: 'lang',
        type: 'select',
        selectOptions: [
          { text: '(Selon fournisseur)', value: '' },
          { text: 'Français', value: 'fr' },
          { text: 'Anglais', value: 'en' },
          { text: 'Néerlandais', value: 'nl' },
        ],
        change: (component: InfoBlockComponent, block: InfoBlock, field: InfoBlockField, event: any) => {
          this.regenerate();
        },
      },
      this.deliveryAddressField,
    ],
  };

  public move: StockMove = null;
  public lang: string = '';
  public delivery_contact: string = null;
  public delivery_address_text: string = null;
  public opening_hours: string = null;

  public static generate(move: StockMove): PrintingModel {
    let model: StockMovePrintingModel = new StockMovePrintingModel();
    model.move = move;
    model.delivery_contact = move ? move.delivery_contact : '';
    model.delivery_address_text = move ? move.delivery_address_text : '';
    model.opening_hours = move ? move.openingHours : null;
    model.deliveryAddressField.listItems.items = move.customer ? move.customer.deliveryAddressesList : '';
    return model.regenerate();
  }

  public regenerate(): PrintingModel {
    let move: StockMove = this.move;
    let order: Order = move.order;

    let pageWidth = 210;
    let pageHeight = 295;

    let pageLeftMargin = 10;
    let pageRightMargin = 10;
    let pageTopMargin = 10;
    let pageBottomMargin = 10;

    let quantities: string[] = [];
    let prices: string[] = [];
    let totals: string[] = [];

    // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
    // if (!this.billing_address && order.billing_address) this.billing_address = order.billing_address;
    // if (!this.send_address && order.send_address) this.send_address = order.send_address;

    this.pages = [];

    if (!move || !move.supplier || !move.customer || !order) return this;

    let lang: string = this.lang;
    if (!lang || lang == '') {
      if (move.supplier && move.supplier.lang) lang = this.move.supplier.lang.code;
      else lang = 'fr';
    }

    this.saveFilename = 'Appel sur stock n°' + this.move.fullNumber.replace(/\//g, '-') + '.pdf';
    this.mailFilename =
      {
        fr: 'Appel sur stock n° ',
        nl: '_______________ nr ',
        en: 'Call-off no ',
      }[lang] +
      this.move.shortNumber.replace(/\//g, '-') +
      '-' +
      this.move.number +
      '.pdf';

    // for(let i=0; i<this.order.suppliers.length; ++i)
    // {

    let page: Page = {
      width: pageWidth,
      height: pageHeight,

      leftMargin: pageLeftMargin,
      rightMargin: pageRightMargin,
      topMargin: pageTopMargin,
      bottomMargin: pageBottomMargin,

      style: {
        'font-family': 'Calibri',
        'font-size': '10pt',
        width: pageWidth - pageLeftMargin - pageRightMargin + 'mm',
        height: pageHeight - pageTopMargin - pageBottomMargin + 'mm',
        'margin-top': pageTopMargin + 'mm',
        'margin-left': pageLeftMargin + 'mm',
        'margin-right': pageRightMargin + 'mm',
        'margin-bottom': pageBottomMargin + 'mm',
        'line-height': '4mm',
      },

      headerSize: 33,
      headerElements: [
        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '1mm',
            width: '85mm',
            height: '17mm',
            border: '.5mm solid rgba(0, 176, 240, 1.0)',
            'border-radius': '2.5mm',
            'line-height': '4mm',
          },
          children: [
            {
              style: {
                'font-size': '20pt',
                'font-weight': 'bold',
                'text-align': 'center',
                width: '100%',
                'line-height': '5mm',
                'margin-top': '1.5mm',
                'margin-bottom': '1mm',
                'vertical-align': 'top',
              },
              html: {
                fr: 'APPEL SUR STOCK',
                en: 'CALL-OFF',
                nl: '_______________',
              }[lang],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° :',
                    en: 'No :',
                    nl: 'Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '80%',
                    padding: '0 1mm',
                    'font-weight': 'bold',
                  },
                  html: move.delivery_number,
                },
              ],
            },
            {
              style: {
                display: 'flex',
                'flex-direction': 'row',
              },
              children: [
                {
                  style: {
                    width: '20%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'Date :',
                    en: 'Date :',
                    nl: 'Datum :',
                  }[lang],
                },
                {
                  style: {
                    width: '30%',
                    padding: '0 1mm',
                  },
                  html: DateTimeUtil.format(new Date(move.date), 'd/m/Y'),
                },
                {
                  style: {
                    width: '25%',
                    'text-align': 'right',
                    padding: '0 1mm',
                  },
                  html: {
                    fr: 'N° fourn. :',
                    en: 'Suppl. No :',
                    nl: 'Lever. Nr :',
                  }[lang],
                },
                {
                  style: {
                    width: '25%',
                    padding: '0 1mm',
                  },
                  html: move.supplier ? move.supplier.number : '',
                },
              ],
            },
          ],
        },
        {
          style: {
            position: 'absolute',
            left: '0mm',
            top: '0mm',
            width: '50mm',
            height: '30mm',
            'background-image': 'url(assets/img/logo_documents.png)',
            'background-position': 'center center',
            'background-size': 'contain',
            'background-repeat': 'no-repeat',
          },
          html: '',
        },
      ],

      bodyElements: [
        {
          style: {
            'text-decoration': 'underline',
            'margin-top': '10mm',
            'white-space': 'pre',
          },
          html: {
            fr: 'Cet appel sur stock comprend :',
            nl: 'Deze _____________ omvat :',
            en: 'This call-off includes :',
          }[lang],
        },
        {
          style: {
            'white-space': 'pre',
            'margin-bottom': '5mm',
          },
          html: {
            fr: "- L'appel sur stock\n- Le bordereau de livraison\n- Les étiquettes d'expédition",
            nl: '- Deze _____________\n- De leveringsbon \n- De verzendingslabels',
            en: '- This call-off document\n- The delivery note\n- The shipping labels',
          }[lang],
        },
        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-bottom': '3mm',
          },
          html: {
            fr: 'À la suite du bon de commande n°#, nous avons le plaisir de vous passer commande pour la fourniture suivante :',
            en: 'Concerning our purchase order No. #, we would like to place the following call-off:',
            nl: 'Vervolgens het bestelbon nr. #, hebben wij het plezier van U een bestelling te plaatsen voor de volgende goederen :',
          }[lang].replace('#', order ? order.number : ''),
        },

        {
          style: {
            position: 'absolute',
            right: '0mm',
            top: '0mm',
            width: '85mm',
            height: '25m',
            'white-space': 'pre-wrap',
          },
          children:
            this.move.stock && this.move.stock.supplier
              ? PrintingUtil.formatAddress(
                  this.move.stock.supplier.name,
                  this.move.stock.supplier.commercial_address.contactName,
                  this.move.stock.supplier.commercial_address.getPostalAddress()
                )
              : this.move.supplier
              ? PrintingUtil.formatAddress(
                  this.move.supplier.name,
                  this.move.supplier.commercial_address.contactName,
                  this.move.supplier.commercial_address.getPostalAddress()
                )
              : [],
        },

        {
          style: {
            // 'display': 'flex',
            width: '100%',
            // 'flex-direction': 'row'
          },
          children: [
            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                // 'flex': '1'
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        width: '60mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'font-weight': 'bold',
                      },
                      html: {
                        fr: 'Désignation',
                        en: 'Specifications',
                        nl: 'Specificaties',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(217,217,217,1)',
                        padding: '.5mm 1mm',
                        'font-weight': 'bold',
                      },
                      html: order ? order.article.designation : '',
                    },
                  ],
                },
              ].concat(this.getArticleLines(move, lang)),
            },

            {
              style: {
                display: 'table',
                width: '100%',
                'border-collapse': 'collapse',
                position: 'relative',
                'margin-top': '3mm',
              },
              children: [
                {
                  style: {
                    display: 'table-row',
                  },
                  children: [
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        'background-color': 'rgba(192, 192, 192, 1.0)',
                        width: '60mm',
                        padding: '.5mm 1mm',
                        'text-align': 'right',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                      },
                      html: {
                        fr: "N° DE NOTRE COMMANDE :\nQUANTITÉ :\nPRIX A L'UNITÉ DE VENTE :\nMONTANT DE LA COMMANDE :\nDATE DE LIVRAISON PRÉVUE :\nTRANSPORT :\nCONDITIONNEMENT :\nCONDITIONS DE PAIEMENT :",
                        nl: 'ONS BESTELNUMMER :\nHOEVEELHEID EN VERKOOPSEENHEID :\nPRIJS PER VERKOOPSEENHEID :\nTOTAAL BEDRAG :\nLEVERINGSDATUM :\nTRANSPORT :\nVERPAKKING :\nBETALINGSVOORWAARDEN :',
                        en: 'OUR ORDER NUMBER :\nQUANTITY AND UNIT :\nPURCHASE PRICE PER UNIT :\nORDER AMOUNT :\nESTIMATED DELIVERY DATE :\nTRANSPORT :\nPACKING :\nPAYMENT TERMS :',
                      }[lang],
                    },
                    {
                      style: {
                        display: 'table-cell',
                        border: '.25mm solid rgba(0, 176, 240, 1.0)',
                        padding: '.5mm 1mm',
                        'white-space': 'pre',
                        // 'line-height': '5mm',
                      },
                      html: [
                        order.number,
                        move.quantityAndUnit,
                        NumberUtil.formatMoney(
                          move.stock.converted_buy_price,
                          (move.sell_currency ? move.sell_currency.symbol : '€') +
                            { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                          5,
                          '.'
                        ),
                        NumberUtil.formatMoney(
                          move.converted_totalBuyAmount,
                          (move.sell_currency ? move.sell_currency.symbol : '€') +
                            { fr: ' H.T.V.A.', nl: ' excl. B.T.W.', en: ' excl. VAT' }[lang],
                          2,
                          '.'
                        ),
                        move.delivery_date,
                        {
                          fr: `Vos soins. Bon de livraison et étiquettes ${config.companyName}.`,
                          nl: `Door jou. Leveringsbon en verzendingslabels van ${config.companyName}.`,
                          en: `Your carrier using ${config.companyName} branded delivery note and labels.`,
                        }[lang],
                        order.article instanceof Article && order.article.packaging ? order.article.packaging : '',
                        order.deadline ? order.deadline : '',
                      ].join('\n'),
                    },
                  ],
                },
              ],
            },
          ],
        },

        {
          style: {
            clear: 'both',
          },
        },

        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            'background-color': 'rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            'text-align': 'center',
            // 'line-height': '5mm',
            color: 'rgba(255,255,255,1)',
            'font-weight': 'bold',
            'margin-top': '3mm',
          },
          html: {
            fr: 'IMPÉRATIF',
            en: 'PURCHASE CONDITIONS',
            nl: 'ABSOLUUT NOODZAKELIJK',
          }[lang],
        },
        {
          style: {
            display: 'block',
            border: '.25mm solid rgba(0, 176, 240, 1.0)',
            width: '100%',
            padding: '.5mm 1mm',
            // 'line-height': '4mm',
            // 'white-space': 'pre-wrap',
            flex: '1',
          },
          children: [
            {
              style: {
                display: 'block',
                'font-weight': 'bold',
                padding: '1mm 0mm',
              },
              html: {
                fr: 'Veuillez respecter la quantité exacte commandée et joindre 3 modèles avec votre facture.',
                en: 'Please respect the exact quantity requested, and send 3 models of the product with your first invoice.',
                nl: 'Gelieve de juiste hoeveelheid van de bestelling bij te volgen en 3 produktiemodellen bij te voegen met de faktuur.',
              }[lang],
            },
            {
              style: {
                display: 'block',
                'font-weight': 'bold',
                'background-color': 'rgba(255,255,0,1.0)',
                padding: '1mm 0mm',
              },
              html: {
                fr: 'Confirmez, le plutôt possible, soit par mail, soit par fax , la date de départ usine.',
                en: 'Could you please confirm the dispatch date as early as possible.',
                nl: 'Bevestig ons, zo vroeg mogelijk, per Mail, per Fax, de datum van vertrek fabriek van de goederen.',
              }[lang],
            },
            {
              style: {
                display: 'block',
                color: 'rgba(255,0,0,1.0)',
                'font-weight': 'bold',
                padding: '1mm 0mm',
              },
              html: {
                fr: `Toujours utiliser la note d'envoi fournie par nos soins (ou une note d'envoi neutre reprenant l'adresse de ${config.companyName} comme expéditeur ainsi que la référence suivante : #).`,
                en: `Always use the provided delivery note or a neutral delivery note specifying ${config.companyName} as the sender using the following reference : #`,
                nl: 'Altijd een neutraal verzendingsbon gebruiken ofwel degene geleverd met ons leveringsadres.',
              }[lang].replace('#', move.customer_reference || ''),
            },
          ],
        },

        {
          style: {
            'white-space': 'pre-wrap',
            'font-weight': 'bold',
            // 'line-height': '5mm',
            'margin-top': '3mm',
            'font-style': 'italic',
          },
          html: {
            fr: "En restant à votre disposition pour d'éventuelles questions, et en vous remerciant par avance de la bonne exécution de cette commande, nous vous prions d'agréer nos sincères salutations.",
            nl: 'Verblijvend tot uw dienst voor bijkomende vragen, en alvorens bedankt voor de juiste uitvoering van die bestelling, aanvaar onze meest opgerechte groeten.',
            en: 'We are at your disposal for any further questions. Thank you in advance for the correct execution of this order. Kind regards.',
          }[lang],
        },
      ],

      footerSize: 63,
      footerElements: [
        {
          style: {
            display: 'table',
            width: '100%',
            'border-collapse': 'collapse',
            'margin-top': '3mm',
          },
          children: [
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE LIVRAISON',
                    en: 'DELIVERY ADDRESS',
                    nl: 'LEVERINGS ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'ADRESSE DE FACTURATION',
                    en: 'BILLING ADDRESS',
                    nl: 'FACTURATIE ADRES',
                  }[lang],
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    'background-color': 'rgba(217, 217, 217, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'font-weight': 'bold',
                  },
                  html: {
                    fr: 'NOM ET SIGNATURE',
                    en: 'NAME AND SIGNATURE',
                    nl: 'HANDTEKENING',
                  }[lang],
                },
              ],
            },
            {
              style: {
                display: 'table-row',
              },
              children: [
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children:
                    this.move.self_delivery == 1
                      ? [
                          {
                            style: {
                              'white-space': 'pre-wrap',
                            },
                            html: {
                              fr: 'Enlèvement par nos soins',
                              nl: 'Enlèvement par nos soins',
                              en: 'Enlèvement par nos soins',
                            }[lang],
                          },
                        ]
                      : //PrintingUtil.formatAddress(this.order.customer.name, this.order.delivery_contact, this.order.delivery_address_text, null, this.order.openingHours)
                        PrintingUtil.formatAddress(
                          order.customer.name,
                          this.delivery_contact,
                          this.delivery_address_text,
                          null,
                          this.opening_hours
                        ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '76mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre-wrap',
                    'vertical-align': 'top',
                  },
                  children: PrintingUtil.formatAddress(
                    'G.S. srl',
                    null,
                    'Atrium du Vertbois\nRue du Vertbois 11 bte 20\nB-4000 Liège (BELGIUM)',
                    'BE 0829 982 973'
                  ),
                },
                {
                  style: {
                    display: 'table-cell',
                    border: '.25mm solid rgba(0, 176, 240, 1.0)',
                    width: '38mm',
                    padding: '.5mm 1mm',
                    'text-align': 'center',
                    // 'line-height': '5mm',
                    'white-space': 'pre',
                    position: 'relative',
                    'vertical-align': 'top',
                  },
                  children: [
                    {
                      style: {
                        'text-align': 'center',
                        'white-space': 'pre',
                        'margin-top': '15mm',
                      },
                      html: {
                        fr: 'Le service logistique',
                        en: 'The logistics service',
                        nl: 'De logistieke dienst',
                      }[lang],
                    },
                    {
                      style: {
                        'font-weight': 'bold',
                        'text-align': 'center',
                        'margin-bottom': '5mm',
                      },
                      html: 'Gillian Townley',
                    },
                    {
                      style: {
                        position: 'absolute',
                        left: '2mm',
                        top: '5mm',
                        width: '35mm',
                        height: '8.4mm',
                        'background-image': 'url(assets/img/signature_gill.png)',
                        'background-size': '100% 100%',
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },

        PrintingUtil.getFooter(this.move.accounting, lang),
      ],
    };

    this.pages.push(page);

    //}
    return this;
  }

  public getArticleLines(order: StockMove, lang: string) {
    let headers: any[] = PrintingUtil.getArticleHeaders(order.article, lang);
    let values: any[] = PrintingUtil.getArticleValues(order.article);
    let arr: any[] = [];
    for (let i = 0; i < headers.length; ++i) {
      arr.push({
        style: {
          display: 'table-row',
        },
        children: [
          {
            style: {
              display: 'table-cell',
              'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              width: '50mm',
              padding: '.5mm 1mm',
              'text-align': 'right',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: headers[i],
          },
          {
            style: {
              display: 'table-cell',
              'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
              padding: '.5mm 1mm',
              'white-space': 'pre-wrap',
              'line-height': '4mm',
              'vertical-align': 'top',
              'padding-left': '1mm',
              'padding-top': '0',
              'padding-right': '1mm',
              'padding-bottom': '0',
            },
            html: values[i],
          },
        ],
      });
    }
    if (arr.length > 0) {
      arr[arr.length - 1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
      arr[arr.length - 1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
    }
    return arr;
  }
}
