import * as am4charts from '@amcharts/amcharts4/charts';

import { ApiService } from '../../../services/api/api.service';
import { CredentialsService } from '../../../services/credentials/credentials.service';
import { LoadingPromise } from 'src/app/classes/objects/LoadingPromise.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectUtil } from '../../utils/classes/ObjectUtil.class';
import { ReportCell } from '../../reports/classes/ReportCell.class';
import { ReportHeaderRow } from '../../reports/classes/ReportHeaderRow.class';
import { ReportModel } from '../../reports/classes/ReportModel.class';
import { ReportRow } from '../../reports/classes/ReportRow.class';
import { ReportSetting } from '../../reports/classes/ReportSetting.class';
import { StatsModule } from '../stats.module';
import { config } from '../../../classes/config';

export class MerchantOrdersReportModel extends ReportModel {
  public title: string = 'MONTANT DES COMMANDES PAR REPRÉSENTANT';
  public settings: ReportSetting[] = [
    { name: 'year_start', title: 'Année', type: 'number', value: new Date().getFullYear() },
    { name: 'merchant', title: 'Représentant', type: 'list', value: null, listItems: [], listField: 'numberAndName' },
  ];

  public merchantsData: any = { items: [] };

  public tableStyle: any = {
    border: '2px solid black',
  };

  public headerRows: ReportHeaderRow[] = [{ cells: [] }];
  public rows: ReportRow[] = [];
  public points: any[] = [];

  public static titleCellStyle: any = {
    'background-color': 'rgb(51,51,255)',
    color: 'white',
    'font-weight': 'bold',
    'border-top': '2px solid black',
    'border-bottom': '2px solid black',
    'text-align': 'center',
  };
  public static merchantNameCellStyle: any = {
    'background-color': 'rgb(0,0,0)',
    text: 'rgb(0,176,240)',
    color: 'white',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static body_CellStyle: any = {
    // 'background-color': 'rgb(217, 217, 217)',
    color: 'black',
  };
  public static bodyTotal_CellStyle: any = {
    'background-color': 'rgb(0,176,240)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };
  public static headerTotalCellStyle: any = {
    'background-color': 'rgb(217, 217, 217)',
    color: 'black',
    'font-weight': 'bold',
  };
  public static month_CellStyle: any = {
    'background-color': 'rgb(220, 230, 241)',
    color: 'black',
    'font-weight': 'bold',
    'text-align': 'center',
  };

  public static generate(settings: ReportSetting[] = null) {
    let model: MerchantOrdersReportModel = new MerchantOrdersReportModel();
    if (settings) model.settings = settings;
    return model;
  }

  public regenerate() {
    return LoadingPromise.create<any>((resolve, reject) => {
      let month_names: string[] = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Aout',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ];
      let tri_month_names: string[] = ['1er trimestre', '2ème trimestre', '3ème trimestre', '4ème trimestre'];

      let promises: any[] = [];
      let ids: string[] = null;
      if (!CredentialsService.isUserAllowed('stats_commandesrepresentants_voirtoutlemonde')) {
        ids = [CredentialsService.loggedMerchant.id];
      }
      if (!this.merchantsData.items || !this.merchantsData.items.length)
        promises.push(
          Merchant.load(ids, ['number']).then((result: Merchant[]) => {
            this.merchantsData = result;
            this.getSetting('merchant').listItems = result;
            if (result.length > 0 && !this.getSetting('merchant').value) {
              this.getSetting('merchant').value = result[0];
              // this.regenerate();
            }
          })
        );

      Promise.all(promises).then(
        (result) => {
          this.headerRows = [
            {
              cells: [
                {
                  value: 'MONTANT DES COMMANDES PAR REPRÉSENTANT',
                  colSpan: 14,
                  style: MerchantOrdersReportModel.titleCellStyle,
                },
              ],
            },
            {
              cells: [
                {
                  value: this.getSetting('merchant').value.numberAndName,
                  style: MerchantOrdersReportModel.merchantNameCellStyle,
                },
                { value: 'Janvier', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Février', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Mars', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Avril', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Mai', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Juin', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Juillet', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Aout', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Septembre', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Octobre', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Novembre', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'Décembre', style: MerchantOrdersReportModel.month_CellStyle },
                { value: 'TOTAUX', style: MerchantOrdersReportModel.bodyTotal_CellStyle },
              ],
            },
          ];

          this.rows = [];
          this.rows.push(
            {
              cells: [{ value: "Prix d'achat", style: MerchantOrdersReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Frais de gestion', style: MerchantOrdersReportModel.month_CellStyle }],
            },
            {
              cells: [
                { value: `Frais externes / ${config.companyName}`, style: MerchantOrdersReportModel.month_CellStyle },
              ],
            },
            {
              cells: [{ value: 'Prix de revient', style: MerchantOrdersReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Prix de vente', style: MerchantOrdersReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Marge nette', style: MerchantOrdersReportModel.month_CellStyle }],
            },
            {
              cells: [{ value: 'Nombre de commandes', style: MerchantOrdersReportModel.bodyTotal_CellStyle }],
            }
          );

          ApiService.callModule('reports', 'report', {
            query: 'merchant_orders',
            settings: {
              year_start: this.getSetting('year_start').value,
              year_end: this.getSetting('year_start').value,
              id_merchant: this.getSetting('merchant').value.id,
            },
          }).then(
            (result2: any) => {
              // console.log('results:', results);
              let amount_month: number = 0;
              let total_month: number = 0;
              let charges_month: number = 0;
              let amount_year: number = 0;
              let charges_year: number = 0;
              let buy_arr: number[] = [];
              let ifees_arr: number[] = [];
              let efees_arr: number[] = [];
              let mfees_arr: number[] = [];
              let cost_arr: number[] = [];
              let sell_arr: number[] = [];
              let margin_arr: number[] = [];
              let count_arr: number[] = [];
              let charges_arr: number[] = [];
              let amounts_with_tax: number[] = [];
              let month_count: number = 0;
              // for(let j=0; j<results.length; ++j)
              // {
              // let result2: any = results[j];
              for (let i = 0; i < result2.details.length; ++i) {
                let month_str: any = result2.details[i]['month'];
                let month: number = parseFloat(month_str);
                let buy_str: any = result2.details[i]['buy_price'];
                let buy: number = parseFloat(buy_str);
                let ifees_str: any = result2.details[i]['internal_fees'];
                let ifees: number = parseFloat(ifees_str);
                let efees_str: any = result2.details[i]['external_fees'];
                let efees: number = parseFloat(efees_str);
                let mfees_str: any = result2.details[i]['merchant_fees'];
                let mfees: number = parseFloat(mfees_str);
                let cost_str: any = result2.details[i]['cost_price'];
                let cost: number = parseFloat(cost_str);
                let sell_str: any = result2.details[i]['sell_price'];
                let sell: number = parseFloat(sell_str);
                let margin_str: any = result2.details[i]['net_margin'];
                let margin: number = parseFloat(margin_str);
                let count_str: any = result2.details[i]['count'];
                let count: number = parseFloat(count_str);
                let charge_str: any = result2.details[i]['charge'];
                let charge: number = parseFloat(charge_str);
                if (month >= 1) {
                  if (buy && buy != 0) {
                    if (!buy_arr[month - 1]) buy_arr[month - 1] = 0;
                    buy_arr[month - 1] += buy;
                  }
                  if (ifees && ifees != 0) {
                    if (!ifees_arr[month - 1]) ifees_arr[month - 1] = 0;
                    ifees_arr[month - 1] += ifees;
                  }
                  if (efees && efees != 0) {
                    if (!efees_arr[month - 1]) efees_arr[month - 1] = 0;
                    efees_arr[month - 1] += efees;
                  }
                  if (mfees && mfees != 0) {
                    if (!mfees_arr[month - 1]) mfees_arr[month - 1] = 0;
                    mfees_arr[month - 1] += mfees;
                  }
                  if (cost && cost != 0) {
                    if (!cost_arr[month - 1]) cost_arr[month - 1] = 0;
                    cost_arr[month - 1] += cost;
                  }
                  if (sell && sell != 0) {
                    if (!sell_arr[month - 1]) sell_arr[month - 1] = 0;
                    sell_arr[month - 1] += sell;
                  }
                  if (margin && margin != 0) {
                    if (!margin_arr[month - 1]) margin_arr[month - 1] = 0;
                    margin_arr[month - 1] += margin;
                  }
                  if (count && count != 0) {
                    if (!count_arr[month - 1]) count_arr[month - 1] = 0;
                    count_arr[month - 1] += count;
                  }
                  if (charge && charge != 0) {
                    if (!charges_arr[month - 1]) charges_arr[month - 1] = 0;
                    charges_arr[month - 1] += charge;
                  }
                }
              }
              // }

              let buy_total: number = 0;
              let ifees_total: number = 0;
              let efees_total: number = 0;
              let mfees_total: number = 0;
              let cost_total: number = 0;
              let sell_total: number = 0;
              let margin_total: number = 0;
              let count_total: number = 0;
              let charges_total: number = 0;
              let minimum_total: number = 0;
              let gain_total: number = 0;
              let result_total: number = 0;
              for (let i = 0; i < 12; ++i) {
                try {
                  let buy: number = buy_arr[i] || 0;
                  let ifees: number = ifees_arr[i] || 0;
                  let efees: number = efees_arr[i] || 0;
                  let mfees: number = efees_arr[i] || 0;
                  let cost: number = cost_arr[i] || 0;
                  let sell: number = sell_arr[i] || 0;
                  let margin: number = margin_arr[i] || 0;
                  let count: number = count_arr[i] || 0;
                  let charge: number = charges_arr[i] || 0;
                  let minimum: number = buy > 0 ? (this.getSetting('merchant').value as Merchant).base || 0 : 0;
                  let gain: number = (margin * ((this.getSetting('merchant').value as Merchant).margin || 0)) / 100;
                  let result: number = gain - charge - minimum - mfees;
                  this.rows[0].cells.push({
                    value: buy && buy != 0 ? NumberUtil.formatMoney(buy, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[1].cells.push({
                    value: ifees && ifees != 0 ? NumberUtil.formatMoney(ifees, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[2].cells.push({
                    value: efees && efees != 0 ? NumberUtil.formatMoney(efees, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[3].cells.push({
                    value: cost && cost != 0 ? NumberUtil.formatMoney(cost, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[4].cells.push({
                    value: sell && sell != 0 ? NumberUtil.formatMoney(sell, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[5].cells.push({
                    value: margin && margin != 0 ? NumberUtil.formatMoney(margin, '€', 2, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.body_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.rows[6].cells.push({
                    value: count && count != 0 ? NumberUtil.formatNumber(count, 0, '.') : '',
                    style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
                  });
                  this.points.push({ name: month_names[i], month: i });
                  this.points[i].buy = buy;
                  this.points[i].ifees = ifees;
                  this.points[i].efees = efees;
                  this.points[i].cost = cost;
                  this.points[i].sell = sell;
                  this.points[i].margin = margin;
                  this.points[i].count = count;
                  buy_total += buy;
                  ifees_total += ifees;
                  efees_total += efees;
                  mfees_total += mfees;
                  cost_total += cost;
                  sell_total += sell;
                  margin_total += margin;
                  count_total += count;
                  charges_total += charge;
                  minimum_total += minimum;
                  gain_total += gain;
                  result_total += result;
                } catch {
                  continue;
                }
              }

              this.rows[0].cells.push({
                value: buy_total && buy_total != 0 ? NumberUtil.formatMoney(buy_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[1].cells.push({
                value: ifees_total && ifees_total != 0 ? NumberUtil.formatMoney(ifees_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[2].cells.push({
                value: efees_total && efees_total != 0 ? NumberUtil.formatMoney(efees_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[3].cells.push({
                value: cost_total && cost_total != 0 ? NumberUtil.formatMoney(cost_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[4].cells.push({
                value: sell_total && sell_total != 0 ? NumberUtil.formatMoney(sell_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[5].cells.push({
                value: margin_total && margin_total != 0 ? NumberUtil.formatMoney(margin_total, '€', 2, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.month_CellStyle, ReportCell.moneyCellStyle),
              });
              this.rows[6].cells.push({
                value: count_total && count_total != 0 ? NumberUtil.formatNumber(count_total, 0, '.') : '',
                style: ObjectUtil.merge(MerchantOrdersReportModel.bodyTotal_CellStyle, ReportCell.moneyCellStyle),
              });

              this.createEmptyCells();

              this.charts = [];

              this.charts.push({
                type: am4charts.XYChart,
                inline: false,
                colors: StatsModule.chartsColor,
                titles: [
                  {
                    text: 'Détail mensuel des commandes (' + this.getSetting('merchant').value.name + ')',
                    fontSize: 25,
                    marginBottom: 30,
                  },
                ],
                series: [
                  {
                    type: 'ColumnSeries',
                    name: "Prix d'achat",
                    dataFields: { valueY: 'buy', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Frais de gestion',
                    dataFields: { valueY: 'ifees', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Frais ext. / GS',
                    dataFields: { valueY: 'efees', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Prix de revient',
                    dataFields: { valueY: 'cost', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Prix de vente',
                    dataFields: { valueY: 'sell', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'ColumnSeries',
                    name: 'Marge nette',
                    dataFields: { valueY: 'margin', categoryX: 'name' },
                    yAxis: 'amounts',
                  },
                  {
                    type: 'LineSeries',
                    name: 'Nombre de commandes',
                    dataFields: { valueY: 'count', categoryX: 'name' },
                    bullets: [{ type: 'CircleBullet', stroke: '#fff', strokeWidth: 2 }],
                    yAxis: 'numbers',
                  },
                ],
                // maskBullets: false,
                data: this.points,
                yAxes: [
                  {
                    type: 'ValueAxis',
                    id: 'amounts',
                    dataFields: { value: 'value', category: 'name' },
                    title: { text: 'Montants' },
                  },
                  {
                    type: 'ValueAxis',
                    id: 'numbers',
                    dataFields: { value: 'value', category: 'name' },
                    title: { text: 'Nombre' },
                    renderer: { opposite: true },
                  },
                ],
                xAxes: [
                  {
                    type: 'CategoryAxis',
                    dataFields: { value: 'value', category: 'name' },
                    renderer: { cellStartLocation: 0.15, cellEndLocation: 0.85 },
                  },
                ],
                legend: {},
                container_width: 1200,
                container_height: 480,
                events: {
                  beforedatavalidated: function (ev) {
                    console.log('beforedatavalidated event:', ev);
                    ev.target.data.sort((a, b) => {
                      return a.month - b.month;
                    });
                  },
                },
              });

              resolve(true);
            },
            (err) => {
              console.error(err);
              reject(err);
            }
          );
        },
        (err) => {
          console.error(err);
        }
      );
    });
  }
}
