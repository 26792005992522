import { NgModule, OnInit } from '@angular/core';

import { ClassManager } from '../../classes/objects/ClassManager.class';
import { CommonModule } from '@angular/common';
import { ComponentsModule } from '../../components/components.module';
import { FormsModule } from '@angular/forms';
import { HomepageModule } from '../homepage/homepage.module';
import { HomepageViewComponent } from '../homepage/views/homepage-view/homepage-view.component';
import { InteropViewComponent } from '../interop/interop-view.component';
import { ModuleBase } from '../module-base.class';
import { Supplier } from './classes/Supplier.class';
import { SupplierCategory } from './classes/SupplierCategory.class';
import { SupplierViewComponent } from './views/supplier-view/supplier-view.component';
import { SuppliersListViewComponent } from './views/suppliers-list-view/suppliers-list-view.component';
import { config } from '../../classes/config';

@NgModule({
  imports: [CommonModule, FormsModule, ComponentsModule, HomepageModule],
  declarations: [SuppliersListViewComponent, SupplierViewComponent],
  exports: [SuppliersListViewComponent, SupplierViewComponent],
  entryComponents: [SuppliersListViewComponent, SupplierViewComponent],
})
export class SuppliersModule extends ModuleBase {
  public static moduleName = 'suppliers';

  public ngOnInit() {
    ClassManager.registerClass('Supplier', Supplier);
    ClassManager.registerClass('SupplierCategory', SupplierCategory);

    HomepageViewComponent.registerGroups({
      database: {
        name: 'Base de données',
        permission: 'home_groupe_basededonnees',
        children: [
          {
            name: 'Fournisseurs',
            permission: 'home_bouton_basededonnees_fournisseurs',
            component: SuppliersListViewComponent,
          },

          ...(config.interop.lists.suppliers
            ? [
                {
                  name: 'Fournisseurs 🏎️',
                  permission: 'home_bouton_basededonnees_fournisseurs',
                  href: '/suppliers',
                },
              ]
            : []),
        ],
      },
    });

    return super.ngOnInit();
  }
}
