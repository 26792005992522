import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpUserEvent } from '@angular/common/http';

import { Observable } from 'rxjs';
import { debug } from 'util';
import { environment } from 'src/environments/environment';

export class HttpUtil {
  private static client: HttpClient = null;

  public static init(http: HttpClient) {
    HttpUtil.client = http;
  }

  public static get(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe?: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType: 'arraybuffer';
      withCredentials?: boolean;
    }
  ): Observable<ArrayBuffer> {
    return HttpUtil.client.get(url, options);
  }

  /**
   * Construct a POST request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `Object`.
   */
  public static post(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe: 'body';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): any {
    return HttpUtil.client.post(url, body, {
      ...options,
      observe: 'body',
      withCredentials: false,
    });
  }
  /**
   * Construct a POST request which interprets the body as JSON and returns the full event stream.
   *
   * @return an `Observable` of all `HttpEvent`s for the request, with a body type of `T`.
   */
  public static post_type<T>(
    url: string,
    body: any | null,
    options?: {
      headers?:
        | HttpHeaders
        | {
            [header: string]: string | string[];
          };
      observe: 'events';
      params?:
        | HttpParams
        | {
            [param: string]: string | string[];
          };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<HttpEvent<T>> {
    return HttpUtil.client.post<T>(url, body, options);
  }
}
