import { Accounting } from '../../other-data/classes/Accounting.class';
import { Address } from '../../addresses/classes/Address.class';
import { CurrenciesService } from 'src/app/services/currencies/currencies.service';
import { DateTimeUtil } from '../../utils/classes/DateTimeUtil.class';
import { Invoice } from '../../invoicing/classes/Invoice.class';
import { NumberUtil } from '../../utils/classes/NumberUtil.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel2 } from '../../../classes/objects/ObjectModel2.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { Order } from '../../orders/classes/Order.class';
import { Stock } from './Stock.class';

export class StockAdjustment extends ObjectModel3 {
  public accounting: Accounting = null;
  public stock: Stock = null;
  public order: Order = null;

  public date: string = null;
  public quantity: number = null;
  public remarks: string = null;

  public get unit() {
    return this.stock ? this.stock.unit : null;
  }
  public get customer() {
    return this.stock ? this.stock.customer : null;
  }
  public get supplier() {
    return this.stock ? this.stock.supplier : null;
  }
  public get article() {
    return this.stock ? this.stock.article : null;
  }
  public get stockAndUnit() {
    return this.stock ? this.stock.stockAndUnit : null;
  }
  public get adjustmentWithUnit() {
    return (this.quantity > 0 ? '+' : '') + NumberUtil.formatNumber(this.quantity) + (this.unit ? ' ' + this.unit : '');
  }

  constructor() {
    super(StockAdjustment);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'stock_adjustments',
      id: 'id',
    },
    values: {
      date: { type: 'string' },
      quantity: { type: 'number' },
      remarks: { type: 'string' },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      order: { type: 'Order', clone: false, save: false, delete: false },
      stock: { type: 'Stock', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
