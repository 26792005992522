import { Address } from '../../addresses/classes/Address.class';
import { Lang } from '../../other-data/classes/Lang.class';
import { Nomenclature } from '../../articles/classes/Nomenclature.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';
import { ServerApi } from '../../../classes/api/ServerApi.class';
import { SupplierCategory } from './SupplierCategory.class';

export class Supplier extends ObjectModel3 {
  public name: string = null;
  public bob_id: string = null;
  public category: SupplierCategory = null;
  public number: string = null;
  public lang: Lang = null;
  public deadline: string = null;
  public product: string = null;
  public remarks: string = null;
  public show_wallonia_logo: number = 0;

  public vat_number: string = null;
  public vat: number = 21;
  public iban: string = null;
  public bic: string = null;

  public archived: number = 0;

  public commercial_address: Address = new Address();

  public contacts: Address[] = [];
  public nomenclatures: Nomenclature[] = [];

  public get categoryName() {
    return this.category && this.category.name;
  }
  public get langName() {
    return this.lang && this.lang.name;
  }
  public get commercialAddressEmail() {
    return this.commercial_address && this.commercial_address.email;
  }

  public get orderedContacts() {
    return this.contacts.sort((a, b) => {
      const splitA = (a.name1 || '').split(' ').filter(Boolean) || [];
      const splitB = (b.name1 || '').split(' ').filter(Boolean) || [];
      const nameA = splitA[splitA.length - 1] || '';
      const nameB = splitB[splitB.length - 1] || '';
      return nameA.localeCompare(nameB);
    });
  }

  constructor() {
    super(Supplier);
  }

  public static getNextNumber(prefix: string = 'FP') {
    return ServerApi.callModule('suppliers', 'nextNumber', { prefix: prefix });
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    database: {
      table: 'suppliers',
      id: 'id',
    },
    trashDelete: true,
    values: {
      name: { type: 'string' },
      bob_id: { type: 'string' },
      number: { type: 'string' },
      product: { type: 'string' },
      vat_number: { type: 'string' },
      vat: { type: 'number' },
      iban: { type: 'string' },
      bic: { type: 'string' },
      remarks: { type: 'string' },
      deadline: { type: 'string' },
      archived: { type: 'number' },
      show_wallonia_logo: { type: 'number' },
    },
    children: {
      commercial_address: {
        type: 'Address',
        clone: true,
        save: true,
        delete: true,
      },
      category: {
        type: 'SupplierCategory',
        clone: false,
        save: false,
        delete: false,
      },
      lang: { type: 'Lang', clone: false, save: false, delete: false },
    },
    links: {
      contacts: {
        type: 'Address',
        table: 'suppliers_addresses',
        id_self: 'id_supplier',
        id_link: 'id_address',
        clone: true,
        save: true,
        delete: true,
      },
      nomenclatures: {
        type: 'Nomenclature',
        table: 'suppliers_nomenclatures',
        id_self: 'id_supplier',
        id_link: 'id_nomenclature',
        clone: false,
        save: false,
        delete: false,
      },
    },
  };
}
