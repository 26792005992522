import { Component, OnInit } from '@angular/core';
import { DialogComponent } from 'src/app/components/dialogs/dialog/dialog.component';

@Component({
  selector: 'app-change-order-number',
  templateUrl: './change-order-number.component.html',
  styleUrls: ['./change-order-number.component.css']
})
export class ChangeOrderNumberComponent extends DialogComponent {

    public newNumber: any = {
        number: null,
        delivery_number: null
    };

}
