<div class="settings-block">
  <h3>Paramètres</h3>
  <info-block [block]="customerBlock" #deliveryBlockComponent [data]="settings"></info-block>
</div>

<h3>Historique</h3>
<div class="history-type-selection">
  <label for="historyType_sales" class="historyType_sales" [ngClass]="{'active': historyType === 'sales'}">
    <input type="radio" name="historyType" id="historyType_sales" [(ngModel)]="historyType" [value]="'sales'" />
    <span>Dossiers commerciaux</span>
  </label>
  <label for="historyType_orders" class="historyType_orders" [ngClass]="{'active': historyType === 'orders'}">
    <input type="radio" name="historyType" id="historyType_orders" [(ngModel)]="historyType" [value]="'orders'" />
    <span>Commandes</span>
  </label>
  <label for="historyType_invoices" class="historyType_invoices" [ngClass]="{'active': historyType === 'invoices'}">
    <input type="radio" name="historyType" id="historyType_invoices" [(ngModel)]="historyType" [value]="'invoices'" />
    <span>Factures</span>
  </label>
</div>

<div [hidden]="historyType != 'sales'">
  <data-grid #salesGrid id="customer-history-sales" [items]="filteredSales" [sortArray]="sales"
    [columns]="salesGridColumns" [rowActions]="salesGridRowActions" [resizable]="true" [editable]="false"
    [selectable]="true" [pageSize]="20" (itemDblClick)="saleDblClick($event, $event.item)" [fixedColumnsCount]="2"
    [rowStyleFunction]="gridRowStyle">
  </data-grid>
</div>

<div [hidden]="historyType != 'orders'">
  <data-grid #ordersGrid id="customer-history-orders" [items]="filteredOrders" [sortArray]="orders"
    [columns]="ordersGridColumns" [rowActions]="ordersGridRowActions" [resizable]="true" [editable]="false"
    [selectable]="true" [pageSize]="20" (itemDblClick)="saleDblClick($event, $event.item)" [fixedColumnsCount]="2"
    [rowStyleFunction]="gridRowStyle">
  </data-grid>
</div>

<div [hidden]="historyType != 'invoices'">
  <data-grid #invoicesGrid id="customer-history-invoices" [items]="invoices" [sortArray]="invoices"
    [columns]="invoicesGridColumns" [rowActions]="invoicesGridRowActions" [resizable]="true" [editable]="false"
    [selectable]="true" [pageSize]="20" (itemDblClick)="saleDblClick($event, $event.item)" [fixedColumnsCount]="2"
    [rowStyleFunction]="gridRowStyle">
  </data-grid>
</div>
