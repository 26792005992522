<div class="other-data-container">
    <tabset #dataTabs type="pills">
        <tab heading="Représentants" class="tab-container merchants-grid-container" (select)="setSelectedTab('merchants')">
            <div class="data-table-container merchants-grid-container">
                <h3>Représentants</h3>
                <data-grid #grids #merchantsGrid id="otherdata-merchants" [actions]="merchantsGridActions" [columns]="merchantsGridColumns"
                            [items]="merchants" [editable]="true" [resizable]="false" [selectable]="true" [rowStyleFunction]="merchantsRowStyle"></data-grid>
<!--                <h3>Signatures</h3>-->
<!--                <div>-->
<!--                    <button class="btn btn-default" (click)="addSignature()"><i class="fa fa-plus"></i></button>-->
<!--                    <button class="btn btn-default" (click)="deleteSignatures()"><i class="fa fa-trash"></i></button>-->
<!--                </div>-->
<!--                <info-block *ngFor="let signature of signatures" [block]="signatureBlock" [data]="signature"-->
<!--                            [checkable]="true" (selectedChange)="signaturesSelectionChanged($event)"-->
<!--                            style="display: inline-block; width: 45%; margin-right: 20px;"></info-block>-->
            </div>
        </tab>
        <tab heading="Clients / Fournisseurs" class="tab-container addresses-tab-container" (select)="setSelectedTab('customers')">
            <div class="data-table-container categories-grid-container">
                <h3>Catégories clients</h3>
                <data-grid #grids #customerCategoriesGrid id="otherdata-customers" [actions]="customerCategoriesGridActions" [columns]="categoriesGridColumns" [items]="customer_categories" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container categories-grid-container">
                <h3>Catégories fournisseurs</h3>
                <data-grid #grids #supplierCategoriesGrid id="otherdata-suppliers" [actions]="supplierCategoriesGridActions" [columns]="categoriesGridColumns" [items]="supplier_categories" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container customers-numbering-grid-container">
                <h3>Préfixes numérotation clients</h3>
                <data-grid #grids #cNumberingsGrid id="otherdata-numberings" [actions]="customersNumberingsGridActions" [columns]="customersNumberingsGridColumns" [items]="customersNumberings" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container suppliers-numbering-grid-container">
                <h3>Préfixes numérotation fournisseurs</h3>
                <data-grid #grids #suppliersNumberingsGrid id="otherdata-suppliers-numberings" [actions]="suppliersNumberingsGridActions" [columns]="suppliersNumberingsGridColumns" [items]="suppliersNumberings" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container langs-grid-container">
                <h3>Langues</h3>
                <data-grid #grids #langsGrid id="otherdata-langs" [actions]="langsGridActions" [columns]="langsGridColumns" [items]="langs" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container customer-references-grid-container">
                <h3>Types de demande de prix client</h3>
                <data-grid #grids #customerReferencesGrid id="otherdata-customer-references" [actions]="customerReferencesGridActions" [columns]="customerReferencesGridColumns" [items]="customerReferences" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Adresses" class="tab-container addresses-tab-container" (select)="setSelectedTab('addresses')">
            <div class="data-table-container postcodes-grid-container">
                <h3>Codes postaux</h3>
                <data-grid #grids #postcodesGrid id="otherdata-postcodes" [actions]="postcodesGridActions" [columns]="postcodesGridColumns" [items]="postcodes" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>Pays</h3>
                <data-grid #grids #countriesGrid id="otherdata-countries" [actions]="countriesGridActions" [columns]="countriesGridColumns" [items]="countries" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Facturation" class="tab-container billing-tab-container" (select)="setSelectedTab('billing')">
            <div class="data-table-container">
                <h3>Échéances</h3>
                <data-grid #grids #deadlinesGrid id="otherdata-deadlines" [actions]="deadlinesGridActions" [columns]="deadlinesGridColumns" [items]="deadlines" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>Afficher le nom et l'adresse sur les étiquettes</h3>
                <data-grid #grids #labelnamesGrid id="otherdata-labelnames" [actions]="label_namesGridActions" [columns]="label_namesGridColumns" [items]="label_names" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>Escomptes</h3>
                <data-grid #grids #discountsGrid id="otherdata-discounts" [actions]="discountsGridActions" [columns]="discountsGridColumns" [items]="discounts" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>États des paiements</h3>
                <data-grid #grids #paymentStatusesGrid id="otherdata-payment-statuses" [actions]="paymentStatusesGridActions" [columns]="paymentStatusesGridColumns" [items]="paymentStatuses" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>Rappels de paiement</h3>
                <data-grid #grids #remindersGrid id="otherdata-reminders" [actions]="remindersGridActions" [columns]="remindersGridColumns" [items]="reminders" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Livraison" class="tab-container delivery-tab-container" (select)="setSelectedTab('delivery')">
            <div class="data-table-container">
                <h3>Livraison</h3>
                <data-grid #grids #deliveriesGrid id="otherdata-deliveries" [actions]="deliveriesGridActions" [columns]="deliveriesGridColumns" [items]="deliveries" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container">
                <h3>Délais de livraison</h3>
                <data-grid #grids #deliverytimesGrid id="otherdata-delivery-times" [actions]="delivery_timesGridActions" [columns]="delivery_timesGridColumns" [items]="delivery_times" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Articles" class="tab-container articles-tab-container" (select)="setSelectedTab('articles')">
            <div class="data-table-container">
                <h3>Sens d'enroulement</h3>
                <data-grid #grids #rollingsGrid id="otherdata-rollings" [actions]="rollingsGridActions" [columns]="rollingsGridColumns" [items]="rollings" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Utilisateurs" class="tab-container users-grid-container" (select)="setSelectedTab('users')">
            <div class="data-table-container user-profiles-grid-container">
                <h3>Profils</h3>
                <data-grid #grids #userProfilesGrid id="otherdata-user-profiles" [actions]="userProfilesGridActions" [columns]="userProfilesGridColumns" [items]="userProfiles" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container users-grid-container">
                <h3>Utilisateurs</h3>
                <data-grid #grids #usersGrid id="otherdata-users" [actions]="usersGridActions" [columns]="usersGridColumns" [items]="users" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container permissions-grid-container">
                <h3>Permissions</h3>
                <data-grid #grids #permissionsGrid id="otherdata-permissions" [actions]="permissionsGridActions" [columns]="permissionsGridColumns" [items]="permissions" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
        <tab heading="Comptabilité" class="tab-container accounting-grid-container" (select)="setSelectedTab('accounting')">
            <div class="data-table-container accounting-grid-container">
                <h3>Types de charges</h3>
                <data-grid #grids #chargesTypesGrid id="otherdata-charges-types" [actions]="chargesTypesGridActions" [columns]="chargesTypesGridColumns" [items]="chargesTypes" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
            <div class="data-table-container accounting-grid-container">
                <h3>Monnaies</h3>
                <data-grid #grids #currenciesGrid id="otherdata-currencies" [actions]="currenciesGridActions" [columns]="currenciesGridColumns" [items]="currencies" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
                <p><b>*Note </b>:<br/>
                   Les unités monétaires doivent être spécifiés comme suit pour chaque langue :<br/>
                    <span style="font-family: Courier;">&lt;langue&gt;:&lt;valeur&gt;,&lt;langue&gt;:&lt;valeur&gt;,(...)</span><br/>
                   Par exemple, pour la livre sterling au singulier :<br/>
                    <span style="font-family: Courier;">fr:livre,en:pound,nl:pond</span>
                </p>

            </div>
            <div class="data-table-container accounting-grid-container">
                <h3>Comptabilités</h3>
                <data-grid #grids #accountingsGrid id="otherdata-accountings" [actions]="accountingsGridActions" [columns]="accountingsGridColumns" [items]="accountings" [editable]="true" [resizable]="false" [selectable]="true"></data-grid>
            </div>
        </tab>
    </tabset>
</div>
