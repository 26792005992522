import { Component, OnInit, ViewChild } from '@angular/core';

import { AccessViewComponent } from '../../../../components/views/access-view.component';
import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { AccountingsService } from 'src/app/services/accountings/accountings.service';
import { ArrayUtil } from 'src/app/modules/utils/classes/ArrayUtil.class';
import { DataGridComponent } from 'src/app/components/data-grid/data-grid.component';
import { DataGridRowAction } from '../../../../components/data-grid/classes/DataGridRowAction.class';
import { DialogButton } from 'src/app/components/dialogs/classes/DialogButton.class';
import { DialogsComponent } from 'src/app/components/dialogs/dialogs.component';
import { NotificationsComponent } from 'src/app/components/notifications/notifications.component';
import { Order } from '../../../orders/classes/Order.class';
import { Stock } from '../../classes/Stock.class';
import { StockAdjustment } from '../../classes/StockAdjustment.class';
import { StockAdjustmentViewComponent } from '../stock-adjustment-view/stock-adjustment-view.component';
import { StockMove } from '../../classes/StockMove.class';
import { StockViewComponent } from '../stock-view/stock-view.component';
import { Toolbar } from '../../../../components/toolbar/classes/Toolbar.class';
import { ViewsComponent } from 'src/app/components/views/views.component';

@Component({
  selector: 'app-stocks-adjustments-list-view',
  templateUrl: './stocks-adjustments-list-view.component.html',
  styleUrls: ['./stocks-adjustments-list-view.component.css'],
})
export class StocksAdjustmentsListViewComponent extends AccessViewComponent {
  public permission: string = 'ecriture_stocks_ajustements';

  public toolbar: Toolbar = {
    class: 'toolbar-big',
    viewTitle: 'Ajustements de stocks',
    data: this,
    elements: [
      {
        type: 'button',
        text: 'Ajuster<br/>un stock',
        icon: 'plus',
        click: function (view: StocksAdjustmentsListViewComponent) {
          view.createAdjustment();
        },
        access: this.writeAccess,
      },
      { type: 'separator' },
      {
        type: 'button',
        text: 'Supprimer',
        icon: 'times',
        click: function (view: StocksAdjustmentsListViewComponent) {
          view.deleteAdjustment();
        },
        access: this.writeAccess,
      },
      // { type: 'separator-large' },
      // {
      //     type: 'button',
      //     text: 'Archiver',
      //     icon: 'archive',
      //     click: function(view: StocksAdjustmentsListViewComponent) {
      //         view.archiveStocks();
      //     },
      //     access: this.writeAccess
      // },
      // { type: 'separator-large' },
      // {
      //     type: 'toggle-button',
      //     text: 'Afficher les<br/>éléments archivés',
      //     icon: 'filter',
      //     value: false,
      //     click: function(view: StocksListViewComponent) {
      //         view.filterStocks = !this.value;
      //         if (view.filterStocks) view.grid.clearSelection();
      //     }
      // },
      { type: 'spacing' },
    ],
  };

  @ViewChild('grid') grid: DataGridComponent;
  public gridRowActions: DataGridRowAction[] = [
    {
      name: 'Afficher',
      icon: 'search',
      click: (event: any, item: any) => {
        this.adjustmentDblClick(event, item);
      },
    },
  ];

  // public filterStocks: boolean = true;
  public adjustments: StockAdjustment[] = [];
  public adjustmentGridColumns = [
    { title: 'Stock', field: 'stock.number', width: 200 },
    { title: 'Date', field: 'date', type: 'date', width: 120 },
    { title: 'Ajustement', field: 'adjustmentWithUnit', width: 250 },
    { title: 'Article', field: 'article.designation', width: 550 },
    { title: 'Client', field: 'customer.nameWithIdentifier', width: 200 },
    { title: 'Fournisseur', field: 'supplier.name', width: 200 },
    { title: 'Commande', field: 'order.number', width: 200 },
    { title: 'Remarques', field: 'remarks', width: 550 },
  ];

  ngOnInit() {
    this.reloadAdjustments();
  }
  onAccountingChanged(accounting: Accounting) {
    this.reloadAdjustments();
  }
  onActivate() {
    this.reloadAdjustments();
  }

  reloadAdjustments() {
    let conditions: string = null;
    if (AccountingsService.currentAccounting) conditions = `id_accounting='${AccountingsService.currentAccounting.id}'`;
    StockAdjustment.load(null, ['~date'], null, false, conditions).then(
      (result) => {
        this.adjustments = result;
      },
      (err) => {
        console.error(err);
      }
    );
  }

  createAdjustment() {
    let adjustment: StockAdjustment = new StockAdjustment();
    adjustment.accounting = AccountingsService.currentAccounting;
    this.adjustments.push(adjustment);
    this.adjustmentDblClick(null, adjustment);
  }

  deleteAdjustment() {
    DialogsComponent.display({
      icon: 'warning',
      title: 'Supprimer les dossiers ?',
      message: 'Êtes-vous sûr(e) de vouloir supprimer les ajustements sélectionnés ?',
      buttons: DialogButton.yesNoButtons,
    }).then((result: any) => {
      if (result === DialogButton.RESULT_YES) {
        let adjustments: StockAdjustment[] = this.grid.selectedItems as StockAdjustment[];
        if (Array.isArray(adjustments) && adjustments.length > 0) {
          let promises: any[] = [];
          for (let i = 0; i < adjustments.length; ++i) promises.push(adjustments[i].moveToTrash());
          Promise.all(promises).then(
            (result) => {
              NotificationsComponent.push({
                type: 'success',
                title: 'Dossiers clients supprimés',
                summary: 'Les ajustements de stock ont été supprimées avec succès',
              });
              ArrayUtil.removeElements(this.adjustments, adjustments);
            },
            (err) => {
              NotificationsComponent.push({
                type: 'error',
                title: 'Erreur lors de la suppression',
                summary: "Une erreur s'est produite lors de la suppression",
                content: err,
              });
              console.error(err);
            }
          );
        }
      }
    });
  }

  adjustmentDblClick(event, adjustment) {
    ViewsComponent.openView(StockAdjustmentViewComponent, adjustment);
  }

  // archiveStocks()
  // {
  //     let stocks: Stock[] = this.grid.selectedItems as Stock[];
  //     if (Array.isArray(stocks) && stocks.length > 0)
  //     {
  //         let promises: any[] = [];
  //         for(let i=0; i<stocks.length; ++i) {
  //             stocks[i].archived = 1;
  //             promises.push(stocks[i].save2());
  //         }
  //         Promise.all(promises).then(
  //             (result) => {
  //                 NotificationsComponent.push({
  //                     type: 'success',
  //                     title: 'Stocks archivés',
  //                     summary: 'Les stocks ont été archivés avec succès'
  //                 });
  //                 this.grid.clearSelection();
  //                 // ArrayUtil.removeElements(this.orders, orders);
  //             },
  //             (err) => {
  //                 NotificationsComponent.push({
  //                     type: 'error',
  //                     title: 'Erreur lors de l\'archivage',
  //                     summary: 'Une erreur s\'est produite lors de l\'archivage des stocks',
  //                     content: err
  //                 });
  //                 console.error(err);
  //             }
  //         )
  //     }
  // }
  //
  // gridRowStyle(item: Stock)
  // {
  //     return (item && item.archived == 1) ? {
  //         'color': 'gray',
  //         'font-style': 'italic'
  //     } : null;
  // }
}
