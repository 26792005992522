import { Accounting } from 'src/app/modules/other-data/classes/Accounting.class';
import { ToolbarItem } from './ToolbarItem.class';
import { ToolbarMenuItem } from './ToolbarMenuItem.class';

export class Toolbar {
  public class?: string = null;
  public viewTitle?: string = null;
  public elements?: ToolbarItem[] = null;
  public data?: any = null;
  public showMenu?: boolean = false;
  public showAccountMenu?: boolean = false;
  public showAccountingMenu?: boolean = false;
  public interopComponentName?: string = null;
  public interopComponentProps?: any = null;

  public onPrevPage?: () => boolean = null;

  public static _menuItems: ToolbarMenuItem[] = [];

  public static getToolbarItem(toolbar: Toolbar, name: string) {
    if (!toolbar.elements) return null;
    for (let i = 0; i < toolbar.elements.length; ++i) {
      if (toolbar.elements[i].name == name) return toolbar.elements[i];
    }
  }

  public static registerMenuItem(item: ToolbarMenuItem) {
    Toolbar._menuItems.push(item);
  }
}
