import { HttpUtil } from "../../utils/classes/HttpUtil.class";
import { Observable } from "../../../../../node_modules/rxjs";

export class ExcelData
{
    public static serviceUrl: string = 'https://api.graphiseal.com/modules/excel/excel-to-json.php';

    public static loadExcelData(fileName: string): Observable<ArrayBuffer>
    {
        return HttpUtil.get(ExcelData.serviceUrl + '?file=' + encodeURIComponent(fileName));
    }
}