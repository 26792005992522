import { Accounting } from '../../other-data/classes/Accounting.class';
import { Customer } from '../../customers/classes/Customer.class';
import { Merchant } from '../../other-data/classes/Merchant.class';
import { ObjectDefinition } from '../../../classes/objects/ObjectDefinition.class';
import { ObjectModel3 } from 'src/app/classes/objects/ObjectModel3.class';

export class Goal extends ObjectModel3 {
  public accounting: Accounting = null;
  public merchant: Merchant = null;
  public customer: Customer = null;

  public year: number = new Date().getFullYear();
  public amounts: any = {};
  public margins: any = {};

  public get total(): number {
    return Object.keys(this.margins).reduce((prev, cur) => {
      return prev + (this.amounts[cur] || 0);
    }, 0);
  }
  public get totalRawMargin(): number {
    return Object.keys(this.margins).reduce((prev, cur) => {
      return prev + ((this.amounts[cur] || 0) * this.margins[cur]) / 100;
    }, 0);
  }
  public get totalNetMargin(): number {
    return this.merchant ? (this.totalRawMargin * (100 - this.merchant.margin)) / 100 : 0;
  }
  public get merchantAmount(): number {
    return this.merchant ? (this.totalRawMargin * this.merchant.margin) / 100 : 0;
  }
  public get calculatedMarkup(): number {
    return (100 * this.totalRawMargin) / this.total;
  }

  public percent(id: string): number {
    let perc: number = 0;
    if (this.amounts) perc = this.amounts[id] / this.total;
    return perc * 100;
  }

  public get customerCategoryName() {
    return this.customer && this.customer.category && this.customer.category.name;
  }

  constructor() {
    super(Goal);
  }

  /* ObjectModel FUNCTIONS */

  public static definition: ObjectDefinition = {
    trashDelete: true,
    database: {
      table: 'goals',
      id: 'id',
    },
    values: {
      year: { type: 'number' },
      amounts: { type: 'json' },
      margins: { type: 'json' },
      margin: { type: 'number' },
    },
    children: {
      accounting: { type: 'Accounting', clone: false, save: false, delete: false },
      merchant: { type: 'Merchant', clone: false, save: false, delete: false },
      customer: { type: 'Customer', clone: false, save: false, delete: false },
    },
    links: {},
  };
}
