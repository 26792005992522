<ul class="notifications-component">
    <li *ngFor="let notif of notifications" class="notification-item notitication-{{ notif.type }}"
        [ngStyle]="{'opacity': notif.alpha}" (mouseover)="keepOpen(notif)" (mouseout)="closeTimeout(notif)">
        <div class="notification-title" (click)="notif.expanded = !notif.expanded">{{ notif.title }}</div>
        <div class="notification-summary" [innerHTML]="notif.summary" (click)="notif.expanded = !notif.expanded"></div>
        <div class="notification-content" *ngIf="notif.content && notif.expanded" [innerHTML]="notif.content" [attr.title]="getText(notif)" (click)="notif.expanded = !notif.expanded"></div>
        <div class="notification-buttons">
            <div class="notification-expand-button" *ngIf="notif.content" (click)="notif.expanded = !notif.expanded"><i class="fa fa-{{ notif.expanded ? 'chevron-up' : 'chevron-down' }}"></i></div>
            <div class="notification-remove-button" (click)="removeNotification(notif)"><i class="fa fa-times"></i></div>
        </div>
    </li>
</ul>
