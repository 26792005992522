import { Page } from "../../printing/classes/Page.class";
import { Table } from "../../printing/classes/Table.class";
import { PrintableElement } from "../../printing/classes/PrintableElement.class";
import { PrintingModel } from "../../printing/classes/PrintingModel.class";
import { InfoBlock } from "../../../components/info-block/classes/InfoBlock.class";
import { DateTimeUtil } from "../../utils/classes/DateTimeUtil.class";
import { InfoBlockComponent } from "../../../components/info-block/info-block.component";
import { InfoBlockField } from "../../../components/info-block/classes/InfoBlockField.class";
import { Price } from "../../sales/classes/Price.class";
import { Incident } from "./Incident.class";
import { NumberUtil } from "../../utils/classes/NumberUtil.class";
import { Order } from "../../orders/classes/Order.class";
import { PrintingUtil } from "../../utils/classes/PrintingUtil.class";

export class IncidentPrintingModel extends PrintingModel
{
    public incident: Incident = null;
    public lang: string = '';
    public delivery_address: string = null;

    public static generate(incident: Incident): PrintingModel
    {
        let model: IncidentPrintingModel = new IncidentPrintingModel();
        model.incident = incident;
        return model.regenerate();
    }

    public regenerate(): PrintingModel
    {
        let lang: string = this.lang;
        if (!lang || lang == '')
        {
            if (this.incident.order.supplier.lang) lang = this.incident.order.supplier.lang.code;
            else lang = 'fr';
        }

        let incident: Incident = this.incident;
        let order: Order = this.incident.order;

        this.saveFilename = ("Fiche incident - Commande " + incident.order.number).replace(/\//g, '-') + '.pdf';
        this.mailFilename = {
            'fr': "Fiche incident - Commande " + incident.order.number,
            'en': "Issue report - Order " + incident.order.number,
            'nl': "Probleem verslag - Bestelling " + incident.order.number,
        }[lang].replace(/\//g, '-') + '.pdf';

        let pageWidth = 210;
        let pageHeight = 295;

        let pageLeftMargin = 10;
        let pageRightMargin = 10;
        let pageTopMargin = 10;
        let pageBottomMargin = 10;

        let quantities: string[] = [];
        let prices: string[] = [];
        let totals: string[] = [];

        // if (!this.delivery_address && order.delivery_address) this.delivery_address = order.delivery_address;
        // if (!this.billing_address && order.billing_address) this.billing_address = order.billing_address;
        // if (!this.send_address && order.send_address) this.send_address = order.send_address;

        this.pages = [];

        // for(let i=0; i<this.order.suppliers.length; ++i)
        // {

            let page: Page = {

                width: pageWidth,
                height: pageHeight,

                leftMargin: pageLeftMargin,
                rightMargin: pageRightMargin,
                topMargin: pageTopMargin,
                bottomMargin: pageBottomMargin,

                style: {
                    'font-family': 'Calibri',
                    'font-size': '10pt',
                    'width': (pageWidth - pageLeftMargin - pageRightMargin) + 'mm',
                    'height': (pageHeight - pageTopMargin - pageBottomMargin) + 'mm',
                    'margin-top': pageTopMargin + 'mm',
                    'margin-left': pageLeftMargin + 'mm',
                    'margin-right': pageRightMargin + 'mm',
                    'margin-bottom': pageBottomMargin + 'mm',
                    'line-height': '4mm'
                },

                headerSize: 33,
                headerElements: [
                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '1mm',
                            'width': '85mm',
                            'height': '17mm',
                            'border': '.5mm solid rgba(0, 176, 240, 1.0)',
                            'border-radius': '2.5mm',
                            'line-height': '4mm'
                        },
                        children: [
                            {
                                style: {
                                    'font-size': '20pt',
                                    'font-weight': 'bold',
                                    'text-align': 'center',
                                    'width': '100%',
                                    'line-height': '5mm',
                                    'margin-top': '1.5mm',
                                    'margin-bottom': '1mm',
                                    'vertical-align': 'top'
                                },
                                html: ({
                                    'fr': 'FICHE INCIDENT',
                                    'en': 'INCIDENT SHEET',
                                    'nl': 'FICHE INCIDENTEEL'
                                }[lang])
                            },
                            {
                                style: {
                                    'display': 'flex',
                                    'flex-direction': 'row'
                                },
                                children: [
                                    {
                                        style: {
                                            'width': '20%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'N° CDE :',
                                                'en': 'Ord. No :',
                                                'nl': 'Best. Nr :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '80%',
                                            'padding': '0 1mm',
                                            'font-weight': 'bold'
                                        },
                                        html: incident.order.number
                                    }
                                ]
                            },
                            {
                                style: {
                                    'display': 'flex',
                                    'flex-direction': 'row'
                                },
                                children: [
                                    {
                                        style: {
                                            'width': '20%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'Date :',
                                                'en': 'Date :',
                                                'nl': 'Datum :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '30%',
                                            'padding': '0 1mm'
                                        },
                                        html: DateTimeUtil.format(new Date(incident.date), 'd/m/Y')
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'text-align': 'right',
                                            'padding': '0 1mm'
                                        },
                                        html: ({
                                                'fr': 'N° fourn. :',
                                                'en': 'Suppl. No :',
                                                'nl': 'Lever. Nr :'
                                               } [lang])
                                    },
                                    {
                                        style: {
                                            'width': '25%',
                                            'padding': '0 1mm'
                                        },
                                        html: incident.order.supplier.number
                                    }
                                ]
                            },
                        ]
                    },
                    {
                        style: {
                            'position': 'absolute',
                            'left': '0mm',
                            'top': '0mm',
                            'width': '50mm',
                            'height': '30mm',
                            'background-image': 'url(assets/img/logo_documents.png)',
                            'background-position': 'center center',
                            'background-size': 'contain',
                            'background-repeat': 'no-repeat'
                        },
                        html: ''
                    }
                ],

                bodyElements: [
                    {
                        style: {
                            'text-decoration': 'underline',
                            'white-space': 'pre',
                            'margin-top': '10mm'
                        },
                        html: ({
                                'fr': '\nVotre interlocuteur :',
                                'en': '\nYour contact-person :',
                                'nl': '\nUw contact persoon :'
                               } [lang])
                    },
                    {
                        style: {
                            'white-space': 'pre',
                            'font-weight': 'bold'
                        },
                        html: incident.order.sale.merchant.name
                    },
                    {
                        style: {
                            'white-space': 'pre'
                        },
                        html: (this.incident.order.sale.merchant && this.incident.order.sale.merchant.email ? 'Mail : ' + this.incident.order.sale.merchant.email + '\n' : '') +
                            (this.incident.order.sale.merchant && this.incident.order.sale.merchant.phone ? ({
                                'fr': 'Portable : ',
                                'en': 'Mobile : ',
                                'nl': 'Mobiele : '
                            }[lang]) + this.incident.order.sale.merchant.phone + '\n' : '') +
                            ({
                                'fr': 'Téléphone : ',
                                'en': 'Phone : ',
                                'nl': 'Telefoon : '
                            }[lang]) + '+32 (0)4 380 06 05'
                    },



                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse',
                            'position': 'relative',
                            'margin-top': '3mm'
                            // 'flex': '1'
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'background-color': 'rgba(217,217,217,1)',
                                            'width': '50mm',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'right',
                                            'font-weight': 'bold'
                                        },
                                        html: ({
                                                'fr': 'Référence de notre dossier :',
                                                'en': 'Price quotation reference :',
                                                'nl': 'Verwijzing van de prijsofferte :'
                                            }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            // 'background-color': 'rgba(217,217,217,1)',
                                            'padding': '.5mm 1mm',
                                            'font-weight': 'bold'
                                        },
                                        html: incident.order.sale.numberWithVersion
                                    }
                                ]
                            }
                        ]
                    },



                    {
                        style: {
                            'position': 'absolute',
                            'right': '0mm',
                            'top': '0mm',
                            'width': '85mm',
                            'height': '25m'
                        },
                        children: [
                            {
                                style: {
                                    'white-space': 'pre',
                                    'font-weight': 'bold'
                                },
                                html: this.incident.order.supplier.name
                            },
                            {
                                style: {
                                    'white-space': 'pre',
                                },
                                html: this.incident.order.supplier.commercial_address.getPostalAddress()
                            }
                        ]
                    },

                    {
                        style: {
                            // 'display': 'flex',
                            'width': '100%',
                            // 'flex-direction': 'row'
                        },
                        children: [

                            {
                                style: {
                                    'display': 'table',
                                    'width': '100%',
                                    'border-collapse': 'collapse',
                                    'position': 'relative',
                                    'margin-top': '3mm'
                                    // 'flex': '1'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-row'
                                        },
                                        children: [
                                            {
                                                style: {
                                                    'display': 'table-cell',
                                                    'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                                    'background-color': 'rgba(217,217,217,1)',
                                                    'width': '50mm',
                                                    'padding': '.5mm 1mm',
                                                    'text-align': 'right',
                                                    'font-weight': 'bold'
                                                },
                                                html: ({
                                                        'fr': 'Désignation',
                                                        'en': 'Specifications',
                                                        'nl': 'Specificaties'
                                                    }[lang])
                                            },
                                            {
                                                style: {
                                                    'display': 'table-cell',
                                                    'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                                    'background-color': 'rgba(217,217,217,1)',
                                                    'padding': '.5mm 1mm',
                                                    'font-weight': 'bold'
                                                },
                                                html: incident.order.sale.article.designation
                                            }
                                        ]
                                    }
                                ].concat(this.getArticleLines(incident, lang))
                            },



                            {
                                style: {
                                    'display': 'table',
                                    'width': '100%',
                                    'border-collapse': 'collapse',
                                    'position': 'relative',
                                    'margin-top': '3mm'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-row'
                                        },
                                        children: [
                                            {
                                                style: {
                                                    'display': 'table-cell',
                                                    'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                                    'width': '50mm',
                                                    'padding': '.5mm 1mm',
                                                    'text-align': 'right',
                                                    'white-space': 'pre',
                                                    // 'line-height': '5mm',
                                                },
                                                html: ({
                                                        'fr': 'Quantité et unité défectueuse :\nPrix d\'achat à l\'unité\nMontant estimé du litige :',
                                                        'nl': 'Hoeveel. en de onvolko. eenheid :\nDe aankoopprijs aan de eenheid :\nHet geschat. bedrag van het ges. :',
                                                        'en': 'Quantity and defective unit :\nPurchase price per unit :\nIncident estimated amount :'
                                                    })[lang]
                                            },
                                            {
                                                style: {
                                                    'display': 'table-cell',
                                                    'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                                    'padding': '.5mm 1mm',
                                                    'white-space': 'pre',
                                                    // 'line-height': '5mm',
                                                },
                                                html: [
                                                    incident.quantityAndUnit,
                                                    NumberUtil.formatMoney(incident.order.buy_price, '€ H.T.V.A.', 5, '.'),
                                                    NumberUtil.formatMoney(incident.amount, '€ H.T.V.A.', 2, '.')
                                                ].join('\n')
                                            }
                                        ]
                                    }
                                ]
                            }

                        ]
                    },

                    {
                        style: {
                            'clear': 'both'
                        }
                    },

                    {
                        style: {
                            'display': 'block',
                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                            'background-color': 'rgba(0, 176, 240, 1.0)',
                            'width': '100%',
                            'padding': '.5mm 1mm',
                            'text-align': 'center',
                            // 'line-height': '5mm',
                            'color': 'rgba(255,255,255,1)',
                            'font-weight': 'bold',
                            'margin-top': '3mm'
                        },
                        html: ({
                            'fr': 'NATURE DU LITIGE',
                            'en': 'INCIDENT DESCRIPTION - IMPORTANT REMARK',
                            'nl': 'BELANGERIJKE OPMERKINGEN - AARD VAN HET GESCHIL'
                        }[lang])
                    },
                    {
                        style: {
                            'display': 'block',
                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                            'width': '100%',
                            'padding': '.5mm 1mm',
                            // 'line-height': '4mm',
                            'white-space': 'pre-wrap',
                            'flex': '1',
                            'vertical-align': 'top'
                        },
                        html: incident.description
                    },

                ],

                footerSize: 63,
                footerElements: [
                    {
                        style: {
                            'display': 'table',
                            'width': '100%',
                            'border-collapse': 'collapse',
                            'margin-top': '3mm'
                        },
                        children: [
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'background-color': 'rgba(217, 217, 217, 1.0)',
                                            'width': '40%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'font-weight': 'bold'
                                        },
                                        html: ({
                                            'fr': 'ADRESSE DE LIVRAISON',
                                            'en': 'DELIVERY ADDRESS',
                                            'nl': 'LEVERINGS ADRES'
                                        }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'background-color': 'rgba(217, 217, 217, 1.0)',
                                            'width': '40%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'font-weight': 'bold'
                                        },
                                        html: ({
                                            'fr': 'ADRESSE DE FACTURATION',
                                            'en': 'BILLING ADDRESS',
                                            'nl': 'FACTURATIE ADRES'
                                        }[lang])
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'background-color': 'rgba(217, 217, 217, 1.0)',
                                            'width': '20%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'font-weight': 'bold'
                                        },
                                        html: ({
                                            'fr': 'NOM ET SIGNATURE',
                                            'en': 'NAME AND SIGNATURE',
                                            'nl': 'HANDTEKENING'
                                        }[lang])
                                    }
                                ]
                            },
                            {
                                style: {
                                    'display': 'table-row'
                                },
                                children: [
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'width': '40%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'white-space': 'pre',
                                            'vertical-align': 'top'
                                        },
                                        children: PrintingUtil.formatAddress(incident.customer.name, order.delivery_contact, order.delivery_address_text, null, null, lang)
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'width': '40%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'white-space': 'pre',
                                            'vertical-align': 'top'
                                        },
                                        children: PrintingUtil.formatAddress(incident.customer.name, order.invoicing_contact, order.invoicing_address_text, null, null, lang)
                                    },
                                    {
                                        style: {
                                            'display': 'table-cell',
                                            'border': '.25mm solid rgba(0, 176, 240, 1.0)',
                                            'width': '20%',
                                            'padding': '.5mm 1mm',
                                            'text-align': 'center',
                                            // 'line-height': '5mm',
                                            'white-space': 'pre',
                                            'position': 'relative',
                                            'vertical-align': 'top'
                                        },
                                        children: [
                                            {
                                                style: {
                                                    'text-align': 'center',
                                                    'white-space': 'pre'
                                                },
                                                html: ({
                                                    'fr': '\n\n\n\nLe service commercial',
                                                    'en': '\n\n\n\nCommercial department',
                                                    'nl': '\n\n\n\nCommercieele dienst'
                                                }[lang])
                                            },
                                            {
                                                style: {
                                                    'font-weight': 'bold',
                                                    'text-align': 'center'
                                                },
                                                html: "Christophe Toupy"
                                            },
                                            {
                                                style: {
                                                    'position': 'absolute',
                                                    'left': '0mm',
                                                    'top': '0mm',
                                                    'width': '34mm',
                                                    'height': '25mm',
                                                    'background-image': 'url(assets/img/signature_blue.png)',
                                                    'background-size': 'contain'
                                                }
                                            }
                                        ]
                                    }
                                ]
                            },
                        ]
                    },

                    PrintingUtil.getFooter(this.incident.accounting, lang)
                ]

            };

            this.pages.push(page);

        //}
        return this;
    }

    public getArticleLines(incident: Incident, lang: string)
    {
        let headers: any[] = PrintingUtil.getArticleHeaders(incident.article, lang);
        let values: any[] = PrintingUtil.getArticleValues(incident.article);
        let arr: any[] = [];
        for(let i=0; i<headers.length; ++i)
        {
            arr.push({
                style: {
                    'display': 'table-row'
                },
                children: [
                    {
                        style: {
                            'display': 'table-cell',
                            'border-left': '.25mm solid rgba(0, 176, 240, 1.0)',
                            'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                            'width': '50mm',
                            'padding': '.5mm 1mm',
                            'text-align': 'right',
                            'white-space': 'pre-wrap',
                            'line-height': '4mm',
                            'vertical-align': 'top',
                            'padding-left': '1mm',
                            'padding-top': '0',
                            'padding-right': '1mm',
                            'padding-bottom': '0'
                        },
                        html: headers[i]
                    },
                    {
                        style: {
                            'display': 'table-cell',
                            'border-right': '.25mm solid rgba(0, 176, 240, 1.0)',
                            'padding': '.5mm 1mm',
                            'white-space': 'pre-wrap',
                            'line-height': '4mm',
                            'vertical-align': 'top',
                            'padding-left': '1mm',
                            'padding-top': '0',
                            'padding-right': '1mm',
                            'padding-bottom': '0'
                        },
                        html: values[i]
                    }
                ]
            });
        }
        if (arr.length > 0)
        {
            arr[arr.length-1].children[0].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
            arr[arr.length-1].children[1].style['border-bottom'] = '.25mm solid rgba(0, 176, 240, 1.0)';
        }
        return arr;
    }
}
